import { getInstancesURL } from '../../../app.urls';
import { isEmpty } from '../../../Core';

// export const setSelectedRows = (rowData, accumulator, selectedRowIds) => {
//   if (accumulator) {
//     if (
//       !selectedRowIds.includes(rowData.id) &&
//       !accumulator.find(a => a.id === rowData.id)
//     ) {
//       accumulator.push({
//         id: rowData.id,
//         name: rowData.name
//       });
//     }
//   }
// };

export const picklistOptionsToReferenceValues = picklistOptions => {
  return picklistOptions.map(options => {
    return {
      id: options.value,
      name: options.label
    };
  });
};

export const getIndices = (selectedInstances, instanceList) => {
  if (isEmpty(selectedInstances) || isEmpty(instanceList)) {
    return [];
  }

  let indices = [];

  selectedInstances.forEach(instance => {
    let index = instanceList.findIndex(
      i => i.id === instance.relatedInstanceId
    );
    if (index !== -1) {
      indices.push(index);
    }
  });

  return indices;
};

export const getRelatedInstanceEndpoints = (
  relatedTypeName,
  skip,
  take,
  stateFilter,
  attributeFilter
) => {
  return {
    url: getInstancesURL,
    params: {
      instanceTypeName: relatedTypeName,
      getOnlyConnectableInstances: true,
      skip,
      take,
      stateFilter,
      ...attributeFilter
    }
  };
};

export const getUpdatedList = (originalSelection, currentSelectedInst) => {
  if (currentSelectedInst) {
    let newSelection = [];
    let currentSelectionValues = currentSelectedInst.map(cs => {
      return cs.value;
    });
    originalSelection.map(selec => {
      if (currentSelectionValues.includes(selec.id)) {
        newSelection.push(selec);
      }
    });

    return newSelection;
  } else {
    return null;
  }
};
