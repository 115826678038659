import React, { useState } from 'react';
import classnames from 'classnames';
import classes from './Relation.module.css';
import { CreateAndConnectPopup } from './CreateAndConnectPopup';

const noop = () => {};

export const CreateAndLinkRelation = props => {
  const {
    typeSingularName,
    isDisabled,
    saveSuccessCallback,
    isPopupVisible = noop
  } = props;
  const [showCreateLayoutPopup, viewCreateLayoutPopup] = useState(false);

  return (
    <>
      <div
        className={classnames(classes['white-btn'], classes['link-btn'], {
          'opacity-light': isDisabled,
          'pointer-event-none': isDisabled,
          'pointer-event-auto': !isDisabled
        })}
        onClick={() => {
          viewCreateLayoutPopup(true);
          isPopupVisible(true);
        }}
      >
        <i className="fas fa-plus" /> Add {typeSingularName}
      </div>

      {showCreateLayoutPopup ? (
        <CreateAndConnectPopup
          {...props}
          onClose={() => {
            viewCreateLayoutPopup(false);
            isPopupVisible(false);
          }}
          saveSuccessCallback={() => {
            saveSuccessCallback();
            viewCreateLayoutPopup(false);
            isPopupVisible(false);
          }}
        />
      ) : null}
    </>
  );
};
