import React from 'react';
import { Button, ButtonEnums } from '../../../lib/components';

export const UndoIcon = ({ onUndoClicked, isDisabled }) => {
  return (
    <Button
      title="Cancel"
      appearance={ButtonEnums.Appearance.Primary}
      disabled={isDisabled}
      size={ButtonEnums.Size.Small}
      onClick={onUndoClicked}
    >
      <i className="fa fa-undo" />
    </Button>
  );
};
