import { getDashboardConfiguredAttributes } from './instanceQuery';
import { usePaginatedGet } from '../utils';

const PAGE_SIZE = 10;

const instanceBase = '/instances';

export function useVersion(instanceId, columns, pageSize = PAGE_SIZE) {
  const {
    state,
    reload,
    nextPage,
    paginationState,
    fetchItems
  } = usePaginatedGet({
    requestConfig: fetchVersionsInfo,
    instanceId,
    columns,
    skip: 0,
    take: pageSize
  });

  return { state, fetchItems, reload, nextPage, paginationState };
}

const fetchVersionsInfo = ({ instanceId, columns, skip, take }) => {
  const attributes = getDashboardConfiguredAttributes(columns);
  let versionEndPoint = getInstanceVersionsEndpoint(instanceId, attributes);

  return {
    endpoint: versionEndPoint.url,
    config: {
      params: { attributes: attributes, skip, take }
    }
  };
};

const getInstanceVersions = instanceId => {
  return `${instanceBase}/${instanceId}/versions`;
};

const getInstanceVersionsEndpoint = (instanceId, attributes, skip, take) => {
  return {
    url: getInstanceVersions(instanceId),
    params: {
      attributes: attributes,
      skip: skip,
      take: take
    }
  };
};
