import React from 'react';
import {
  Button,
  ButtonEnums,
  Box,
  VerticalSeparator
} from '../../../lib/components';
import Icon from '../../../lib/components/Icon';
import { paginate } from './Pagination';

export const DataGridPaginationHeader = ({
  paginationContext,
  onActionPerformed,
  totalRecordCount,
  isLoading
}) => {
  const { skip, take } = paginationContext;

  const getPageNumber = () => {
    return Math.ceil((skip + take) / take);
  };

  const canNextPage = !(skip + take >= totalRecordCount);
  const canPreviousPage = !(skip <= 0);

  const nextPage = () => {
    const nextPageNo = getPageNumber() + 1;
    const { startIndex } = paginate(totalRecordCount, nextPageNo, take);
    onActionPerformed(startIndex, take);
  };

  const previousPage = () => {
    const nextPageNo = getPageNumber() - 1;
    const { startIndex } = paginate(totalRecordCount, nextPageNo, take);
    onActionPerformed(startIndex, take);
  };

  return (
    <DataGridPaginationButton
      previousPage={previousPage}
      nextPage={nextPage}
      isPreviousDisabled={!canPreviousPage || isLoading}
      isNextDisabled={!canNextPage || isLoading}
    />
  );
};

export const DataGridPaginationButton = ({
  previousPage,
  nextPage,
  isPreviousDisabled,
  isNextDisabled
}) => {
  return (
    <Box
      justifyContent="center"
      alignItems="center"
      border="1px solid var(--primary-lighter)"
      height="2rem"
      padding="2px 0"
      marginLeft="5px"
    >
      <Button
        appearance={ButtonEnums.Appearance.TransparentWithHover}
        onClick={previousPage}
        disabled={isPreviousDisabled}
        size={ButtonEnums.Size.Medium}
        style={{ height: '2rem' }}
      >
        <Icon iconClasses={['fa', 'fa-chevron-left']} fontSize="1.3rem" />
      </Button>
      <VerticalSeparator height="100%" margin="0px" padding="0px" />
      <Button
        appearance={ButtonEnums.Appearance.TransparentWithHover}
        onClick={nextPage}
        disabled={isNextDisabled}
        size={ButtonEnums.Size.Medium}
        style={{ height: '2rem' }}
      >
        <Icon iconClasses={['fa', 'fa-chevron-right']} fontSize="1.3rem" />
      </Button>
    </Box>
  );
};
