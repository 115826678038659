import { useAppInsightsContext } from '../../Contexts/AppInsightsContext';
import { ErrorBoundary } from './ErrorBoundary';

export function ErrorBoundaryWithAppInsights({ children, customWidgetName }) {
  const { trackException: appInsightsTrackException } = useAppInsightsContext();

  const trackException = (error) => {
    appInsightsTrackException(error, document.title, customWidgetName);
  };

  return <ErrorBoundary trackException={trackException} children={children} />;
}
