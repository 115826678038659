const { Enums } = require('../../../Enums');
const { Button, ButtonEnums } = require('../../../lib/components');

export const RenderActionsOverflowIcon = ({ actions }) => {
  let secondaryActions = getSecondaryActions(actions);
  return actions.length > 0 && secondaryActions.length > 0 ? (
    <Button
      appearance={ButtonEnums.Appearance.Primary}
      style={{
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
        color: 'var(--surface)',
        borderLeft: ' 1px solid var(--surface)',
        width: '20px'
      }}
      size={ButtonEnums.Size.Medium}
    >
      <span style={{ lineHeight: '1.5rem', fontSize: '1rem' }}>
        <i className="fas fa-angle-down" />
      </span>
    </Button>
  ) : null;
};

export function getSecondaryActions(actions) {
  let secondaryActions = [];

  if (actions && actions.length > 0) {
    const { actionType, actionsData } = actions && actions[0];

    if (actionType === Enums.AllowedOperations.Promote) {
      if (
        actionsData &&
        actionsData.allowedActions &&
        actionsData.allowedActions.length > 1
      ) {
        let promoteActions = actionsData && actionsData.allowedActions.slice(1);
        let promoteActionData = {
          actionType: Enums.AllowedOperations.Promote,
          actionsData: {
            allowedActions: promoteActions
          }
        };
        secondaryActions.push(promoteActionData);
        if (actions.length > 1) {
          let slicedData = actions && actions.slice(1);
          secondaryActions.push(...slicedData);
        }
      } else if (
        actionsData &&
        actionsData.allowedActions &&
        actionsData.allowedActions.length === 1
      ) {
        secondaryActions =
          actions.length > 1 ? actions && actions.slice(1) : [];
      }
    } else {
      secondaryActions = actions.length > 1 ? actions && actions.slice(1) : [];
    }
  }
  return secondaryActions;
}
