import React from 'react';
import { TextBox, Box } from '../../lib/components';
import { Attribute } from '../../shared/Components';

export const renderFirstValueField = (
  field,
  value,
  onEdit,
  condition,
  firstFieldErrorMessage,
  isFirstFieldDisabled
) => {
  if (isFirstFieldDisabled) {
    return (
      <Box height="30px">
        <TextBox value={''} isDisabled={true} />
      </Box>
    );
  } else if (field && field.attributeType === 'Reference') {
    let config = { ...field };
    if (condition === 'In') {
      config.isMultiselect = true;
    } else {
      config.isMultiselect = false;
    }
    let attribute = Attribute(config);
    return attribute.renderFilterInput(value, onEdit, firstFieldErrorMessage);
  } else if (field && field.attributeType === 'RichText') {
    let config = { ...field };

    let attribute = Attribute(config);
    return attribute.renderFilterInput(value, onEdit, firstFieldErrorMessage);
  } else if (field && condition === 'In') {
    let config = { ...field };

    let attribute = Attribute(config);
    return attribute.renderFilterInput(value, onEdit, firstFieldErrorMessage);
  } else if (field && field.attributeType === 'Picklist') {
    let config = { ...field };

    let attribute = Attribute(config);
    return attribute.renderFilterInput(value, onEdit, firstFieldErrorMessage);
  } else if (field) {
    let config = { ...field };
    if (field.attributeType === 'User') {
      config.isMultiselect = false;
    }
    let attribute = Attribute(config);
    return attribute.renderInput(value, onEdit, firstFieldErrorMessage);
  } else {
    return (
      <Box height="30px">
        <TextBox value={value} onEdit={onEdit} />
      </Box>
    );
  }
};

export const renderSecondValueField = ({
  field,
  value,
  onEdit,
  secondFieldErrorMessage
}) => {
  let attribute = Attribute(field);
  return attribute.renderFilterInput(
    value,
    onEdit,
    secondFieldErrorMessage,
    false,
    true
  );
};

export const getDashboardAttributesOptions = fields => {
  let dashboardAttributesOptions = [];

  fields.forEach(field => {
    if (
      field.attributeType !== 'Attachment' &&
      field.attributeType !== 'Expression'
    ) {
      dashboardAttributesOptions.push({
        name: field.attributeName,
        displayName: field.label
      });
    }
  });

  return dashboardAttributesOptions;
};
