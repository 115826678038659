import { client } from "./client";

export const uploadBlob = async ({ fileValue, fileUrl, defaultBlockSize = 262144, timeout = 60000 }) => {

    const state = initializeState({ file: fileValue, fileUrl, defaultBlockSize, timeout });
    const reader = new FileReader();
    reader.onloadend = function (evt) {
        if (evt.target.readyState === FileReader.DONE && !state.cancelled) {
            uploadBlock(evt.target.result, state, reader);
        }
    };

    return new Promise((resolve, reject) => {
        state.resolve = resolve;
        state.reject = reject;
        uploadFileInBlocks(reader, state);
    });
};

const uploadBlock = (resultArray, state, reader) => {
    const uri = state.fileUrl + '&comp=block&blockid=' + state.blockIds[state.blockIds.length - 1] + '&timeout=' + state.timeout + '&chunkNumber=' + (state.blockIds.length - 1);
    const requestData = new global.Uint8Array(resultArray);

    client(uri, {
        data: requestData,
        processData: false,
        method: 'PUT',
        headers: {
            'Content-Type': 'application/octet-stream',
            Accept: 'application/vnd.api+json',
            'x-ms-blob-type': 'BlockBlob',
            'Content-Disposition': 'file; filename="' + state.file.name + '"',
            'x-ms-blob-content-disposition': 'attachment; filename="' + state.file.name + '"'
        }
    }).then(() => {
        state.bytesUploaded += requestData.length;
        uploadFileInBlocks(reader, state);
    }).catch(error => {
        state.reject(error);
    });
};

const uploadFileInBlocks = (reader, state) => {
    if (!state.cancelled) {
        if (state.totalBytesRemaining > 0) {

            const fileContent = state.file.slice(state.currentFilePointer, state.currentFilePointer + state.maxBlockSize);
            const blockId = state.blockIdPrefix + pad(state.blockIds.length, 6);

            state.blockIds.push(window.btoa(blockId));
            reader.readAsArrayBuffer(fileContent);

            state.currentFilePointer += state.maxBlockSize;
            state.totalBytesRemaining -= state.maxBlockSize;
            if (state.totalBytesRemaining < state.maxBlockSize) {
                state.maxBlockSize = state.totalBytesRemaining;
            }
        } else {
            commitBlockList(state);
        }
    }
};

const commitBlockList = async (state) => {

    const uri = state.fileUrl + '&comp=blocklist';

    let requestBody = '<?xml version="1.0" encoding="utf-8"?><BlockList>';
    for (let i = 0; i < state.blockIds.length; i++) {
        requestBody += '<Latest>' + state.blockIds[i] + '</Latest>';
    }
    requestBody += '</BlockList>';

    let response = await client(uri, {
        data: requestBody,
        timeout: state.timeout,
        processData: false,
        method: 'PUT',
        headers: {
            'Content-Type': 'application/octet-stream',
            Accept: 'application/vnd.api+json',
            'Content-Disposition': 'file; filename="' + state.file.name + '"',
            'x-ms-blob-content-disposition': 'attachment; filename="' + state.file.name + '"'
        }
    }).then(() => {
        state.resolve(response);
    }).catch(error => {
        state.reject(error);
    });
};

//A valid Base64 string value that identifies the block.
//Prior to encoding, the string must be less than or equal to 64 bytes in size.
//For a given blob, the length of the value specified for the blockid parameter must be the same size for each block.
const pad = (number, length) => {
    let str = '' + number;
    while (str.length < length) {
        str = '0' + str;
    }
    return str;
};

const initializeState = config => {
    let blockSize = getBlockSize(config);
    if (config.blockSize) blockSize = config.blockSize;

    let maxBlockSize = blockSize; // Default Block Size
    let numberOfBlocks = 1;

    const file = config.file;

    const fileSize = file.size;
    if (fileSize < blockSize) {
        maxBlockSize = fileSize;
    }

    if (fileSize % maxBlockSize === 0) {
        numberOfBlocks = fileSize / maxBlockSize;
    } else {
        numberOfBlocks = parseInt(fileSize / maxBlockSize, 10) + 1;
    }

    return {
        timeout: getTimeout(config),
        maxBlockSize: maxBlockSize, //Each file will be split in 256 KB.
        numberOfBlocks: numberOfBlocks,
        totalBytesRemaining: fileSize,
        currentFilePointer: 0,
        blockIds: [],
        blockIdPrefix: 'block-',
        bytesUploaded: 0,
        file: file,
        fileUrl: config.fileUrl,
        error: ''
    };
};

const getBlockSize = config => {
    return config && config.defaultBlockSize ? config.defaultBlockSize : Number(process.env.REACT_APP_FILE_UPLOAD_BLOCK_SIZE);
};

const getTimeout = config => {
    return config && config.timeout ? config.timeout : process.env.REACT_APP_PROTRAK_API_FILE_UPLOAD_TIMEOUT;
};
