import { useProtrakApi, protrakApiClient } from '../utils';

const PROGRAMS = 'programs';
const PROGRAMS_BY_TYPE = `${PROGRAMS}/programType/{0}`;

export const getProgramsByType = async ({
  programType,
  authContext,
  inputValue = ''
}) => {
  const url = PROGRAMS_BY_TYPE.replace('{0}', programType);
  try {
    const res = await protrakApiClient(url, {}, authContext);
    return res.data;
  } catch (error) {
    throw error;
  }
};

const programsByTypePromise = ({ programType }) => {
  const url = PROGRAMS_BY_TYPE.replace('{0}', programType);
  return {
    endpoint: url,
    config: {
      method: 'GET'
    }
  };
};

export function useProgramsByType(programType, defer = true) {
  const { state, run } = useProtrakApi(
    { requestConfig: programsByTypePromise, programType },
    { defer: defer }
  );
  return { state, run };
}

export function useProgramsByProgramType(programType) {
  const { state, run } = useProtrakApi(
    { requestConfig: programsByTypePromise, programType },

    { defer: true }
  );

  return { state, run };
}
