export const getNavigatorLanguage = () => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0];
  } else {
    return (
      navigator.userLanguage ||
      navigator.language ||
      navigator.browserLanguage ||
      'en'
    );
  }
};

export const convertToLocaleString = (value, locale) => {
  if (!value) { return value; }
  return value.toLocaleString(locale ? locale.name : getNavigatorLanguage(), {
    maximumFractionDigits: 2
  });
};

export const isNumber = val => {
  return !isNaN(parseFloat(val)) && typeof parseFloat(val) === 'number';
};
