import React from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { useDeviceType, DeviceTypes } from '../../UseDeviceType';
import { GlobalStyle } from '../../GlobalStyle';
import { createTheme } from '@mui/material';

export let theme = {};
export const AppThemeProvider = ({
  children,
  colorPalette,
  typography = {}
}) => {
  const currentDeviceType = useDeviceType();
  const defaultColorPalette = {
    PRIMARY: 'Black',
    SECONDARY: 'Blue'
  };

  theme = {};
  if (
    currentDeviceType === DeviceTypes.mobile ||
    currentDeviceType === DeviceTypes.tablet
  ) {
    theme = mobileTheme(colorPalette ? colorPalette : defaultColorPalette);
  } else {
    theme = desktopTheme(colorPalette ? colorPalette : defaultColorPalette);
  }
  theme.colorPalette = colorPalette ? colorPalette : defaultColorPalette;
  theme.typography = typography;

  const customTheme = createTheme(muiTheme());

  return (
    <MuiThemeProvider theme={{ ...theme, ...customTheme }}>
      <GlobalStyle typography={typography} />
      {children}
    </MuiThemeProvider>
  );
};

const muiTheme = () => {
  return {
    typography: {
      fontFamily: theme.typography.baseFontFamily,
      fontSize: 14
    },
    palette: {
      primary: {
        main: '#7719aa', // var(--secondary)
        light: '#a137da', // var( --secondary-light)
        dark: '#4f0875' //var(--secondary-dark)
      },
      secondary: {
        main: '#fff', // var(--surface)
        light: '#fafafa', //var(--primary-light)
        dark: 'rgba(78,78,78,0.22)' //var(--primary-lighter)
      },
      error: {
        main: '#ed1c24' //var(--error)
      },
      success: {
        main: '#05c12e' //var(--success)
      },
      text: {
        primary: '#34383c', // var(--primary-dark)
        secondary: '#1e2226', // var(--primary)
        light: '#818181', //var(--font-color-light)
        dark: ' #6c6c6c' //var(--font-color-dark)
      },
      disabled: {
        main: '#f1f1f1',
        secondary: '#b5c9e1',
        light:'#f7f7f7'
      }
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: '3px',
            '&:hover fieldset': {
              borderColor: 'var(--secondary)!important'
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderWidth: '1px',
              borderColor: 'var(--secondary)!important'
            }
          },
          input: {
            padding: '6px'
          }
        }
      }
    }
  };
};

const mobileTheme = colorPalette => {
  return {
    fontVariants: {
      h1: {
        size: '1.833‬rem',
        color: colorPalette.PRIMARY_DARK,
        lineHeight: '2.75rem',
        letterSpacing: '0.01rem'
      },
      h2: {
        size: '1.333rem',
        color: colorPalette.PRIMARY_DARK,
        lineHeight: '2rem',
        letterSpacing: '0.01rem'
      },
      h3: {
        size: '1.167rem',
        color: colorPalette.PRIMARY_DARK,
        lineHeight: '1.75rem',
        letterSpacing: '0.01rem'
      },
      h4: {
        size: '1rem',
        color: colorPalette.PRIMARY_DARK,
        lineHeight: '1.5rem',
        letterSpacing: '0.01rem'
      },
      h5: {
        size: '1rem',
        color: colorPalette.PRIMARY_DARK,
        hoverColor: colorPalette.PRIMARY_DARK,
        lineHeight: '1.5rem',
        letterSpacing: '0.01rem'
      },
      text: {
        size: '1rem',
        lineHeight: '1.4285714285714286rem',
        color: colorPalette.PRIMARY_DARK,
        weight: 'normal',
        fontStyle: 'normal'
      },
      caption: {
        size: '0.75rem',
        lineHeight: '1.083rem',
        color: colorPalette.PRIMARY_DARK,
        fontWeight: '500'
      },
      xsmall: {
        size: '0.75rem',
        lineHeight: '1.083rem'
      },
      label: {
        size: '0.9167rem',
        lineHeight: '1.333rem',
        paddingBottom: '6px'
      }
    }
  };
};

const desktopTheme = colorPalette => {
  return {
    fontVariants: {
      h1: {
        size: '2rem',
        color: colorPalette.PRIMARY_DARK,
        lineHeight: '3rem',
        letterSpacing: '0.01rem'
      },
      h2: {
        size: '1.5rem',
        color: colorPalette.PRIMARY_DARK,
        lineHeight: '2.25rem',
        letterSpacing: '0.01rem'
      },
      h3: {
        size: ' 1.3rem',
        color: colorPalette.PRIMARY_DARK,
        lineHeight: '2rem',
        letterSpacing: '0.01rem'
      },
      h4: {
        size: '1.167rem',
        color: colorPalette.PRIMARY_DARK,
        lineHeight: '1.75rem',
        letterSpacing: '0.01rem'
      },
      h5: {
        size: '1rem',
        color: colorPalette.PRIMARY_DARK,
        lineHeight: '1.5rem',
        letterSpacing: '0.01rem'
      },
      text: {
        size: '1.1rem',
        lineHeight: '1.65rem',
        color: colorPalette.PRIMARY_DARK,
        weight: 'normal',
        fontStyle: 'normal'
      },
      caption: {
        size: '0.8333rem',
        lineHeight: '1.25rem',
        color: colorPalette.PRIMARY_DARK,
        fontWeight: '500'
      },
      xsmall: {
        size: '0.75rem',
        lineHeight: '1.083rem'
      },
      label: {
        size: '0.9167rem',
        lineHeight: '1.333rem',
        paddingBottom: '6px',
        letterSpacing: '0.01rem'
      }
    }
  };
};
