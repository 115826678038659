import React from 'react';

import AsyncSelect from 'react-select/async';
import { customStyles } from './PicklistRenderStyling';

export const AsyncDropdown = ({
  value,
  isMultiselect,
  onValueChange,
  defaultOptions,
  loadOptions,
  isDisabled,
  placeholder,
  style = customStyles,
  noOptionsMessage = () => 'Please type user name',
  menuPlacement = 'auto'
}) => {
  const getPlaceholder = () => {
    if (placeholder !== null) {
      return placeholder;
    }
    if (isMultiselect) {
      return 'Select some options';
    }
    return 'Select an option';
  };

  return (
    <AsyncSelect
      menuPortalTarget={document.body}
      menuPlacement={menuPlacement}
      value={value}
      isMulti={isMultiselect}
      styles={style}
      onChange={onValueChange}
      placeholder={getPlaceholder()}
      defaultOptions={defaultOptions}
      loadOptions={loadOptions}
      isClearable={true}
      noOptionsMessage={noOptionsMessage}
      cacheOptions
      isDisabled={isDisabled}
    />
  );
};

export default AsyncDropdown;
