export function paginatedReducer(state, action) {
  if (!action || !action.type) {
    throw new Error('Invalid action passed, action.type value not available');
  }
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isLoading: true,
        isError: false,
        isFulfilled: false
      };
    case 'FETCH_SUCCESS':
      let initalData = state.data || [];
      let paginateditems =
        action.payload && action.payload.skip > 0
          ? [...initalData.items, ...action.payload.items]
          : action.payload.items;
      let paginatedData = { ...action.payload, items: paginateditems };
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: paginatedData,
        isFulfilled: true
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        isLoading: false,
        isFulfilled: false,
        isError: true,
        data: action.payload
      };
    default:
      throw new Error('Invalid action passed');
  }
}
