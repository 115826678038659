import React, { useContext } from 'react';

export const SettingsContext = React.createContext();

export const useSettingsContext = () => {
  const context = useContext(SettingsContext);
  if (context === undefined) {
    throw new Error(
      'must be used as a child of an TenantSettingsContextProvider'
    );
  }
  return context;
};