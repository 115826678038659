function evaluateStyleModelFromString(styleString) {
  let newStyleObject = {};

  const tokens =
    styleString && styleString !== '' ? styleString.split(',') : [];
  tokens.forEach(token => {
    if (token === '') {
      return;
    }

    const cssMetadata = token.split(':');
    if (cssMetadata.length > 2 || cssMetadata.length < 2) {
      throw new Error('Invalid CSS value');
    }

    const cssProperty = cssMetadata[0];
    const cssValue = cssMetadata[1];
    switch (cssProperty) {
      case 'backgroundColor':
        newStyleObject = { ...newStyleObject, backgroundColor: cssValue };
        return;

      case 'color':
        newStyleObject = { ...newStyleObject, color: cssValue };
        return;

      case 'fontWeight':
        newStyleObject = { ...newStyleObject, fontWeight: cssValue };
        return;

      case 'fontStyle':
        newStyleObject = { ...newStyleObject, fontStyle: cssValue };
        return;

      case 'textDecorationLine':
        newStyleObject = { ...newStyleObject, textDecorationLine: cssValue };
        return;

      default:
        return;
    }
  });

  return newStyleObject;
}

export { evaluateStyleModelFromString };
