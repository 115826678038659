export function paginate(totalItems, currentPage = 1, pageSize = 10) {
  // calculate total pages
  let totalPages = Math.ceil(totalItems / pageSize);

  // ensure current page isn't out of range
  if (currentPage < 1) {
    currentPage = 1;
  } else if (currentPage > totalPages) {
    currentPage = totalPages;
  }

  // calculate start and end item indexes
  let startIndex = (currentPage - 1) * pageSize;
  let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

  // return object with all pager properties required by the view
  return {
    startIndex: startIndex,
    endIndex: endIndex
  };
}

export const getPageNumber = (skip, take) => {
  return Math.ceil((skip + take) / take);
};
