import React from 'react';
import '../Content/css/Common.css';

import { Container, Spinner } from '../lib/components';
import { useAuthContext } from '../Core';

const loadPostAuthenticationApp = () => import('./PostAuthenticationApp');
const AuthenticatedApp = React.lazy(loadPostAuthenticationApp);
const LoginApp = React.lazy(() => import('./PreAuthenticationApp'));

const Loader = () => {
  return (
    <Container>
      <Spinner />
    </Container>
  );
};

const App = () => {
  const authContext = useAuthContext();

  React.useEffect(() => {
    loadPostAuthenticationApp();
  }, []);

  return (
    <React.Suspense fallback={<Loader />}>
      {authContext.userData && authContext.userData.accessToken ? <AuthenticatedApp /> : <LoginApp />}
    </React.Suspense>
  );
};

export default App;
