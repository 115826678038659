export const defaultColorPalette = {
  PRIMARY_DARK: 'var(--primary-dark)',
  PRIMARY: 'var(--primary)',
  PRIMARY_LIGHT: 'var(--primary-light)',
  PRIMARY_SHADOW: 'var(--shadow-color)',
  SECONDARY_DARK: 'var(--secondary-dark)',
  SECONDARY: 'var(--secondary)',
  SECONDARY_LIGHT: 'var(--secondary-light)',
  SECONDARY_LIGHTER: 'var(--secondary-lighter)',
  PRIMARY_LIGHTER: 'var(--primary-lighter)',
  ERROR: 'var(--error)',
  SUCCESS: 'var(--success)',
  SURFACE: 'var(--surface)'
};
