import React, { useState } from 'react';
import classes from './InstanceActions.module.css';
import { UnlinkAction } from './UnlinkAction';
import { EditRelations } from './EditRelations';

import {
  Button,
  ButtonEnums,
  MenuButton,
  Modal,
  Box
} from '../../../lib/components';
import { Action } from './Action';
import { Enums } from '../../../Enums';
import { useRouter } from '../../Page';
import { SaveProgressModal } from '../../Layouts/SaveProgressModal';
import { PromoteDialog } from '../PromoteActions';
import { usePromoteOperation } from '../PromoteActions/usePromoteOperation';
import { AsyncDeleteAction } from './AsyncDeleteAction';
import {
  getSecondaryActions,
  RenderActionsOverflowIcon
} from './InstanceActionUtils';
import { CreateACopyAction } from './CreateACopyAction';

const BUTTON_MAX_WIDTH = '250px';
export const InstanceActions = props => {
  const { actions, showAsButton, alignPromoteButton } = props;
  const [selectedActionData, setSelectedActionData] = useState(null);
  const { history } = useRouter();

  const {
    executePromote,
    promoteSuccessCallback,
    handleChange,
    setPromoteAction,
    onPromoteActionChanged,
    onCancel,
    setSelectedAction,
    setIsModalOpen,
    setComments,
    instActionCallBack,
    comments,
    errorType,
    errorMessage,
    isModalOpen,
    promoteAction,
    isPromoting,
    selectedAction,
    isPromoteProgressVisible,
    isPromoteFieldsDisabled,
    isPromoteActionsCompleted
  } = usePromoteOperation();

  if (
    selectedActionData &&
    selectedActionData.type === Enums.AllowedOperations.CreateACopy
  ) {
    const { actionsData } = actions.find(
      a => a.actionType === selectedActionData.type
    );
    return (
      <CreateACopyAction
        relationTypeName={actionsData.relationTypeName}
        relationDirection={actionsData.relationDirection}
        instanceId={actionsData.instanceId}
        typeName={actionsData.typeName}
        relationTypeId={
          history.location && history.location.pathname
            ? history.location.pathname.split('/').pop()
            : null
        }
        onSuccess={() => {
          actionsData.successCallback();
          setSelectedActionData(null);
        }}
        onClose={() => setSelectedActionData(null)}
      />
    );
  }
  if (
    selectedActionData &&
    selectedActionData.type === Enums.AllowedOperations.Delete
  ) {
    const { actionsData } = actions.find(
      a => a.actionType === selectedActionData.type
    );
    return (
      <Modal open={true}>
        <AsyncDeleteAction
          key="delete"
          promiseParams={actionsData.promiseParams}
          message={actionsData.message}
          successCallback={() => {
            actionsData.successCallback();
            setSelectedActionData(null);
          }}
          onClose={() => setSelectedActionData(null)}
        />
      </Modal>
    );
  }
  if (
    selectedActionData &&
    selectedActionData.type === Enums.AllowedOperations.Promote
  ) {
    const { actionsData } = actions.find(
      a => a.actionType === selectedActionData.type
    );

    function renderPromoteDialog() {
      return !isPromoteFieldsDisabled ? (
        <PromoteDialog
          promoteActions={actionsData.allowedActions}
          isPromoteFieldsDisabled={isPromoteFieldsDisabled}
          setComments={setComments}
          comments={comments}
          handleChange={handleChange}
          selectedAction={selectedAction}
          setSelectedAction={setSelectedAction}
          selectedPromoteAction={promoteAction}
        />
      ) : null;
    }

    return (
      <SaveProgressModal
        instance={{
          id: actionsData.instanceId,
          name: actionsData.instanceName
        }}
        showOnlyPromoteModal={true} //its a temp fix
        promoteReminderActions={renderPromoteDialog()}
        executePromote={executePromote}
        selectedPromoteAction={promoteAction}
        promoteCallback={() => {
          instActionCallBack();
          if (errorMessage && actionsData) {
            history.push({
              pathname: `/${actionsData.instanceTypeName}/view/${actionsData.instanceId}`,
              state: {
                promoteError: errorMessage
              }
            });
          } else {
            actionsData.successCallback();
            setSelectedActionData(null);
          }
        }}
        onCancel={() => {
          onCancel();
          setSelectedActionData(null);
        }}
        isPromoteActionsCompleted={isPromoteActionsCompleted}
        disable={isPromoting}
        isPromoteProgressVisible={isPromoteProgressVisible}
        isModalOpen={isModalOpen}
        promoteSuccessCallback={promoteSuccessCallback}
        errorType={errorType}
        selectedAction={selectedAction}
        comments={comments}
      />
    );
  }
  if (
    selectedActionData &&
    selectedActionData.type === Enums.AllowedOperations.Unlink
  ) {
    const { actionsData } = actions.find(
      a => a.actionType === selectedActionData.type
    );
    return (
      <Modal open={true}>
        <UnlinkAction
          key="unlink"
          relationId={actionsData.relationId}
          message={actionsData.message}
          successCallback={() => {
            actionsData.successCallback();
            setSelectedActionData(null);
          }}
          onClose={() => setSelectedActionData(null)}
        />
      </Modal>
    );
  }
  if (
    selectedActionData &&
    selectedActionData.type === Enums.AllowedOperations.EditRelation
  ) {
    const { actionsData } = actions.find(
      a => a.actionType === selectedActionData.type
    );
    return (
      <EditRelations
        key="EditRelation"
        relationDetails={actionsData.relationDetails}
        parentInstanceId={actionsData.parentInstanceId}
        config={actionsData.config}
        successCallback={() => {}}
        saveSuccessCallback={() => {
          actionsData.successCallback();
          setSelectedActionData(null);
        }}
        onClose={() => setSelectedActionData(null)}
      />
    );
  }

  const ButtonComponent = () => {
    if (actions.length > 0) {
      let secondaryOptions = getSecondaryActions(actions);
      let secondaryOptionsLength = secondaryOptions.length;
      let initialAction = actions && actions[0];
      const { actionType, actionsData, actionButtonRenderer } = initialAction;
      let action = { type: actionType, data: actionsData };

      if (actionButtonRenderer) {
        return actionButtonRenderer;
      } else if (actionType === Enums.AllowedOperations.Promote) {
        let promoteActionData = actionsData && actionsData.allowedActions[0];
        action = { type: actionType, data: promoteActionData };

        return (
          <Button
            text={promoteActionData.displayName || promoteActionData.name}
            title={promoteActionData.displayName || promoteActionData.name}
            appearance={ButtonEnums.Appearance.Primary}
            style={{
              color: 'var(--surface)',
              width: secondaryOptionsLength > 0 ? '80%' : '90%',
              maxWidth: BUTTON_MAX_WIDTH,
              alignSelf: 'center'
            }}
            size={ButtonEnums.Size.Medium}
            onClick={() => {
              setSelectedActionData(action);
              setPromoteAction(action.data);
              onPromoteActionChanged(action.data);
              setIsModalOpen(true);
            }}
          />
        );
      }

      return (
        <Button
          text={actionType}
          title={actionType}
          appearance={ButtonEnums.Appearance.Primary}
          style={{
            color: 'var(--surface)',
            width: secondaryOptionsLength > 0 ? '80px' : '100px',
            alignSelf: 'center'
          }}
          textStyle={{ maxWidth: secondaryOptionsLength > 0 ? '75px' : '95px' }}
          size={ButtonEnums.Size.Medium}
          onClick={() => {
            setSelectedActionData(action);
            setPromoteAction(action.data);
            onPromoteActionChanged(action.data);
            setIsModalOpen(true);
          }}
        />
      );
    }
    return '-';
  };

  if (showAsButton) {
    return (
      <MenuButton
        key="MenuButton"
        style={{ width: '100%', justifyContent: alignPromoteButton }}
        initialComponent={<ButtonComponent />}
        anchorComponent={<RenderActionsOverflowIcon actions={actions} />}
        menuComponent={
          <Box display="block" className={classes.actions}>
            {
              <Action
                actions={getSecondaryActions(actions)}
                onselect={action => {
                  setSelectedActionData(action);
                  setPromoteAction(action.data);
                  onPromoteActionChanged(action.data);
                  setIsModalOpen(true);
                }}
              />
            }
          </Box>
        }
      />
    );
  }

  return (
    <MenuButton
      key="MenuButton"
      style={{ justifyContent: alignPromoteButton }}
      anchorComponent={
        <div className={classes['actions-icon']}>
          <i className="fa fa-ellipsis-h" />
        </div>
      }
      menuComponent={
        <div className={classes.actions}>
          {
            <Action
              actions={actions}
              onselect={action => {
                setSelectedActionData(action);
                setPromoteAction(action.data);
                onPromoteActionChanged(action.data);
                setIsModalOpen(true);
              }}
            />
          }
        </div>
      }
    />
  );
};
