import React from 'react';
import { H3, Box } from '../../../lib/components';
import { useDeviceType, DeviceTypes } from '../../../UseDeviceType';

export const Group = props => {
  const currentDeviceType = useDeviceType();

  const heightOfLabel = 90;
  const groupContainerHeight = props.sectionHeight
    ? `calc(${props.sectionHeight} - ${heightOfLabel}px)`
    : null;

  const minGroupContainerHeight = props.sectionHeight ? null : '400px';
  const getBorderStyle = () => {
    const border = '1px solid var(--primary-lighter)';
    let borderStyle = {};
    if (props.showBorder) {
      borderStyle = {
        borderTop: border,
        borderBottom: border,
        borderLeft: border,
        borderRight: border
      };
    }
    if (props.highlightTopBorder) {
      borderStyle.borderTop = '3px solid var(--secondary)';
    }
    return borderStyle;
  };

  const getPadding = () => {
    if (props.headerComponent) {
      return '';
    }
    if (props.showBorder) {
      return '0.45rem 0 0 0';
    }
    return '';
  };

  return (
    <Box
      width={'100%'}
      boxShadow={'0 1px 2px 0'}
      {...getBorderStyle()}
      borderRadius="5px"
      padding={getPadding()}
      backgroundColor={'SURFACE'}
      overflow={'auto'}
    >
      <Box
        minHeight={props.sectionHeight ? minGroupContainerHeight : ''}
        position={props.sectionHeight ? 'relative' : ''}
        direction={'column'}
        width={'100%'}
        backgroundColor={'SURFACE'}
      >
        <GroupHeader
          headerComponent={props.headerComponent}
          showBorder={props.showBorder}
          showTitle={props.showTitle}
          name={props.name}
          displayName={props.displayName}
          currentDeviceType={currentDeviceType}
          devices={DeviceTypes}
        />
        <Box
          overflow={
            props.overflowVisible
              ? { overflowX: 'visible', overflowY: 'visible' }
              : { overflowX: 'hidden', overflowY: 'auto' }
          }
          height={props.sectionHeight ? [groupContainerHeight] : ''}
          alignContent={'start'}
          direction={'column'}
        >
          {props.children}
        </Box>
      </Box>
    </Box>
  );
};

const GroupHeader = ({
  headerComponent,
  showBorder,
  showTitle,
  name,
  displayName,
  currentDeviceType,
  devices
}) => {
  if (!showTitle && !headerComponent) {
    return null;
  }

  if (currentDeviceType === devices.mobile) {
    return (
      <MobileGroupHeader
        headerComponent={headerComponent}
        showBorder={showBorder}
        showTitle={showTitle}
        name={name}
        displayName={displayName}
      />
    );
  } else {
    return (
      <DesktopGroupHeader
        headerComponent={headerComponent}
        showBorder={showBorder}
        showTitle={showTitle}
        name={name}
        displayName={displayName}
      />
    );
  }
};

const MobileGroupHeader = ({
  headerComponent,
  showBorder,
  showTitle,
  name,
  displayName
}) => {
  return (
    <Box
      alignItems={'center'}
      height={'2.5rem'}
      padding={'12px'}
      justifyContent={'space-between'}
      minHeight={headerComponent ? '3.5rem' : ''}
      style={{
        flexDirection: 'column',
        placeItems: 'flex-start',
        height: 'auto',
        width: '100%'
      }}
    >
      {groupTitle({
        showBorder,
        name,
        displayName,
        showTitle,
        headerComponent
      })}
      {headerComponent}
    </Box>
  );
};

const DesktopGroupHeader = ({
  headerComponent,
  showBorder,
  showTitle,
  name,
  displayName
}) => {
  return (
    <Box
      alignItems={'center'}
      height={'2.5rem'}
      padding={'12px'}
      justifyContent={'space-between'}
      minHeight={headerComponent ? '3.5rem' : ''}
    >
      {groupTitle({
        showBorder,
        name,
        displayName,
        showTitle,
        headerComponent
      })}
      {headerComponent}
    </Box>
  );
};

function groupTitle({ showBorder, displayName, showTitle, headerComponent }) {
  const marginTop = () => {
    if (headerComponent && !showBorder) {
      return '1.2rem';
    } else if (headerComponent) {
      return '1rem';
    } else {
      return showBorder ? '0.6rem' : '1.2rem';
    }
  };

  return (
    <H3
      marginLeft="1rem"
      marginBottom="1rem"
      color={'PRIMARY'}
      fontWeight={700}
      marginTop={marginTop()}
    >
      {showTitle ? displayName : ''}
    </H3>
  );
}
