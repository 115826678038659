import { addDays, addMonths, addWeeks, addYears, getDay, getMonth, getYear, startOfDay, subDays, subMonths, subWeeks, subYears } from "date-fns";
import { convertUTCToTenantTimeZone, convertTenantTimeZoneToUTC } from ".";

export function interpretDate(dateMnemonic, timezone) { // StartOfToday(+7d)
    if (!dateMnemonic) {
        return null;
    }
    if (dateMnemonic === '') {
        return null;
    }
    let date = null;
    const mnemonic = getMnemonic(dateMnemonic);
    const specifierString = getOperatorOperandSpecifierString(dateMnemonic);
    if (specifierString && specifierString.length === 3) {
        const operator = specifierString.charAt(0);
        const operand = specifierString.charAt(1);
        const specifier = specifierString.charAt(2);
        date = getDateFromMnemonic(timezone, mnemonic, operator, operand, specifier);
    } else {
        date = getDateFromMnemonic(timezone, mnemonic);
    }
    return convertTenantTimeZoneToUTC(date, timezone);
}

function getDateFromMnemonic(timezone, mnemonic, operator, operand, specifier) {
    let startTime = getStartTimeFromMnemonic(mnemonic, timezone);
    if (operator && operand && specifier) {
        switch (specifier) {
            case DateMnemonicConstants.DaySpecifier:
                return operator === DateMnemonicConstants.Plus
                    ? addDays(startTime, operand)
                    : subDays(startTime, operand);
            case DateMnemonicConstants.WeekSpecifier:
                return operator === DateMnemonicConstants.Plus
                    ? addWeeks(startTime, operand)
                    : subWeeks(startTime, operand);
            case DateMnemonicConstants.MonthSpecifier:
                return operator === DateMnemonicConstants.Plus
                    ? addMonths(startTime, operand)
                    : subMonths(startTime, operand);
            case DateMnemonicConstants.YearSpecifier:
                return operator === DateMnemonicConstants.Plus
                    ? addYears(startTime, operand)
                    : subYears(startTime, operand);
            default:
                return startTime;
        }
    }
    return startTime;
}

function getStartTimeFromMnemonic(mnemonic, timezone) {
    const timeZoneDate = startOfDay(convertUTCToTenantTimeZone(new Date().toISOString(), timezone));
    switch (mnemonic) {
        case DateMnemonicConstants.Day:
            return timeZoneDate;
        case DateMnemonicConstants.Week:
            const currentDateDay = getDay(timeZoneDate);
            if (currentDateDay === 0) { // sunday
                return addDays(timeZoneDate, -6);
            } else {
                return addDays(timeZoneDate, 1 - currentDateDay);
            }
        case DateMnemonicConstants.Month:
            return new Date(getYear(timeZoneDate), getMonth(timeZoneDate), 1);
        case DateMnemonicConstants.Year:
            return new Date(getYear(timeZoneDate), 1, 1);
        default:
            return null;
    }
}

function getOperatorOperandSpecifierString(dateMnemonic) {
    if (!dateMnemonic) {
        return null;
    }
    if (dateMnemonic === '') {
        return null;
    }
    const splitStr = dateMnemonic.split('('); // ['StartOfToday', '+7d)']
    if (splitStr && splitStr.length === 2) { // true
        let value = splitStr[1]; // '+7d)'
        if (value) {
            return value.replace(')', ''); // '+7d'
        }
    }
    return null;
}

function getMnemonic(dateMnemonic) { // StartOfToday(+7d)
    if (!dateMnemonic) {
        return null;
    }
    if (dateMnemonic === '') {
        return null;
    }
    if (dateMnemonic.toLowerCase().includes('today')) { // true
        return DateMnemonicConstants.Day; // this return will execute
    } else if (dateMnemonic.toLowerCase().includes('week')) {
        return DateMnemonicConstants.Week;
    } else if (dateMnemonic.toLowerCase().includes('month')) {
        return DateMnemonicConstants.Month;
    } else if (dateMnemonic.toLowerCase().includes('year')) {
        return DateMnemonicConstants.Year;
    } else {
        return null;
    }
}

// returns utc date string
export function parseDate(dateString, timezone) {
    if (!dateString) {
        return null;
    }
    if (dateString === '') {
        return null;
    }
    if (Array.isArray(dateString) && dateString.length === 1) {
        if (isNaN(Date.parse(dateString[0]))) {
            return interpretDate(dateString[0], timezone);
        }
    } else if (isNaN(Date.parse(dateString))) {
        return interpretDate(dateString, timezone);
    }
    return dateString;
}

export const DateMnemonicConstants = {
    Mnemonic: "Mnemonic",
    Operator: "Operator",
    Operand: "Operand",
    Specifier: "Specifier",

    StartOfToday: "StartOfToday",
    StartOfWeek: "StartOfWeek",
    StartOfMonth: "StartOfMonth",
    StartOfYear: "StartOfYear",

    Day: "DAY",
    Week: "WEEK",
    Month: "MONTH",
    Year: "YEAR",
    DaySpecifier: "d",
    WeekSpecifier: "w",
    MonthSpecifier: "m",
    YearSpecifier: "y",
    Plus: '+',
    Minus: '-'
};