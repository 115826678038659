export const generateGuid = () => {
  var id = '';
  for (let i = 0; i < 8; i++) {
    id += getHexaDigit();
  }
  id += '-';
  for (let i = 0; i < 4; i++) {
    id += getHexaDigit();
  }
  id += '-';
  for (let i = 0; i < 4; i++) {
    id += getHexaDigit();
  }
  id += '-';
  for (let i = 0; i < 4; i++) {
    id += getHexaDigit();
  }
  id += '-';
  for (let i = 0; i < 12; i++) {
    id += getHexaDigit();
  }
  return id;
};
const getHexaDigit = () => {
  return Math.floor(Math.random() * 16)
    .toString(16)
    .toLowerCase();
};

export const isEmptyGuid = guid => {
  if (guid === '00000000-0000-0000-0000-000000000000') {
    return true;
  }
  return false;
};
