import React from 'react';

export const StateAttribute = config => {
  const getValue = value => {
    return (value && (value.displayName || value.name)) || '-';
  };

  const renderAttributeValue = value => {
    return <>{getValue(value)}</>;
  };

  const renderAttributeInput = value => {
    return renderAttributeValue(value);
  };

  const filterOptions = [];
  const defaultSortOrder = 'Ascending';

  return {
    renderAttributeValue,
    renderAttributeInput,
    filterOptions,
    defaultSortOrder,
    getValue
  };
};
