import { toQueryString, usePaginatedGet } from '../utils';
import { generateInstanceQuery } from './instanceQuery';

const instanceBase = '/instances';

export function useRelatedInstanceQuery(pageSize) {
  const {
    state,
    reload,
    nextPage,
    previousPage,
    paginationState,
    hasMoreItems,
    fetchItems
  } = usePaginatedGet(
    {
      requestConfig: fetchRelatedInstances
    },
    { defer: true },
    pageSize
  );

  return {
    state,
    reload,
    nextPage,
    previousPage,
    paginationState,
    hasMoreItems,
    fetchItems
  };
}

const fetchRelatedInstances = ({
  instanceQueryParams,
  instanceId,
  skip,
  take
}) => {
  const instanceQuery = generateInstanceQuery(instanceQueryParams);
  const endpoint = createRelatedInstEndpoint(instanceQuery, instanceId);
  return {
    endpoint: endpoint, config: {
      params: { skip, take },
      method: 'GET'
    }
  };
};

const createRelatedInstEndpoint = (query, instanceId) => {
  if (query) {
    const queryParams = toQueryString(query);

    const endpoint = `${instanceBase}/${instanceId}/relatedInstances?${queryParams}`;

    return endpoint;
  }

  return null;
};
