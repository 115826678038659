import React from 'react';
import classes from './Filter.module.css';
import { Button, ButtonEnums, Box, Grid } from '../../lib/components';


export const FilterPopup = ({
  children,
  successCallback,
  resetCallback,
  isApplyDisabled,
  closePopup,
  isDisabled,
  isReportFilter
}) => {
  return (
    <>
      { isReportFilter ? <Box direction="column" overflow="auto">{children}</Box> : <div className={classes['popup-body']}>{children}</div> }
      <Grid
        rows={['auto']}
        columns={['1fr', 'auto']}
        areas={[['main']]}
        padding="5px 10px"
        borderTop="0.5px solid var(--primary-lighter)"
      >
        <Box justifySelf="end">
          <Box padding="5px 5px" display="inline-block">
            <Button
              appearance={ButtonEnums.Appearance.Primary}
              text={'Apply'}
              onClick={successCallback}
              size={ButtonEnums.Size.Medium}
              disabled={isApplyDisabled}
            >
              <i className="fa fa-check" />
            </Button>
          </Box>
          <Box padding="5px 5px" display="inline-block">
            <Button
              appearance={ButtonEnums.Appearance.Default}
              outline
              text={'Reset'}
              onClick={resetCallback}
              size={ButtonEnums.Size.Medium}
              disabled={isDisabled}
            >
              <i className="fa fa-undo" />
            </Button>
          </Box>
          { isReportFilter && <Box padding="5px 5px" display="inline-block">
            <Button
              appearance={ButtonEnums.Appearance.Default}
              outline
              text={'Close'}
              onClick={closePopup}
              size={ButtonEnums.Size.Medium}
            >
              <i className="fa fa-times" />
            </Button>
          </Box> }
        </Box>
      </Grid>
    </>
  );
};
