import { useState, useContext, useCallback } from 'react';
import { mapActionToDropdownItem } from './utils';
import InstanceEditContext from '../../../Contexts/InstaceEditContext';
import {
  useAppInsightsContext,
  PROTRAK_EVENTS
} from '../../../Contexts/AppInsightsContext';
import { ASYNC_JOB_ACTIONS, useAsyncJobContext } from '../../../Core';

export function usePromoteOperation() {
  const [promoteAction, setPromoteAction] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [isPromoteProgressVisible, setPromoteProgressVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [comments, setComments] = useState('');
  const [isPromoteActionsCompleted, setPromoteActionsCompleted] = useState(
    false
  );

  const { trackEvent } = useAppInsightsContext();
  const { dispatch, reload } = useAsyncJobContext();
  const errorType = typeof errorMessage;

  const {
    instanceEditState,
    instanceDetails,
    onPromoteButtonClicked,
    onPromoteSuccess,
    onPromoteError,
    reloadInstanceDetails
  } = useContext(InstanceEditContext);

  const isPromoting = isPromoteProgressVisible && !isPromoteActionsCompleted;
  const isPromoteFieldsDisabled =
    isPromoteProgressVisible || isPromoteActionsCompleted;

  const handleChange = event => {
    setSelectedAction({ value: event.target.value, label: event.target.title });
  };

  const onPromoteActionChanged = action => {
    const instViewSelectedAction = action
      ? mapActionToDropdownItem(action)
      : null;

    setSelectedAction(instViewSelectedAction);
  };

  const executePromote = () => {
    trackEvent(PROTRAK_EVENTS.PROMOTE);
    if (selectedAction && selectedAction.value) {
      setPromoteProgressVisible(true);
    } else {
      alert('Please select action');
    }
  };

  const onCancel = () => {
    setComments('');
    setPromoteAction(null);
    onPromoteActionChanged(null);
    setPromoteProgressVisible(false);
    setPromoteActionsCompleted(false);
    setIsModalOpen(false);
  };

  const promoteSuccessCallback = (id, error) => {
    setComments('');
    setErrorMessage(error);
    setPromoteActionsCompleted(true);
  };

  const promoteCallback = () => {
    setPromoteAction(null);
    onPromoteActionChanged(null);
    setPromoteProgressVisible(false);
    setPromoteActionsCompleted(false);
    setIsModalOpen(false);
    if (errorMessage) {
      errorType === 'string'
        ? reloadInstanceDetails()
        : onPromoteError(errorMessage);
    } else {
      onPromoteSuccess();
    }
    setErrorMessage('');
  };

  const postAsyncPromote = useCallback(
    response => {
      if (response.promoteActionAsyncJobId) {
        dispatch({
          type: ASYNC_JOB_ACTIONS.TOGGLE_NOTIFICATION_DRAWER
        });
        reload();
      }
    },
    [dispatch, reload]
  );

  const instActionCallBack = () => {
    setPromoteAction(null);
    setPromoteActionsCompleted(false);
    setPromoteProgressVisible(false);
    setIsModalOpen(false);
    onPromoteActionChanged(null);
  };

  const saveSuccessCallback = () => {
    reloadInstanceDetails();
  };

  return {
    executePromote,
    promoteSuccessCallback,
    handleChange,
    setPromoteAction,
    onPromoteActionChanged,
    onCancel,
    promoteCallback,
    postAsyncPromote,
    setSelectedAction,
    setIsModalOpen,
    setComments,
    instActionCallBack,
    saveSuccessCallback,
    onPromoteError,
    errorMessage,
    comments,
    errorType,
    isModalOpen,
    promoteAction,
    isPromoting,
    selectedAction,
    isPromoteProgressVisible,
    isPromoteFieldsDisabled,
    isPromoteActionsCompleted,
    instanceEditState,
    instanceDetails,
    onPromoteButtonClicked
  };
}
