export const getErrorMessage = (instanceEditState, attributeName) => {
  if (instanceEditState && instanceEditState.invalidAttributes) {
    let errorMessage = instanceEditState.invalidAttributes[attributeName];
    return errorMessage;
  }
  return '';
};

export const getInstanceFieldValueObject = (instanceEditState, field) => {
  if (instanceEditState) {
    if (field.fieldType === 'State') {
      return instanceEditState.details ? instanceEditState.details.state : '-';
    }
    if (field.fieldType === 'Lifecycle') {
      return getLifecycleValue(instanceEditState, field);
    }
    if (
      instanceEditState.editedValues &&
      instanceEditState.editedValues[field.attributeName]
    ) {
      return instanceEditState.editedValues[field.attributeName];
    }
    if (
      instanceEditState.attributeValues &&
      instanceEditState.attributeValues[field.attributeName]
    ) {
      return instanceEditState.attributeValues[field.attributeName];
    }
  }
  return null;
};

export function getLifecycleValue(instanceEditState, field) {
  if (instanceEditState.details) {
    if (instanceEditState.details.lifecycle) {
      return instanceEditState.details.lifecycle;
    }
  }
  if (instanceEditState.editedValues) {
    if (instanceEditState.editedValues[field.attributeName]) {
      return instanceEditState.editedValues[field.attributeName];
    }
  }

  return '-';
}
