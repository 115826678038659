import React from 'react';
import { Enums } from '../../Enums';
import {
  CheckboxGroup,
  Picklist,
  RadioButtonGroup
} from '../../lib/components';
import { Prefix, Suffix } from './prefixSuffix';
import { isArrayOfObject } from '../../Core';

export const PicklistAttribute = config => {
  const getValue = value => {
    if (!value) {
      return [];
    }
    if (typeof value === 'string') {
      return value.split(',');
    }
    if (value.arrayValue) {
      return value.arrayValue;
    }
    return [];
  };

  const getDefaultValue = () => {
    return { arrayValue: config.defaultValue };
  };

  const getDisplayValue = value => {
    const arrValue = getValue(value);
    if (!arrValue || !(Array.isArray(arrValue)) || arrValue.length === 0) {
      return null;
    }
    return arrValue
      .map(a => {
        const option = config.options.find(o => o.name === a);
        const displayValue = option ? option.displayName || option.name : null;
        return displayValue;
      })
      .join(', ');
  };

  const renderAttributeValue = value => {
    const val = getDisplayValue(value);
    if (!val) {
      return <>{'-'}</>;
    }
    return (
      <>
        <Prefix value={config.prefix} />
        {val}
        <Suffix value={config.suffix} />
      </>
    );
  };

  const renderAttributeInput = (value, onEdit) => {
    if (config.options && config.options.length > 3) {
      return (
        <Picklist
          isMultiselect={config.isMultiselect}
          fieldValue={getValue(value)}
          options={config.options}
          onEdit={newValue => onEdit({ arrayValue: newValue })}
          placeholder={config.placeholder}
          showClearValue={true}
        />
      );
    }
    if (config.isMultiselect) {
      return (
        <CheckboxGroup
          value={getValue(value)}
          options={config.options}
          onEdit={newValue => onEdit({ arrayValue: newValue })}
        />
      );
    }
    return (
      <RadioButtonGroup
        value={
          value &&
          value.arrayValue &&
          value.arrayValue.length > 0 &&
          getValue(value)[0]
        }
        options={config.options}
        onEdit={newValue => onEdit({ arrayValue: newValue })}
        attributeName={config.attributeName}
      />
    );
  };

  const renderFilterAttributeInput = (value, onEdit, isWorksheet) => {
    return (
      <Picklist
        isMultiselect={isWorksheet ? config.isMultiselect : false}
        fieldValue={getValue(value)}
        options={config.options}
        onEdit={newValue => onEdit({ arrayValue: newValue })}
        placeholder={config.placeholder}
        showClearValue={true}
      />
    );
  };

  const filterOptions = [
    { displayName: 'Contains', name: Enums.Conditions.Contains },
    { displayName: 'Not Contains', name: Enums.Conditions.NotContains },
    { displayName: 'Equals', name: Enums.Conditions.Equals },
    { displayName: 'Not Equals', name: Enums.Conditions.NotEquals },
    { displayName: 'Is Empty', name: Enums.Conditions.IsEmpty },
    { displayName: 'Is Not Empty', name: Enums.Conditions.IsNotEmpty }
  ];

  const defaultSortOrder = !config.isMultiselect ? 'Ascending' : '';

  const evaluateCondition = (condition, valueObj, compareToValueStr) => {
    const value = getValue(valueObj);
    switch (condition) {
      case Enums.Conditions.Equals:
        if (!value || value.length === 0) {
          return false;
        }
        return value.join() === compareToValueStr;
      case Enums.Conditions.IsEmpty:
        return !value || value.length === 0;
      case Enums.Conditions.Contains:
        return !value || value.includes(compareToValueStr);
      case Enums.Conditions.NotContains:
        return !value || !value.includes(compareToValueStr);
      case Enums.Conditions.NotEquals:
        if (!value || value.length === 0) {
          return false;
        }
        return value.join() !== compareToValueStr;
      case Enums.Conditions.IsNotEmpty:
        return !value || value.length !== 0;
      default:
        return false;
    }
  };

  return {
    getDisplayValue,
    renderAttributeValue,
    renderAttributeInput,
    renderFilterAttributeInput,
    filterOptions,
    getValue,
    defaultSortOrder,
    getDefaultValue,
    evaluateCondition
  };
};

export const stringifyPicklistOptions = values => {
  if (!values || !values.length > 0) {
    return '';
  }

  if (Array.isArray(values) && !isArrayOfObject(values)) {
    return values.join();
  } else if (!Array.isArray(values)) {
    return values;
  } else {
    let picklistValues = values.map(value => {
      return value.label;
    });

    return picklistValues.join();
  }
};
