import React, { useEffect, useRef } from 'react';
import { Grid, Dropdown, Box, H3 } from '../../lib/components';
import { useInstanceQuery, mapPicklistValues } from '../../Core';
import isEqual from 'lodash/isEqual';

const pageSize = 1000;
export const ParentFilterGroup = ({
  parentFilterConfig,
  parentFilter,
  isDisabled,
  index,
  onEdit,
  typeName,
  maxMenuHeight
}) => {
  const {
    state: instanceDetails,
    fetchItems: fetchInstanceDetails
  } = useInstanceQuery({ pageSize });

  const prevParentFilterRef = useRef(null);

  useEffect(() => {
    //fetch during first render when previous filter will be null
    //or fetch only when parent filter object has changed between renders
    if (
      !prevParentFilterRef.current ||
      !isEqual(parentFilter, prevParentFilterRef.current)
    ) {
      prevParentFilterRef.current = parentFilter;
      fetchInstanceDetails({
        widget: { fields: [{ attributeName: 'Name' }] },
        typeName,
        parentFilter,
        skip: 0,
        take: 10000
      });
    }
  }, [fetchInstanceDetails, parentFilter, typeName]);

  const onValueChange = selectedItems => {
    let selectedObj = {
      instanceId: selectedItems !== null ? selectedItems.value : null,
      relationDirection: 'From',
      relationTypeName: parentFilterConfig.relationTypeName,
      typeName: parentFilterConfig.typeName
    };

    onEdit(selectedObj, index);
  };

  const getSelectedFilter = () => {
    let selectedFilter = {
      id: null,
      name: 'All'
    };

    if (instanceDetails && instanceDetails.data) {
      selectedFilter =
        instanceDetails.data.items &&
        instanceDetails.data.items.find(
          i => i.id === parentFilterConfig.instanceId
        );

      if (!selectedFilter) {
        parentFilterConfig.instanceId = null;
        selectedFilter = {
          id: null,
          name: 'All'
        };
      }
    }

    return selectedFilter;
  };

  const getOptions = () => {
    let options =
      instanceDetails && instanceDetails.data && instanceDetails.data.items;

    options &&
      !options.some(a => a.name === 'All') &&
      options.unshift({
        id: null,
        name: 'All'
      });

    return options;
  };

  return (
    <Grid rows={['auto']} columns={['auto']} areas={[['main']]} padding="5px">
      <Box padding="3px">
        <H3 fontWeight={500}>{parentFilterConfig.typeName}</H3>
      </Box>
      <Dropdown
        maxMenuHeight={maxMenuHeight}
        defaultValues={mapPicklistValues([getSelectedFilter()], 'name', 'id')}
        picklistOptions={mapPicklistValues(getOptions(), 'name', 'id')}
        isMultiselect={false}
        isDisabled={isDisabled}
        onValueChange={onValueChange}
        isLoading={instanceDetails.isLoading}
        isClearable={true}
      />
    </Grid>
  );
};
