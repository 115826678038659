import React, { useState } from 'react';
import Popup from '../Popup';
import ReferenceRelatedInstanceList from './ReferenceRelatedInstanceList';
import { AreArrayContentSame } from '../../../Core';

import useTranslatedValue from '../../../Hooks/useTranslatedValue';

export const RelatedInstanceListPopup = props => {
  const {
    typeName,
    relatedTypeName,
    relationDirection,
    selectedInstances = [],
    isMultiselect,
    successCallback,
    config,
    closeCallback
  } = props;
  const okText = useTranslatedValue('OK');
  const [newSelectedInstance, setSelectedInstances] = useState([]);

  const onInstanceSelected = selectedRows => {
    if (
      selectedRows &&
      !AreArrayContentSame(newSelectedInstance, selectedRows)
    ) {
      setSelectedInstances(selectedRows);
    }
  };

  const onSelectionComplete = () => {
    successCallback(newSelectedInstance);
  };

  return (
    <Popup
      title={`Select ${typeName}`}
      closePopup={closeCallback}
      successButtonText={okText}
      successCallback={onSelectionComplete}
    >
      <ReferenceRelatedInstanceList
        relatedInstances={selectedInstances}
        relatedTypeName={relatedTypeName}
        isMultiselect={isMultiselect}
        relationDirection={relationDirection}
        successCallback={onInstanceSelected}
        config={config}
      />
    </Popup>
  );
};
