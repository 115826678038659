import { useEffect } from 'react';
import { postAsyncJob, ASYNC_JOB_TYPES_CONTRACT } from './asyncJobs';
import { useProtrakApi, useAsyncJobContext, ASYNC_JOB_ACTIONS } from '../utils';

export function useExportInstance() {
  const { dispatch, reload } = useAsyncJobContext();

  const onSuccess = () => {
    dispatch({
      type: ASYNC_JOB_ACTIONS.TOGGLE_NOTIFICATION_DRAWER
    });
    reload();
  };
  const { state: exportInstanceState, run: exportInstance } = useProtrakApi(
    {
      promiseFn: exportInstancePromiseFn
    },
    { defer: true, onSuccess: onSuccess }
  );

  useEffect(() => {
    if (exportInstanceState.isError) {
      alert('Failed to Export');
    }
  }, [exportInstanceState]);

  return [exportInstanceState, exportInstance];
}

const exportInstancePromiseFn = ({ instanceId, authContext }) => {
  return postAsyncJob({
    type: ASYNC_JOB_TYPES_CONTRACT.ExportDocument,
    instanceId, authContext
  });
};
