import styled from 'styled-components';

export const TitleOnBorder = styled.div`
  margin-top: ${props => props.marginTop};
  margin-left: ${props => props.marginLeft};
  background-color: ${props => props.theme.colorPalette[props.backgroundColor]};
  width: ${props => (props.width ? props.width : 'fit-content')};
  padding: ${props => props.padding};
  height: ${props => props.height};
  line-height: ${props => props.lineHeight};
  display: ${props => props.display};
  align-items: ${props => props.alignItems};
`;
