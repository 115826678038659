import { useReducer, useEffect, useCallback, useRef } from 'react';
import { dataFetchReducer } from './dataFetchReducer';
import { protrakApiClient } from './protrakApiClient';

/**
 * @param {requestConfig, promiseFn, ...args} firstParam 
 * @param {defer} _options object that contains defer flag
 * @returns 
 */
export function useApi({ requestConfig, promiseFn, ...args }, _options = {}) {
  const reducer = _options.reducer || dataFetchReducer;

  const [state, dispatch] = useReducer(reducer, {
    isLoading: false,
    isError: false,
    data: _options.initialData
  });

  const promiseArgs = useRef(args);
  const options = useRef(_options);
  const isAborted = useRef(false);
  const isMounted = useRef(true);

  const handleSuccess = useCallback(
    response => {
      dispatch({
        type: 'FETCH_SUCCESS',
        payload: response.data,
        status: response.status
      });

      if (options.current.cacheKey && options.current.middleware && options.current.middleware.addKeyValue && response.data) {
        options.current.middleware.addKeyValue(options.current.cacheKey, response.data,
          options.current.entity);
      }

      options.current.onSuccess && options.current.onSuccess(response.data);
    },
    [options]
  );

  const handleError = useCallback(
    error => {
      if (error && error.timedOut && !isAborted.current) {
        dispatch({
          type: 'FETCH_FAILURE',
          payload: 'Connection timed out. Please try again later.'
        });
      } else if (
        options.current.onAuthError &&
        error.response &&
        error.response.status === 401
      ) {
        if (!isAborted.current) {
          options.current.onAuthError();
        }
      } else {
        dispatch({ type: 'FETCH_FAILURE', payload: error });
      }
      options.current.onError && options.current.onError(error);
    },
    [options]
  );

  // if requestConfig function is provided - framework will handle apiCall
  const execute = useCallback(async () => {
    dispatch({ type: 'FETCH_INIT' });
    try {
      let response;
      if (requestConfig) {
        const protrakClientParams = requestConfig({ ...promiseArgs.current });
        response = await protrakApiClient(protrakClientParams.endpoint, protrakClientParams.config, options.current.middleware);
        // if mapping function is provided then map to required response
        if (promiseArgs.current.mapResponse) {
          response = promiseArgs.current.mapResponse(response);
        }
      } else if (promiseFn) {
        response = await promiseFn({ ...promiseArgs.current, authContext: options.current.middleware });
      }
      if (!isAborted.current || isMounted.current) {
        handleSuccess(response);
      }
    } catch (error) {
      if (error.response && error.response.status === 304
        && options.current.cacheKey && options.current.middleware && options.current.middleware.getValueForKey) {
        const cachedResponse = options.current.middleware.getValueForKey(options.current.cacheKey, options.current.entity);
        dispatch({
          type: 'FETCH_SUCCESS',
          payload: cachedResponse,
          status: 200
        });
      }
      else {
        handleError(error);
      }
    }
  }, [handleError, handleSuccess, promiseFn, requestConfig, options]);

  useEffect(() => {
    return () => {
      isAborted.current = true;
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!options.current.defer) {
      execute();
    }
  }, [execute]);

  const run = useCallback(
    newArgs => {
      if (newArgs) {
        const lastArgs = promiseArgs.current || {};
        promiseArgs.current = { ...lastArgs, ...newArgs };
      }
      execute();
    },
    [execute]
  );

  const abort = () => {
    isAborted.current = true;
  };

  return {
    state,
    run,
    abort
  };
}
