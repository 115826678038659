import { InstanceCreateContextProvider } from '../../../../Contexts/InstanceCreateContextProvider';
import { CloneLayout } from './CloneLayout';
import { useInstanceDetailsForSections, isEmpty } from '../../../../Core';
import {
  Modal,
  CloneModalContainer,
  CloneLoadingState
} from '../../../../lib/components';

export const CloneInstanceDetails = props => {
  const {
    instanceTypeName,
    instanceId,
    relationTypeId,
    onInstanceCreated,
    relationTypeName,
    relationDirection,
    saveSuccessCallback,
    onSuccess,
    onClose,
    config
  } = props;

  const { state: instanceDetails } = useInstanceDetailsForSections(
    instanceId,
    config.sections
  );

  let defaultValues = {};
  if (instanceDetails.data && instanceDetails.data.attributes) {
    instanceDetails.data.attributes.map(attribute => {
      if (attribute.type !== 'Attachment') {
        if (
          attribute.referenceValues &&
          attribute.referenceValues[0].id === relationTypeId
        ) {
          defaultValues[attribute.name] = { ...attribute, canUpdate: false };
        } else {
          defaultValues[attribute.name] = { ...attribute, canUpdate: true };
        }
      }
      return defaultValues;
    });
  }

  let isCreateLinkSuccess = Object.values(defaultValues).some(
    instance => instance.canUpdate === false
  );

  if (instanceDetails.isLoading || isEmpty(instanceDetails.data)) {
    return (
      <Modal
        open={true}
        background
        children={<CloneModalContainer children={<CloneLoadingState />} />}
      />
    );
  }

  return (
    <InstanceCreateContextProvider
      instanceId={instanceId}
      sections={config.sections}
      config={config}
      defaultValues={defaultValues}
    >
      <CloneLayout
        headerTitle={'Create ' + config.typeSingularName}
        successButtonText="Save"
        instanceTypeName={instanceTypeName}
        instanceId={instanceId}
        onInstanceCreated={onInstanceCreated}
        saveSuccessCallback={saveSuccessCallback}
        onSuccess={onSuccess}
        onClose={onClose}
        config={config}
        isCreateLinkSuccess={isCreateLinkSuccess}
        relationTypeName={relationTypeName}
        relationDirection={relationDirection}
      />
    </InstanceCreateContextProvider>
  );
};
