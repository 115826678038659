import React, { useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import StateFilter, { StateFilterContainer, ReportStateFilterContainer } from '../StateFilter';
import AttributeFilter, { AttributeFilterContainer, ReportAttributeFilterContainer } from '../AttributeFilter';
import { FilterPopup } from './FilterPopup';
import ParentFilter, { ParentFilterContainer, ReportParentFilterContainer } from '../ParentFilter';
import RelationFilter, { RelationFilterContainer, ReportRelationFilterContainer } from '../RelationFilter';
import { defaultSelection, isEmpty } from '../../Core';
import {
  useAppInsightsContext,
  PROTRAK_EVENTS
} from '../../Contexts/AppInsightsContext';

export const Filter = ({
  attributeFilter,
  stateFilter,
  parentFilter,
  relationFilter,
  fields,
  onFilterApplied,
  instanceId,
  toggleFilterPopup,
  defaultConfig,
  hideParentFilter = false,
  hideRelationFilter = false,
  hideStateFilter = false,
  hideAttributeFilter = false,
  isReportFilter = false
}) => {
  let initialStateFilter = createCopyOfFilter(stateFilter);
  let initialAttributeFilter = createCopyOfFilter(attributeFilter);
  let initialParentFilter = createCopyOfFilter(parentFilter);
  let initialRelationFilter = createCopyOfFilter(relationFilter);

  const { trackEvent } = useAppInsightsContext();

  const [defaultStateFilter, setDefaultStateFilter] = useState(
    initialStateFilter
  );
  const [defaultAttributeFilter, setDefaultAttributeFilter] = useState(
    initialAttributeFilter
  );
  const [defaultParentFilter, setDefaultParentFilter] = useState(
    initialParentFilter
  );
  const [defaultRelationFilter, setDefaultRelationFilter] = useState(
    initialRelationFilter
  );

  const [canReset, setCanReset] = useState(false);
  const [canApply, setCanApply] = useState(false);

  function createCopyOfFilter(filter) {
    return cloneDeep(filter);
  }

  const onResetButtonClicked = () => {
    setDefaultStateFilter(defaultConfig.stateFilter);
    setDefaultAttributeFilter(defaultConfig.attributeFilter);
    setDefaultParentFilter(defaultConfig.parentFilter);
    setDefaultRelationFilter(defaultConfig.relationFilters);
    setCanReset(true);
    setCanApply(!isFilterValid());
  };

  const isFilterValid = () => {
    return !isEmpty(defaultConfig.stateFilter)
    || !isEmpty(defaultConfig.attributeFilter)
    || !isEmpty(defaultConfig.parentFilter)
    || !isEmpty(defaultConfig.relationFilters);
  };

  const onClearButtonClicked = () => {
    if (attributeFilter && attributeFilter.isVisible === true) {
      setDefaultAttributeFilter({ isVisible: true });
    } else {
      setDefaultAttributeFilter(null);
    }
  };

  const onStateFilterChanged = newStates => {
    initialStateFilter = {
      ...initialStateFilter,
      states: [...newStates]
    };

    setDefaultStateFilter(initialStateFilter);
    setCanReset(false);
  };

  const onAttributeFilterChanged = newFilter => {
    initialAttributeFilter = {
      ...initialAttributeFilter,
      attributeGroups: [...newFilter.attributeGroups]
    };

    setDefaultAttributeFilter(initialAttributeFilter);
    setCanReset(false);
  };

  const onParentFilterChanged = (newFilter, index) => {
    let appliedParentFilter = {
      ...defaultParentFilter
    };
    appliedParentFilter.filters[index] = newFilter;

    setDefaultParentFilter(appliedParentFilter);
    setCanReset(false);
  };

  const onRelationFilterChanged = (newFilter, index) => {
    let appliedRelationFilter = [...defaultRelationFilter];

    appliedRelationFilter[index] = newFilter;

    setDefaultRelationFilter(appliedRelationFilter);
    setCanReset(false);
  };

  const setInvalidExpressionPresent = value => {
    setCanApply(value);
  };

  const onSuccessCallback = () => {
    if (
      defaultAttributeFilter &&
      defaultAttributeFilter.attributeGroups &&
      defaultAttributeFilter.attributeGroups.length > 0
    ) {
      defaultAttributeFilter.attributeGroups.forEach(items => {
        items.attributeFilterConditions.forEach(element => {
          if (typeof element.firstValue === 'string') {
            element.firstValue = element.firstValue
              ? element.firstValue.trim()
              : element.firstValue;
          }
          if (typeof element.secondValue === 'string') {
            element.secondValue = element.secondValue
              ? element.secondValue.trim()
              : element.secondValue;
          }
        });
      });
    }
    trackEvent(PROTRAK_EVENTS.FILTER);
    onFilterApplied(
      defaultStateFilter,
      defaultAttributeFilter,
      defaultParentFilter,
      defaultRelationFilter
    );
  };

  return (
    <FilterPopup
      closePopup={() => toggleFilterPopup && toggleFilterPopup()}
      successCallback={onSuccessCallback}
      isApplyDisabled={canApply}
      resetCallback={onResetButtonClicked}
      isDisabled={canReset}
      isReportFilter={isReportFilter}
    >
      {!hideParentFilter ? (
        getParentFilter({ isReportFilter, defaultParentFilter, onParentFilterChanged })
      ) : null}
      {!hideRelationFilter && defaultRelationFilter ? (
        getRelationFilter({ hideParentFilter, isReportFilter, defaultRelationFilter, onRelationFilterChanged, instanceId })
      ) : null}
      {!hideStateFilter ? (
        getStateFilter({ hideParentFilter, hideRelationFilter, defaultRelationFilter, isReportFilter, defaultStateFilter, onStateFilterChanged })
      ) : null}
      {!hideAttributeFilter ? (
        getAttributeFilter({
          hideParentFilter, hideRelationFilter,
          hideStateFilter, isReportFilter,
          parentFilter, relationFilter,
          defaultAttributeFilter,
          fields,
          defaultConfig,
          setInvalidExpressionPresent,
          onAttributeFilterChanged,
          onClearButtonClicked
        })
      ) : null}
    </FilterPopup>
  );
};

const getAttributeFilter = ({ hideParentFilter,
  hideRelationFilter,
  hideStateFilter,
  isReportFilter,
  parentFilter,
  relationFilter,
  defaultAttributeFilter,
  fields,
  defaultConfig,
  setInvalidExpressionPresent,
  onAttributeFilterChanged,
  onClearButtonClicked
}) => {
  const isParentFilterPresent = (parentFilter && parentFilter.filters.length > 0) ||
    (relationFilter && relationFilter.length > 1);
  const attributeFilterComponent = AttributeFilter({
    attributesFilter: defaultAttributeFilter,
    fields: getConfiguredAttributeFields(
      fields,
      defaultConfig.attributeFilter
    ),
    setInvalidExpressionPresent: setInvalidExpressionPresent,
    onAttributeFilterApplied: onAttributeFilterChanged,
    clearAttributeFilter: onClearButtonClicked
  });
  if (!attributeFilterComponent) {
    return null;
  }
  if (isReportFilter){
    return (<ReportAttributeFilterContainer height={isParentFilterPresent ? '40vh' : '50vh'} applyMarginTop={!hideParentFilter || !hideRelationFilter || !hideStateFilter || !isReportFilter}>{attributeFilterComponent}</ReportAttributeFilterContainer>);
  }
  return (<AttributeFilterContainer height={isParentFilterPresent ? '40vh' : '50vh'}>{attributeFilterComponent}</AttributeFilterContainer>);

};

const getStateFilter = ({ hideParentFilter, hideRelationFilter, isReportFilter, defaultStateFilter, onStateFilterChanged, defaultRelationFilter }) => {
  const stateFilterComponent = StateFilter({
    stateFilter: defaultStateFilter,
    onStateFilterChanged: onStateFilterChanged
  });
  if (!stateFilterComponent) {
    return null;
  }
  return (
    isReportFilter
      ? <ReportStateFilterContainer applyMarginTop={!hideParentFilter || (!hideRelationFilter && defaultRelationFilter) || !isReportFilter}>{stateFilterComponent}</ReportStateFilterContainer>
      : <StateFilterContainer>{stateFilterComponent}</StateFilterContainer>
  );
};

const getRelationFilter = ({ hideParentFilter, isReportFilter, defaultRelationFilter, onRelationFilterChanged, instanceId }) => {
  const relationFilterComponent = RelationFilter({
    relationFilters: defaultRelationFilter,
    onParentFilterChanged: onRelationFilterChanged,
    instanceId: instanceId
  });
  if (!relationFilterComponent) {
    return null;
  }
  return (
    isReportFilter
      ? <ReportRelationFilterContainer applyMarginTop={!hideParentFilter || !isReportFilter}>{relationFilterComponent}</ReportRelationFilterContainer>
      : <RelationFilterContainer>{relationFilterComponent}</RelationFilterContainer>
  );
};

const getParentFilter = ({ isReportFilter, defaultParentFilter, onParentFilterChanged }) => {
  const parentFilterComponent = ParentFilter({
    parentFilters: defaultParentFilter,
    onParentFilterChanged: onParentFilterChanged,
  });
  if (!parentFilterComponent) {
    return null;
  }
  return (
    isReportFilter
      ? <ReportParentFilterContainer applyMarginTop={!isReportFilter}>{parentFilterComponent}</ReportParentFilterContainer>
      : <ParentFilterContainer>{parentFilterComponent}</ParentFilterContainer>
  );
};

const getConfiguredAttributeFields = (fields, attributesFilter) => {
  let visibleFields = fields.filter(f => f.isVisible === true);

  attributesFilter &&
    attributesFilter.attributeGroups &&
    attributesFilter.attributeGroups.forEach(function (attrGroup) {
      return attrGroup.attributeFilterConditions.forEach(function (
        attrFilterCondition
      ) {
        if (attrFilterCondition.attributeName !== defaultSelection) {
          let attrFields = visibleFields.find(
            i => i.attributeName === attrFilterCondition.attributeName
          );
          if (!attrFields) {
            let invisibleFields = fields.find(
              i => i.attributeName === attrFilterCondition.attributeName
            );
            visibleFields.push(invisibleFields);
          }
        }
      });
    });

  return visibleFields;
};
