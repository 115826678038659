import React from 'react';
import classes from './Popup.module.css';
import classnames from 'classnames';
import { Button, ButtonEnums, H2 } from '../../../lib/components';
import { CancelIcon } from '../../Icons/SVGIcons';

export const Popup = ({
  children,
  title,
  closePopup,
  successButtonText,
  successCallback,
  closeButtonText,
  resetButtonText,
  showResetButton = false,
  resetCallback,
  noSuccessCallback,
  noCloseButton,
  disableSuccessButton
}) => {
  return (
    <div className={classnames('popup-overlay', 'overflow-auto')}>
      <div className={classes['popup-inner']}>
        <div className={classes['popup-header']}>
          <H2>{title}</H2>
          <div className={classes['close-button']}>
            <Button
              appearance={ButtonEnums.Appearance.TransparentWithHover}
              onClick={closePopup}
              size={ButtonEnums.Size.Small}
            >
              <CancelIcon />
            </Button>
          </div>
        </div>
        <div className={classes['popup-body']}>{children}</div>

        <div className={classes['popup-footer']}>
          <div className={classes['reset-button-container']}>
            {showResetButton ? (
              <div className={classes['footer-button']}>
                <Button
                  appearance={ButtonEnums.Appearance.Primary}
                  text={resetButtonText}
                  onClick={resetCallback}
                  size={ButtonEnums.Size.Medium}
                />
              </div>
            ) : null}
          </div>

          <div className={classes['footer-buttons']}>
            {noSuccessCallback ? null : (
              <div className={classes['footer-button']}>
                <Button
                  appearance={ButtonEnums.Appearance.Primary}
                  text={successButtonText}
                  onClick={successCallback}
                  size={ButtonEnums.Size.Medium}
                  disabled={disableSuccessButton}
                />
              </div>
            )}

            {noCloseButton ? null : (
              <div className={classes['footer-button']}>
                <Button
                  appearance={ButtonEnums.Appearance.Default}
                  outline
                  text={closeButtonText || 'Close'}
                  onClick={closePopup}
                  size={ButtonEnums.Size.Medium}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
