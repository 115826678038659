import React, { useState } from 'react';
import { Box, Grid, Button, ButtonEnums, Modal } from '../../../lib/components';
import useTranslatedValue from '../../../Hooks/useTranslatedValue';
import ErrorDetails from '../../../Components/Common/ErrorDetails';

export const ErrorModal = ({ state }) => {
  const [showError, setShowError] = useState(true);
  const okText = useTranslatedValue('OK');
  return (
    <Modal open={showError}>
      <Grid
        columns={['1fr']}
        areas={[['top']]}
        rows={['2fr', '1fr']}
        backgroundColor={'SURFACE'}
        border="1px solid var(--primary-lighter)"
        padding="20px 10px 5px 10px"
        textAllign="left"
        width="auto"
        overflow="auto"
        position="fixed"
        justifyItems="center"
        style={{
          left: '40%',
          right: '40%',
          top: '15%'
        }}
      >
        <ErrorDetails state={state} />
        <Box justifySelf="end">
          <Box padding="5px 5px" display="inline-block">
            <Button
              appearance={ButtonEnums.Appearance.Primary}
              text={okText}
              onClick={() => setShowError(!showError)}
              size={ButtonEnums.Size.Medium}
            />
          </Box>
        </Box>
      </Grid>
    </Modal>
  );
};
