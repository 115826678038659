import React, {
  useEffect,
  useState,
  useReducer,
  useRef,
  useCallback
} from 'react';
import { useCookies } from 'react-cookie';
import {
  useAuthenticate,
  invalidateToken,
  useValidateAccount,
  AuthContext,
  keyValueStorage,
  TOKEN_CACHE_KEY
} from '../Core';
import { refreshToken } from '../Components/Login/SSOAuthProvider';
import useTranslatedValue from '../Hooks/useTranslatedValue';
import CryptoJS from 'crypto-js';
import { useAppInsightsContext } from './AppInsightsContext';

const userDataCookieOptions = { path: '/' };
export const SYSTEM_GENERATED_PASSWORD_KEY = '__is_system_generated_password__';

const AuthContextProvider = props => {
  const sessionExpiredMessage = useTranslatedValue('SESSION_EXPIRED');
  const [userLoginHook, doPost] = useAuthenticate();
  const [validateUserHook, validate] = useValidateAccount();
  const [userValidationResponse, setUserValidationResponse] = useState({});
  const [cookies, setCookie, removeCookie] = useCookies([TOKEN_CACHE_KEY]);
  const [userData, setUserData] = useState(
    getAccessTokenIfUserAlreadyLoggedIn(cookies)
  );
  const [userEmailId, setUserEmailId] = useState('');
  const [isError, setIsError] = useState(false);
  const [isErrorInValidating, setIsErrorInValidating] = useState(false);
  const [ssoTokenRenewalObj, setSSOTokenRenewalObj] = useState({});
  const [isTokenRenewalInProgress, setIsTokenRenewalInProgress] = useState(
    false
  );
  const [isLoginWithPassword, setIsLoginWithPassword] = useState();
  const [ssoConfiguration, setSSOConfiguration] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [userLogo, setUserLogo] = useState();
  const [selectedOrgId, setSelectedOrgId] = useState();
  const { getData, setData } = keyValueStorage();
  const [renewTokenState, dispatch] = useReducer(renewTokenReducer, {});
  const lastRunApi = useRef();
  const { setTenantDetails } = useAppInsightsContext();

  const onAuthError = (run, abort) => {
    if (window.parent.document._onAuthError) {
      window.parent.document._onAuthError();
      return;
    }
    logout(() => clearSelectedOrgId());
    // TODO - revisit below scenrio for SSO 401 scenario
    // lastRunApi.current = run;
    dispatch({ onSuccess: run });
    // const isRefreshTokenRequired = getData('isRefreshTokenRequired');
    // if (isRefreshTokenRequired === 'true' && !ssoTokenRenewalObj.isLoading) {
    //   abort();
    //   renewSSOToken();
    // } else {
    //   logout(() => clearSelectedOrgId());
    // }
  };

  const updateCookieForSystemGeneratedPassword = data => {
    if (!data && !data.userData) {
      return;
    }
    if (
      (isLoginWithPassword || cookies[SYSTEM_GENERATED_PASSWORD_KEY]) &&
      data.userData.isSystemGeneratedPassword
    ) {
      setCookie(SYSTEM_GENERATED_PASSWORD_KEY, true, userDataCookieOptions);
    } else {
      removeCookie(SYSTEM_GENERATED_PASSWORD_KEY, userDataCookieOptions);
    }
  };

  const getValueForKey = key => {
    return JSON.parse(getData(key));
  };
  const addKeyValue = (key, data) => {
    setData(key, JSON.stringify(data));
  };

  const login = loginProps => {
    const {
      orgId,
      userEmail,
      password,
      provider,
      accessToken,
      logout
    } = loginProps;
    setSelectedOrgId(orgId);
    setSSOTokenRenewalObj({});
    setIsError(false);
    setUserEmailId(userEmail);
    doPost(
      { userEmail, password, provider, accessToken, orgId, logout },
      AuthContext.logout
    );
  };

  const loginWithDifferentUser = () => {
    setSSOTokenRenewalObj({});
    setUserValidationResponse({});
    setIsLoginWithPassword(false);
    setSSOConfiguration();
    setIsError(false);
  };

  const validateUser = userEmail => {
    setSSOConfiguration(null);
    validate({ userEmail });
  };

  const renewSSOToken = async () => {
    setErrorMessage(sessionExpiredMessage);
    let ssoObj;
    if (ssoConfiguration) {
      ssoObj = ssoConfiguration;
    } else {
      ssoObj = JSON.parse(getData('ssoObj'));
      setSSOConfiguration(ssoObj);
    }

    if (ssoObj) {
      setUserLogo(ssoObj.userLogo);
      setSSOTokenRenewalObj({});
      setIsTokenRenewalInProgress(true);
      const response = await refreshToken(ssoObj);
      if (response && response.idToken && response.idToken.rawIdToken) {
        login({
          userEmail: ssoObj.userEmail,
          provider: ssoObj.providerName,
          accessToken: response.idToken.rawIdToken,
          orgId: selectedOrgId
        });
      } else {
        logout();
      }
    } else {
      logout();
    }
  };

  useEffect(() => {
    if (userData && !cookies[TOKEN_CACHE_KEY]) {
      setCookie(TOKEN_CACHE_KEY, userData, userDataCookieOptions);
    }
  });

  useEffect(() => {
    if (!isTokenRenewalInProgress && userLoginHook.isFulfilled) {
      setUserData(userLoginHook.data);
      setCookie(TOKEN_CACHE_KEY, userLoginHook.data, userDataCookieOptions);
      setSSOTokenRenewalObj(userLoginHook);
      setIsError(false);
    } else if (userLoginHook.isError) {
      setErrorMessage(
        userLoginHook.data &&
        userLoginHook.data.response &&
        userLoginHook.data.response.data
      );
      setUserData(userLoginHook.data);
      setCookie(TOKEN_CACHE_KEY, userLoginHook.data, userDataCookieOptions);
      setSSOTokenRenewalObj(userLoginHook);
      setIsError(true);
    } else {
      setIsTokenRenewalInProgress(false);
    }
  }, [isTokenRenewalInProgress, setCookie, userLoginHook]);

  useEffect(() => {
    if (ssoTokenRenewalObj.isFulfilled) {
      renewTokenState.onSuccess && lastRunApi.current && lastRunApi.current();
      setSSOTokenRenewalObj({});
    } else if (ssoTokenRenewalObj.isError) {
      if (isError) {
        loginFailed();
      } else {
        logout();
      }
    }
  }, [logout, renewTokenState, ssoTokenRenewalObj, isError, loginFailed]);

  const resetLoginErrors = () => {
    setUserData(null);
    setIsError(false);
    setSSOTokenRenewalObj({});
  };

  const loginFailed = useCallback(() => {
    setUserData(null);
    removeCookie(TOKEN_CACHE_KEY, userDataCookieOptions);
    clearStorage();
  }, [removeCookie]);

  useEffect(() => {
    if (validateUserHook.isFulfilled) {
      if (validateUserHook.data && validateUserHook.data.length === 1) {
        setTenantDetails(validateUserHook.data[0]);
      }
      setIsErrorInValidating(false);
    } else if (validateUserHook.isError) {
      setIsErrorInValidating(true);
    }
    setUserValidationResponse(validateUserHook);
  }, [validateUserHook, setTenantDetails]);

  const logout = useCallback(
    onLogoutSuccess => {
      invalidateToken(userData);
      setUserValidationResponse({});
      setIsLoginWithPassword(false);
      setUserData(null);
      removeCookie(TOKEN_CACHE_KEY, userDataCookieOptions);
      removeCookie(SYSTEM_GENERATED_PASSWORD_KEY, userDataCookieOptions);
      clearStorage();
      onLogoutSuccess && onLogoutSuccess();
    },
    [userData, removeCookie]
  );

  const clearSelectedOrgId = () => {
    setSelectedOrgId(null);
  };
  return (
    <AuthContext.Provider
      value={{
        userData,
        userEmailId,
        login,
        logout,
        isError,
        validateUser,
        isErrorInValidating,
        userValidationResponse,
        setUserValidationResponse,
        renewSSOToken,
        ssoTokenRenewalObj,
        setSSOTokenRenewalObj,
        ssoConfiguration,
        setSSOConfiguration,
        errorMessage,
        setErrorMessage,
        userLogo,
        setUserLogo,
        isLoginWithPassword,
        setIsLoginWithPassword,
        selectedOrgId,
        clearSelectedOrgId,
        setSelectedOrgId,
        setIsErrorInValidating,
        onAuthError,
        getValueForKey,
        addKeyValue,
        loginWithDifferentUser,
        resetLoginErrors,
        updateCookieForSystemGeneratedPassword
      }}
      {...props}
    />
  );
};

const getAccessTokenIfUserAlreadyLoggedIn = cookies => {
  if (window.location.href.includes('admin/')) {
    if (
      window.parent.document.cookie &&
      window.parent.document.cookie.includes('__protrak_user_token__=')
    ) {
      const cookieArr = window.parent.document.cookie.split(
        '__protrak_user_token__='
      );
      const tokenArr = cookieArr[1].split(';');
      if (tokenArr && tokenArr.length > 0) {
        const tokenObjectString = decodeURIComponent(tokenArr[0]);
        if (tokenObjectString && tokenObjectString.includes('accessToken')) {
          return {
            accessToken: JSON.parse(tokenObjectString).accessToken,
            isAdmin: true
          };
        }
        if (tokenObjectString) {
          return {
            accessToken: tokenObjectString,
            isAdmin: true
          };
        }
      }
    }
    if (window.parent.document._onAuthError) {
      window.parent.document._onAuthError();
      return;
    }
  }
  const searchString = window.location.search.replace('?', '');
  const values = searchString.split('&');
  const tokenValue = values.find(value => value.includes('token'));
  if (tokenValue) {
    const token = tokenValue.replace('token=', '');
    const bytes = CryptoJS.AES.decrypt(
      token,
      process.env.REACT_APP_DECRYPT_SECRET_KEY
    );
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return { accessToken: decrypted, isAdmin: false };
  }

  return cookies[TOKEN_CACHE_KEY];
};

function renewTokenReducer(state, action) {
  if (!action) {
    throw new Error('Invalid action passed, action.');
  }
  return {
    ...state,
    onSuccess: action.onSuccess
  };
}

const clearStorage = () => {
  window.localStorage.clear();
};

export { AuthContextProvider };
