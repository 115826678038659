import React from 'react';
import classnames from 'classnames';

const SPACE = ' ';

export function Prefix({ value }) {
  if (!value) {
    return null;
  }
  return <span>{`${value}${SPACE}`}</span>;
}

export function Suffix({ value }) {
  if (!value) {
    return null;
  }
  return <span>{`${SPACE}${value}`}</span>;
}

export function PrefixForInput({ value }) {
  if (!value) {
    return null;
  }
  return (
    <span
      className={classnames('form-input-prefix')}
    >{`${value}${SPACE}`}</span>
  );
}

export function SuffixForInput({ value }) {
  if (!value) {
    return null;
  }
  return (
    <span
      className={classnames('form-input-suffix')}
    >{`${value}${SPACE}`}</span>
  );
}
