import { useProtrakApi } from '../utils';
const typeBase = '/types';
export const CONFIG_TYPE = {
  CREATE: 'create',
  VIEW: 'view',
  EDIT: 'edit',
  DASHBOARD: 'dashboard',
  REPORT: 'report'
};

export const ATTRIBUTE_FIELD_TYPE_CONTRACT = {
  NAME: 'Name',
  RELATION_ATTRIBUTE: 'Relation Attribute',
  STATE: 'State',
  ATTRIBUTE: 'Attribute',
  PARENT: 'Parent',
  ACTIONS: 'Actions'
};

export function useTypeLayoutConfig(typeName, configType) {
  if (!typeName) {
    throw new Error('Invalid typeName passed');
  }
  if (!configType) {
    throw new Error('Invalid configType passed');
  }
  const endpoint = `${typeBase}/${typeName}/${configType}`;
  const { state } = useProtrakApi(
    { requestConfig: promiseFn, endpoint },
    { cacheKey: endpoint }
  );
  return state;
}

const promiseFn = ({ endpoint }) => {
  const cacheData = JSON.parse(window.localStorage.getItem(endpoint));
  return {
    endpoint: endpoint,
    config: {
      modified: cacheData ? cacheData.modified : null
    }
  };
};

export function useTypeConfiguration(typeName) {
  if (!typeName) {
    throw new Error('Invalid typeName passed');
  }

  const endpoint = `${typeBase}/${typeName}`;
  const { state, run } = useProtrakApi(
    { requestConfig: getTypeData, endpoint },
    { defer: true }
  );
  return { state, run };
}

const getTypeData = ({ endpoint }) => {
  return { endpoint: endpoint };
};

const typesPromise = () => {
  return {
    endpoint: typeBase,
    config: {
      method: 'GET'
    }
  };
};

const typePromise = ({ typeName }) => {
  const url = `${typeBase}/${typeName}`;
  return {
    endpoint: url,
    config: {
      method: 'GET'
    }
  };
};

export function useTypes() {
  const { state, run } = useProtrakApi(
    { requestConfig: typesPromise },
    { defer: false }
  );
  return { state, run };
}

export function useType(typeName) {
  const { state, run } = useProtrakApi(
    { requestConfig: typePromise, typeName },
    { defer: false }
  );
  return { state, run };
}

export function useFileTypesByName(typeName) {
  const endpoint = `${typeBase}/${typeName}/fileTypes`;
  const { state, run } = useProtrakApi(
    { requestConfig: promiseFn, endpoint },
    { cacheKey: endpoint }
  );

  return { state, run };
}
