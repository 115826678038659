import React, { useState } from 'react';
import classNames from 'classnames';
import classes from '../Filter/Filter.module.css';
import {
  renderFirstValueField,
  renderSecondValueField,
  getDashboardAttributesOptions
} from './ExpressionGroupUtility';
import { Picklist } from '../../lib/components';
import { Attribute, ErrorMessage } from '../../shared/Components';
import { defaultSelection, isEmpty } from '../../Core';

const SPECIAL_FILTER_CONDITIONS = [
  'IsEmpty',
  'IsNotEmpty',
  'ContextUser',
  'Today',
  'LastOneWeek',
  'LastOneMonth',
  'LastOneYear',
  'NextWeek',
  'NextOneMonth',
  'NextOneYear'
];

export const ExpressionGroup = ({
  filterExpression,
  fields,
  onFilterConditionChanged,
  attrFilterConditionLength,
  index,
  onFilterConditionOperatorChanged,
  onExpressionDelete,
  setInvalidExpressionPresent,
  showDelete
}) => {
  let fieldValue = [];
  let field = fields.find(
    f => f.attributeName === filterExpression.attributeName
  );

  if (!field) {
    field = {
      attributeName: filterExpression.attributeName,
      attributeType: 'Text',
      label: filterExpression.attributeName,
      value: filterExpression.attributeName,
      fieldType: 'Attribute'
    };
    fieldValue.push(field);
  }
  const fieldValues = fieldValue.concat(fields);
  const attributeNames = getDashboardAttributesOptions(fieldValues);

  let attribute = field ? Attribute(field) : {};
  const [conditions, setConditions] = useState(
    field ? attribute.filterConditions : []
  );

  const onAttributeChange = newOptions => {
    let selectedAttributeField = fieldValues.find(
      f => f.attributeName === newOptions[0]
    );

    attribute = Attribute(selectedAttributeField);
    setConditions(attribute.filterConditions);

    let newFilterCondition = {
      ...filterExpression,
      condition: defaultSelection,
      attributeName: newOptions[0],
      firstValue: '',
      secondValue: ''
    };
    let isInvalid =
      newFilterCondition.attributeName !== defaultSelection &&
      newFilterCondition.condition === defaultSelection;
    setInvalidExpressionPresent(isInvalid);
    onFilterConditionChanged(newFilterCondition, index);
  };

  const onConditonChange = newOptions => {
    let newFilterCondition = {};
    let selectedAttributeField = fieldValues.find(
      f => f.attributeName === filterExpression.attributeName
    );

    if (selectedAttributeField.attributeType === 'Boolean') {
      newFilterCondition = {
        ...filterExpression,
        firstValue: false,
        secondValue: '',
        condition: newOptions[0]
      };
    } else {
      newFilterCondition = {
        ...filterExpression,
        firstValue: '',
        secondValue: '',
        condition: newOptions[0]
      };
    }

    let isInvalid = isFirstValueRequired(newFilterCondition, fieldValues);
    setInvalidExpressionPresent(isInvalid);
    onFilterConditionChanged(newFilterCondition, index);
  };

  const onFirstValueChange = newValue => {
    let selectedAttributeField = fieldValues.find(
      f => f.attributeName === filterExpression.attributeName
    );

    let attr = Attribute(selectedAttributeField);
    let firstValue = attr.getFilterAttributeValue(newValue);

    let newFilterCondition = {
      ...filterExpression,
      firstValue: Array.isArray(firstValue) ? firstValue.join() : firstValue
    };

    let isInvalid = isFilterValid(newFilterCondition);
    setInvalidExpressionPresent(isInvalid);
    onFilterConditionChanged(newFilterCondition, index);
  };

  const onSecondValueChange = newValue => {
    let selectedAttributeField = fieldValues.find(
      f => f.attributeName === filterExpression.attributeName
    );

    let attr = Attribute(selectedAttributeField);
    let firstValue = attr.getAttributeValue(newValue);

    let newFilterCondition = {
      ...filterExpression,
      secondValue: Array.isArray(firstValue) ? firstValue.join() : firstValue
    };

    let isInvalid = isEmpty(newFilterCondition.secondValue);
    setInvalidExpressionPresent(isInvalid);
    onFilterConditionChanged(newFilterCondition, index);
  };

  const onOperatorChange = newOptions => {
    let currentFilterLength = index + 1;
    let newFilterCondition = {
      ...filterExpression,
      operator: newOptions[0]
    };

    if (
      filterExpression.operator === 'None' ||
      currentFilterLength === attrFilterConditionLength
    ) {
      onFilterConditionOperatorChanged(newFilterCondition, index);
    } else {
      onFilterConditionChanged(newFilterCondition, index);
    }
  };

  let isConditionDisabled = filterExpression.attributeName === defaultSelection;
  let isFirstFieldDisabled = filterExpression.condition === defaultSelection;

  let conditionErrorMessage =
    filterExpression.attributeName !== defaultSelection &&
      filterExpression.condition === defaultSelection
      ? 'This Field is required'
      : '';

  let firstFieldErrorMessage =
    filterExpression.condition !== defaultSelection &&
      field.attributeType !== 'Boolean' &&
      isEmpty(filterExpression.firstValue)
      ? 'This Field is required'
      : '';

  let secondFieldErrorMessage =
    filterExpression.condition === 'Between' &&
      isEmpty(filterExpression.secondValue)
      ? 'This Field is required'
      : '';

  const renderFirstValue = (
    <>
      {renderFirstValueField(
        field,
        filterExpression.firstValue,
        onFirstValueChange,
        filterExpression.condition,
        firstFieldErrorMessage,
        isFirstFieldDisabled
      )}
    </>
  );

  const renderSecondValue = (
    <>
      {renderSecondValueField({
        field,
        value: filterExpression.secondValue,
        onEdit: onSecondValueChange,
        secondFieldErrorMessage
      })}
    </>
  );

  const renderDeleteIcon = showDelete ? (
    <div
      onClick={() => onExpressionDelete(index)}
      className={classNames('font-color-red', 'cursor-pointer')}
    >
      <i className="fa fa-times" aria-hidden="true" />
    </div>
  ) : null;

  return (
    <div className={classes['filter-container']}>
      <div className={classNames('padding-5px', 'display-grid')}>
        <Picklist
          isMultiselect={false}
          fieldValue={[filterExpression.attributeName]}
          options={attributeNames}
          onEdit={onAttributeChange}
        />
      </div>

      <div className={classNames('padding-5px', 'display-grid')}>
        <Picklist
          isMultiselect={false}
          fieldValue={
            filterExpression.condition
              ? [filterExpression.condition]
              : [conditions[0]]
          }
          options={conditions}
          onEdit={onConditonChange}
          isDisabled={isConditionDisabled}
          placeholder="Select condition"
        />
        <ErrorMessage errorMessage={conditionErrorMessage} />
      </div>

      <div className={classNames('padding-5px', 'display-grid')}>
        {shouldRenderFirstValue(filterExpression)
          ? renderFirstValue
          : null}
      </div>

      <div className={classNames('padding-5px', 'display-grid')}>
        {field && filterExpression.condition === 'Between'
          ? renderSecondValue
          : null}
      </div>

      <div className={classNames('padding-5px', 'display-grid')}>
        <Picklist
          isMultiselect={false}
          fieldValue={[filterExpression.operator]}
          options={Operators}
          onEdit={onOperatorChange}
          isDisabled={isOperatorDisabled(filterExpression)}
          placeholder="Select operator"
        />
      </div>
      <div className={classes['delete-icon']}>{renderDeleteIcon}</div>
    </div>
  );
};

function shouldRenderFirstValue(filterExpression) {
  return !SPECIAL_FILTER_CONDITIONS.includes(filterExpression.condition);
}

function isFilterValid(newFilterCondition) {
  let { condition } = newFilterCondition;
  let isValid = true;
  if (condition === 'Between') {
    isValid =
      isEmpty(newFilterCondition.firstValue) ||
      isEmpty(newFilterCondition.secondValue);
  } else if (
    newFilterCondition.firstValue === true ||
    newFilterCondition.firstValue === false
  ) {
    isValid = false;
  } else {
    isValid = isEmpty(newFilterCondition.firstValue);
  }
  return isValid;
}

function isFirstValueRequired(newFilterCondition, fieldValues) {
  let { condition } = newFilterCondition;
  let selectedAttributeField = fieldValues.find(
    f => f.attributeName === newFilterCondition.attributeName
  );
  let isInvalid = false;
  if (SPECIAL_FILTER_CONDITIONS.includes(condition)) {
    isInvalid = false;
  } else {
    isInvalid =
      newFilterCondition.condition !== defaultSelection &&
      isEmpty(newFilterCondition.firstValue) &&
      selectedAttributeField.attributeType !== 'Boolean';
  }
  return isInvalid;
}

function isOperatorDisabled(filterExpression) {
  let { condition } = filterExpression;
  let isDisabled = true;
  if (SPECIAL_FILTER_CONDITIONS.includes(condition)) {
    isDisabled = false;
  } else if (condition === 'Between') {
    isDisabled =
      isEmpty(filterExpression.firstValue) ||
      isEmpty(filterExpression.secondValue);
  } else {
    isDisabled = isEmpty(filterExpression.firstValue);
  }
  return isDisabled;
}

export default ExpressionGroup;

const Operators = [
  { name: 'And', displayName: 'And' },
  { name: 'Or', displayName: 'Or' }
];
