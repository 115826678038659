import React from 'react';
import classes from './Button.module.css';
import classnames from 'classnames';
import { Box } from '../../../lib/components';

export const Button = ({
  text,
  title,
  disabled = false,
  appearance = ButtonEnums.Appearance.Default,
  onClick,
  size,
  outline,
  children,
  type = 'button',
  style,
  textStyle
}) => {
  let padding = ButtonEnums.Padding.Default;
  if (size === ButtonEnums.Size.Large) {
    padding = ButtonEnums.Padding.Large;
  } else if (size === ButtonEnums.Size.Medium) {
    padding = ButtonEnums.Padding.Medium;
  } else if (size === ButtonEnums.Size.Small) {
    padding = ButtonEnums.Padding.Small;
  }

  return (
    <button
      className={classnames(
        classes['btn'],
        outline ? classes[`${appearance}-outline`] : classes[`${appearance}`]
      )}
      disabled={disabled === true ? 'disabled' : ''}
      onClick={onClick}
      title={title}
      type={type}
      style={style}
    >
      {
        <Box alignItems={'center'} padding={padding}>
          {children ? (
            <Box paddingLeft={'3px'} paddingRight={'3px'}>
              {children}
            </Box>
          ) : null}
          {text !== undefined ? (
            <span style={textStyle} className={classes['btn-text']}>
              {text}
            </span>
          ) : null}
        </Box>
      }
    </button>
  );
};

export const ButtonEnums = {
  Appearance: {
    Default: 'btn-default',
    Primary: 'btn-primary',
    Link: 'btn-link',
    LinkDark: 'btn-link-dark',
    Subtle: 'btn-subtle',
    SubtleLink: 'btn-subtle-link',
    Transparent: 'btn-transparent',
    TransparentWithHover: 'btn-transparent-with-bg'
  },
  Size: {
    Small: 'small',
    Medium: 'medium',
    Large: 'large'
  },
  Padding: {
    Default: '0',
    Small: '0.25rem',
    Medium: '0.4rem',
    Large: '0.55rem'
  }
};

export default Button;
