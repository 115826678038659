import { getMsalApp } from './ssoUtils';
import { keyValueStorage } from '../../Core';

export const login = (clientId, tenantId, userEmail, redirectUri) => {
  const { setData } = keyValueStorage();
  const msalApp = getMsalApp(clientId, tenantId, redirectUri);
  return msalApp
    .loginPopup({
      loginHint: userEmail
    })
    .then(response => {
      msalApp.setAccountCache(response.account);
      setData('userAccount', JSON.stringify(response.account));
      return response;
    })
    .catch(err => {
      return { isError: true, data: err };
    });
};

export const refreshToken = ssoObject => {
  const { getData } = keyValueStorage();
  const userAccount = JSON.parse(getData('userAccount'));
  if (userAccount) {
    for (const key of Object.keys(window.sessionStorage)) {
      if (key.includes('"authority":')) {
        window.sessionStorage.removeItem(key);
      }
    }
    const tokenRequest = {
      scopes: [ssoObject.clientId],
      loginHint: ssoObject.userEmail
    };
    const msalApp = new getMsalApp(
      ssoObject.clientId,
      ssoObject.tenantId,
      ssoObject.redirectUri
    );

    msalApp.setAccountCache(userAccount);
    msalApp.account = userAccount;

    return msalApp
      .acquireTokenSilent(tokenRequest)
      .then(response => {
        return response;
      })
      .catch(err => {
        return { isError: true, data: err };
      });
  }
};
