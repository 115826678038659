import React from 'react';
import { ParentFilterGroup } from './ParentFilterGroup';

export const ParentFilter = ({ parentFilters, onParentFilterChanged }) => {
  if (!parentFilters) {
    return null;
  }

  if (parentFilters.filters.length < 1) {
    return null;
  }

  const getInstQuery = key => {
    let parentobjarr = [];

    parentFilters.filters.forEach((parentFilter, index) => {
      if (index < key) {
        parentobjarr.push(parentFilter);
      }
    });

    return parentobjarr;
  };

  return (
    parentFilters.filters &&
      parentFilters.filters.map((parentFilter, key) => {
        let parentobjarr = getInstQuery(key);

        return (
          <ParentFilterGroup
            parentFilterConfig={parentFilter}
            index={key}
            key={key}
            isDisabled={parentFilters.enforced}
            parentFilter={{ filters: parentobjarr }}
            onEdit={onParentFilterChanged}
            typeName={parentFilter.typeName}
          />
        );
      })
  );
};
