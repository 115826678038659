import React from 'react';
import classes from './DataGrid.module.css';
import { Cell } from './Cell';
import { DescendingSortIcon, AscendingSortIcon } from './SortIcons';
import { Text } from '../../../lib/components';

export const Header = ({ columns, sortConfig, onSortApplied, columnObj }) => {
  const { sortedBy, isDescending } = sortConfig;
  const renderColumnHeader = column => {
    if (typeof column.Header === 'function') {
      return column.Header(column);
    }
    return column.Header;
  };
  const renderSortIndicator = column => {
    if (sortedBy && sortedBy === column.accessor) {
      return (
        <Text style={{ marginLeft: '0.2rem' }}>
          {isDescending ? <DescendingSortIcon /> : <AscendingSortIcon />}
        </Text>
      );
    }
    return null;
  };
  const handleSort = column => {
    if (!column.sortable) {
      return;
    }
    if (onSortApplied) {
      if (sortedBy && sortedBy === column.accessor) {
        //already sorted
        onSortApplied({
          sortBy: column.accessor,
          isSortByDescending: !isDescending
        });
      } else {
        onSortApplied({ sortBy: column.accessor, isSortByDescending: false });
      }
    }
  };
  if (columnObj !== undefined) {
    return getCell(
      columnObj.index,
      handleSort,
      columnObj,
      renderColumnHeader,
      renderSortIndicator
    );
  }
  const columnCells = columns.map((column, index) => {
    return getCell(
      index,
      handleSort,
      column,
      renderColumnHeader,
      renderSortIndicator
    );
  });
  return columnCells;
};
function getCell(
  index,
  handleSort,
  column,
  renderColumnHeader,
  renderSortIndicator
) {
  const cellClassNames = {
    [classes['header-cell']]: true
  };
  return (
    <Cell
      key={`ch_${index}`}
      classNamesToApply={cellClassNames}
      style={{ width: column.width }}
    >
      <div
        className={classes['header-cell-content']}
        onClick={() => handleSort(column)}
        data-testid="column-header"
        style={{
          cursor: `${column.sortable ? 'pointer' : 'default'}`
        }}
      >
        {renderColumnHeader(column)}
        {renderSortIndicator(column)}
      </div>
    </Cell>
  );
}
