import React from 'react';
import {
  Box,
  CheckBox,
  BorderBox,
  Container,
  Spinner
} from '../../../lib/components';
import { useGridSelectContext } from '../../../Contexts/GridSelectionContext';
import { useDimensions } from '../../../Hooks/useDimensions';
import {
  convertDataToDesiredFormatForVirtualizedGrid,
  convertColumnsToDesiredFormatForVirtualizedGrid
} from '../VirtualizedDataGrid';
import { useState } from 'react';
const VirtualDataGrid = React.lazy(
  async () => await import('../VirtualizedDataGrid/VirtualizedDataGrid')
);

export function SelectableDataGrid({
  columns = [],
  data,
  singleSelect,
  isLoading,
  nextPage,
  sortConfig,
  onSortApplied,
  paginationContext,
  ...otherProps
}) {
  const [ref, dimensions] = useDimensions();
  const [previouslyConvertedData, setPreviouslyConvertedData] = useState([]);
  const {
    selectedItems: selectedRows = [],
    areAllItemsSelected,
    onItemSelect,
    keyAccessor,
    onItemUnselect
  } = useGridSelectContext();

  const checkboxColumn = {
    Header:
      singleSelect || (data && !data.length) ? null : (
        <Box
          justifyContent="center"
          alignItems="center"
          height="100%"
          padding="0 0.5rem"
        >
          <CheckBox
            data-testid={`row-selector-${-1}`}
            onChange={e => handleClick(e, onItemSelect, -1, onItemUnselect)}
            checked={areAllItemsSelected}
          />
        </Box>
      ),
    Cell: props => (
      <Box
        justifyContent="center"
        alignItems="center"
        height="100%"
        //padding="0 0.5rem"
      >
        <CheckBox
          data-testid={`row-selector-${props.rowIndex}`}
          onClick={e => {
            e.stopPropagation();
          }}
          onChange={() => {}}
          checked={selectedRows.some(
            r => r && r[keyAccessor] === props.original[keyAccessor]
          )}
        />
      </Box>
    ),
    Width: '40',
    WidthType: 'fixed'
  };

  const isRowSelected = item => {
    if (
      selectedRows.some(r => r && item && r[keyAccessor] === item[keyAccessor])
    ) {
      return true;
    }
    return false;
  };
  let convertedColumns = [];
  const allColumns = [checkboxColumn, ...columns];
  let convertedData = [];
  if (dimensions && dimensions.width) {
    convertedColumns = convertColumnsToDesiredFormatForVirtualizedGrid(
      allColumns,
      dimensions,
      sortConfig,
      onSortApplied,
      columns
    );
    convertedData = convertDataToDesiredFormatForVirtualizedGrid(
      data,
      convertedColumns,
      isRowSelected,
      paginationContext.skip === 0 ? [] : previouslyConvertedData,
      keyAccessor
    );
  }

  const getNextData = () => {
    setPreviouslyConvertedData(previouslyConvertedData.concat(convertedData));
    nextPage();
  };
  const getCombinedData = () => {
    if (isLoading && paginationContext.skip === 0) {
      return [];
    }
    if (paginationContext && paginationContext.skip === 0) {
      if (previouslyConvertedData.length !== 0) {
        setPreviouslyConvertedData([]);
      }
      return convertedData;
    }
    return previouslyConvertedData.concat(convertedData);
  };
  if (!(columns && columns.length)) {
    return null;
  }
  return (
    <BorderBox
      style={{ height: '100%', width: '99.7%' }}
      display={'block'}
      ref={ref}
    >
      {dimensions ? (
        <React.Suspense
          fallback={
            <Container>
              <Spinner />
            </Container>
          }
        >
          <VirtualDataGrid
            data={getCombinedData()}
            isLoading={isLoading}
            nextPage={getNextData}
            gridColumns={convertedColumns}
            dimensions={dimensions}
            key={dimensions.width + dimensions.height}
            widthType="px"
            isRowSelected={isRowSelected}
            onItemSelect={onItemSelect}
            onItemUnselect={onItemUnselect}
            {...otherProps}
          />
        </React.Suspense>
      ) : null}
    </BorderBox>
  );
}

const handleClick = (event, onRowSelect, index, onRowUnselect) => {
  if (event.target.checked) {
    onRowSelect(index);
  } else {
    onRowUnselect(index);
  }
};
