import React, { useContext } from 'react';
import InstanceEditContext from '../../Contexts/InstaceEditContext';
import {
  Box,
  Spinner,
  Button,
  ButtonEnums,
  Modal,
  Grid,
  Text,
  H4
} from '../../lib/components';
import ErrorDetails from '../Common/ErrorDetails';
import useTranslatedValue from '../../Hooks/useTranslatedValue';
import { InstancePromoteStatus } from '../Common/PromoteActions/InstancePromoteStatus';
export const SaveProgressModal = ({
  promoteReminderActions,
  showOnlyPromoteModal = false,
  executePromote,
  disablePromoteReminder,
  selectedAction,
  isPromoteProgressVisible,
  selectedPromoteAction,
  promoteCallback,
  postAsyncPromote,
  comments,
  isModalOpen,
  errorType,
  saveSuccessCallback,
  isPromoteActionsCompleted,
  promoteSuccessCallback,
  instance,
  onCancel,
  disable
}) => {
  const { instanceEditState } = useContext(InstanceEditContext);

  const OkText = useTranslatedValue('OK');
  const isSaveOperationComplete =
    instanceEditState &&
    instanceEditState.saveOperationState !== 'pending' &&
    instanceEditState.saveOperationState !== 'error';

  const isSaveSuccess =
    instanceEditState && instanceEditState.saveOperationState === 'success';

  const fileUploadStatus = () => {
    if (instanceEditState && !instanceEditState.uploadAttachmentState) {
      return null;
    }
    if (
      instanceEditState &&
      instanceEditState.uploadAttachmentState === 'inProgress'
    ) {
      return (
        <Box marginTop="10px">
          <Box margin="0 20px" alignItems="center" height="20px" width="20px">
            <Spinner small />
          </Box>

          <Text>{`Uploading ${instanceEditState &&
            instanceEditState.fileCount} file(s)...`}</Text>
        </Box>
      );
    }
    if (
      instanceEditState &&
      instanceEditState.uploadAttachmentState === 'success' &&
      !isPromoteProgressVisible
    ) {
      return (
        <Box marginTop="10px">
          <Box
            color="SUCCESS"
            margin="0 20px"
            alignItems="center"
            background="#C8E6C9"
            borderRadius="50%"
            height="20px"
            width="20px"
          >
            <i
              className="fas fa-check"
              aria-hidden="true"
              style={{ padding: '0 4px' }}
            />
          </Box>
          <Text>{'File(s) uploaded successfully'}</Text>
        </Box>
      );
    }
    if (
      instanceEditState &&
      instanceEditState.uploadAttachmentState === 'error'
    ) {
      return (
        <Box marginTop="10px">
          <Box
            color={'ERROR'}
            margin="0 20px"
            alignItems="center"
            height="20px"
            width="20px"
          >
            <i
              className="fas fa-exclamation-triangle"
              style={{ padding: '0 4px' }}
            />
          </Box>
          <ErrorDetails state={instanceEditState} />
        </Box>
      );
    }
  };
  const saveStatus = () => {
    if (
      instanceEditState &&
      instanceEditState.saveOperationState === 'pending' &&
      instanceEditState.uploadAttachmentState !== 'inProgress'
    ) {
      return (
        <Box marginTop="10px">
          <Box margin="0 20px" alignItems="center" height="20px" width="20px">
            <Spinner small />
          </Box>

          <Text>{'Saving your changes...'}</Text>
        </Box>
      );
    }
    if (
      instanceEditState &&
      instanceEditState.saveOperationState === 'success'
    ) {
      return (
        <Box marginTop="10px">
          <Box
            color="SUCCESS"
            margin="0 20px"
            alignItems="center"
            background="#C8E6C9"
            borderRadius="50%"
            height="20px"
            width="20px"
          >
            <i
              className="fas fa-check"
              aria-hidden="true"
              style={{ padding: '0 4px' }}
            />
          </Box>
          <Text>{'Saved successfully'}</Text>
        </Box>
      );
    }
    if (instanceEditState && instanceEditState.saveOperationState === 'error') {
      return (
        <Box marginTop="10px">
          <Box
            color={'ERROR'}
            margin="0 20px"
            alignItems="center"
            height="20px"
            width="20px"
          >
            <i
              className="fas fa-exclamation-triangle"
              style={{ padding: '0 4px' }}
            />
          </Box>
          <ErrorDetails state={instanceEditState} />
        </Box>
      );
    }
    return null;
  };

  const promoteStatus = () => {
    if (!isPromoteProgressVisible) {
      return null;
    }
    return (
      <Box className="popup-body">
        <InstancePromoteStatus
          key={instance && instance.id}
          instanceId={instance && instance.id}
          errorType={errorType}
          action={selectedAction.value}
          actionLabel={selectedAction.label}
          comment={comments}
          promoteCallback={(id, message) => promoteSuccessCallback(id, message)}
          postAsyncPromote={postAsyncPromote}
        />
      </Box>
    );
  };

  function isSaveSuccessVisible() {
    if (showOnlyPromoteModal) {
      return false;
    }
    if (disablePromoteReminder) {
      return true;
    }
    if (
      instanceEditState &&
      instanceEditState.details &&
      !instanceEditState.details.allowedOperations
    ) {
      return true;
    }
    if (
      instanceEditState &&
      instanceEditState.details &&
      !instanceEditState.details.allowedActions
    ) {
      return true;
    }
    if (
      instanceEditState &&
      instanceEditState.details &&
      instanceEditState.details.allowedOperations &&
      !instanceEditState.details.allowedOperations.includes('Promote')
    ) {
      return true;
    }
    if (
      instanceEditState &&
      instanceEditState.details &&
      instanceEditState.details.allowedActions &&
      instanceEditState.details.allowedActions.length === 0
    ) {
      return true;
    }
    return false;
  }

  const promoteActionDialogue = () => {
    if (isSaveSuccessVisible()) {
      return null;
    }

    if (isPromoteProgressVisible) {
      return null;
    }

    return promoteReminderActions;
  };

  const ModalCloseButton = () => {
    if (
      (isSaveSuccess && disablePromoteReminder) ||
      (!showOnlyPromoteModal && isPromoteActionsCompleted)
    ) {
      return null;
    }

    return (
      <Button
        disabled={disable}
        appearance={ButtonEnums.Appearance.TransparentWithHover}
        onClick={
          isSaveSuccess && !disablePromoteReminder
            ? () => {
                onCancel();
                saveSuccessCallback();
              }
            : () => onCancel()
        }
        size={ButtonEnums.Size.Medium}
      >
        <i className="fa fa-times" aria-hidden="true" />
      </Button>
    );
  };
  const promoteOrSaveOperationStatus = () => {
    if (isPromoteProgressVisible) {
      return promoteStatus();
    }
    return saveStatus();
  };
  const operationStatus = () => {
    if (instanceEditState) {
      return (
        <>
          {fileUploadStatus()}
          {promoteOrSaveOperationStatus()}
          {isSaveOperationComplete ? promoteActionDialogue() : null}
        </>
      );
    }
    return (
      <>
        {promoteStatus()}
        {promoteActionDialogue()}
      </>
    );
  };
  return (
    <Modal open={isModalOpen} background>
      <Grid
        columns={['1fr']}
        areas={[['top']]}
        rows={['auto', 'auto', 'auto']}
        position="fixed"
        margin="auto"
        textAlign="left"
        width="40vw"
        boxShadow="1px 2px 2px 3px"
        boxShadowColor="var(--primary-lighter)"
        borderRadius="10px"
        style={{
          right: '10%',
          top: '20%',
          left: '10%',
          background: 'var(--surface)'
        }}
      >
        <Grid
          columns={['4fr', '1fr']}
          padding={'5px 10px'}
          borderBottom="0.5px solid var(--primary-lighter)"
          areas={[['filter', 'filtertop']]}
          rows={['auto']}
          alignItems={'center'}
          height="6vh"
        >
          <H4
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
            color={'var(--primary-lighter)'}
          >
            {instance && instance.name}
          </H4>
          <Box justifySelf="end">
            <ModalCloseButton />
          </Box>
        </Grid>
        <Box
          direction={'column'}
          minHeight="20vh"
          maxHeight="60vh"
          overflow="auto"
          style={isPromoteProgressVisible ? { justifyContent: 'center' } : null}
        >
          {operationStatus()}
        </Box>

        {!instanceEditState || isSaveOperationComplete ? (
          <Grid
            rows={['auto']}
            columns={['1fr', 'auto']}
            areas={[['main']]}
            padding="5px 10px"
            borderTop="0.5px solid var(--primary-lighter)"
            height="7vh"
          >
            <Box justifySelf="end">
              {
                <ModalFooter
                  executePromote={executePromote}
                  isPromoteActionsCompleted={isPromoteActionsCompleted}
                  disable={disable}
                  promoteCallback={promoteCallback}
                  selectedPromoteAction={selectedPromoteAction}
                  onCancel={onCancel}
                  OkText={OkText}
                  isSaveSuccessVisible={isSaveSuccessVisible()}
                  saveSuccessCallback={saveSuccessCallback}
                  instanceEditState={instanceEditState}
                />
              }
            </Box>
          </Grid>
        ) : null}
      </Grid>
    </Modal>
  );
};

const ModalFooter = ({
  executePromote,
  isPromoteActionsCompleted,
  selectedPromoteAction,
  onCancel,
  OkText,
  disable,
  isSaveSuccessVisible,
  promoteCallback,
  saveSuccessCallback
}) => {
  if (isSaveSuccessVisible) {
    return (
      <Box margin={'5px'} display="inline-block">
        <Button
          appearance={ButtonEnums.Appearance.Primary}
          size={ButtonEnums.Size.Medium}
          outline
          onClick={() => {
            onCancel();
            saveSuccessCallback();
          }}
          text={OkText}
        />
      </Box>
    );
  }

  if (isPromoteActionsCompleted && disable === false) {
    return (
      <Box margin={'5px'} display="inline-block">
        <Button
          appearance={ButtonEnums.Appearance.Primary}
          size={ButtonEnums.Size.Medium}
          outline
          onClick={promoteCallback}
          text={OkText}
        />
      </Box>
    );
  }
  return (
    <>
      <Box margin={'5px'} display="inline-block">
        <Button
          appearance={ButtonEnums.Appearance.Primary}
          size={ButtonEnums.Size.Medium}
          text={'Submit'}
          outline
          onClick={executePromote}
          disabled={disable}
        />
      </Box>
      {!selectedPromoteAction ? (
        <Box margin={'5px'} display="inline-block">
          <Button
            appearance={ButtonEnums.Appearance.Default}
            text={'Not Now'}
            outline
            onClick={() => {
              onCancel();
              saveSuccessCallback();
            }}
            size={ButtonEnums.Size.Medium}
            disabled={disable}
          />
        </Box>
      ) : null}
    </>
  );
};
