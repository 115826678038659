export function keyValueStorage() {
  const setData = (key, data) => {
    window.localStorage.setItem(key, data);
  };

  const getData = key => {
    return window.localStorage.getItem(key);
  };
  return {setData, getData};
}
