import React from 'react';
import Reference from '../../Components/Forms/Reference';
import { Enums } from '../../Enums';
import { Prefix, Suffix } from './prefixSuffix';
import { InstanceLink } from './InstanceLink';
import { Box, TextBox, TextArea } from '../../lib/components';

export const ReferenceAttribute = config => {
  const getValue = value => {
    if (!value) {
      return null;
    }
    return value.referenceValues;
  };

  const canCreateRelatedType = () => {
    return (
      config.canCreateRelatedType &&
      !(config.linkOption && config.linkOption === 'SearchOnly')
    );
  };

  const canLinkRelatedType = () => {
    return (
      config.canLinkRelatedType &&
      !(config.linkOption && config.linkOption === 'CreateOnly')
    );
  };

  const getDisplayValue = value => {
    return getValue(value);
  };

  const renderAttributeValue = value => {
    const fieldValue = getValue(value);
    let relatedInstances =
      fieldValue &&
      fieldValue.map((val, index) => {
        return config.relatedTypeName && val.id ? (
          <InstanceLink
            typeName={config.relatedTypeName}
            instanceId={val.id}
            targetLayout={'view'}
            openInNewTab={true}
            key={val.id}
          >
            {val.name}
            {fieldValue.length !== index + 1 && fieldValue.length !== 0 ? (
              <span>,&nbsp;</span>
            ) : null}
          </InstanceLink>
        ) : (
          <div key={val.id}>
            {val.name}
            {fieldValue.length !== index + 1 && fieldValue.length !== 0 ? (
              <span>,&nbsp;</span>
            ) : null}
          </div>
        );
      });

    return fieldValue ? (
      <React.Fragment>
        <Prefix value={config.prefix} />
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'stretch'
          }}
        >
          {relatedInstances}
        </Box>
        <Suffix value={config.suffix} />
      </React.Fragment>
    ) : (
      '-'
    );
  };

  const getFilterValue = value => {
    if (value) {
      return typeof value === 'string' ? value : value.textValue;
    }
    return null;
  };

  const renderAttributeInput = (fieldValue, onEdit) => {
    return (
      <Reference
        value={getValue(fieldValue)}
        onEdit={newValue => onEdit({ referenceValues: newValue })}
        fieldLabel={config.label}
        relatedTypeName={config.relatedTypeName}
        relationDirection={config.relationDirection}
        isMultiselect={config.isMultiselect}
        canCreateRelatedType={canCreateRelatedType()}
        canLinkRelatedType={canLinkRelatedType()}
        config={config}
      />
    );
  };

  const renderFilterAttributeInput = (value, onEdit) => {
    if (config && config.isMultiselect) {
      return (
        <TextArea
          value={getFilterValue(value)}
          onEdit={onEdit}
          style={{ fontWeight: 'bold' }}
        />
      );
    } else {
      return (
        <Box height="28px" width="100%">
          <TextBox
            value={getFilterValue(value)}
            onEdit={newValue => onEdit({ textValue: newValue })}
          />
        </Box>
      );
    }
  };

  const filterOptions = [
    { displayName: 'Contains', name: Enums.Conditions.Contains },
    { displayName: 'NotContains', name: Enums.Conditions.NotContains },
    { displayName: 'Equals', name: Enums.Conditions.Equals },
    { displayName: 'In', name: Enums.Conditions.In },
    { displayName: 'NotEquals', name: Enums.Conditions.NotEquals },
    { displayName: 'IsEmpty', name: Enums.Conditions.IsEmpty },
    { displayName: 'IsNotEmpty', name: Enums.Conditions.IsNotEmpty }
  ];

  const defaultSortOrder = '';

  const evaluateCondition = (condition, valueObj, compareToValueStr) => {
    const value = getValue(valueObj);
    switch (condition) {
      case Enums.Conditions.Equals:
        return (
          value &&
          value.length === 1 &&
          value[0].name &&
          value[0].name.toLowerCase() === compareToValueStr.toLowerCase()
        );
      case Enums.Conditions.NotEquals:
        return (
          value &&
          value.length === 1 &&
          value[0].name &&
          value[0].name.toLowerCase() !== compareToValueStr.toLowerCase()
        );
      case Enums.Conditions.Contains:
        return (
          value &&
          value.some(
            ref =>
              ref.name &&
              ref.name.toLowerCase().includes(compareToValueStr.toLowerCase())
          )
        );
      case Enums.Conditions.NotContains:
        return (
          value &&
          !value.some(
            ref =>
              ref.name &&
              ref.name.toLowerCase().includes(compareToValueStr.toLowerCase())
          )
        );
      case Enums.Conditions.IsNotEmpty:
        return value && value.length > 0;
      case Enums.Conditions.IsEmpty:
        return !value || value.length === 0;
      case Enums.Conditions.In:
        const values = compareToValueStr.toLowerCase().split('\n');
        return (
          value &&
          value.some(ref => values.includes(ref.name && ref.name.toLowerCase()))
        );
      default:
        return false;
    }
  };

  return {
    getDisplayValue,
    renderAttributeValue,
    renderAttributeInput,
    evaluateCondition,
    renderFilterAttributeInput,
    filterOptions,
    getValue,
    getFilterValue,
    defaultSortOrder
  };
};
