import React from 'react';
import { Enums } from '../../Enums';
import { Prefix, Suffix } from './prefixSuffix';
import { FormattedDateTime, ProtrakDateTime } from '../../lib/components';
import {
  isDateBetween,
  isDateEqual,
  isDateGreater,
  isDateGreaterOrEqual,
  isDateLastOneMonth,
  isDateLastOneYear,
  isDateLastWeek,
  isDateLesser,
  isDateLessOrEqual,
  isDateNextWeek,
  isDateToday,
  isDateNextOneYear,
  isDateNextOneMonth,
  parseDate,
  formatDateTimeWithTimeZone
} from '../../Core';

export const DateTimeAttribute = config => {
  const getValue = value => {
    if (value) {
      return typeof value === 'string' ? value.trim() : value.dateValue;
    }
    return null;
  };

  const getDefaultValue = timezone => {
    if (config.defaultValue) {
      const parsedDate = parseDate(config.defaultValue[0], timezone);
      return { dateValue: parsedDate };
    }
    return { dateValue: null };
  };

  const getDisplayValue = (value, settings) => {
    const { timeFormat, dateFormat, timeZone } = settings.dateTimeFormat;
    return formatDateTimeWithTimeZone(
      getValue(value),
      timeFormat,
      dateFormat,
      timeZone
    );
  };

  const renderAttributeValue = value => {
    const val = getValue(value);
    if (!val) {
      return <>{'-'}</>;
    }
    return (
      <>
        <Prefix value={config.prefix} />
        <FormattedDateTime dateTimeStr={val} />
        <Suffix value={config.suffix} />
      </>
    );
  };

  const renderAttributeInput = (fieldValue, onEdit) => {
    return (
      <ProtrakDateTime
        value={getValue(fieldValue)}
        onEdit={newValue => onEdit({ dateValue: newValue })}
        showTimeInput={true}
        placeholder={config.placeholder}
        disabled={config.disabled}
      />
    );
  };

  const filterOptions = [
    { displayName: 'Between', name: Enums.Conditions.Between },
    {
      displayName: 'Less Than or Equal',
      name: Enums.Conditions.LessThanOrEqual
    },
    { displayName: 'Less Than', name: Enums.Conditions.LessThan },
    {
      displayName: 'Greater Than or Equal',
      name: Enums.Conditions.GreaterThanOrEqual
    },
    { displayName: 'Greater Than', name: Enums.Conditions.GreaterThan },
    { displayName: 'Equals', name: Enums.Conditions.Equals },
    { displayName: 'Is Empty', name: Enums.Conditions.IsEmpty },
    { displayName: 'Is Not Empty', name: Enums.Conditions.IsNotEmpty },
    { displayName: 'Today', name: Enums.Conditions.Today },
    { displayName: 'Last One Week', name: Enums.Conditions.LastOneWeek },
    { displayName: 'Last One Month', name: Enums.Conditions.LastOneMonth },
    { displayName: 'Last One Year', name: Enums.Conditions.LastOneYear },
    { displayName: 'Next Week', name: Enums.Conditions.NextWeek },
    { displayName: 'Next One Month', name: Enums.Conditions.NextOneMonth },
    { displayName: 'Next One Year', name: Enums.Conditions.NextOneYear }
  ];

  const evaluateCondition = (
    condition,
    valueObj,
    firstValueStr,
    secondValueStr,
    timeZone
  ) => {
    const value = getValue(valueObj);
    const firstValue = parseDate(firstValueStr, timeZone);
    const secondValue = parseDate(secondValueStr, timeZone);
    switch (condition) {
      case Enums.Conditions.Equals:
        return isDateEqual(value, firstValue);
      case Enums.Conditions.Between:
        return isDateBetween(value, firstValue, secondValue);
      case Enums.Conditions.GreaterThan:
        return isDateGreater(value, firstValue);
      case Enums.Conditions.GreaterThanOrEqual:
        return isDateGreaterOrEqual(value, firstValue);
      case Enums.Conditions.LessThanOrEqual:
        return isDateLessOrEqual(value, firstValue);
      case Enums.Conditions.LessThan:
        return isDateLesser(value, firstValue);
      case Enums.Conditions.NextWeek:
        return isDateNextWeek(value);
      case Enums.Conditions.IsNotEmpty:
        return value && value.length > 0;
      case Enums.Conditions.IsEmpty:
        return !value || value.length === 0;
      case Enums.Conditions.Today:
        return isDateToday(value);
      case Enums.Conditions.LastOneWeek:
        return isDateLastWeek(value);
      case Enums.Conditions.LastOneMonth:
        return isDateLastOneMonth(value);
      case Enums.Conditions.LastOneYear:
        return isDateLastOneYear(value);
      case Enums.Conditions.NextOneMonth:
        return isDateNextOneMonth(value);
      case Enums.Conditions.NextOneYear:
        return isDateNextOneYear(value);
      default:
        return false;
    }
  };

  const defaultSortOrder = 'Descending';

  return {
    getDisplayValue,
    renderAttributeValue,
    renderAttributeInput,
    filterOptions,
    getValue,
    defaultSortOrder,
    getDefaultValue,
    evaluateCondition
  };
};
