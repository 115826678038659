import React from 'react';
import classes from './Attachment.module.css';
import classNames from 'classnames';
import { FileDownloader, Box } from '../../../lib/components';
import {
  DefaultIcon,
  DocIcon,
  PdfIcon,
  XlsIcon,
  JpgIcon,
  PptIcon,
  TextIcon,
  VideoIcon,
  AudioIcon
} from '../../../Components/Icons/SVGIcons';

export const AttachmentContainer = ({
  fileValue,
  selectedFile,
  setSelctedFile,
  onChange,
  fileRef
}) => {
  const removeFile = event => {
    setSelctedFile(null);
    onChange(null);
    fileRef.current.value = '';
  };

  const getFileName = fileData => {
    if (!fileData) {
      return null;
    }
    if (fileData.fileName) {
      return fileData.fileName;
    }
    return fileData.name;
  };

  if (!fileValue) {
    return <Box style={{ marginLeft: '0.5rem' }}>{'-'}</Box>;
  }

  if (!fileValue.fileId) {
    return fileValue ? (
      <Box className={classNames(classes['selected-files'])}>
        <div> {fileValue.name ? getFileIcon(fileValue.name) : null}</div>
        <div className={classNames(classes['file-upload'])}>
          {selectedFile || fileValue.name}
        </div>
        <div
          className={(classes['filter-close-button'], classes['close-button'])}
          onClick={removeFile}
        >
          <i className="fa fa-times" aria-hidden="true" />
        </div>
      </Box>
    ) : (
      '-'
    );
  }

  return fileValue ? (
    <Box className={classNames(classes['selected-files'])}>
      <div> {fileValue.fileName ? getFileIcon(fileValue.fileName) : null}</div>
      <FileDownloader
        fileId={fileValue.fileId}
        fileName={getFileName(fileValue)}
        render={previewOrDownload => (
          <Box
            className={classNames(
              'action-link',
              'cursor-pointer',
              'padding-0px',
              'text-overflow-ellipses'
            )}
            onClick={previewOrDownload}
            title={getFileName(fileValue)}
          >
            <div
              color="SECONDARY"
              className={classNames(classes['file-upload'])}
            >
              {getFileName(fileValue)}
            </div>
          </Box>
        )}
      />
      <div
        className={(classes['filter-close-button'], classes['close-button'])}
        onClick={removeFile}
        style={{ marginLeft: '0.8rem' }}
      >
        <i className="fa fa-times" aria-hidden="true" />
      </div>
    </Box>
  ) : (
    '-'
  );
};

export const getFileIcon = fileName => {
  switch (fileName.split('.').pop()) {
    case 'doc':
    case 'docx':
      return <DocIcon />;
    case 'pdf':
      return <PdfIcon />;
    case 'jpg':
    case 'png':
    case 'gif':
      return <JpgIcon />;
    case 'xls':
    case 'xlsx':
    case 'csv':
      return <XlsIcon />;
    case 'txt':
      return <TextIcon />;
    case 'ppt':
    case 'pptx':
      return <PptIcon />;
    case 'wmv':
    case 'mov':
    case 'mkv':
    case 'mp4':
      return <VideoIcon />;
    case 'mp3':
    case 'wav':
    case 'wma':
      return <AudioIcon />;
    default:
      return <DefaultIcon />;
  }
};
