import React from 'react';
import HelptextIcon from '../HelpTextIcon';
import { Enums } from '../../../Enums';
import UndoIcon from '../UndoIcon';
import { EditIcon } from '../../EditIcon';
import { InstanceInlineSaveButton } from '../../InstanceSaveButton';
import { Label } from '../../../lib/components';
import { RequiredIndicator } from '../../RequiredIndicator';

export const FieldTitleBar = ({
  fieldConfig,
  editMode,
  canUpdate,
  readOnly,
  onEditButtonClicked,
  isSaveInProgress,
  onUndoClicked
}) => {
  return (
    <Label
      style={{
        fontWeight: '600',
        display: 'inline-flex',
        alignItems: 'center',
        paddingBottom: '7px',
        fontSize: 12
      }}
    >
      {fieldConfig.label}
      <RequiredIndicator required={fieldConfig.isRequired} />
      <HelptextIcon helpText={fieldConfig.helpText} />
      {editMode === Enums.EditMode.Inline && canUpdate && readOnly ? (
        <EditIcon onEditClicked={onEditButtonClicked} />
      ) : null}
      {editMode === Enums.EditMode.Inline && !readOnly ? (
        <>
          &nbsp;
          <InstanceInlineSaveButton attributeName={fieldConfig.attributeName} />
          &nbsp;
          <UndoIcon
            onUndoClicked={onUndoClicked}
            isDisabled={isSaveInProgress}
          />
        </>
      ) : null}
    </Label>
  );
};
