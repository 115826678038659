import React, { useState, useEffect } from 'react';
import Popup from '../../Forms/Popup';
import useTranslatedValue from '../../../Hooks/useTranslatedValue';
import { useUnlink } from '../../../Core';
import { Container, Spinner } from '../../../lib/components';
import { Text } from '../../../lib/components';

export const UnlinkAction = ({
  relationId,
  message,
  successCallback,
  onClose
}) => {
  const unlinkText = useTranslatedValue('ACTION_UNLINK');
  const okText = useTranslatedValue('OK');
  const unlinkFailedText = useTranslatedValue('UNLINK_FAILED');
  const [enableSuccessCallback, setEnableSuccessCallback] = useState(false);

  const { state: unlinkInstance, run: doUnlinkInstance } = useUnlink(
    relationId
  );

  useEffect(() => {
    if (
      !unlinkInstance.isLoading &&
      !unlinkInstance.isError &&
      unlinkInstance.isFulfilled
    ) {
      setEnableSuccessCallback(true);
    } else if (unlinkInstance.isError) {
      setEnableSuccessCallback(true);
    }
  }, [unlinkFailedText, unlinkInstance]);

  const unlink = () => {
    doUnlinkInstance();
  };

  const getMessage = () => {
    if (unlinkInstance.isError) {
      const errorString =
        unlinkInstance.data &&
        unlinkInstance.data.response &&
        unlinkFailedText + unlinkInstance.data.response.data;
      return errorString;
    }
    return 'Unlinked successfully';
  };

  const unlinkPopupData = () => (
    <div>
      <label>
      <Text>
        {message || '-' }
      </Text>
      </label>
    </div>
  );

  return (
    <>
      <Popup
        title={unlinkText}
        closePopup={onClose}
        successButtonText={enableSuccessCallback ? okText : unlinkText}
        successCallback={() =>
          enableSuccessCallback ? successCallback() : unlink()
        }
        noSuccessCallback={unlinkInstance.isLoading}
        noCloseButton={unlinkInstance.isLoading || enableSuccessCallback}
      >
        <UnlinkActionContent/>
      </Popup>
    </>
  );

  function UnlinkActionContent() {
    if (unlinkInstance.isLoading) {
      return (
        <Container>
          <Spinner />
        </Container>
      );
    }
    return (
      <label>
       {enableSuccessCallback ? getMessage() : unlinkPopupData()}
      </label>
    );
  }

};
