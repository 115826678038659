import {
  GridStatus,
  NoRecordsFound,
  VirtualizedGridStatus
} from '../../Common/Grid/TableGridFooter';
import { ReloadButton } from '../../ReloadButton';
import GridHeader from '../../Common/Grid/GridHeader';
import { VerticalSeparator } from '../../../lib/components';

export const GridRecordsToolbar = props => {
  const { paginationContext, data, isLoading, onPageChange, onReload } = props;
  return (
    <>
      <GridStatus
        paginationContext={paginationContext}
        totalRecords={data && !isLoading ? data.totalCount : 0}
      />
      <GridHeader
        totalRecords={data && !isLoading ? data.totalCount : 0}
        paginationContext={paginationContext}
        onPageChange={(skip, take) => onPageChange(skip, take)}
        isLoading={isLoading}
      />
      <ReloadButton onClick={onReload} isLoading={isLoading} />
    </>
  );
};

export const VirtualizedGridRecordsToolbar = props => {
  const { data, isLoading, onReload, selectedRowsCount } = props;
  return (
    <>
      <VirtualizedGridStatus
        totalRecords={data && !isLoading ? data.totalCount : 0}
        selectedRowsCount={selectedRowsCount}
      />

      {isLoading || (data && data.totalCount > 0) ? null : (
        <>
          <NoRecordsFound />
          <VerticalSeparator />
        </>
      )}
      <ReloadButton onClick={onReload} isLoading={isLoading} />
    </>
  );
};
