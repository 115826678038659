import { Enums } from '../utils';
import { isEmpty, useProtrakApi, filesBase } from '../utils';

export const useFileDownloadUrl = (fileId, defer) =>
  useProtrakApi(
    { requestConfig: generateBlobUrlPromiseFn, fileId },
    { defer: defer }
  );

const generateBlobUrlPromiseFn = ({ fileId }) => {
  return { endpoint: fileId ? `${filesBase}/${fileId}` : null };
};

export const getEditedAttachmentAttributes = context => {
  let attachmentAttributes = [];
  if (!context) {
    return [];
  }

  if (
    context.instanceEditState &&
    context.instanceEditState.editedValues &&
    Object.keys(context.instanceEditState.editedValues).length
  ) {
    Object.keys(context.instanceEditState.editedValues).forEach(k => {
      const { type } = context.instanceEditState.editedValues[k];
      if (
        type === Enums.AttributeTypes.Attachment &&
        !isEmpty(context.instanceEditState.editedValues[k].fileValue)
      ) {
        attachmentAttributes.push(context.instanceEditState.editedValues[k]);
      }
    });
  }

  return attachmentAttributes;
};

export const populateAttributeParams = editedValues => {
  let atributeParams = [];
  if (editedValues) {
    for (let [key, value] of Object.entries(editedValues)) {
      if (value.type === 'RichText') {
        atributeParams.push({ name: key, ...value });
        continue;
      }
      const param = { ...value };
      if (param.textValue) {
        param.textValue = param.textValue.trim();
      }
      atributeParams.push({ name: key, ...param });
    }
  }

  return atributeParams;
};
