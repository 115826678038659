import { useReducer } from 'react';
import { instanceCreateReducer } from '../Components/Layouts/InstLayouts/createEditReducer';
import {
  useInstanceCreateAction,
  useEditRelationAttributes,
  useInstanceSaveAction,
  isEmpty,
  useSettingsContext
} from '../Core';
import { Attribute } from '../shared/Components';
import InstanceEditContext from './InstaceEditContext';
import { isEdited } from '../Components/InstanceSaveButton';
import { Enums } from '../Enums';

export const InstanceCreateContextProvider = props => {
  const { config, defaultValues } = props;
  const { settings } = useSettingsContext();

  const {
    requiredFields,
    defaultAttributes
  } = getRequiredFieldsAndAttributeDefaultValues(config, settings);

  const editedValues = {
    ...defaultAttributes,
    ...defaultValues
  };

  const requiredAttributeFields = Object.entries(requiredFields).filter(
    function (obj1) {
      return !Object.entries(editedValues).some(function (obj2) {
        return obj1[0] === obj2[0];
      });
    }
  );

  const initialState = {
    editedValues: !isEmpty(editedValues) ? editedValues : {},
    details: {
      instanceTypeName: config.typeName,
      allowedActions: config.allowedActions,
      allowedOperations: config.allowedActions ? ['Promote'] : null
    },
    invalidAttributes: Object.fromEntries(requiredAttributeFields),
    newlyAddedAttachments: [],
    saveOperationState: null,
    showPromoteReminder: false,
    uploadAttachmentState: null
  };

  const [instanceEditState, instanceEditDispatch] = useReducer(
    instanceCreateReducer,
    initialState
  );

  const context = {
    instanceEditState,
    instanceEditDispatch
  };

  const [, saveInstance] = useInstanceSaveAction(context);

  const [, createInstance] = useInstanceCreateAction(
    context
  );

  const [
    ,
    saveRelationInstance
  ] = useEditRelationAttributes(context);

  const onPromoteSuccess = () => {
    instanceEditDispatch({ type: 'PROMOTE_SUCCESS' });
  };

  const onPromoteError = error => {
    instanceEditDispatch({ type: 'PROMOTE_ERROR', payload: error });
  };

  const onPromoteButtonClicked = action => {
    if (
      isEdited(instanceEditState) &&
      isEmpty(instanceEditState.invalidAttributes)
    ) {
      createInstance({ typeName: config.typeName });
    }
  };

  const getAttributeWorkingValue = attributeName => {
    return instanceEditState.editedValues[attributeName]
      ? instanceEditState.editedValues[attributeName]
      : {};
  };

  return (
    <InstanceEditContext.Provider
      value={{
        instanceEditState,
        instanceEditDispatch,
        saveRelationInstance,
        saveInstance,
        onPromoteSuccess,
        onPromoteError,
        onPromoteButtonClicked,
        createInstance,
        getAttributeWorkingValue,
        canConnect: true
      }}
      {...props}
    />
  );
};

export const getRequiredFieldsAndAttributeDefaultValues = (config, settings) => {
  let requiredFields = {};
  let defaultAttributes = {};
  if (config && config.sections && config.sections.length) {
    config.sections.forEach(section => {
      section.widgets &&
        section.widgets.forEach(widget => {
          widget.fields &&
            widget.fields.forEach(field => {
              getRequiredFields(field, requiredFields);
              getAttributeDefaultValues(field, defaultAttributes, settings);
            });
        });
    });
  }
  return { requiredFields, defaultAttributes };
};

function getAttributeDefaultValues(field, defaultValues, settings) {
  if (field.defaultValue) {
    const attribute = Attribute(field);
    defaultValues[field.attributeName] = {
      ...attribute.getDefaultValues(settings ? settings.dateTimeFormat.timeZone : null),
      canUpdate: true,
      name: field.attributeName,
      type: field.attributeType
    };
  }
}

function getRequiredFields(field, requiredAttributes) {
  let attributeField = Attribute(field);
  if (
    field.isRequired &&
    field.attributeType !== Enums.AttributeTypes.Boolean
  ) {
    requiredAttributes[field.attributeName] = attributeField.validate();
  }
}
