import { useMedia } from './Core';

const DeviceSize = {
  mobile: 'screen and (max-width: 620px) ',
  tablet: 'screen and (min-width: 620px) and (max-width: 772px)',
  desktop: 'screen and (min-width: 772px) and (max-width: 1366px)',
  largeDesktop: 'screen and (min-width: 1367px)'
};
export const DeviceTypes = {
  mobile: 'Mobile',
  tablet: 'Tablet',
  desktop: 'Desktop',
  largeDesktop: 'LargeDesktop'
};
export const useDeviceType = () => {
  const queries = [
    DeviceSize.mobile,
    DeviceSize.tablet,
    DeviceSize.desktop,
    DeviceSize.largeDesktop
  ];
  const defaultValue = 'desktop';
  const val = useMedia(queries, Object.values(DeviceTypes), defaultValue);
  return val;
};
