import React from 'react';
import { Box } from '../../lib/components';

export const StateFilterContainer = ({children}) => {
    return (
    <Box
      border=" 1px solid var(--primary-lighter)"
      marginTop="15px"
      padding="5px"
      display="block"
      borderRadius="5px">{children}</Box>
    );
};
