import React from 'react';
import Popup from '../../Forms/Popup';
import useTranslatedValue from '../../../Hooks/useTranslatedValue';
import { useDeleteInstance } from '../../../Core';
import { Container, Spinner } from '../../../lib/components';
import { JobSubmittedMessage } from '../../Layouts/InstLayouts/InstEditLayout/AccessControl/JobSubmittedMessage';
import ErrorDetails from '../ErrorDetails';

export const AsyncDeleteAction = ({
  promiseParams,
  message,
  successCallback,
  onClose
}) => {
  const [deleteInstance, doDelete] = useDeleteInstance();

  const deleteText = useTranslatedValue('ACTION_DELETE');
  const closeText = useTranslatedValue('CLOSE');
  const doneText = useTranslatedValue('DONE');

  const doInstanceDelete = () => {
    doDelete({ promiseParams });
  };

  const { isFulfilled, isLoading, isError } = deleteInstance;

  const MessageBasedOnCurrentState = () => {
    if (isLoading) {
      return (
        <Container>
          <Spinner />
        </Container>
      );
    }
    if (isError) {
      return <ErrorDetails state={deleteInstance} />;
    }
    if (isFulfilled) {
      return <JobSubmittedMessage />;
    }
    return <label>{message}</label>;
  };
  const onPopupClose = () => {
    if (isFulfilled) {
      successCallback();
    } else {
      onClose();
    }
  };
  return (
    <Popup
      title={deleteText}
      closePopup={() => onPopupClose()}
      successButtonText={isFulfilled ? doneText : deleteText}
      closeButtonText={closeText}
      successCallback={() => {
        isFulfilled ? successCallback() : doInstanceDelete();
      }}
      noSuccessCallback={isLoading}
      noCloseButton={isLoading || isFulfilled}
      disableSuccessButton={isLoading || isError}
    >
      <MessageBasedOnCurrentState />
    </Popup>
  );
};
