import { css } from 'styled-components';

export const formCustomOverflowStyles = css`
  overflow: ${props =>
    typeof props.overflow === 'string' ? props.overflow : null};
  overflow-x: ${props =>
    typeof props.overflow === 'object'
      ? props.overflow.overflowX
        ? props.overflow.overflowX
        : null
      : null};
  overflow-y: ${props =>
    typeof props.overflow === 'object'
      ? props.overflow.overflowY
        ? props.overflow.overflowY
        : null
      : null};
`;
