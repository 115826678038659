import axios from 'axios';

axios.interceptors.response.use(
  config => config,
  error => {
    if (error.code === 'ECONNABORTED') {
      error.timedOut = true;
    }
    return Promise.reject(error);
  }
);

export function client(url, config = {}) {
  return axios(url, config);
}
