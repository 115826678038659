import React from 'react';
import PopOutLink from '../../../../../Common/PopOutLink';
import { theme, Caption, Box } from '../../../../../../lib/components';
import { Link } from 'react-router-dom';

export const VersionDetailsPopup = props => {
  const {
    selectedMinorDetails,
    children,
    containerStyle,
    openInNewTab = false,
    showPopOutIcon = false
  } = props;
  const route = {
    pathname: `/${selectedMinorDetails.instanceTypeName}/view/${selectedMinorDetails.instanceId}/${selectedMinorDetails.id}`
  };
  const styles = { color: theme.colorPalette.SECONDARY, alignSelf: 'center' };
  return (
    <Box style={containerStyle}>
      {openInNewTab ? (
        <PopOutLink to={route}>
          <Box style={styles}>{children}</Box>
        </PopOutLink>
      ) : (
        <Link
          onClick={e => e.stopPropagation()}
          to={route}
          style={{ width: '100%', ...styles }}
        >
          {children}
        </Link>
      )}
      {showPopOutIcon ? (
        <Caption lineHeight={'1.5rem'} style={{ marginLeft: '0.3rem' }}>
          <PopOutLink to={route}>
            <i
              className="fas fa-external-link-alt"
              aria-hidden="true"
              style={{
                color: theme.colorPalette.SECONDARY
              }}
            />
          </PopOutLink>
        </Caption>
      ) : null}
    </Box>
  );
};
