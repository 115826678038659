import React from 'react';
import { Container, H3 } from '../../../../../lib/components';
import useTranslatedValue from '../../../../../Hooks/useTranslatedValue';

export function JobSubmittedMessage() {
  const message = useTranslatedValue('ASYNC_JOB_SUBMITTED_MESSAGE');
  return (
    <Container padding={'1rem'}>
      <H3>{message}</H3>
    </Container>
  );
}
