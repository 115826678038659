export const replaceStateWithDisplayName = (element, states) => {
  element.state = states[element.state] ? states[element.state] : element.state;
  return element;
};

export const formatLegends = (
  legends,
  numberOfCharactersInALegendLine,
  isMinimumLengthRequired
) => {
  return legends.map(legend => {
    return getLegendName(
      legend,
      numberOfCharactersInALegendLine,
      isMinimumLengthRequired
    );
  });
};

export const getLegendName = (
  name,
  numberOfCharactersInALegendLine,
  isMinimumLengthRequired = true
) => {
  // We have to give fixed width to legend to maintain consistency in graph.
  if (!numberOfCharactersInALegendLine) {
    numberOfCharactersInALegendLine = 15;
  }
  if (name.length < numberOfCharactersInALegendLine) {
    if (!isMinimumLengthRequired) {
      return name;
    }
    let i = name.length;
    while (i < numberOfCharactersInALegendLine) {
      name = [name.slice(0, i), ' ', name.slice(i)].join('');
      i++;
    }
    return name;
  }
  let i = numberOfCharactersInALegendLine;

  while (i < name.length) {
    let newLineStr;
    if (name.charAt(i) === ' ') {
      newLineStr = '<br>';
    } else {
      newLineStr = '-<br>';
    }
    name = [name.slice(0, i), newLineStr, name.slice(i)].join('');
    i = i + numberOfCharactersInALegendLine;
  }
  return name;
};

export const getHeight = (length, dimensions) => {
  if (!dimensions) {
    return;
  }
  if (length <= 10) {
    return Math.max(dimensions.height, 400);
  }
  // If data is more then we have to give space to show scrollbar
  return Math.max(dimensions.height - 100, 400);
};
export const getCustomWidth = (
  numberOfElementsOnXAxis,
  currentDeviceType,
  devices
) => {
  if (!numberOfElementsOnXAxis) {
    return null;
  }
  if (
    currentDeviceType === devices.mobile ||
    currentDeviceType === devices.tablet
  ) {
    return numberOfElementsOnXAxis * 30;
  }
  return numberOfElementsOnXAxis * 50;
};

export function getCsvHeaders(config) {
  if (config.group && config.columns && config.columns.length !== 0) {
    const groupByAttributeHeader = {
      label: config.group.displayName,
      key: config.group.attribute.attributeName,
      attributeType:
        config.group.attribute.attributeType === 'Picklist' ? 'Picklist' : '',
      prefix: '',
      suffix: ''
    };
    const aggregateColumnHeaders = config.columns
      .filter(col => col.aggregateType !== 'None')
      .map(c => {
        return {
          label: c.displayName ? c.displayName : c,
          key: c.displayName ? c.displayName : c
        };
      });

    return [groupByAttributeHeader, ...aggregateColumnHeaders];
  } else {
    const pivotRowHeader = config.pivotRows.map(r => {
      return {
        label: r.reportAttribute.displayName,
        key: r.reportAttribute.attribute
          ? r.reportAttribute.attribute.attributeName
          : r.reportAttribute.displayName.toLowerCase(),
        prefix: '',
        suffix: ''
      };
    });
    let pivotColumnHeader = [];
    if (config.pivotColumns && config.pivotColumns.length !== 0) {
      pivotColumnHeader = config.pivotColumns.map(c => {
        return {
          label: c.reportAttribute.displayName,
          key: c.reportAttribute.attribute
            ? c.reportAttribute.attribute.attributeName
            : c.reportAttribute.displayName.toLowerCase(),
          prefix: '',
          suffix: ''
        };
      });
    }
    const aggregateColumnHeaders = config.values
      .filter(col => col.aggregateType !== 'None')
      .map(c => {
        return {
          label: c.reportAttribute.displayName
            ? c.reportAttribute.displayName
            : c,
          key: c.reportAttribute.displayName ? c.reportAttribute.displayName : c
        };
      });
    return [...pivotRowHeader, ...pivotColumnHeader, ...aggregateColumnHeaders];
  }
}

export const getFormattedValues = values => {
  const maxNumberOfCharactersAllowedInValue = 25;
  return values.map(v => {
    return getFormattedValue(v, maxNumberOfCharactersAllowedInValue);
  });
};

export const getFormattedValue = (
  value,
  maxNumberOfCharactersAllowedInValue
) => {
  if (value.length <= maxNumberOfCharactersAllowedInValue) {
    return value;
  }
  return value.slice(0, maxNumberOfCharactersAllowedInValue) + '...';
};

export function getAttributeField(groupByField, attributeName) {
  let attributeField;
  if (groupByField && groupByField.attributeType === 'Picklist') {
    attributeField =
      groupByField.options &&
      groupByField.options.find(f => f.name === attributeName);
  }
  return attributeField;
}
