import React from 'react';
import { Prefix, Suffix } from './prefixSuffix';
import { isNumber, convertToLocaleString } from '../../Core';

export const ExpressionAttribute = config => {
  const getValue = value => {
    return value && value.textValue !== null ? value.textValue : '-';
  };

  const renderAttributeValue = value => {
    const val = getValue(value);
    if (val === null) {
      return <>{'-'}</>;
    }

    return (
      <>
        <Prefix value={config.prefix} />
        {isNumber(val) ? convertToLocaleString(parseFloat(val)) : val}
        <Suffix value={config.suffix} />
      </>
    );
  };

  const getDefaultValue = () => {
    return { textValue: config.defaultValue ? config.defaultValue[0] : null };
  };

  const renderAttributeInput = (value, onEdit, errorMessage) => {
    return renderAttributeValue(value);
  };

  const filterOptions = [];
  const defaultSortOrder = '';

  return {
    renderAttributeValue,
    renderAttributeInput,
    filterOptions,
    getValue,
    defaultSortOrder,
    getDefaultValue
  };
};
