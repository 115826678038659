import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';

import translationEng from './locales/en/translation.json';
import translationFre from './locales/fre/translation.json';

i18n.use(XHR).init({
  resources: {
    en: {
      translationsNm: translationEng
    },
    fre: {
      translationsNm: translationFre
    }
  },
  lng: 'en',
  fallbackLng: 'en',
  debug: false,

  // have a common namespace used around the full app
  // ns: ['translations'],
  defaultNS: 'translationsNm',

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ','
  },

  react: {
    wait: true
  }
});

export default i18n;
