import {
  validateAttributeFilter,
  generateStateFilterObj,
  generateAttributeFilterObj,
  generateParentFilterObj,
  generateRelationFilterObj
} from './widgetsFilter';
import { ATTRIBUTE_FIELD_TYPE_CONTRACT } from './type';

export const generateInstanceQuery = ({
  widget,
  typeName,
  stateFilter,
  attributeFilter,
  parentFilter = [],
  relationFilters,
  sortBy,
  isSortByDescending,
  relationAttributes
}) => {
  let attributeNames = getDashboardConfiguredAttributes(widget.fields);
  let relationAttributesNames = getConfiguredRelationAttributes(
    relationAttributes || []
  );
  let validAttributeFilterGroups = validateAttributeFilter(attributeFilter);
  let stateFilterObject = generateStateFilterObj(stateFilter);
  let filterObj = generateAttributeFilterObj(validAttributeFilterGroups) || {};
  let parentFilterObject = generateParentFilterObj(parentFilter);
  let relationFilterObject = generateRelationFilterObj(relationFilters);

  let instanceQueryContract = {
    attributes: attributeNames,
    ...filterObj,
    ...stateFilterObject,
    ...parentFilterObject,
    ...relationFilterObject,
    getAllowedOperations: true,
    getAllowedActions: true
  };

  if (sortBy) {
    instanceQueryContract = {
      ...instanceQueryContract,
      sortBy
    };
  }

  if (typeof isSortByDescending === 'boolean') {
    instanceQueryContract = {
      ...instanceQueryContract,
      isSortByDescending
    };
  }

  // For Instance Details
  if (typeName) {
    instanceQueryContract = {
      ...instanceQueryContract,
      instanceTypeName: typeName
    };
  }

  if (typeof widget.getOnlyActionableInstances === 'boolean') {
    instanceQueryContract = {
      ...instanceQueryContract,
      getOnlyActionableInstances: widget.getOnlyActionableInstances
    };
  }

  if (typeof widget.getOnlyConnectableInstances === 'boolean') {
    instanceQueryContract = {
      ...instanceQueryContract,
      getOnlyConnectableInstances: widget.getOnlyConnectableInstances
    };
  }

  if (relationAttributes && relationAttributes.length) {
    instanceQueryContract = {
      ...instanceQueryContract,
      relationAttributes: relationAttributesNames
    };
  }

  return instanceQueryContract;
};

export const getDashboardConfiguredAttributes = fields => {
  if (!fields) {
    return [];
  }
  let attributes = new Set();
  fields
    .filter(f => !isRelationAttribute(f) && isFieldVisible(f))
    .forEach(f => attributes.add(f.attributeName));
  fields.forEach(field => {
    addAttributesForDisplayFormatCondition(
      field.formatConditions,
      attributes);
  });

  return [...attributes];
};

const addAttributesForDisplayFormatCondition = (dfc, attributes) => {
  if (!dfc || !dfc.length) {
    return;
  }
  dfc.forEach(fc => {
    if (!fc || !fc.rule || !fc.rule.rules  || !fc.rule.rules.length) {
      return;
    }
    fc.rule.rules.forEach(rule => {
      rule.attributeFilterConditions.forEach(afc => {
        attributes.add(afc.attributeName);
      });
    });
  });
};

export const getConfiguredRelationAttributes = fields => {
  if (!fields) {
    return [];
  }
  return fields
    .filter(f => isRelationAttribute(f) && isFieldVisible(f))
    .map(f => f.attributeName);
};

const isRelationAttribute = field =>
  field.fieldType === ATTRIBUTE_FIELD_TYPE_CONTRACT.RELATION_ATTRIBUTE;

const isFieldVisible = field => Boolean(field.isVisible);
