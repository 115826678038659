import React from 'react';
import { Enums } from '../../Enums';
import { TextBox, TextArea } from '../../lib/components';
import { Prefix, Suffix } from './prefixSuffix';

export const NameAttribute = config => {
  const getValue = value => {
    if (!value) {
      return null;
    }
    if (typeof value === 'string') {
      return value;
    }
    return value.textValue;
  };

  const getDefaultValue = () => {
    return { textValue: config.defaultValue ? config.defaultValue[0] : null };
  };

  const getDisplayValue = value => {
    return getValue(value);
  };

  const renderAttributeValue = value => {
    const val = getValue(value);
    if (!val) {
      return <>{'-'}</>;
    }
    return (
      <>
        <Prefix value={config.prefix} />
        {val}
        <Suffix value={config.suffix} />
      </>
    );
  };

  const renderAttributeInput = (value, onEdit) => {
    return (
      <TextBox
        value={getValue(value)}
        onEdit={newValue => onEdit({ textValue: newValue.replace(/^ +/, '') })}
        placeholder={config.placeholder}
      />
    );
  };

  const renderFilterAttributeInput = (value, onEdit) => {
    return (
      <TextArea
        value={getValue(value)}
        onEdit={onEdit}
        style={{ fontWeight: 'bold' }}
      />
    );
  };

  const filterOptions = [
    { displayName: 'Contains', name: Enums.Conditions.Contains },
    { displayName: 'NotContains', name: Enums.Conditions.NotContains },
    { displayName: 'Equals', name: Enums.Conditions.Equals },
    { displayName: 'NotEquals', name: Enums.Conditions.NotEquals },
    { displayName: 'In', name: Enums.Conditions.In },
    { displayName: 'IsEmpty', name: Enums.Conditions.IsEmpty },
    { displayName: 'IsNotEmpty', name: Enums.Conditions.IsNotEmpty }
  ];

  const evaluateCondition = (condition, valueObj, compareToValueStr) => {
    const value = getValue(valueObj);
    switch (condition) {
      case Enums.Conditions.Equals:
        return value && value.toLowerCase() === compareToValueStr.toLowerCase();
      case Enums.Conditions.Contains:
        return (
          value && value.toLowerCase().includes(compareToValueStr.toLowerCase())
        );
      case Enums.Conditions.NotContains:
        return (
          value &&
          !value.toLowerCase().includes(compareToValueStr.toLowerCase())
        );
      case Enums.Conditions.NotEquals:
        return value && value.toLowerCase() !== compareToValueStr.toLowerCase();
      case Enums.Conditions.In:
        return (
          value &&
          compareToValueStr
            .toLowerCase()
            .split('\n')
            .includes(value.toLowerCase())
        );
      case Enums.Conditions.IsEmpty:
        return !value || value.length === 0;
      case Enums.Conditions.IsNotEmpty:
        return value && value.length > 0;
      default:
        return false;
    }
  };

  const defaultSortOrder = 'Ascending';

  return {
    getValue,
    getDisplayValue,
    renderAttributeValue,
    renderAttributeInput,
    renderFilterAttributeInput,
    filterOptions,
    defaultSortOrder,
    getDefaultValue,
    evaluateCondition
  };
};
