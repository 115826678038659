import React from 'react';
import { protrakApiClient, toQueryString, useAuthContext } from '../../Core';
import { customStyles } from './Dropdown/PicklistRenderStyling';
import { AsyncPaginate } from 'react-select-async-paginate';

const INSTANCES = 'instances';

export const ReferenceAttributePaginateDropDown = ({
  relatedTypeName,
  config,
  selectedReferences,
  onEdit,
  placeholder,
  customStyle,
  isDisabled = false,
  isMultiselect = false,
  menuPlacement = 'auto'
}) => {
  const onValueChange = values => {
    if (isMultiselect) {
      onEdit(values ? values.map(item => item.value) : []);
    } else {
      onEdit(values ? values.value : '');
    }
  };

  const authContext = useAuthContext();

  const usersPromise = async (search, prevOptions) => {
    const stateFilter =
      config && config.stateFilter && config.stateFilter.states
        ? config.stateFilter.states
        : [];
    const attributeFilter = [
      {
        AttributeFilterConditions: [
          {
            AttributeName: 'Name',
            Condition: 'Contains',
            FirstValue: search,
            Operator: 'None'
          }
        ],
        Operator: 'None'
      }
    ];
    const query = {
      instanceTypeName: relatedTypeName,
      getOnlyConnectableInstances: true,
      skip: prevOptions.length,
      take: 10,
      ...stateFilter,
      AttributeFilterExpressions: attributeFilter
    };
    const queryParams = toQueryString(query);
    try {
      const res = await protrakApiClient(
        `${INSTANCES}?${queryParams}`,
        {},
        authContext
      );
      if (res.data) {
        return {
          options: toSelectArray(res.data.items),
          hasMore: res.data.totalCount > prevOptions.length + 10
        };
      }
      throw 'Response is not proper';
    } catch (error) {
      throw error;
    }
  };

  const toSelectArray = (values = []) => {
    return values.map(item => {
      return { value: item, label: item.name };
    });
  };

  const getSingleSelectDefaultValues = value => {
    if (value && value.length > 0) {
      return { label: value[0].name, value: value[0] };
    } else if (value && value.name) {
      return { label: value.name, value: value };
    }
    return {};
  };

  const preSelectedValues = isMultiselect
    ? selectedReferences &&
      selectedReferences.map(item => {
        return { value: item, label: item.name };
      })
    : getSingleSelectDefaultValues(selectedReferences);

  const getPlaceholder = () => {
    if (placeholder !== null) {
      return placeholder;
    }
    if (isMultiselect) {
      return 'Select some options';
    }
    return 'Select an option';
  };

  return (
    <AsyncPaginate
      debounceTimeout={300}
      value={preSelectedValues}
      loadOptions={usersPromise}
      onChange={onValueChange}
      isMulti={isMultiselect}
      isClearable={true}
      isDisabled={isDisabled}
      styles={{ ...customStyles, ...customStyle }}
      placeholder={getPlaceholder()}
      menuPortalTarget={document.body}
      menuPlacement={menuPlacement}
    />
  );
};
