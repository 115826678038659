import React, { createRef } from 'react';
import Dropzone from 'react-dropzone';
import { Grid, Text } from '../lib/components';
import Icon from '../lib/components/Icon';
import { ReactComponent as uploadSVG } from '../Content/Images/Upload.svg';

export const FileDropZone = ({
  children,
  onFileSelect,
  isMultiSelect,
  noClick,
  noKeyboard,
  showBorder,
  showText,
  isUploadPermitted,
  onFileRejected
}) => {
  const dropzoneRef = createRef();

  function openDialog() {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  }

  const getBorderStyle = isDragActive => {
    if (isDragActive) {
      return '3px dashed var(--secondary)';
    } else if (showBorder) {
      return '1px dashed var(--secondary)';
    }
    return 'none';
  };

  return (
    <Dropzone
      ref={dropzoneRef}
      accept={process.env.REACT_APP_SUPPORTED_FILE_TYPE}
      onDrop={acceptedFiles => onFileSelect(acceptedFiles)}
      multiple={isMultiSelect}
      maxSize={Number(process.env.REACT_APP_FILE_SIZE_BYTES)}
      onDropRejected={onFileRejected}
      noClick={noClick}
      noKeyboard={noKeyboard}
      disabled={!isUploadPermitted}
    >
      {({
        getRootProps,
        getInputProps,
        isDragActive,
        isFocused,
        rejectedFiles,
        dropzoneProps
      }) => (
        <div
          style={{
            minHeight: '8rem',
            border: getBorderStyle(isDragActive),
            alignItems: 'center',
            direction: 'column',
            height: '100%',
            textAlign: 'center',
            backgroundColor: isDragActive ? 'rgba(0, 0, 0, 0.5)' : null
          }}
          {...getRootProps()}
        >
          {isDragActive ? (
            <Grid
              height="100%"
              rows={['auto', 'auto']}
              columns={['auto']}
              areas={[['icon'], ['text']]}
              justifyItems={'center'}
              justifyContent={'center'}
              direction="column"
            >
              <Icon style={{ alignSelf: 'end' }} SVG={uploadSVG} size="40%" />
              <Text
                fontWeight={400}
                color={'SURFACE'}
                lineHeight={'0.5rem'}
                padding={'0.8rem'}
                style={{ fontSize: '1.5em' }}
              >
                Upload Files
              </Text>
            </Grid>
          ) : null}

          {!isDragActive ? children(openDialog) : null}
          <input {...getInputProps()} />
          {showText ? (
            <p style={{ cursor: 'pointer' }}>
              Drop your files here or <b>Browse</b>
            </p>
          ) : null}
        </div>
      )}
    </Dropzone>
  );
};
