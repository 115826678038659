import { useProtrakApi, protrakApiClient } from '../utils';

const homeBaseURL = 'home';
const settingsURL = `${homeBaseURL}/settings`;
const navigationMenuURL = `${homeBaseURL}/navigationMenu`;
const homeLayoutURL = `${homeBaseURL}/layout`;
const userMeUrl = '/users/me';

const reportBaseURL = 'report';
const reportNavigationMenuURL = `${reportBaseURL}/navigationMenu`;

export const HOME_LAYOUT_STD_WIDGET_TYPE_CONTRACT = {
  MyTasks: 'MyTasks',
  MyMessages: 'MyMessages',
  ManageTasks: 'ManageTasks',
  MyRecentlyUpdatedItems: 'MyRecentlyUpdatedItems'
};

export function useTenantSettings() {
  const { state, run } = useProtrakApi({
    requestConfig: tenantSettingsPromiseFn
  });
  return [state, run];
}

const tenantSettingsPromiseFn = () => {
  return { endpoint: settingsURL };
};

export function useContextUserDetails() {
  const { state, run } = useProtrakApi({
    requestConfig: contextUserDetailsPromiseFn
  });
  return [state, run];
}

const contextUserDetailsPromiseFn = () => {
  return { endpoint: userMeUrl };
};

export function useNavigationMenu() {
  const { state, run } = useProtrakApi({
    requestConfig: navigationMenuPromiseFn
  });
  return [state, run];
}

const navigationMenuPromiseFn = () => {
  return { endpoint: navigationMenuURL };
};

export function useReportNavigationMenu() {
  const { state, run } = useProtrakApi({
    requestConfig: reportNavigationMenuPromiseFn
  });
  return [state, run];
}

const reportNavigationMenuPromiseFn = () => {
  return { endpoint: reportNavigationMenuURL };
};

export function useAllSettings() {
  const { state, run } = useProtrakApi({ promiseFn: allSettingsPromiseFn });
  return [state, run];
}

const allSettingsPromiseFn = async ({ authContext }) => {
  try {
    const userDetailsResponse = await protrakApiClient(
      userMeUrl,
      {},
      authContext
    );
    if (userDetailsResponse) {
    }
    const [
      settingsResponse,
      navigationConfigResponse,
      reportNavigationConfigResponse
    ] = await Promise.all([
      protrakApiClient(settingsURL, {}, authContext),
      protrakApiClient(navigationMenuURL, {}, authContext),
      protrakApiClient(reportNavigationMenuURL, {}, authContext)
    ]);

    return {
      data: {
        settings: settingsResponse.data,
        userData: userDetailsResponse.data,
        navigationConfigData: navigationConfigResponse.data,
        reportNavigationConfigData: reportNavigationConfigResponse.data
      }
    };
  } catch (error) {
    throw error;
  }
};

export const useHomeLayoutConfig = () => {
  const { state, run } = useProtrakApi(
    { requestConfig: fetchHomeLayout },
    { cacheKey: homeLayoutURL }
  );
  return { state, run };
};

const fetchHomeLayout = () => {
  const cacheData = JSON.parse(window.localStorage.getItem(homeLayoutURL));
  return {
    endpoint: homeLayoutURL,
    config: {
      modified: cacheData ? cacheData.modified : null
    }
  };
};
