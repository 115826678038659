import classNames from 'classnames';
import { VersionDetailsPopup } from './Layouts/InstLayouts/InstViewLayout/Widgets/Versions/VersionDetailsPopup';
import { InstanceLink } from '../shared/Components';

export const InstanceNameRenderer = (attribute, value, options) => {
  return (
    <>
      {options.showIcon ? (
        <i className={classNames(options.showIcon, 'margin-right-5px')} />
      ) : null}
      {options.isVersion ? (
        <VersionDetailsPopup
          selectedMinorDetails={options.versionDetails}
          viewConfiguration={options.viewConfig}
          openInNewTab={options.openInNewTab}
          showPopOutIcon={options.showPopOutIcon}
        >
          {attribute.renderValue(value)}
        </VersionDetailsPopup>
      ) : (
        <InstanceLink
          typeName={options.typeName}
          instanceId={options.instanceId}
          openInNewTab={options.openInNewTab}
          showPopOutIcon={options.showPopOutIcon}
          targetLayout={'view'}
          canCreateInstance={options.canCreateInstance}
        >
          {attribute.renderValue(value)}
        </InstanceLink>
      )}
    </>
  );
};
