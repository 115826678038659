import React, { useEffect, useState } from 'react';
import { ReactFlowProvider } from 'react-flow-renderer';
import { DeviceTypes, useDeviceType } from '../../../UseDeviceType';
import { getNodeToBeCentered, getReactFlowRendererDataFromLifecyclesAndActivities, ReactFlowConstants } from './lifecycleToReactFlowMapping';
import { ReactFlowRenderer } from './ReactFlowRenderer';

export function ProtrakFlowRenderer({
    lifecycle,
    performedPromoteActions,
    currentState,
    reload,
    onEdgeClicked,
    onNodeClicked,
    onDeviceTypeChange
}) {
    const currentDeviceType = useDeviceType();
    const [deviceType,] = useState(currentDeviceType);
    const currentOrientation = (currentDeviceType === DeviceTypes.desktop) || (currentDeviceType === DeviceTypes.largeDesktop)
        ? ReactFlowConstants.horizontal
        : ReactFlowConstants.vertical;
    let rendererData = null;
    let currentCenter = null;

    useEffect(() => {
        onDeviceTypeChange(currentDeviceType);
        if (currentDeviceType !== deviceType) {
            reload(currentDeviceType);
        }
    }, [currentDeviceType, deviceType, reload, onDeviceTypeChange]);

    if (!lifecycle || !performedPromoteActions) {
        return null;
    }

    rendererData = getReactFlowRendererDataFromLifecyclesAndActivities(
        lifecycle, performedPromoteActions, currentOrientation, { enableNodeClick: onNodeClicked !== undefined, enableEdgeClick: onEdgeClicked !== undefined });
    currentCenter = getNodeToBeCentered(currentState, rendererData, currentOrientation);

    return (
        <ReactFlowProvider>
            <ReactFlowRenderer
                orientation={currentOrientation}
                rendererData={rendererData}
                showBasicToolbar
                center={currentCenter}
                onEdgeClicked={onEdgeClicked}
                initialZoom={ReactFlowConstants.initialZoom}
            />
        </ReactFlowProvider>
    );
}
