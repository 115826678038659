import {
  mapPicklistValues,
  toQueryString,
  usePaginatedGet, useApi, useProtrakApi, protrakApiClient
} from '../utils';
const instanceBase = '/instances';
const userBase = '/users';
const instanceUsersURL = `${instanceBase}/{0}/users`;
const allUsersUrl = `${userBase}`;

export const deserializeUserValues = (
  picklistValues,
  existingSelection,
  isMultiselect
) => {
  if (picklistValues) {
    if (isMultiselect) {
      return picklistValues
        .map(u => {
          return getUserObject(u, existingSelection);
        })
        .filter(u => u);
    } else {
      return [getUserObject(picklistValues, existingSelection)];
    }
  } else {
    return null;
  }
};

const getUserObject = (allUsers, existingSelection) => {
  let userId =
    existingSelection &&
    existingSelection.find(u => u.userId === allUsers.value);
  if (!userId) {
    return {
      userId: allUsers.value,
      userName: allUsers.label
    };
  } else {
    return existingSelection.find(au => au.userId === allUsers.value);
  }
};

export const TOKEN_CACHE_KEY = '__protrak_user_token__';

export function useAuthenticate() {
  const { state, run } = useApi(
    { requestConfig: authenticate },
    { defer: true }
  );
  return [state, run];
}

const authenticate = ({
  userEmail,
  password,
  provider,
  accessToken,
  orgId
}) => {
  return {
    endpoint: `${userBase}/authenticate`,
    config: {
      method: 'POST',
      data: {
        userEmail,
        password,
        provider,
        accessToken,
        tenantId: orgId,
        clientType: 'Web',
        clientDetails: 'Protrak SPA'
      }
    }
  };
};

export function useValidateAccount() {
  const { state, run } = useApi(
    { requestConfig: validateUserPromise },
    { defer: true }
  );
  return [state, run];
}

const validateUserPromise = ({ userEmail }) => {
  return {
    endpoint: `${userBase}/${userEmail}/validateAccount`,
    config: {
      method: 'GET'
    }
  };
};

export const invalidateToken = (userData, authContext) => {
  let contextToPass;
  if (authContext) {
    contextToPass = authContext;
  } else {
    // because SPA calls this method from authcontext itself.
    contextToPass = { userData: userData };
  }
  protrakApiClient(`${userBase}/invalidateToken?accesstoken=${userData.accessToken}`, {
    method: 'PUT'
  }, contextToPass);
};

export function useChangePassword() {
  const { state, run } = useProtrakApi(
    { requestConfig: changePassword },
    { defer: true }
  );
  return [state, run];
}

const changePassword = ({ currentPassword, newPassword }) => {
  return {
    endpoint: `${userBase}/changePassword`,
    config: {
      method: 'PUT',
      data: {
        oldPassword: currentPassword,
        newPassword: newPassword
      }
    }
  };
};

export const getUsers = async ({
  inputValue,
  includeAdmins,
  excludeValues = [],
  roleFilter = {},
  authContext
}) => {
  let query = {
    includeAdmins: includeAdmins,
    userNameKeyword: inputValue,
    ...roleFilter
  };

  const queryParams = toQueryString(query);

  try {
    const res = await protrakApiClient(`${allUsersUrl}?${queryParams}`, {}, authContext);
    if (excludeValues.length) {
      let items = [];

      items = res.data.items.filter(item => {
        return !excludeValues.some(value => value.id === item.id);
      });

      return mapPicklistValues(items, 'userName', 'id');
    }

    return mapPicklistValues(res.data.items, 'userName', 'id');
  } catch (error) {
    throw error;
  }
};

export const getInstanceUsers = async (
  instanceId,
  searchByText,
  includeAdmins,
  authContext
) => {
  try {
    const res = await protrakApiClient(
      `${instanceUsersURL.replace(
        '{0}',
        instanceId
      )}?userNameKeyword=${searchByText}&includeAdmins=${includeAdmins}`, {}, authContext
    );
    return mapPicklistValues(
      res.data.items.map(u => u.user),
      'userName',
      'id'
    );
  } catch (error) {
    throw error;
  }
};

export function useForgotPassword() {
  const { state, run } = useApi({ requestConfig: forgotPassword }, { defer: true });
  return [state, run];
}

const forgotPassword = ({ userEmail, tenantId }) => {
  return {
    endpoint: `${userBase}/forgotPassword`,
    config: {
      method: 'PUT',
      data: {
        userEmail: userEmail,
        tenantId: tenantId
      }
    }
  };
};

export function useInstanceUsers(instanceId) {
  const {
    state: fetchInstanceUsersState,
    reload,
    nextPage,
    previousPage,
    paginationState,
    hasMoreItems
  } = usePaginatedGet({
    requestConfig: fetchInstanceUsersAsync,
    mapResponse: mapFetchUserInstanceResponse,
    instanceId
  });

  return {
    fetchInstanceUsersState,
    reload,
    nextPage,
    previousPage,
    paginationState,
    hasMoreItems
  };
}

const mapFetchUserInstanceResponse = (apiResponse) => {
  const mapUsers = items => {
    return items.map(item => {
      let userObj = item.user;
      userObj.recursiveAccess = item.recursiveAccess;
      userObj.accessSource = item.accessSource;
      return userObj;
    });
  };

  return {
    ...apiResponse,
    data: {
      ...apiResponse.data,
      items: mapUsers(apiResponse.data.items)
    }
  };
};

const fetchInstanceUsersAsync = ({ instanceId, skip, take }) => {
  return {
    endpoint: `${instanceBase}/${instanceId}/users`,
    config: {
      params: { skip, take },
      method: 'GET'
    }
  };
};

export const useAddUserToInstance = config => {
  return useProtrakApi(
    {
      requestConfig: addUserToInstanceAsync
    },
    { defer: true, onSuccess: config.onSuccess }
  );
};

const addUserToInstanceAsync = ({ instanceId, userId, isRecursive }) => {
  const addInstanceUserUrl = `${instanceBase}/${instanceId}/users`;
  return {
    endpoint: addInstanceUserUrl,
    config: {
      method: 'PUT',
      params: { userId: userId, recursiveAccess: isRecursive }
    }
  };
};

const specificUser = `${userBase}/{0}`;

export const useUserDetails = userId => {
  const { state, run } = useProtrakApi(
    {
      requestConfig: fetchUserInfo,
      userId
    },
    { defer: true }
  );
  return [state, run];
};

export const useUserInfo = userId => {
  const { state } = useProtrakApi({
    requestConfig: fetchUserInfo,
    userId
  });
  return { state };
};

const fetchUserInfo = ({ userId }) => {
  return {
    endpoint: specificUser.replace('{0}', userId)
  };
};
