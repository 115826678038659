import React from 'react';
import { RelationFilterGroup } from './RelationFilterGroup';

export const RelationFilter = ({
  relationFilters,
  instanceId,
  onParentFilterChanged
}) => {
  if (!relationFilters) {
    return null;
  }

  const filterLength = relationFilters.length;

  if (filterLength <= 1) {
    return null;
  }

  function getRelatedInstQuery(key) {
    let relationobjarr = [];

    relationFilters.map((relationFilter, index) => {
      if (index <= key) {
        relationobjarr.push(relationFilter);
      }
    });

    return relationobjarr;
  }

  return (
    relationFilters &&
      relationFilters.map((relationFilter, key) => {
        let relationobjarr = getRelatedInstQuery(key);

        return key < filterLength - 1 ? (
          <RelationFilterGroup
            InstanceDetail={relationFilter}
            index={key}
            key={key}
            instanceId={instanceId}
            relationFilters={relationobjarr}
            onEdit={onParentFilterChanged}
            typeName={relationFilter.typeName}
          />
        ) : null;
      })
  );
};
