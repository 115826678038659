import styled from 'styled-components';
import { formCustomOverflowStyles } from './Styles';

export const Box = styled.div`
  display: ${props => props.display || 'flex'};
  height: ${props => props.height};
  box-sizing: border-box;
  min-width: ${props => props.minWidth};
  min-height: ${props => props.minHeight};
  outline: none;
  flex-direction: ${props => props.direction};
  flex-wrap: ${props => props.flexWrap};
  grid-gap: ${props => props.gridgap};
  grid-area: ${props => props.gridArea};
  border: ${props => props.border};
  border-bottom: ${props => props.borderBottom};
  border-top: ${props => props.borderTop};
  border-right: ${props => props.borderRight};
  border-left: ${props => props.borderLeft};
  border-radius: ${props => props.borderRadius};
  top: ${props => props.top};
  right: ${props => props.right};
  bottom: ${props => props.bottom};
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
  scrollbar-width: ${props => props.scrollbar};
  width: ${props => props.width};
  background-color: ${props =>
    props.backgroundColor
      ? props.theme.colorPalette[props.backgroundColor]
      : null};
  background: ${props => props.background};
  flex: ${props => props.flex};
  margin: ${props => props.margin};
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
  margin-left: ${props => props.marginLeft};
  margin-right: ${props => props.marginRight};
  text-align: ${props => props.textAlign};
  cursor: ${props => props.cursor};
  justify-self: ${props => props.justifySelf};
  white-space: ${props => props.whiteSpace};
  text-overflow: ${props => props.textOverflow};
  color: ${props =>
    props.color ? props.theme.colorPalette[props.color] : props.color};
  line-height: ${props => props.lineHeight};
  padding: ${props => props.padding};
  padding-top: ${props => props.paddingTop};
  padding-bottom: ${props => props.paddingBottom};
  padding-left: ${props => props.paddingLeft};
  padding-right: ${props => props.paddingRight};
  box-shadow: ${props => getBoxShadowStyle(props)};
  position: ${props => props.position};
  max-width: ${props => props.maxWidth};
  align-self: ${props => props.alignSelf};
  max-height: ${props => props.maxHeight};
  ${props => (props.overflow ? formCustomOverflowStyles : '')};
`;

function getBoxShadowStyle(props) {
  if (!props.boxShadow) {
    return '';
  }

  return (
    props.boxShadow +
    ' ' +
    (props.boxShadowColor
      ? props.boxShadowColor
      : props.theme.colorPalette['PRIMARY_SHADOW']
    )
  );
}

