import React from 'react';
import { RelationEditPopup } from '../../../shared/Components';
import { InstanceCreateContextProvider } from '../../../Contexts/InstanceCreateContextProvider';
import { getConfiguredRelationAttributes } from '../../../Core';

export const EditRelations = props => {
  const { config, relationDetails, onClose } = props;
  const editConfig = getRelatedAttributesSections(config.fields);

  const relationAttributesNames = getConfiguredRelationAttributes(
    config.fields || []
  );

  const relationAttributesDetails = getRelationAttributeDetails(
    relationDetails,
    relationAttributesNames
  );

  return (
    <InstanceCreateContextProvider
      config={editConfig}
      defaultValues={relationAttributesDetails}
    >
      <RelationEditPopup
        {...props}
        editConfig={editConfig}
        closeCallback={onClose}
      />
    </InstanceCreateContextProvider>
  );
};

//TODO: Remove this method after FieldsWidget refactoring
export const getRelatedAttributesSections = fields => {
  return {
    sections: [
      {
        widgets: [
          {
            widgetType: 'FieldsWidget',
            fields: fields.filter(f => f.fieldType === 'Relation Attribute')
          }
        ]
      }
    ]
  };
};

const getRelationAttributeDetails = (relationAttrDetails, attributesNames) => {
  if (attributesNames && relationAttrDetails && !attributesNames.length) {
    return {};
  }

  let relAttrDetails = {};

  attributesNames.forEach(name => {
    let detail = relationAttrDetails[name];
    if (detail) {
      relAttrDetails[name] = detail;
    }
  });

  return relAttrDetails;
};
