import React from 'react';
import { H4 } from '../../lib/components/H4';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    const { trackException } = this.props;
    trackException(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          <H4 color={'ERROR'}>
            Something went wrong, please contact system administrator.
          </H4>
        </div>
      );
    }

    return this.props.children;
  }
}
