import useEventListener from './useEventListener';

const useWindowCloseWarning = shouldShowPrompt => {
  const handler = event => {
    if (shouldShowPrompt) {
      // Cancel the event as stated by the standard.
      event.preventDefault();
      // Chrome requires returnValue to be set.
      // the message does not matter, browser shows it's own message
      event.returnValue = '';
    }
  };
  useEventListener('beforeunload', handler);
};

export default useWindowCloseWarning;
