import { useRef, useState, useLayoutEffect } from 'react';
import useEventListener from './useEventListener';

export function useDimensions() {
  const ref = useRef(null);
  const current = ref.current;
  const [dimensions, setDimensions] = useState({});
  const [size, setSize] = useState(window.innerWidth + window.innerHeight);
  useEventListener('resize', () => {
    setSize(window.innerWidth + window.innerHeight);
  });
  useEventListener('navigationMenuToggled', () => {
    setSize(
      window.innerWidth +
        window.innerHeight +
        (ref.current && ref.current.offsetWidth)
    );
  });
  useLayoutEffect(() => {
    try {
      setDimensions(current && current.getBoundingClientRect().toJSON());
    } catch (e) {
      // some browser do not support this functionality and throw error so we have to catch this error in order to not affect other functions
    }
  }, [current, size]);
  // this ref needs to be attached to the component for which use dimensions is being done
  return [ref, dimensions];
}

// Added this additional hook for ReportDataGrid for tabular pivot reports specifically
// useDimension hook has current as useLayoutEffect dependency which was giving incorrect
// height for report data grid. but also same time it was working fine for all other usages.
// hence added this additional hook
// TODO need to revisit this issue
export function useDimensionsForReportDataGrid() {
  const ref = useRef(null);
  const [dimensions, setDimensions] = useState({});
  const [size, setSize] = useState(window.innerWidth + window.innerHeight);
  useEventListener('resize', () => {
    setSize(window.innerWidth + window.innerHeight);
  });
  useEventListener('navigationMenuToggled', () => {
    setSize(
      window.innerWidth +
        window.innerHeight +
        (ref.current && ref.current.offsetWidth)
    );
  });
  useLayoutEffect(() => {
    try {
      setDimensions(ref.current && ref.current.getBoundingClientRect().toJSON());
    } catch (e) {
      // some browser do not support this functionality and throw error so we have to catch this error in order to not affect other functions
    }
  }, [size]);
  // this ref needs to be attached to the component for which use dimensions is being done
  return [ref, dimensions];
}
