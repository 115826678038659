export const mapToISOTimeFormat = timeFormat => {
  switch (timeFormat) {
    case 'hms':
      return 'HH:mm:ss';
    case 'hmsa':
      return 'hh:mm:ss a';
    case 'hm':
      return 'HH:mm';
    case 'hma':
      return 'hh:mm a';
    default:
      return 'HH:mm:ss';
  }
};
