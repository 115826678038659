import styled from 'styled-components';

export const CircularButton = styled.button`
  margin: ${props => props.margin || '5px'};
  border-radius: ${props => props.borderRadius || '50%'};
  border: ${props => props.border || 'none'};
  background: ${props =>
    props.background || props.theme.colorPalette.PRIMARY_DARK};
  cursor: ${props => props.cursor || 'pointer'};
  position: ${props => props.position};
  padding: ${props => props.padding};
  left: ${props => props.left};
  top: ${props => props.top};
  right: ${props => props.right};
  bottom: ${props => props.bottom};
  &:focus {
    outline: none;
  }
`;
