import React, { useLayoutEffect, useContext } from 'react';
import Field from '../Forms/Field';
import formClasses from '../Forms/Forms.module.css';
import InstanceEditContext from '../../Contexts/InstaceEditContext';

const FieldsWidget = ({ fields, editMode }) => {
  const { instanceEditState } = useContext(InstanceEditContext);

  let refs = {};

  fields.forEach(f => {
    refs[f.attributeName] = React.createRef();
  });

  useLayoutEffect(() => {
    if (instanceEditState.validationRuleErrors) {
      let firstValidationRuleError =
        refs[Object.keys(instanceEditState.validationRuleErrors)[0]];
      if (firstValidationRuleError) {
        if (firstValidationRuleError.current) {
          firstValidationRuleError.current.scrollIntoView(false);
        }
      }
    }
  }, [instanceEditState.validationRuleErrors, refs]);

  const renderFields = () =>
    fields &&
    fields.map((f, index) => {
      return (
        <React.Fragment key={`${f.attributeName}_${index}`}>
          <Field field={f} editMode={editMode} ref={refs[f.attributeName]} />
        </React.Fragment>
      );
    });

  return <>{renderFields()}</>;
};

export default FieldsWidget;
