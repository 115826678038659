import { useProtrakApi } from '../utils';

const INTEGRATIONS = `integrations`;
const UPDATE_INTEGRATION = `${INTEGRATIONS}/{0}`;
const INTEGRATION_DETAILS = `${INTEGRATIONS}/{0}/integration`;
const TYPE_MAPPINGS = `${INTEGRATIONS}/{0}/mappings`;
const TYPE_MAPPING = `${INTEGRATIONS}/{0}/mappings/{1}`;
const REMOTE_TYPES = `${UPDATE_INTEGRATION}/remoteTypes`;
const REMOTE_ATTRIBUTES = `${UPDATE_INTEGRATION}/remoteTypes/{1}/attributes`;

const integrationsPromise = () => {
  return {
    endpoint: INTEGRATIONS,
    config: {
      method: 'GET',
    }
  };
};

const integrationDetailsPromise = ({ integrationName }) => {
  const url = INTEGRATION_DETAILS.replace('{0}', integrationName);
  return {
    endpoint: url, config: {
      method: 'GET'
    }
  };
};

const createIntegrationPromise = ({ payload }) => {
  return {
    endpoint: INTEGRATIONS, config: {
      method: 'POST',
      data: payload
    }
  };
};

const updateIntegrationPromise = ({ payload, integrationName }) => {
  const url = UPDATE_INTEGRATION.replace('{0}', integrationName);
  return {
    endpoint: url, config: {
      method: 'PUT',
      data: payload
    }
  };
};

const typeMappingsPromise = ({ integrationName }) => {
  const url = TYPE_MAPPINGS.replace('{0}', integrationName);
  return {
    endpoint: url, config: {
      method: 'GET'
    }
  };
};

const typeMappingPromise = ({ integrationName, typeName }) => {
  const url = TYPE_MAPPING.replace('{0}', integrationName).replace(
    '{1}',
    typeName
  );
  return {
    endpoint: url, config: {
      method: 'GET'
    }
  };
};

const addTypeMappingPromise = ({
  payload,
  integrationName,
  typeName
}) => {
  const url = TYPE_MAPPING.replace('{0}', integrationName).replace(
    '{1}',
    typeName
  );
  return {
    endpoint: url, config: {
      method: 'POST',
      data: payload
    }
  };
};

const updateTypeMappingPromise = ({
  payload,
  integrationName,
  typeName
}) => {
  const url = TYPE_MAPPING.replace('{0}', integrationName).replace(
    '{1}',
    typeName
  );
  return {
    endpoint: url, config: {
      method: 'PUT',
      data: payload
    }
  };
};

const deleteTypeMappingPromise = ({ integrationName, typeName }) => {
  const url = TYPE_MAPPING.replace('{0}', integrationName).replace(
    '{1}',
    typeName
  );
  return {
    endpoint: url, config: {
      method: 'DELETE'
    }
  };
};

const remoteTypesPromise = ({ integrationName }) => {
  const url = REMOTE_TYPES.replace('{0}', integrationName);
  return {
    endpoint: url, config: {
      method: 'GET'
    }
  };
};

const remoteAttributesPromise = ({ integrationName, remoteTypeName }) => {
  const url = REMOTE_ATTRIBUTES.replace('{0}', integrationName).replace(
    '{1}',
    remoteTypeName
  );
  return {
    endpoint: url, config: {
      method: 'GET'
    }
  };
};

export function useIntegrations() {
  const { state, run } = useProtrakApi(
    { requestConfig: integrationsPromise },
    { defer: false }
  );
  return { state, run };
}

export function useIntegrationDetails(integrationName) {
  const { state, run } = useProtrakApi(
    { requestConfig: integrationDetailsPromise, integrationName },
    { defer: false }
  );
  return { state, run };
}

export function useCreateIntegration() {
  const { state, run } = useProtrakApi(
    {
      requestConfig: createIntegrationPromise
    },
    { defer: true }
  );
  return { state, run };
}

export function useUpdateIntegration() {
  const { state, run } = useProtrakApi(
    { requestConfig: updateIntegrationPromise },
    { defer: true }
  );
  return { state, run };
}

export function useTypeMappings(integrationName) {
  const { state, run } = useProtrakApi(
    {
      requestConfig: typeMappingsPromise,
      integrationName
    },
    { defer: false }
  );
  return { state, run };
}

export function useTypeMapping(integrationName, typeName) {
  const { state, run } = useProtrakApi(
    {
      requestConfig: typeMappingPromise,
      integrationName,
      typeName
    },
    { defer: false }
  );
  return { state, run };
}

export function useAddTypeMapping() {
  const { state, run } = useProtrakApi(
    { requestConfig: addTypeMappingPromise },
    { defer: true }
  );
  return { state, run };
}

export function useUpdateTypeMapping() {
  const { state, run } = useProtrakApi(
    { requestConfig: updateTypeMappingPromise },
    { defer: true }
  );
  return { state, run };
}

export function useDeleteTypeMapping() {
  const { state, run } = useProtrakApi(
    { requestConfig: deleteTypeMappingPromise },
    { defer: true }
  );
  return { state, run };
}

export function useRemoteTypes(integrationName) {
  const { state, run } = useProtrakApi(
    {
      requestConfig: remoteTypesPromise,
      integrationName
    },
    { defer: true }
  );
  return { state, run };
}

export function useRemoteAttributes(integrationName, remoteTypeName) {
  const { state, run } = useProtrakApi(
    {
      requestConfig: remoteAttributesPromise,
      integrationName,
      remoteTypeName
    },
    { defer: true }
  );
  return { state, run };
}
