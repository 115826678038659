import {
  AllUsersAttribute,
  BooleanAttribute,
  PicklistAttribute,
  CurrencyAttribute,
  TextAttribute,
  DateTimeAttribute,
  DateAttribute,
  AttachmentAttribute,
  ExpressionAttribute,
  NumericAttribute
} from '.';
import { NameAttribute } from './NameAttribute';
import { DescriptionAttribute } from './DescriptionAttribute.js';
import { ReferenceAttribute } from './ReferenceAttribute';
import { RichtextAttribute } from './RichtextAttribute';
import { StateAttribute } from './StateAttribute';
import { LifecycleAttribute } from './LifecycleAttribute';
import { validateAttributeValue } from './validateAttributeValue';
import { ErrorMessage } from './ErrorMessage';
import { Grid } from '../../lib/components';

const attributeTypeFnMap = {
  DateTime: DateTimeAttribute,
  Text: TextAttribute,
  Boolean: BooleanAttribute,
  Picklist: PicklistAttribute,
  Numeric: NumericAttribute,
  Currency: CurrencyAttribute,
  Date: DateAttribute,
  RichText: RichtextAttribute,
  User: AllUsersAttribute,
  Attachment: AttachmentAttribute,
  Reference: ReferenceAttribute,
  Expression: ExpressionAttribute,
  Lifecycle: LifecycleAttribute
};

export const Attribute = config => {
  if (!config) {
    throw new Error('Invalid config passed');
  }

  let attributeTypeFn = {};
  if (config.fieldType === 'State') {
    attributeTypeFn = StateAttribute;
  } else if (
    config.attributeName === 'Name' ||
    config.attributeName === 'name' ||
    config.attributeName === 'TrackingId'
  ) {
    attributeTypeFn = NameAttribute;
  } else if (config.attributeName === 'Description') {
    attributeTypeFn = DescriptionAttribute;
  } else {
    attributeTypeFn = attributeTypeFnMap[config.attributeType];
  }

  if (typeof attributeTypeFn !== 'function') {
    throw new Error('Invalid config passed');
  }

  const {
    renderAttributeValue,
    renderAttributeInput,
    renderFilterAttributeInput,
    filterOptions,
    getValue,
    getDisplayValue,
    getFilterValue,
    defaultSortOrder,
    getDefaultValue,
    validateValue,
    evaluateCondition
  } = attributeTypeFn(config);

  const renderValue = (value, options) => {
    return renderAttributeValue(value, options);
  };

  const getAttributeDisplayValue = (value, settings)=>{
    return getDisplayValue(value, settings);
  };

  const getAttributeValue = value => {
    return getValue(value);
  };

  const getFilterAttributeValue = value => {
    if (getFilterValue && typeof getFilterValue === 'function') {
      return getFilterValue(value);
    } else {
      return getValue(value);
    }
  };

  const validate = value => {
    //if attribute has exported custom validator function, use it
    //else use default validator
    if (validateValue) {
      let errorMessage = validateValue(getAttributeValue(value));
      if (errorMessage) {
        return errorMessage;
      }
    } else {
      return validateAttributeValue(config, getAttributeValue(value));
    }
  };

  const renderInput = (value, onEdit, errorMessage) => {
    return (
      <Grid
        gridgap={'3px'}
        areas={[['auto']]}
        rows={['auto']}
        columns={['auto']}
      >
        {renderAttributeInput(value, onEdit)}
        <ErrorMessage errorMessage={errorMessage} />
      </Grid>
    );
  };

  const renderFilterInput = (
    value,
    onEdit,
    errorMessage,
    isWorksheet,
    isSecondvalue = false
  ) => {
    if (
      renderFilterAttributeInput &&
      typeof renderFilterAttributeInput === 'function'
    ) {
      return (
        <Grid
          gridgap={'3px'}
          areas={[['auto']]}
          rows={['auto']}
          columns={['auto']}
        >
          {renderFilterAttributeInput(
            value,
            onEdit,
            isWorksheet,
            isSecondvalue
          )}
          <ErrorMessage errorMessage={errorMessage} />
        </Grid>
      );
    } else {
      return (
        <Grid
          gridgap={'3px'}
          areas={[['auto']]}
          rows={['auto']}
          columns={['auto']}
        >
          {renderAttributeInput(value, onEdit)}
          <ErrorMessage errorMessage={errorMessage} />
        </Grid>
      );
    }
  };

  const getDefaultValues = (timezone) => {
    return getDefaultValue(timezone);
  };

  const evaluateRuleCondition = (
    condition,
    valueObj,
    compareToValueStr,
    secondValueStr,
    timeZone
  ) => {
    if (!evaluateCondition) {
      return false;
    }
    return evaluateCondition(
      condition,
      valueObj,
      compareToValueStr,
      secondValueStr,
      timeZone
    );
  };

  const filterConditions = filterOptions;

  return {
    renderValue,
    renderInput,
    renderFilterInput,
    validate,
    filterConditions,
    getAttributeDisplayValue,
    getAttributeValue,
    getFilterAttributeValue,
    defaultSortOrder,
    getDefaultValues,
    evaluateRuleCondition
  };
};
