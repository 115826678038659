import React from 'react';
import { Enums } from '../../Enums';
import { AllUsers } from './AllUsers';
import { Prefix, Suffix } from './prefixSuffix';

export const AllUsersAttribute = config => {
  const getValue = value => {
    if (value) {
      return typeof value === 'string' ? value.split(',') : value.userValues;
    }
    return [];
  };

  const getFilterValue = value => {
    let filterValue = value;
    if (
      typeof value !== 'string' &&
      value['userValues'] &&
      Array.isArray(value.userValues) &&
      value.userValues[0].userId
    ) {
      filterValue = value.userValues[0].userId;
    }
    return filterValue;
  };

  const getDisplayValue = value => {
    return stringifyUserValues(getValue(value));
  };

  const renderAttributeValue = value => {
    const val = getValue(value);
    if (!val) {
      return <>{'-'}</>;
    }
    return (
      <>
        <Prefix value={config.prefix} />
        {stringifyUserValues(val)}
        <Suffix value={config.suffix} />
      </>
    );
  };

  const renderAttributeInput = (fieldValue, onEdit) => {
    return (
      <AllUsers
        key={getValue(fieldValue)}
        isMultiselect={config.isMultiselect}
        userRoleFilters={config.userRoleFilters}
        value={getValue(fieldValue)}
        onEdit={newValue => onEdit({ userValues: newValue })}
        placeholder={config.placeholder}
      />
    );
  };

  const filterOptions = [
    { displayName: 'ContextUser', name: Enums.Conditions.ContextUser },
    { displayName: 'Contains', name: Enums.Conditions.Contains },
    { displayName: 'NotContains', name: Enums.Conditions.NotContains },
    { displayName: 'Equals', name: Enums.Conditions.Equals },
    { displayName: 'NotEquals', name: Enums.Conditions.NotEquals },
    { displayName: 'IsEmpty', name: Enums.Conditions.IsEmpty },
    { displayName: 'IsNotEmpty', name: Enums.Conditions.IsNotEmpty }
  ];

  const defaultSortOrder = !config.isMultiselect ? 'Ascending' : '';

  const evaluateCondition = (condition, valueObj, compareToValueStr) => {
    const value = getValue(valueObj);
    switch (condition) {
      case Enums.Conditions.Equals:
        return (
          value && value.length === 1 && value[0].userId === compareToValueStr
        );
      case Enums.Conditions.NotEquals:
        return (
          value && value.length === 1 && value[0].userId !== compareToValueStr
        );
      case Enums.Conditions.Contains:
        return value && value.some(user => user.userId === compareToValueStr);
      case Enums.Conditions.NotContains:
        return value && !value.some(user => user.userId === compareToValueStr);
      case Enums.Conditions.IsNotEmpty:
        return value && value.length > 0;
      case Enums.Conditions.IsEmpty:
        return !value || value.length === 0;
      case Enums.Conditions.ContextUser:
        return (
          value && value.some(user => user.userId === config.currentUserId)
        );
      default:
        return false;
    }
  };

  return {
    getDisplayValue,
    renderAttributeValue,
    renderAttributeInput,
    filterOptions,
    evaluateCondition,
    getValue,
    getFilterValue,
    defaultSortOrder
  };
};

export const stringifyUserValues = userValues => {
  if (!userValues) {
    return '';
  }

  let selectedUsersList = userValues.map(u => {
    return u.userName;
  });

  return selectedUsersList.join();
};
