
import { protrakApiClient, uploadBlob } from '.';

export const filesBase = '/files';

export const uploadFile = async (fileValue, authContext, defaultBlockSize = 262144, timeout = 60000) => {

  const fileData = await getFileUrl(fileValue, authContext);

  if (fileData.data && fileValue) {
    let fileUrl = fileData.data.fileUrl;

    try {
      await uploadBlob({
        fileValue, fileUrl, defaultBlockSize, timeout
      });
    } catch (error) {
      throw new Error('file not uploaded');
    }

    return fileData;
  } else {
    throw new Error('Invalid file URL - please contact your system administrator');
  }
};

const getFileUrl = async (fileName, authContext) => {
  const data = JSON.stringify(fileName);
  const res = await protrakApiClient(filesBase, {
    data,
    method: 'POST'
  }, authContext);
  return res;
};
