import React from 'react';
import { Text } from '../../../lib/components';
import { CellContent } from './CellContent';

export function ColumnHeader({ label, styles = {} }) {
  return (
    <CellContent height="100%" {...styles}>
      <Text>{label}</Text>
    </CellContent>
  );
}
