import { client } from "./client";

export function protrakApiClient(endpoint, config = {}, middleware) {
    
    const apiBaseUrl = getBaseUrl(middleware);
    const accessToken = middleware && middleware.userData ? middleware.userData.accessToken : undefined;

    let headers = {};

    headers.Accept = 'text/json';
    headers['Content-Type'] = 'application/json';

    if (accessToken) {
        headers.Authorization = `accesstoken=${accessToken}`;
    }
    if (config.headers) {
        headers = { ...headers, ...config.headers };
    }
    if (config.modified) {
        headers['If-Modified-Since'] = config.modified;
    }
    return getClient(middleware)(endpoint, {
        ...config,
        baseURL: apiBaseUrl,
        headers,
        timeout: getTimeOut(middleware)
    });
}

function getClient(middleware) {
    return middleware && middleware.baseApiConfig && middleware.baseApiConfig.client ?
    middleware.baseApiConfig.client : client;
}

function getTimeOut(middleware) {
    return middleware && middleware.baseApiConfig && middleware.baseApiConfig.TIME_OUT ?
    middleware.baseApiConfig.TIME_OUT : process.env.REACT_APP_PROTRAK_API_TIMEOUT;
}

function getBaseUrl(middleware) {
    if (middleware && middleware.baseApiConfig && middleware.baseApiConfig.BASE_URL && middleware.baseApiConfig.API_VERSION) {
        return `${middleware.baseApiConfig.BASE_URL}/${middleware.baseApiConfig.API_VERSION}/`;
    } else {
        return `${process.env.REACT_APP_PROTRAK_API_BASE_URL}/${process.env.REACT_APP_PROTRAK_API_VERSION}/`;
    }
}