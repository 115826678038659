import React, { useState, useContext } from 'react';
import classes from './Reference.module.css';
import { customStyles } from '../../../lib/components/Dropdown/PicklistRenderStyling';
import { getUpdatedList } from './ReferenceUtility';
import { Dropdown, Text } from '../../../lib/components';
import { mapPicklistValues } from '../../../Core';
import { RelatedInstanceListPopup } from './RelatedInstanceListPopup';
import { CreateInstance } from '../CreateLayoutPopup/CreateInstance';
import InstanceEditContext from '../../../Contexts/InstaceEditContext';
import { ReferenceAttributePaginateDropDown } from '../../../lib/components/ReferenceAttributePaginateDropDown';

export const Reference = ({
  value = [],
  onEdit,
  fieldLabel,
  relatedTypeName,
  relationDirection,
  isMultiselect,
  canCreateRelatedType,
  canLinkRelatedType,
  config
}) => {
  const [showInstList, viewInstList] = useState(false);
  const [showCreateInst, viewCreateInst] = useState(false);
  const { instanceDetails, instanceEditState } = useContext(
    InstanceEditContext
  );

  const getMultiselect = data => {
    if (isMultiselect) {
      return value !== null ? onEdit([...value, ...data]) : onEdit([...data]);
    }
    return onEdit([...data]);
  };

  const onReferenceValueChange = data => {
    getMultiselect(data);
    viewInstList(false);
  };

  const onRefInstCreated = data => {
    getMultiselect(data);
    viewCreateInst(false);
  };

  const onChange = selectedItems => {
    onEdit(getUpdatedList(value, selectedItems));
  };

  const onReferenceDropdownValueChange = data => {
    if (isMultiselect) {
      return onEdit([...data]);
    }
    return data !== '' ? onEdit([data]) : onEdit([]);
  };

  const SearchButton = () => {
    return (
      <div
        className={classes['reference-attr-action']}
        onClick={() => viewInstList(true)}
      >
        <Text color={'inherit'}>
          <i className="fa fa-search" aria-hidden="true" />
        </Text>
      </div>
    );
  };

  const CreateButton = () => {
    return (
      <div
        className={classes['reference-attr-action']}
        onClick={() => viewCreateInst(true)}
      >
        <Text color={'inherit'}>
          <i className="fa fa-plus" aria-hidden="true" />
        </Text>
      </div>
    );
  };

  return (
    <>
      <div className={classes['reference-attr-container']}>
        {canLinkRelatedType &&
        config.showAs &&
        config.showAs.toLowerCase() === 'dropdown' ? (
          <ReferenceAttributePaginateDropDown
            selectedReferences={value}
            relatedTypeName={relatedTypeName}
            isMultiselect={isMultiselect}
            onEdit={event => onReferenceDropdownValueChange(event)}
            config={config}
          />
        ) : (
          <Dropdown
            defaultValues={mapPicklistValues(value, 'name', 'id')}
            isMultiselect={isMultiselect}
            styles={customStyles}
            onValueChange={onChange}
            placeholder={config.placeholder}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
              Menu: () => null,
              CrossIcon: () => null
            }}
          />
        )}
        <>
          {canLinkRelatedType &&
          (!config.showAs || config.showAs.toLowerCase() !== 'dropdown') ? (
            <SearchButton />
          ) : null}
          {canCreateRelatedType ? <CreateButton /> : null}
        </>
      </div>
      {showInstList ? (
        <RelatedInstanceListPopup
          typeName={fieldLabel}
          relatedInstances={value}
          relatedTypeName={relatedTypeName}
          selectedInstances={value}
          successCallback={onReferenceValueChange}
          relationDirection={relationDirection}
          isMultiselect={isMultiselect}
          config={config}
          closeCallback={() => viewInstList(false)}
        />
      ) : null}
      {showCreateInst ? (
        <CreateInstance
          instanceTypeName={relatedTypeName}
          onInstanceCreated={data => onRefInstCreated(data)}
          onClose={() => viewCreateInst(false)}
          instanceName={instanceDetails && instanceDetails.data.name}
          instanceId={instanceDetails && instanceDetails.data.id}
          originalInstanceTypeName={
            instanceEditState && instanceEditState.details.instanceTypeName
          }
          relationTypeName={config.relationTypeName}
          successCallback={data => onRefInstCreated(data)}
          saveSuccessCallback={() => viewCreateInst(false)}
        />
      ) : null}
    </>
  );
};
