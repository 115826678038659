import React from 'react';
import { Box } from '../../lib/components';

export const ReportAttributeFilterContainer = ({children, applyMarginTop, height}) => {
    return (
    <Box
      border=" 1px solid var(--primary-lighter)"
      marginTop={applyMarginTop ? '15px' : '0px'}
      padding="5px"
      display="block"
      height={height}
      borderRadius="5px">{children}</Box>
    );
};
