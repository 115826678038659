import React from 'react';
import { Grid } from '../../lib/components/Grid';
import { Box } from '../../lib/components';
import { ErrorBoundaryWithAppInsights } from '../ErrorBoundary';

export const PageLayout = ({ Navigation, Header, Toolbar, Content }) => {
  return (
    <Grid
      rows={['var(--app-header-height)', 'auto']}
      areas={[['header'], ['content']]}
      columns={['auto']}
      height="100%"
    >
      <ErrorBoundaryWithAppInsights>
        <Box
          gridArea="header"
          alignItems="center"
          borderBottom={'1px solid var(--primary-lighter)'}
          backgroundColor={'SURFACE'}
        >
          {Navigation}
          <Box
            flex="1"
            height="100%"
            borderLeft="1px solid var(--primary-lighter)"
            paddingLeft="0.4rem"
          >
            {Header}
          </Box>
          {Toolbar}
        </Box>
        <Box
          gridArea="content"
          direction="column"
          overflow={{ overflowX: 'hidden', overflowY: 'auto' }}
        >
          {Content}
        </Box>
      </ErrorBoundaryWithAppInsights>
    </Grid>
  );
};
