import React from 'react';
import useTranslatedValue from '../../../Hooks/useTranslatedValue';
import { Link } from 'react-router-dom';

const PopOutLink = ({ to, children }) => {
  const popoutTitle = useTranslatedValue('OPEN_IN_NEW_TAB');
  return (
    <Link
      onClick={e => e.stopPropagation()}
      to={to}
      target={'_blank'}
      title={popoutTitle}
    >
      {children}
    </Link>
  );
};

export default PopOutLink;
