import { ColumnHeader } from './ColumnHeader';
import { CellContent } from './CellContent';
import { Text, Box } from '../../../lib/components';
import ProtrakGridActions from './ProtrakGridActions';
import classNames from 'classnames';
import { InstanceNameRenderer } from '../../InstanceNameRenderer';
import { Attribute } from '../../../shared/Components';

export const mapFieldToColumnConfig = (
  fields,
  data,
  reload,
  actionsContext,
  isSortable = true
) => {
  return fields.map(field => {
    if (field.attributeName === 'Actions') {
      return {
        Header: (
          <ColumnHeader
            label={'Actions'}
            styles={{ justifyContent: 'center', width: '100%' }}
          />
        ),
        accessor: '',
        Cell: rowProps => actionsPopup(rowProps, data, reload, actionsContext),
        sortable: false,
        width: field.width,
        percentageWidth: field.percentageWidth
      };
    } else if (field.attributeName === 'name') {
      return {
        Header: <ColumnHeader label={field.label} />,
        accessor: field.attributeName,
        Cell: rowProps =>
          renderAttribute(field, rowProps, InstanceNameRenderer),
        sortable: isSortable,
        width: field.width,
        percentageWidth: field.percentageWidth
      };
    } else if (field.attributeName === 'custom') {
      return {
        Header: field.Header ? (
          field.Header
        ) : (
          <ColumnHeader label={field.label} />
        ),
        accessor: field.attributeName,
        Cell: rowProps => renderCustomAction(field, rowProps, actionsContext),
        sortable: isSortable,
        width: field.width,
        percentageWidth: field.percentageWidth
      };
    } else if (field.attributeName === 'State') {
      return {
        Header: <ColumnHeader label={field.label} />,
        accessor: 'State',
        Cell: rowProps => (
          <CellContent>
            <Text>
              {rowProps.original.state.displayName ||
                rowProps.original.state.name}
            </Text>
          </CellContent>
        ),
        width: field.width,
        percentageWidth: field.percentageWidth
      };
    } else {
      return {
        Header: <ColumnHeader label={field.label} />,
        accessor: field.attributeName,
        Cell: rowProps => renderAttribute(field, rowProps, renderCellValue),
        sortable: isSortable,
        width: field.width,
        percentageWidth: field.percentageWidth
      };
    }
  });
};

const actionsPopup = (props, data, reload, actionsContext) => {
  return (
    <div className={classNames('action-container')}>
      <ProtrakGridActions
        row={props.original}
        instances={data}
        reload={reload}
        actionsContext={actionsContext}
      />
    </div>
  );
};
const renderAttribute = (field, props, renderAttributeValue) => {
  let attribute = Attribute(field);

  const routableProps = {
    typeName: props.original.instanceTypeName,
    instanceId: props.original.id,
    showPopOutIcon: true
    // TODO: Accommodate API changes for typeIcon
  };

  if (field.attributeType === 'Attachment') {
    const value = {
      fileValue: {
        fileId: props.original.fileId,
        fileName: props.original.fileName
      }
    };
    return (
      <Box alignItems="center">
        <Text>{renderAttributeValue(attribute, value, routableProps)}</Text>
      </Box>
    );
  }

  return (
    <Box alignItems="center">
      <Text>{renderAttributeValue(attribute, props.value, routableProps)}</Text>
    </Box>
  );
};

const renderCellValue = (attribute, value, properties) => {
  return attribute.renderValue(value, properties);
};

const renderCustomAction = (field, props, actionsContext) => {
  return field.customComponent({ props, actionsContext });
};
