import React, { useEffect, useRef } from 'react';
import { Grid, Dropdown, Box, H3 } from '../../lib/components';
import { useRelatedInstanceQuery, mapPicklistValues } from '../../Core';
import isEqual from 'lodash/isEqual';

const pageSize = 1000;
export const RelationFilterGroup = ({
  InstanceDetail,
  relationFilters,
  index,
  onEdit,
  instanceId,
  widget,
  relationAttributes,
  maxMenuHeight
}) => {
  const {
    state: instanceDetails,

    fetchItems: runRelatedInstanceQuery
  } = useRelatedInstanceQuery(pageSize);

  const prevRelationFilterRef = useRef(null);

  useEffect(() => {
    if (
      !prevRelationFilterRef.current ||
      !isEqual(relationFilters, prevRelationFilterRef.current)
    ) {
      prevRelationFilterRef.current = relationFilters;
      runRelatedInstanceQuery({
        instanceQueryParams: {
          widget: { fields: [{ attributeName: 'Name' }] },
          relationFilters,
          relationAttributes
        },
        instanceId,
        skip: 0,
        take: 10000
      });
    }
  }, [
    instanceId,
    relationAttributes,
    relationFilters,
    runRelatedInstanceQuery,
    widget
  ]);

  const onValueChange = selectedItems => {
    let selectedObj = {
      instanceId: selectedItems !== null ? selectedItems.value : null,
      relationDirection: InstanceDetail.relationDirection,
      relationTypeName: InstanceDetail.relationTypeName,
      typeName: InstanceDetail.typeName
    };

    onEdit(selectedObj, index);
  };

  const getSelectedFilter = () => {
    let selectedFilter = {
      relatedInstanceId: null,
      relatedInstanceName: 'All'
    };

    if (instanceDetails && instanceDetails.data) {
      selectedFilter =
        instanceDetails.data.items &&
        instanceDetails.data.items.find(
          i => i.relatedInstanceId === InstanceDetail.instanceId
        );

      if (!selectedFilter) {
        InstanceDetail.instanceId = null;
        selectedFilter = {
          relatedInstanceId: null,
          relatedInstanceName: 'All'
        };
      }
    }

    return selectedFilter;
  };

  const getOptions = () => {
    let options =
      instanceDetails && instanceDetails.data && instanceDetails.data.items;

    options &&
      !options.some(a => a.relatedInstanceName === 'All') &&
      options.unshift({
        relatedInstanceId: null,
        relatedInstanceName: 'All'
      });

    return options;
  };

  return (
    <Grid rows={['auto']} columns={['auto']} areas={[['main']]} padding="5px">
      <Box display="block" padding="3px">
        <H3 fontWeight={500}>{InstanceDetail.typeName}</H3>
      </Box>
      <Dropdown
        maxMenuHeight={maxMenuHeight}
        defaultValues={mapPicklistValues(
          [getSelectedFilter()],
          'relatedInstanceName',
          'relatedInstanceId'
        )}
        picklistOptions={mapPicklistValues(
          getOptions(),
          'relatedInstanceName',
          'relatedInstanceId'
        )}
        isMultiselect={false}
        onValueChange={onValueChange}
        isLoading={instanceDetails.isLoading}
        isClearable={true}
      />
    </Grid>
  );
};
