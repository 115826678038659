import { validateWithRegExPattern } from '../../Core';

export const validateAttributeValue = (config, value) => {
  if (config.isRequired && isRequiredValueMissing(value)) {
    return requiredErrorMessage(config);
  }
  if (value && config.regEx && !validateWithRegExPattern(value, config.regEx)) {
    return config.regExErrorMessage;
  }
  return '';
};

const isRequiredValueMissing = value => {
  if (!value && typeof value !== 'boolean') {
    return true;
  }
  if (Array.isArray(value) && !value.length) {
    return true;
  }
  return false;
};

const requiredErrorMessage = config => {
  const displayName = config.label || config.attributeName;
  return `${displayName || 'This field'} is required`;
};
