import { usePaginatedGet } from '../utils';

export function useInstanceActivities({
  PAGE_SIZE,
  instanceId,
  versionId,
  skip,
  type
}) {
  const {
    state: instanceActivities,
    fetchItems,
    nextPage,
    reload,
    paginationState,
    hasMoreItems
  } = usePaginatedGet({
    requestConfig: getInstanceActivities,
    skip: skip,
    take: PAGE_SIZE,
    isSortByDescending: true,
    instanceId: instanceId,
    versionId: versionId,
    type: type
  });

  return {
    instanceActivities,
    nextPage,
    reload,
    fetchItems,
    paginationState,
    hasMoreItems
  };
}

const getInstanceActivities = ({
  take,
  skip,
  isSortByDescending,
  versionId,
  instanceId,
  type
}) => {
  const endPointUrl = versionId
    ? `/instances/${instanceId}/versions/${versionId}/activities`
    : `/instances/${instanceId}/activities`;
  return {
    endpoint: endPointUrl,
    config: {
      params: {
        type: type,
        take: take,
        isSortByDescending: isSortByDescending,
        skip: skip
      },
    }
  };
};
