import React, { useState } from 'react';
import { mapActionToDropdownItem } from './utils';
import classes from './PromoteActions.module.css';
import useTranslatedValue from '../../../Hooks/useTranslatedValue';
import { Spinner, Modal } from '../../../lib/components';
import { ActionSelector } from './ActionSelector';
import { InstanceBulkPromoteStatus } from './InstanceBulkPromoteStatus';

import {
  TextArea,
  Box,
  Button,
  ButtonEnums,
  H3,
  H2
} from '../../../lib/components';
import { CancelIcon } from '../../Icons';
import { DataGrid } from '../DataGrid';
import {
  useAppInsightsContext,
  PROTRAK_EVENTS
} from '../../../Contexts/AppInsightsContext';

const RESET_SKIP = 0;
export const BulkPromoteDialog = ({
  promoteActions,
  selectedPromoteAction,
  instances,
  onCancel,
  onSuccess,
  onError,
  isModalOpen
}) => {
  const Take = parseInt(process.env.REACT_APP_BULK_PROMOTE_BATCH_COUNT, 10);
  const CommentsText = useTranslatedValue('COMMENTS');
  let instancesList = [];
  const okText = useTranslatedValue('OK');
  const [errorMessage, setErrorMessage] = useState('');
  const [isErrorDetailsModalOpen, setIsErrorDetailsModalOpen] = useState(false);
  const [instNameToShowError, setInstNameToShowError] = useState();
  const [instanceErrorsToDisplay, setInstanceErrors] = useState([]);
  const { trackEvent } = useAppInsightsContext();
  const [skip, setSkip] = useState(RESET_SKIP);

  const skipTake = skip + Take;

  instances.forEach(instance => {
    instancesList.push({
      id: instance.id,
      name: instance.name,
      callStatus: ''
    });
  });

  if (instancesList.length > 0) {
    let limit =
      skipTake > instancesList.length ? instancesList.length : skipTake;
    for (let count = 0; count < limit; count++) {
      if (
        instancesList[count].callApi === undefined ||
        instancesList[count].callApi !== true
      ) {
        instancesList[count].callApi = true;
      }
    }
  }

  const errorType = typeof errorMessage;

  const [comments, setComments] = useState('');
  const [selectedAction, setSelectedAction] = useState(
    selectedPromoteAction
      ? mapActionToDropdownItem(selectedPromoteAction)
      : null
  );
  const [isPromoteProgressVisible, setPromoteProgressVisible] = useState(false);
  const [isPromoteActionsCompleted, setPromoteActionsCompleted] = useState(
    false
  );
  const [isCloseDisabled, setCloseDisabled] = useState(false);

  const disable = isPromoteProgressVisible && !isPromoteActionsCompleted;

  const onCloseClicked = () => {
    if (isPromoteActionsCompleted) {
      promoteCallback();
    }
    setComments('');
    setSelectedAction(null);
    setSkip(RESET_SKIP);
    onCancel();
  };

  const executePromote = () => {
    trackEvent(PROTRAK_EVENTS.BULKPROMOTE);
    if (selectedAction && selectedAction.value) {
      setCloseDisabled(true);
      setPromoteProgressVisible(true);
    } else {
      alert('Please select action');
    }
  };

  const promoteSuccessCallback = (id, error) => {
    setErrorMessage(error);
    const keyIndex = instancesList.findIndex(x => x.id === id);
    if (
      instancesList[keyIndex] &&
      instancesList[keyIndex].callStatus === 'done'
    ) {
      return;
    }

    instancesList[keyIndex].callStatus = 'done';
    let callbackCount = instancesList.filter(x => x.callStatus === 'done')
      .length;

    if (callbackCount === skipTake) {
      setSkip(prevSkip => prevSkip + Take);
    } else if (callbackCount === instancesList.length) {
      setPromoteActionsCompleted(true);
      setCloseDisabled(false);
    }
  };

  const promoteCallback = () => {
    setComments('');
    setPromoteProgressVisible(false);
    setSelectedAction(null);
    setPromoteActionsCompleted(false);
    setSkip(RESET_SKIP);
    if (errorMessage && onError) {
      onError();
    } else {
      onSuccess();
    }
  };

  const PromoteItemsList = () => {
    return (
      <>
        <Box
          margin="10px 0px"
          direction="column"
          minHeight="20vh"
          overflow={{
            overflowY: 'auto'
          }}
        >
          {instancesList.map(instance => {
            return (
              <Box
                justifyContent="space-between"
                marginBottom="10px"
                key={instance.id}
                borderBottom="1px solid var(--primary-lighter)"
              >
                <Box margin="5px">{instance.name}</Box>
                <Box justifyContent="center">
                  {isPromoteProgressVisible ? (
                    <InstanceBulkPromoteStatus
                      instanceId={instance.id}
                      actionLabel={selectedAction.label}
                      action={selectedAction.value}
                      comment={comments}
                      errorType={errorType}
                      callApi={instance.callApi}
                      promoteCallback={(id, message) =>
                        promoteSuccessCallback(id, message)
                      }
                      openErrorDisplayModal={instanceErrorMessageDetails => {
                        setInstNameToShowError(instance.name);
                        setInstanceErrors(instanceErrorMessageDetails);
                        setIsErrorDetailsModalOpen(true);
                      }}
                    />
                  ) : null}
                </Box>
              </Box>
            );
          })}
        </Box>
      </>
    );
  };

  const instanceListModal = (
    <Box direction="column">
      <Box>
        <Box direction="column" height="55vh" overflow="auto" width={'100%'}>
          {PromoteItemsList()}
        </Box>
        {isPromoteProgressVisible ? null : (
          <Box margin="0px 10px" direction="column" width={'100%'}>
            <Box direction="column">
              <ActionSelector
                disable={disable}
                promoteActions={promoteActions}
                selectedAction={selectedAction}
                selectedPromoteAction={selectedPromoteAction}
                onChange={selectedOption => {
                  setSelectedAction(selectedOption);
                }}
              />
              <H3 margin="10px 0px">{CommentsText}</H3>
              <Box width="100%" alignSelf="center">
                <TextArea rows={5} value={comments} onEdit={setComments} />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {promoteModalFooter(
        okText,
        executePromote,
        isPromoteActionsCompleted,
        disable,
        () => promoteCallback()
      )}
    </Box>
  );

  function instanceErrorDetails() {
    const errorObject = [];
    Object.keys(instanceErrorsToDisplay).map(function(name) {
      errorObject.push({
        attribute: name,
        error: instanceErrorsToDisplay[name]
      });
      return errorObject;
    });
    return (
      <>
        <Box margin="10px" direction="Column" height="57vh" overflow="auto">
          <Box height="fit-content" width="100%" justifySelf="center">
            <DataGrid
              isLoading={false}
              data={errorObject}
              columns={errorColumns()}
              keyAccessor={'attribute'}
            />
          </Box>
        </Box>
        <Box justifyContent={'flex-end'}>
          <Button
            appearance={ButtonEnums.Appearance.Primary}
            size={ButtonEnums.Size.Medium}
            outline
            text={'Back'}
            onClick={() => {
              setIsErrorDetailsModalOpen(false);
            }}
          />
        </Box>
      </>
    );
  }

  return (
    <Modal open={isModalOpen} background>
      <Box height="auto" padding={'10px'}>
        <Box
          direction="column"
          minWidth="65vw"
          maxWidth="80vw"
          backgroundColor={'SURFACE'}
          borderRadius="5px"
          display={isErrorDetailsModalOpen ? 'flex' : 'none'}
        >
          {PromoteHeader(
            instances,
            isCloseDisabled,
            onCloseClicked,
            instNameToShowError
          )}
          {instanceErrorDetails()}
        </Box>

        <Box
          direction="column"
          minWidth="65vw"
          maxWidth="80vw"
          backgroundColor={'SURFACE'}
          borderRadius="5px"
          display={isErrorDetailsModalOpen ? 'none' : 'flex'}
        >
          {PromoteHeader(instances, isCloseDisabled, onCloseClicked)}
          {instanceListModal}
        </Box>
      </Box>
    </Modal>
  );
};

const errorColumns = () => {
  return [
    {
      Header: 'Attribute',
      accessor: 'attribute',
      cellComponent: ''
    },
    {
      Header: 'Error',
      accessor: 'error',
      cellComponent: ''
    }
  ];
};

export const PromoteEnums = {
  DisplayType: {
    Confirmation: 'Confirmation',
    Reminder: 'Reminder'
  }
};

const promoteModalFooter = (
  okText,
  executePromote,
  promoteActionsCompleted,
  disable,
  promoteCallback
) => {
  if (promoteActionsCompleted) {
    return (
      <Box
        className={classes['form-field-group']}
        borderTop="1px solid var(--primary-lighter)"
        justifyContent="flex-end"
      >
        <Box
          justifySelf="end"
          minWidth="5rem"
          justifyContent="flex-end"
          margin="5px"
        >
          <Button
            appearance={ButtonEnums.Appearance.Primary}
            size={ButtonEnums.Size.Medium}
            outline
            onClick={promoteCallback}
          >
            {okText}
          </Button>
        </Box>
      </Box>
    );
  }
  return (
    <Box
      className={classes['form-field-group']}
      borderTop="1px solid var(--primary-lighter)"
      justifyContent="flex-end"
      height="auto"
    >
      <Box justifySelf="end" minWidth="5rem" justifyContent="space-between">
        <Box margin={'5px'}>
          <Button
            appearance={ButtonEnums.Appearance.Primary}
            size={ButtonEnums.Size.Medium}
            outline
            onClick={executePromote}
          >
            {disable ? <Spinner small /> : <Box>Submit</Box>}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const PromoteHeader = (
  instances,
  isCloseDisabled,
  onClose,
  instNameToShowError
) => {
  return (
    <Box
      justifyContent="space-between"
      borderBottom="1px solid var(--primary-lighter)"
      padding="10px"
      alignItems="center"
    >
      <H2>
        {instNameToShowError
          ? instNameToShowError
          : instances.length + ' Items selected'}
      </H2>
      <Box>
        <Button
          disabled={isCloseDisabled}
          appearance={ButtonEnums.Appearance.TransparentWithHover}
          onClick={() => onClose()}
          size={ButtonEnums.Size.Small}
        >
          <CancelIcon />
        </Button>
      </Box>
    </Box>
  );
};
