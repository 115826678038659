import React from 'react';
import { Box } from '../../lib/components';

export const ParentFilterContainer = ({children}) => {
    return (
        <Box border=" 1px solid var(--primary-lighter)" marginTop="15px" borderRadius="5px">
            {children}
        </Box>
    );
};
