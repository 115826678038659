import {
  subDays,
  startOfDay,
  endOfDay,
  isWithinInterval,
  parseISO
} from 'date-fns';

export const groupDataByTimeInterval = (totalData, key, timeRanges) => {
  const reference = new Date();
  const groupedData = {};
  const calculatedTimes = {};
  timeRanges.forEach(range => {
    groupedData[range.key] = [];
    let startSubDaysVal = subDays(reference, range.startDay);
    let startDateVal = startOfDay(startSubDaysVal);

    let endSubDaysVal = subDays(reference, range.endDay);
    let endDateVal = endOfDay(endSubDaysVal);

    calculatedTimes[range.key] = {
      startDate: startDateVal,
      endDate: endDateVal
    };
  });

  if (totalData) {
    totalData.items.forEach(data => {
      const time = data[key];
      timeRanges.some(r => {
        if (r.startDay === -1) {
          groupedData[r.key].push(data);
          return true;
        } else if (
          isWithinInterval(parseISO(time), {
            start: calculatedTimes[r.key].startDate,
            end: calculatedTimes[r.key].endDate
          })
        ) {
          groupedData[r.key].push(data);
          return true;
        }
        return false;
      });
    });
  }
  return groupedData;
};
