import React from 'react';
import { mapActionToDropdownItem } from './utils';
import useTranslatedValue from '../../../Hooks/useTranslatedValue';

import { Dropdown, Box, H3 } from '../../../lib/components';

export const ActionSelector = ({
  promoteActions,
  disable,
  onChange,
  selectedAction
}) => {
  const ActionNameText = useTranslatedValue('SELECT_ACTION');

  const allowedPromoteActions = promoteActions.filter(
    allPromoteActions => allPromoteActions.allowPromoteOnlyOnScan === false
  );

  const promoteOptions = allowedPromoteActions
    ? allowedPromoteActions.map(a => mapActionToDropdownItem(a))
    : [];

  return (
    <>
      <H3 margin="10px 0px">{ActionNameText}</H3>
      <Box direction="Column">
        <Dropdown
          defaultValues={[selectedAction]}
          picklistOptions={promoteOptions}
          onValueChange={onChange}
          isDisabled={disable}
        />
      </Box>
    </>
  );
};
