import React from 'react';
import { Text } from './Text';
import { theme } from './AppThemeProvider';

export const HorizontalLine = () => {
  return (
    <Text
      style={{
        content: '',
        flex: '1 1',
        borderBottom: '1px solid ' + theme.colorPalette.PRIMARY_LIGHTER,
        margin: 'auto'
      }}
    />
  );
};
