import React from 'react';
import { useLifecycles, mapPicklistValues } from '../../Core';
import { Dropdown } from '../../lib/components';
import { Container, Spinner } from '../../lib/components';

export const LifecycleList = ({ onEdit, defaultValues }) => {
  const { state } = useLifecycles();

  if (state.isLoading) {
    <Container>
      <Spinner small />
    </Container>;
  }

  if (!state.data) {
    return null;
  }

  const onLifecycleChanged = newValue => {
    onEdit(newValue.value);
  };

  return (
    <Dropdown
      onValueChange={onLifecycleChanged}
      defaultValues={mapPicklistValues([defaultValues], 'name', 'name')}
      picklistOptions={mapPicklistValues(state.data, 'displayName', 'name')}
    />
  );
};
