import styled from 'styled-components';

export const H4 = styled.h4`
  font-size: ${props => props.theme.fontVariants.h4.size};
  line-height: ${props =>
    props.lineHeight
      ? props.lineHeight
      : props.theme.fontVariants.h4.lineHeight};
  color: ${props =>
    props.color
      ? props.theme.colorPalette[props.color]
      : props.theme.fontVariants.h4.color};
  display: ${props => (props.display ? props.display : ' inline-block')};
  margin: ${props => (props.margin ? props.margin : ' 0px')};
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 'bold')};
`;
