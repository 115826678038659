import styled from 'styled-components';

export const H3 = styled.h3`
  font-size: ${props => props.theme.fontVariants.h3.size};
  line-height: ${props =>
    props.lineHeight
      ? props.lineHeight
      : props.theme.fontVariants.h3.lineHeight};
  color: ${props =>
    props.color
      ? props.theme.colorPalette[props.color]
      : props.theme.fontVariants.h3.color};
  margin: ${props => (props.margin ? props.margin : '0px')};
  display: ${props => (props.display ? props.display : ' inline-block')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 'bold')};
  letter-spacing: ${props =>
    props.letterSpacing ? props.letterSpacing : '0.01rem'};
`;
