import React from 'react';
import classNames from 'classnames';
import { Enums } from '../../Enums';
import { Boolean } from '../../lib/components';

export const BooleanAttribute = config => {
  const getValue = value => {
    if (typeof value === 'boolean') {
      return value;
    }
    if (value && typeof value.booleanValue === 'boolean') {
      return value.booleanValue;
    }
    if (value && typeof value.booleanValue === 'string') {
      return value.booleanValue.toLowerCase() === 'true';
    }
    return null;
  };

  const getDefaultValue = () => {
    return {
      booleanValue: config.defaultValue ? config.defaultValue[0] : false
    };
  };

  const getDisplayValue = value => {
    return getValue(value);
  };

  const renderAttributeValue = value => {
    const attrValue = getValue(value);
    if (attrValue === null) {
      return <>{'-'}</>;
    }
    const checkUnchechIcon = classNames(
      { 'fa fa-check cursor-default': attrValue },
      { 'fa fa-times cursor-default': !attrValue }
    );
    return <i className={checkUnchechIcon} />;
  };

  const renderAttributeInput = (fieldValue, onEdit) => {
    return (
      <Boolean
        value={getValue(fieldValue)}
        onEdit={newValue => onEdit({ booleanValue: newValue })}
      />
    );
  };

  const filterOptions = [
    { displayName: 'Equals', name: Enums.Conditions.Equals }
  ];

  const defaultSortOrder = 'Ascending';

  const evaluateCondition = (condition, valueObj, compareToValueStr) => {
    const value = getValue(valueObj);
    switch (condition) {
      case Enums.Conditions.Equals:
        return (
          value.toString().toLowerCase() === compareToValueStr.toLowerCase()
        );
      default:
        return false;
    }
  };

  return {
    getDisplayValue,
    renderAttributeValue,
    renderAttributeInput,
    filterOptions,
    getValue,
    defaultSortOrder,
    evaluateCondition,
    getDefaultValue
  };
};
