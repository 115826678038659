import React, { useContext, useState, useEffect } from 'react';
import { Enums } from '../../../Enums';
import InstanceEditContext from '../../../Contexts/InstaceEditContext';
import formClasses from '../Forms.module.css';
import { getErrorMessage, getInstanceFieldValueObject } from './FieldUtility';
import Skeleton from 'react-loading-skeleton';

import { FieldTitleBar } from './FieldTitleBar';
import { Attribute } from '../../../shared/Components';
import { Text } from '../../../lib/components';
import { isEmpty } from '../../../Core';

export const Field = React.forwardRef((props, ref) => {
  const { field, editMode } = props;
  const { instanceEditState, instanceEditDispatch, saveInstance } = useContext(
    InstanceEditContext
  );

  const attribute = Attribute(field);

  const fieldValueObj = getInstanceFieldValueObject(instanceEditState, field);

  const [readOnly, onSwitchToReadOnly] = useState(true);

  useEffect(() => {
    if (
      instanceEditState &&
      instanceEditState.saveOperationState === 'success'
    ) {
      onSwitchToReadOnly(true);
    }
  }, [instanceEditState]);

  const onEditButtonClicked = () => {
    if (isEmpty(instanceEditState.invalidAttributes)) {
      if (!isEmpty(instanceEditState.editedValues)) {
        saveInstance({ showPromoteReminder: false });
      }

      onSwitchToReadOnly(false);
    }
  };

  const onFieldEdited = newValue => {
    let errorMessage = attribute.validate(newValue);
    let updatedValue = { ...newValue };
    let value = {};

    if (instanceEditState.attributeValues) {
      value = {
        //this is done to retain canUpdate etc. in the object.
        ...instanceEditState.attributeValues[field.attributeName],
        ...updatedValue
      };
    } else {
      value = {
        name: field.attributeName,
        type: field.attributeType,
        canUpdate: field.canUpdate || true,
        ...updatedValue
      };
    }

    instanceEditDispatch({
      type: 'ATTRIBUTE_MODIFIED',
      payload: { name: field.attributeName, value, errorMessage }
    });
  };

  const onUndoClicked = () => {
    //TODO: this should be moved to Field
    onSwitchToReadOnly(true);

    instanceEditDispatch({
      type: 'ATTRIBUTE_RESET',
      payload: { name: field.attributeName }
    });
  };

  const errorMessage = getErrorMessage(instanceEditState, field.attributeName);

  const isSaveInProgress =
    instanceEditState && instanceEditState.saveOperationState === 'pending';

  const isAttributeReadonly =
    (editMode === Enums.EditMode.Inline || editMode === Enums.EditMode.None) &&
    readOnly;

  const isInlineEdit = editMode === Enums.EditMode.Inline;

  const renderAttribute = () => {
    if (isAttributeReadonly) {
      return (
        <Text
          style={{
            ...field.conditionalFormattingStyle,
            minWidth: '100%',
            maxHeight: '7rem',
            whiteSpace: 'pre-wrap',
            overflowY: 'auto'
          }}
        >
          {attribute.renderValue(fieldValueObj)}
        </Text>
      );
    } else if (isSaveInProgress && isInlineEdit) {
      return <Skeleton />;
    } else if (editMode && fieldValueObj) {
      if (fieldValueObj.canUpdate) {
        return attribute.renderInput(
          fieldValueObj,
          onFieldEdited,
          errorMessage
        );
      } else {
        return (
          <Text style={{ ...field.conditionalFormattingStyle }}>
            {attribute.renderValue(fieldValueObj)}
          </Text>
        );
      }
    } else {
      return attribute.renderInput(fieldValueObj, onFieldEdited, errorMessage);
    }
  };

  return (
    <div className={formClasses['field-container']} ref={ref}>
      <FieldTitleBar
        fieldConfig={field}
        editMode={editMode}
        canUpdate={fieldValueObj && fieldValueObj.canUpdate}
        readOnly={readOnly}
        onEditButtonClicked={onEditButtonClicked}
        onUndoClicked={onUndoClicked}
        isSaveInProgress={isSaveInProgress}
      />
      {renderAttribute()}
    </div>
  );
});
