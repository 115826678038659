import 'react';
import { mapPicklistValues } from '../../../Core';
import { Dropdown } from '..';

export const Picklist = ({
  isMultiselect,
  isDisabled,
  fieldValue,
  options,
  onEdit,
  isLoading,
  customStyle,
  placeholder,
  showClearValue,
  menuPlacement = 'auto'
}) => {
  const picklistOptions = mapPicklistValues(options, 'displayName', 'name');

  const defaultValues =
    fieldValue &&
    fieldValue
      .map(value => {
        let option = picklistOptions.find(o => o.value === value);
        return option;
      })
      .filter(o => o);

  const onValueChange = selectedItems => {
    onEdit(picklistToFieldOptions(selectedItems, isMultiselect));
  };

  const editView = () => {
    return (
      <>
        <Dropdown
          defaultValues={defaultValues || []}
          picklistOptions={picklistOptions}
          isMultiselect={isMultiselect}
          isDisabled={isDisabled}
          onValueChange={onValueChange}
          customStyle={customStyle}
          isLoading={isLoading}
          placeholder={placeholder}
          isClearable={showClearValue}
          menuPlacement={menuPlacement}
        />
      </>
    );
  };

  return editView();
};

const picklistToFieldOptions = (values, isMultiselect) => {
  if (values) {
    if (isMultiselect) {
      return values.map(value => {
        return value.value;
      });
    } else {
      return [values.value];
    }
  } else {
    return null;
  }
};
