import React, { Suspense } from 'react';
import { Enums } from '../../Enums';
import { Prefix, Suffix } from './prefixSuffix';
import { Container, Spinner, Box, TextBox } from '../../lib/components';
import DOMPurify from 'dompurify';
import '../../../src/Content/css/Common.css';
import { Tooltip } from '@mui/material';
import { ImagePreviewIcon } from '../../Components/Icons';

const Richtext = React.lazy(() => import('../../Components/Forms/Richtext'));

export const RichtextAttribute = config => {
  const getValue = value => {
    if (value) {
      return typeof value === 'string' ? value : value.textValue;
    }
    return null;
  };

  const getDefaultValue = () => {
    return { textValue: config.defaultValue ? config.defaultValue[0] : null };
  };

  const getDisplayValue = value => {
    return getValue(value);
  };

  const renderAttributeValue = value => {
    const val = getValue(value);
    const cleanValue = DOMPurify.sanitize(val);
    if (
      !cleanValue.includes('<img') &&
      cleanValue.replace(/(<([^>]+)>)/gi, '').length === 0
    ) {
      return <>{'-'}</>;
    }
    if (config && config.showRichTextTooltip) {
      return (
        <Tooltip
          title={
            <div id="richTextContent" className={'richtextContent'}>
              <Prefix value={config.prefix} />
              <div
                dangerouslySetInnerHTML={{
                  __html: cleanValue
                }}
              />
              <Suffix value={config.suffix} />{' '}
            </div>
          }
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip #richTextContent': {
                fontSize: '14px',
                color: 'var(--primary-dark)',
                fontWeight: 'normal',
                maxHeight: '20rem',
                overflowY: 'auto',
                minHeight: '1.5rem'
              },
              '& .MuiTooltip-tooltip': {
                background: 'var(--surface)',
                maxWidth: '40rem'
              },
              '& .MuiTooltip-tooltipArrow': {
                boxShadow: '0 0 1.875rem 0.125rem rgba(0, 0, 0, 0.16)',
                borderRadius: '0.4375rem',
                backgroundColor: 'var(--surface)',
                boxSizing: 'border-box'
              },
              '& .MuiTooltip-arrow': {
                width: '3em',
                height: '1.71em',
                color: 'var(--surface)',
                marginTop: '-1.71em !important',
                marginBottom: '-1.71em !important'
              }
            }
          }}
          arrow
        >
          <Box>{renderPreviewContent(cleanValue)}</Box>
        </Tooltip>
      );
    } else {
      return (
        <>
          <div className={'richtextContent'}>
            <Prefix value={config.prefix} />
            <div
              dangerouslySetInnerHTML={{
                __html: cleanValue
              }}
            />
            <Suffix value={config.suffix} />
          </div>
        </>
      );
    }
  };

  const renderPreviewContent = cleanValue => {
    if (
      cleanValue.includes('<img') &&
      cleanValue.replace(/(<([^>]+)>)/gi, '').length <= 10
    ) {
      return <ImagePreviewIcon />;
    } else {
      return (
        <div id="richTextContent" className={'richtextContent'}>
          <Prefix value={config.prefix} />
          <div
            dangerouslySetInnerHTML={{
              __html:
                cleanValue.replace(/(<([^>]+)>)/gi, '').length > 10
                  ? cleanValue.replace(/(<([^>]+)>)/gi, '').slice(0, 10) + '...'
                  : cleanValue.replace(/(<([^>]+)>)/gi, '')
            }}
          />
          <Suffix value={config.suffix} />
        </div>
      );
    }
  };
  const getFilterValue = value => {
    if (!value) {
      return null;
    }
    return typeof value === 'string' ? value : value.textValue;
  };

  const renderAttributeInput = (fieldValue, onEdit) => {
    return (
      <Suspense
        fallback={
          <Container>
            <Spinner small />
          </Container>
        }
      >
        <Richtext
          value={getValue(fieldValue)}
          onEdit={newValue => onEdit({ textValue: newValue })}
        />
      </Suspense>
    );
  };

  const renderFilterAttributeInput = (value, onEdit) => {
    return (
      <Box height="28px" width="100%">
        <TextBox
          value={getFilterValue(value)}
          onEdit={newValue => onEdit({ textValue: newValue })}
        />
      </Box>
    );
  };

  const filterOptions = [
    { displayName: 'Contains', name: Enums.Conditions.Contains },
    { displayName: 'Is Empty', name: Enums.Conditions.IsEmpty },
    { displayName: 'Is Not Empty', name: Enums.Conditions.IsNotEmpty }
  ];

  const defaultSortOrder = 'Ascending';

  const evaluateCondition = (condition, valueObj, compareToValueStr) => {
    const value = getValue(valueObj);
    switch (condition) {
      case Enums.Conditions.Contains:
        return (
          value && value.toLowerCase().includes(compareToValueStr.toLowerCase())
        );
      case Enums.Conditions.IsNotEmpty:
        return value && value.length > 0;
      case Enums.Conditions.IsEmpty:
        return !value || value.length === 0;
      default:
        return false;
    }
  };

  return {
    getDisplayValue,
    renderAttributeValue,
    renderAttributeInput,
    renderFilterAttributeInput,
    filterOptions,
    getValue,
    getFilterValue,
    evaluateCondition,
    defaultSortOrder,
    getDefaultValue
  };
};
