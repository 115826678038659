import React from 'react';
import {
  Grid,
  Box,
  Button,
  ButtonEnums,
  H2,
  Modal
} from '.';
import { CreateDataGridForDrillDown } from '../../Components/Common/ReportWidget/DrillDownData';
import { CancelIcon } from '../../Components/Icons';

export function ShowDrillDownData(
    showDrilldown,
    totalData,
    config,
    filters,
    typeName,
    setShowDrilldown,
    sortState,
    onSortApplied,
    typePluralName,
    states
) {
    let filter;
    let columnValue;
    if (showDrilldown) {
      if (
        config.reportDisplayType === 'PieChart' ||
        config.reportDisplayType === 'PieChart3D'
      ) {
        filter = filters.points[0].hovertext[0];
        columnValue = filters.points[0].value;
      } else if (
        config.reportDisplayType === 'BarChart' ||
        config.reportDisplayType === 'BarChart3D' ||
        config.reportDisplayType === 'LineChart'
      ) {
        filter = filters.points[0].hovertext;
        columnValue = filters.points[0].y;
      } else if (config.reportDisplayType === 'Tabular' ||
        config.reportDisplayType === 'CustomWidget') {
        filter = filters.filterValue;
        columnValue = filters.columnValue;
      }
    }
    const isOthersGroup = filterValue => {
      return typeof filterValue !== 'string';
    };
    const getTitle = filterValue => {
      const getColumnName = () => {
        if (filters.points) {
          return filters.points[0].data.value;
        }
        return filters.columnName;
      };
      if (filterValue) {
        const columnName = getColumnName();
        if (!isOthersGroup(filterValue)) {
          return getTitleValue(filterValue, columnName);
        }
        return getTitleValue('Others', columnName);
      }
      return '';
    };
    let title = getTitle(filter);

    const modalChild = (
      <Grid
        id="das"
        direction="column"
        width="80vw"
        maxHeight="80vh"
        backgroundColor={'SURFACE'}
        borderRadius="5px"
        rows={['auto', '1fr']}
        areas={[['header'], ['main']]}
        columns={['1fr']}
      >
        <Box
          justifyContent="space-between"
          borderBottom="1px solid var(--primary-lighter)"
          padding="10px"
        >
          <H2 marginTop="0.6rem" style={{ wordBreak: 'break-all' }}>
            {title}
          </H2>

          <Box>
            <Button
              appearance={ButtonEnums.Appearance.TransparentWithHover}
              onClick={() => {
                setShowDrilldown(false);
              }}
              size={ButtonEnums.Size.Small}
            >
              <CancelIcon />
            </Button>
          </Box>
        </Box>
        <Box overflow="hidden">
          <Grid
            display="grid"
            height="auto"
            rows={['auto', '1fr']}
            columns={['auto']}
            areas={[['createButton'], ['dataGrid']]}
          >
            {showDrilldown ? (
              <CreateDataGridForDrillDown
                data={totalData}
                config={config}
                filter={filter}
                typeName={typeName}
                sortState={sortState}
                onSortApplied={onSortApplied}
                states={states}
                csvFileTitle={title}
              />
            ) : null}
          </Grid>
        </Box>
      </Grid>
    );
    return <Modal children={modalChild} open={showDrilldown} background />;

    function getTitleValue(filterValue, columnName) {
      if (config.group) {
        if (columnName === 'Count') {
          return `${typePluralName} having ${config.group.displayName} '${filterValue}'`;
        }
        return `${typePluralName} having ${config.group.displayName} '${filterValue}' (${columnName} ${columnValue})`;
      } else if (config.pivotRows) {
        if (columnName === 'Count') {
          return `${typePluralName} having ${config.pivotRows[0].displayName} '${filterValue}'`;
        }
        return `${typePluralName} having ${config.pivotRows[0].displayName} '${filterValue}' (${columnName} ${columnValue})`;
      }
    }
}
