import React from 'react';
import DateTimePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import classnames from 'classnames';
import './DatePicker.css';
import {
  mapToISOTimeFormat,
  mapToISODateFormat,
  useSettingsContext,
  parseDate,
  convertUTCToTenantTimeZone,
  convertTenantTimeZoneToUTC
} from '../../../Core';
import { MonthYearSelector } from '../MonthYearSelector';

export function ProtrakDateTime({
  value: selectedValue,
  onEdit,
  showTimeInput,
  placeholder,
  minDate,
  maxDate,
  disabled = false
}) {
  const { settings } = useSettingsContext();
  const { timeFormat, dateFormat, timeZone } = settings.dateTimeFormat;

  let dateTimeValue = selectedValue
    ? convertUTCToTenantTimeZone(parseDate(selectedValue, timeZone), timeZone)
    : null;

  const onDateChange = changedDate => {
    if (changedDate) {
      onEdit(convertTenantTimeZoneToUTC(changedDate, timeZone));
    } else {
      onEdit('');
    }
  };

  return (
    <div>
      <DateTimePicker
        className={classnames('form-input')}
        showTimeInput={showTimeInput}
        fixedHeight
        timeInputLabel="Time:"
        timeFormat={mapToISOTimeFormat(timeFormat)}
        dateFormat={`${mapToISODateFormat(dateFormat)} ${mapToISOTimeFormat(
          timeFormat
        )}`}
        placeholderText={placeholder}
        minDate={minDate}
        maxDate={maxDate}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled
        }) => (
          <MonthYearSelector
            date={date}
            changeYear={changeYear}
            changeMonth={changeMonth}
            decreaseMonth={decreaseMonth}
            increaseMonth={increaseMonth}
            prevMonthButtonDisabled={prevMonthButtonDisabled}
            nextMonthButtonDisabled={nextMonthButtonDisabled}
          />
        )}
        selected={dateTimeValue}
        onChange={date => {
          onDateChange(date);
        }}
        disabled={disabled}
      />
    </div>
  );
}
