import { createEndpoint } from './typeInstances';
import { usePaginatedGet, useProtrakApi } from '../utils';

const getConnectURL = '/relations';
const instanceBase = '/instances';

export function useRelatedInstances(
  relatedTypeName,
  skip,
  take,
  stateFilter,
  attributeFilter
) {
  const {
    state,
    reload,
    nextPage,
    previousPage,
    paginationState,
    hasMoreItems,
    fetchItems
  } = usePaginatedGet(
    {
      requestConfig: relatedInstDetails,
      relatedTypeName,
      skip,
      take,
      stateFilter,
      attributeFilter
    },
    {
      defer: true
    }
  );

  return {
    state,
    reload,
    nextPage,
    previousPage,
    paginationState,
    hasMoreItems,
    fetchItems
  };
}

export const getRelatedInstanceEndpoints = (
  relatedTypeName,
  skip,
  take,
  stateFilter,
  attributeFilter
) => {
  return {
    url: instanceBase,
    params: {
      instanceTypeName: relatedTypeName,
      getOnlyConnectableInstances: true,
      skip,
      take,
      stateFilter,
      ...attributeFilter
    }
  };
};

const relatedInstDetails = ({
  relatedTypeName,
  skip,
  take,
  stateFilter,
  attributeFilter
}) => {
  const { params } = getRelatedInstanceEndpoints(
    relatedTypeName,
    skip,
    take,
    stateFilter,
    attributeFilter
  );
  return { endpoint: createEndpoint({ ...params, skip, take }) };
};

export function useConnectInstance() {
  const { state, run } = useProtrakApi(
    {
      requestConfig: connectInstance
    },
    { defer: true }
  );
  return [state, run];
}

const connectInstance = ({
  instanceId,
  relationTypeName,
  relationDirection,
  destinationInstanceId,
  relationAttributes
}) => {
  return {
    endpoint: getConnectURL, config: {
      method: 'POST',
      data: {
        relationTypeName: relationTypeName,
        sourceInstanceId: instanceId,
        direction: relationDirection,
        destinationInstanceId: destinationInstanceId,
        relationAttributes: relationAttributes
      }
    }
  };
};

export const useUnlink = relationId => {
  const { state, run } = useProtrakApi(
    { requestConfig: unlinkRelation, unlinkUrl: `${getConnectURL}/${relationId}` },
    { defer: true }
  );

  return { state, run };
};

const unlinkRelation = ({ unlinkUrl }) => {
  return {
    endpoint: unlinkUrl, config: {
      method: 'delete'
    }
  };
};
