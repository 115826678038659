import React, { useEffect, useMemo } from 'react';
import { useInstancePromote, isEmpty } from '../../../Core';
import { Box, Text, Spinner, Image } from '../../../lib/components';
import ErrorDetails from '../ErrorDetails';
import useTranslatedValue from '../../../Hooks/useTranslatedValue';
import errorImg from '../../../Content/Images/errormsg.svg';
import successImg from '../../../Content/Images/success.svg';
import asyncImg from '../../../Content/Images/async.svg';

export const InstancePromoteStatus = ({
  instanceId,
  action,
  actionLabel,
  comment,
  errorType,
  promoteCallback,
  postAsyncPromote
}) => {
  const [state] = useInstancePromote({
    instanceId,
    action,
    comment,
    onSuccess: postAsyncPromote
  });

  const fieldValidationFailedMsg = useTranslatedValue(
    'FIELD_VALIDATION_FAILED'
  );
  const promotedSuccessFully = useTranslatedValue('PROMOTED_SUCCESSFULLY');
  const asyncPromoteStarted = useTranslatedValue('ASYNC_PROMOTE_STARTED');
  const inProgressText = useTranslatedValue('IN_PROGRESS');

  function getErrorMessage() {
    let errorMessageToDisplay = '';
    let errorMessageArray =
      state.data && state.data.response && state.data.response.data;

    if (
      errorMessageArray &&
      errorMessageArray.some(e => e.RelationTypeName !== null)
    ) {
      errorMessageArray.forEach(errObj => {
        if (errObj.RelationTypeName) {
          errorMessageToDisplay += `${errObj.RelationTypeName} : ${errObj.ErrorMessage} \n`;
        }
      });
    }

    if (
      errorMessageArray &&
      errorMessageArray.some(e => e.AttributueName !== null)
    ) {
      errorMessageToDisplay += `${fieldValidationFailedMsg}`;
    }

    return errorMessageToDisplay;
  }

  function getError(data) {
    let errorMsg = '';
    if (typeof data === 'object') {
      errorMsg = {};
      data &&
        data.forEach(d => {
          if (d.AttributueName) {
            errorMsg[d.AttributueName] = d.ErrorMessage;
          }
        });
    } else if (typeof data === 'string') {
      //for program generated errors.
      errorMsg = data;
    }
    return errorMsg;
  }

  let errorMessage = useMemo(() => {
    return state.data && state.data.response && state.data.response.data
      ? getError(state.data.response.data)
      : '';
  }, [state.data]);

  useEffect(() => {
    const { isFulfilled, isError, data } = state;

    if (isFulfilled && data) {
      promoteCallback(instanceId);
    } else if (isError) {
      promoteCallback(instanceId, errorMessage);
    }
  }, [errorMessage, instanceId, promoteCallback, state]);

  if (state.isLoading) {
    return (
      <>
        <Box margin="0 1.7rem 0.8rem 0" alignItems="center">
          <Spinner />
        </Box>
        <Text
          style={{ padding: '0.6rem 0.8rem 0' }}
        >{`"${actionLabel}" ${inProgressText}...`}</Text>
      </>
    );
  }

  if (state.isError) {
    return errorType === 'object' ? (
      <>
        <Box color={'ERROR'} className="popup-icon">
          <Image
            src={errorImg}
            alt="Promote Operation Failed"
            height="40px"
            width="40px"
          />
        </Box>
        <Text
          color={'ERROR'}
          style={{ whiteSpace: 'pre', padding: '0.6rem 0.8rem 0' }}
        >
          {getErrorMessage()}
        </Text>
      </>
    ) : (
      <>
        <Box color={'ERROR'} className="popup-icon">
          <Image
            src={errorImg}
            alt="Promote Operation Failed"
            height="40px"
            width="40px"
          />
        </Box>
        <ErrorDetails state={state} style={{ padding: '0.6rem 0.8rem 0' }} />
      </>
    );
  }

  const SuccessMessageRenderer = ({ message }) => {
    return (
      <>
        <Box direction="column" alignItems={'center'}>
          <Box className="popup-icon" color="SUCCESS">
            {state.data.promoteActionAsyncJobId ? (
              <Image
                src={asyncImg}
                alt="Asynchronous Promote Operation"
                height="50px"
                width="50px"
              />
            ) : (
              <Image
                src={successImg}
                alt="Promote Operation Successful"
                height="40px"
                width="40px"
              />
            )}
          </Box>
          <Text style={{ padding: '0.6rem 0.8rem 0' }}>{message}</Text>
        </Box>
      </>
    );
  };

  if (!isEmpty(state.data)) {
    let successMsg = promotedSuccessFully;
    if (state.data.promoteActionAsyncJobId) {
      successMsg = asyncPromoteStarted.replace('{0}', actionLabel);
    } else {
      successMsg = successMsg.replace('{0}', actionLabel);
    }
    return <SuccessMessageRenderer message={successMsg} />;
  }

  return null;
};
