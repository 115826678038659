import React, { useEffect, useState, useContext } from 'react';
import { useConnectInstance } from '../../../Core';
import InstanceEditContext from '../../../Contexts/InstaceEditContext';
import { ErrorModal } from '../ErrorModal';
import { CreateInstance } from '../../../Components/Forms/CreateLayoutPopup/CreateInstance';

export const CreateAndConnectPopup = props => {
  const {
    relationTypeName,
    relationDirection,
    relatedTypeName,
    directRelatedInstance,
    saveSuccessCallback,
    onClose
  } = props;
  const [instLinkHook, linkInstance] = useConnectInstance();
  const [isLinkInProgress, setLinkInProgress] = useState(false);
  const { isLoading } = instLinkHook;
  const { instanceEditState, onLinkSuccess } = useContext(InstanceEditContext);
  const { isFulfilled, isError, data } = instLinkHook;

  useEffect(() => {
    if (isFulfilled) {
      setLinkInProgress(false);
      onLinkSuccess();
      saveSuccessCallback();
    }
  }, [isFulfilled, instLinkHook, onLinkSuccess, saveSuccessCallback]);

  const connectInstance = createdInstId => {
    if (!createdInstId) {
      return;
    }
    if (!isLinkInProgress) {
      setLinkInProgress(true);
      linkInstance({
        instanceId,
        relationTypeName,
        relationDirection,
        destinationInstanceId: createdInstId
      });
    }
  };

  const { instanceId, details } = instanceEditState;

  if (isError) {
    return <ErrorModal state={instLinkHook} />;
  }
  return (
    <CreateInstance
      instanceTypeName={relatedTypeName}
      onInstanceCreated={data => connectInstance(data[0].id)}
      onClose={onClose}
      isLinkLoading={isLoading}
      relationTypeName={relationTypeName}
      instanceId={instanceId}
      instanceName={details.name}
      originalInstanceTypeName={details.instanceTypeName}
      directRelatedInstance={directRelatedInstance}
      successCallback={() => {
        onLinkSuccess();
        saveSuccessCallback();
      }}
      saveSuccessCallback={saveSuccessCallback}
    />
  );
};
