import { useCallback, useEffect } from 'react';
import { populateAttributeParams, getEditedAttachmentAttributes } from './file';
import { useProtrakApi, protrakApiClient } from '../utils';

export function useEditRelationAttributes(context) {
  const [state, run] = useEditRelation(context && context.instanceEditDispatch);
  const saveRelationAttributes = useCallback(
    ({ relationProperties, successCallback }) => {
      const attachmentAttributes = getEditedAttachmentAttributes(context);
      run({
        attachmentAttributes,
        context,
        relationProperties,
        successCallback
      });
    },
    [context, run]
  );
  return [state, saveRelationAttributes];
}

export const saveWorksheetRelationAttributes = async ({
  worksheetEditedInstance, authContext
}) => {
  const response = await saveRelationAttributes({
    relationProperties: worksheetEditedInstance,
    editedValues: worksheetEditedInstance.relationattributes, authContext
  });

  return response;
};

function useEditRelation(instanceEditDispatch) {
  const { state, run } = useProtrakApi({
    promiseFn: uploadAndSaveRelationAttributes
  }, { defer: true });
  useEffect(() => {
    if (state.isFulfilled && state.data) {
      instanceEditDispatch({
        type: 'INST_CREATE_SUCCESS',
        payload: state.data
      });
    } else if (state.isError) {
      instanceEditDispatch({
        type: 'INST_SAVE_ERROR',
        payload: state.data
      });
    }
  }, [state, instanceEditDispatch]);
  return [state, run];
}

const uploadAndSaveRelationAttributes = async ({
  context,
  relationProperties, authContext
}) => {
  context &&
    context.instanceEditDispatch({
      type: 'INST_SAVE_PENDING'
    });
  let editedValues = context
    ? { ...context.instanceEditState.editedValues }
    : {};

  const response = await saveRelationAttributes({
    relationProperties,
    editedValues, authContext
  });

  return response;
};

const saveRelationAttributes = async ({ relationProperties, editedValues, authContext }) => {
  const relationContract = {
    ...relationProperties,
    relationAttributes: populateAttributeParams(editedValues)
  };

  const getConnectURL = '/relations';

  let {
    relationAttributes,
    relationId,
    relationTypeName,
    sourceInstanceId,
    destinationInstanceId,
    direction
  } = relationContract;

  const response = await protrakApiClient(`${getConnectURL}/${relationId}`, {
    data: {
      relationAttributes,
      relationId,
      relationTypeName,
      sourceInstanceId,
      destinationInstanceId,
      direction
    },
    method: 'PUT'
  }, authContext);
  return response;
};
