import { useAuthContext } from './AuthContext';
import { useMultiApi } from './useMultiApi';

export function useProtrakMultiApi({ promiseFn, keyValueArgs }, _options = {}) {
  const authContext = useAuthContext();

  const protrakOptions = {
    ..._options,
    middleware: authContext
  };

  const { state, run } = useMultiApi({ promiseFn, keyValueArgs }, protrakOptions);
  return { state, run };
}