import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import classes from './Attachment.module.css';
import { AttachmentContainer } from './AttachmentContainer';
import { UploadIcon } from '../../../Components/Icons/SVGIcons';
import { useTheme } from 'styled-components';

export const AttachmentPicker = ({ fileValue, onChange, acceptTypes }) => {
  const [selectedFile, setSelctedFile] = useState();
  const theme = useTheme();

  const onFileSelected = event => {
    const { files } = event.target;
    if (files && files.length > 0) {
      setSelctedFile(files[0].name);
      onChange(Array.from(files));
    }
  };

  const fileRef = useRef(null);

  return (
    <div width={'100%'} style={{ display: 'flex' }}>
      <div className={classes['upload-btn-wrapper']}>
        <label
          className={classNames(
            classes['browse-button'],
            'font-color-primary-dark'
          )}
          style={{
            width: 'max-content',
            backgroundColor: theme.palette.secondary.light
          }}
        >
          <span style={{ paddingRight: '0.5rem', color: '#4E5969' }}>
            {'Select File'}
          </span>
          <UploadIcon />
          <input
            className={classNames('form-input', classes['file-input'])}
            ref={fileRef}
            type="file"
            size="60"
            accept={acceptTypes}
            onChange={onFileSelected}
          />
        </label>
      </div>
      <div
        className={classNames(
          classes['file-list-container'],
          classes['file-list']
        )}
      >
        <AttachmentContainer
          fileValue={fileValue}
          selectedFile={selectedFile}
          setSelctedFile={setSelctedFile}
          onChange={onChange}
          fileRef={fileRef}
        />
      </div>
    </div>
  );
};
