import { Grid, Box } from '../../../lib/components';

export const GridHeaderLayout = props => {
  const { leftHeader, rightHeader } = props;

  return (
    <Grid
      rows={['var(--app-header-Toolbar-height)']}
      columns={['70%', '30%']}
      areas={[['main', 'filter']]}
      backgroundColor={'SURFACE'}
    >
      <Box
        padding="10px 5px 10px 0px"
        justifyContent="start"
        alignItems="center"
      >
        {leftHeader}
      </Box>
      <Box
        padding="10px 5px 10px 10px"
        justifyContent="flex-end"
        alignItems="center"
      >
        {rightHeader}
      </Box>
    </Grid>
  );
};
