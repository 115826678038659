import { useMemo } from 'react';
import {
  useParams,
  useLocation,
  useHistory,
  useRouteMatch
} from 'react-router-dom';
import { parseQueryString } from '../../Core';

export function useRouter() {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  return useMemo(() => {
    return {
      redirect: history.push,
      replace: history.replace,
      pathname: location.pathname,
      query: {
        ...parseQueryString(location.search),
        ...params
      },
      match,
      navigationState: location.state ? location.state : {},
      history
    };
  }, [params, match, location, history]);
}
