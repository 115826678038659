import React from 'react';
import { Enums } from '../../Enums';
import {
  EditIcon,
  DeleteIcon,
  UnlinkIcon,
  EditRelationIcon,
  PromoteIcon,
  CopyIcon
} from '../../Components/Icons';

export const ActionIcon = ({ type }) => {
  switch (type) {
    case Enums.AllowedOperations.Edit:
      return <EditIcon />;

    case Enums.AllowedOperations.Delete:
      return <DeleteIcon />;

    case Enums.AllowedOperations.FileDownload:
      return <i className={'fa fa-download'} />;

    case Enums.AllowedOperations.FileUpload:
      return <i className={'fa fa-upload'} />;

    case Enums.AllowedOperations.Promote:
      return <PromoteIcon />;

    case Enums.AllowedOperations.Unlink:
      return <UnlinkIcon />;

      case Enums.AllowedOperations.CreateACopy:
      return <CopyIcon />;

    case Enums.AllowedOperations.EditRelation:
      return <EditRelationIcon />;

    case Enums.AllowedOperations.ViewRelation:
      return <i className={'fa fa-eye'} />;

    default:
      return <i className={'fas fa-pencil-alt'} />;
  }
};
