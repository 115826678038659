import React from 'react';
import useTranslatedValue from '../../../Hooks/useTranslatedValue';
import { Enums } from '../../../Enums';
import { ActionMenuItem } from './ActionMenuItem';

export const Action = ({ actions, onselect }) => {
  const noActionText = useTranslatedValue('ACTION_NO_ACTION');

  const renderActionsBasedOnType = action => {
    const { actionType, actionsData, actionRenderer } = action;

    if (actionRenderer) {
      return actionRenderer;
    }
    if (actionType === Enums.AllowedOperations.Promote) {
      return actionsData.allowedActions.map(allowedaction => {
        return (
          <ActionMenuItem
            key={allowedaction.name}
            text={allowedaction.displayName || allowedaction.name}
            type={actionType}
            disabled={allowedaction.allowPromoteOnlyOnScan}
            onSelect={() => {
              onselect({ type: actionType, data: allowedaction });
            }}
          />
        );
      });
    } else {
      return (
        <ActionMenuItem
          key={actionType}
          text={actionType}
          type={actionType}
          onSelect={() => {
            onselect({ type: actionType, data: actionsData });
          }}
        />
      );
    }
  };

  const renderActions = () => {
    return actions.length ? (
      actions.map((action, index) => {
        return renderActionsBasedOnType(action);
      })
    ) : (
      <div className="padding-5px">{noActionText}</div>
    );
  };

  return renderActions();
};
