import React, { useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import ReactDOM from 'react-dom';
import { usePortal } from './Modal/Modal';
import { useOnClickOutside } from '../../Hooks/useOnClickOutside';
import { Box } from './Box';

export default function MenuButton({
  anchorComponent,
  menuComponent,
  initialComponent,
  style = {}
}) {
  const target = usePortal();
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef();
  const toggleMenu = event => {
    event.nativeEvent && event.nativeEvent.stopImmediatePropagation();
    setIsOpen(!isOpen);
  };
  useOnClickOutside(anchorRef, () => setIsOpen(false));

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement);

  const renderContent = () => {
    return (
      <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        <div>{menuComponent}</div>
      </div>
    );
  };

  return (
    <>
      <Box
        ref={setReferenceElement}
        onMouseUp={event => {
          toggleMenu(event);
        }}
        style={style}
      >
        {initialComponent}
        <div ref={anchorRef}>{anchorComponent}</div>
      </Box>

      {isOpen ? ReactDOM.createPortal(renderContent(), target) : null}
    </>
  );
}
