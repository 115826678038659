import { useProtrakApi } from '../utils';

const lifecycle = '/lifecycles/{0}';

export const useStates = lifecycleName => {
  const lifeCycleUrl = lifecycle.replace('{0}', lifecycleName);
  const { state, run } = useProtrakApi(
    { requestConfig: promiseFn, lifeCycleUrl },
    { cacheKey: lifeCycleUrl }
  );
  return { state, run };
};

const promiseFn = ({ lifeCycleUrl }) => {
  const cacheData = JSON.parse(window.localStorage.getItem(lifeCycleUrl));
  return {
    endpoint: lifeCycleUrl, config: {
      modified: cacheData ? cacheData.modified : null
    }
  };
};
