import React from 'react';
import CloneInstance from '../../Layouts/InstLayouts/CloneInstance';

export const CreateACopyAction = props => {
  const {
    instanceId,
    typeName,
    relationTypeName,
    relationDirection,
    relationTypeId,
    onClose,
    onSuccess
  } = props;

  const onCopySuccess = createdInstance => {
    if (!createdInstance) {
      onClose();
      return;
    }
    onSuccess();
  };

  return (
    <CloneInstance
      relationTypeName={relationTypeName}
      relationDirection={relationDirection}
      instanceId={instanceId}
      typeName={typeName}
      relationTypeId={relationTypeId}
      onSuccess={onSuccess}
      onClose={onCopySuccess}
    />
  );
};
