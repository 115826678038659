import styled, { css } from 'styled-components';

export const BorderBox = styled.div`
  display: ${props => props.display};
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
  flex-direction: ${props => props.direction};
  box-shadow: ${props => {
    if (props.boxShadow) {
      return props.boxShadow +
        ' ' +
        (props.boxShadowColor
          ? props.boxShadowColor
          : props.theme.colorPalette['PRIMARY_SHADOW']);
    } else {
        return '';
    }
  }};
  background-color: ${props =>
    props.backgroundColor
      ? props.theme.colorPalette[props.backgroundColor]
      : ''};
  width: ${props => props.width};
  text-align: ${props => props.textAlign};
  ${props =>
    props.border
      ? formCustomStyles
      : 'border: 1px solid ' + props.theme.colorPalette['PRIMARY_LIGHTER']};
`;

const formCustomStyles = css`
  border: ${props => (props.border.side === 'all' ? customStyle(props) : '')};

  border-top: ${props =>
    props.border.side === 'top' || props.border.side === 'horizontal'
      ? customStyle(props)
      : ''};
  border-bottom: ${props =>
    props.border.side === 'bottom' || props.border.side === 'horizontal'
      ? customStyle(props)
      : ''};
  border-left: ${props =>
    props.border.side === 'left' || props.border.side === 'vertical'
      ? customStyle(props)
      : ''};
  border-right: ${props =>
    props.border.side === 'right' || props.border.side === 'vertical'
      ? customStyle(props)
      : ''};

  border-radius: ${props => (props.border ? props.border.radius : '')};
`;
function customStyle(props) {
  return (
    props.border.size +
    ' ' +
    props.border.style +
    ' ' +
    props.theme.colorPalette[props.border.color]
  );
}
