import React from 'react';
import { LifecycleList } from './LifecycleList';

export const LifecycleAttribute = config => {
  const getValue = value => {
    if (!value) {
      return null;
    }
    if (value.textValue) {
      return value.textValue;
    }
    if (value.name || value.displayName) {
      return value.displayName || value.name;
    }
  };

  const getDefaultValue = value => {
    if (value && value.textValue) {
      return { name: value.textValue };
    }
    return { name: config && config.defaultValue && config.defaultValue[0] };
  };

  const getDisplayValue = value => {
    return getValue(value);
  };

  const renderAttributeValue = value => {
    const val = getValue(value);

    if (!val) {
      return <>{'-'}</>;
    }

    return <>{val}</>;
  };

  const renderAttributeInput = (value, onEdit) => {
    return (
      <LifecycleList
        onEdit={newValue => onEdit({ textValue: newValue })}
        defaultValues={getDefaultValue(value)}
      />
    );
  };

  const validateInput = value => {
    const fieldValue = getValue(value);
    const isDataValid = config.isRequired ? (fieldValue ? true : false) : true;

    return !isDataValid
      ? `${config.attributeName || 'This field'} is required`
      : '';
  };

  return {
    getDisplayValue,
    renderAttributeValue,
    renderAttributeInput,
    getValue,
    getDefaultValue,
    validateInput
  };
};
