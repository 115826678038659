import { useProtrakApi } from '../utils';
const lifecycleBase = '/lifecycles';

export const useLifecycles = () => {
  const lifeCycleUrl = lifecycleBase;
  const { state, run } = useProtrakApi(
    { requestConfig: promiseFn, lifeCycleUrl },
    { cacheKey: lifeCycleUrl }
  );
  return { state, run };
};

const promiseFn = ({ lifeCycleUrl }) => {
  const cacheData = JSON.parse(window.localStorage.getItem(lifeCycleUrl));
  return {
    endpoint: lifeCycleUrl, config: {
      modified: cacheData ? cacheData.modified : null
    }
  };
};
