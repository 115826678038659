import React, { useEffect, useState } from 'react';
import ReactFlow, {
  useZoomPanHelper,
  isNode,
  isEdge,
  Controls
} from 'react-flow-renderer';
import CustomEdge from './CustomEdge';
import CustomNode from './CustomNode';
import reactFlowStyles from './reactFlow.module.css';

const edgeTypes = {
  custom: CustomEdge
};

const nodeTypes = {
  custom: CustomNode
};

export function ReactFlowRenderer({
  rendererData,
  customControls,
  onEdgeClicked,
  onNodeClick,
  onCanvasClick,
  showBasicToolbar = false,
  center = null,
  initialZoom = 1,
  minimumZoom = 0.1
}) {
  const [elements, setElements] = useState(rendererData);
  const { fitView, setCenter, zoomTo } = useZoomPanHelper();

  const onElementClick = (event, elementClicked) => {
    updateSelection(elements, elementClicked, setElements);

    if (isNode(elementClicked) && onNodeClick) {
      onNodeClick({ stateName: elementClicked.id });
    } else if (isEdge(elementClicked) && onEdgeClicked) {
      const { source, data } = elementClicked;
      onEdgeClicked({ stateName: source, action: data.action, activities: data.activities });
    }
  };

  const onPaneClicked = () => {
    clearSelection(elements, setElements);
    if (onCanvasClick) {
      onCanvasClick();
    }
  };

  const onLoad = reactFlowInstance => {
    reactFlowInstance.fitView();
  };

  useEffect(() => {
    setElements(rendererData);
    if (center && center.centerNode) {
      setCenter(center.centerNode.x, center.centerNode.y, initialZoom);
    }
    zoomTo(initialZoom);
  }, [rendererData, fitView, setCenter, center, initialZoom, zoomTo]);

  // 135px is the height of basic toolbar which is provided by library.
  return (
    <div style={{ height: '100%' }}>
      <ReactFlow
        className={reactFlowStyles['react-flow']}
        minZoom={minimumZoom}
        elements={elements}
        onElementClick={onElementClick}
        onPaneClick={onPaneClicked}
        onLoad={onLoad}
        selectNodesOnDrag={false}
        snapToGrid={true}
        edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}
        snapGrid={[20, 20]}
      >
        {showBasicToolbar ? <Controls /> : null}
        {customControls}
      </ReactFlow>
    </div>
  );
}

function updateSelection(elements, nodeClicked, setElements) {
  const filteredElements = elements.filter(e => e.id !== nodeClicked.id);
  const updatedElements = filteredElements.map(element => {
    return {
      ...element,
      data: {
        ...element.data,
        selected: false
      }
    };
  });
  let elementClicked = elements.find(e => e.id === nodeClicked.id);
  elementClicked = {
    ...elementClicked,
    data: { ...elementClicked.data, selected: true }
  };
  setElements([...updatedElements, elementClicked]);
}

function clearSelection(elements, setElements) {
  const updatedElements = elements.map(element => {
    return {
      ...element,
      data: {
        ...element.data,
        selected: false
      }
    };
  });

  setElements(updatedElements);
}
