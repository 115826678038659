import { Box, H4 } from '../../../lib/components';
import useTranslatedValue from '../../../Hooks/useTranslatedValue';

export const ErrorDetails = ({ state }) => {
  const defaultErrorMessage = useTranslatedValue('DEFAULT_ERROR_MESSAGE');
  if (state.error && typeof state.error === 'string') {
    return errorDetail(state.error);
  }
  if (state.data) {
    if (typeof state.data === 'string') {
      return errorDetail(state.data);
    }
    if (
      state.data.response &&
      state.data.response.data &&
      typeof state.data.response.data === 'string'
    ) {
      return errorDetail(state.data.response.data);
    }
  }
  if (state && typeof state === 'object') {
    for (let value of Object.values(state)) {
      if (
        value.isError &&
        value.data &&
        value.data.response &&
        value.data.response.data
      ) {
        return errorDetail(value.data.response.data);
      }
    }
  }
  return errorDetail(defaultErrorMessage);
};

const errorDetail = message => {
  return (
    <Box display={'grid'} textAlign={'center'}>
      <H4 color="ERROR">{message}</H4>
    </Box>
  );
};
