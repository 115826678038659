import React, { useContext } from 'react';

export const AuthContext = React.createContext();

export const useAuthContext = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('must be used as a child of an AuthContext provider');
    }
    return context;
};