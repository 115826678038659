import React, { useState, useEffect } from 'react';
import { useFileDownloadUrl, getFileExtension } from '../../Core';
import { Box } from './Box';
import { Spinner } from './Spinner';
import Icon from './Icon';
import downloadIcon from '../../Content/Images/download.svg';
import cancelIcon from '../../Content/Images/cancel.svg';
import { CircularButton } from './Button';
import { Modal } from './Modal';
import {
  AttachmentPreviewRenderer,
  previewSupportedFileExtensions
} from './AttachmentPreviewRenderer';

const extensionIcons = {
  '.pdf': 'far fa-file-pdf fa-lg',
  '.pptx': 'far fa-file-powerpoint fa-lg',
  '.ppt': 'far fa-file-powerpoint fa-lg',
  '.docx': 'far fa-file-word fa-lg',
  '.doc': 'far fa-file-word fa-lg',
  '.png': 'far fa-file-image fa-lg',
  '.jpg': 'far fa-file-image fa-lg',
  '.gif': 'far fa-file-image fa-lg',
  '.txt': 'far fa-file-alt fa-lg',
  '.xlsx': 'far fa-file-excel fa-lg',
  '.xls': 'far fa-file-excel fa-lg'
};

export function FileDownloader({ fileId, fileName = null, render, onSuccess }) {
  const [showPreview, viewPreview] = useState(false);
  const { state, run } = useFileDownloadUrl(fileId, true);
  const fileIcon = fileName ? getFileIcon(fileName) : null;

  useEffect(() => {
    if (showPreview) {
      run({ fileId });
    }
  }, [fileId, run, showPreview]);

  const startDownload = () => {
    run(fileId);
  };

  useEffect(() => {
    if (
      !previewSupportedFileExtensions.includes(getFileExtension(fileName)) &&
      state &&
      state.isFulfilled &&
      state.data
    ) {
      downloadFile(fileId, state.data);
      onSuccess && onSuccess();
    }

    if (state && state.isFulfilled && !state.data) {
      viewPreview(false);
    }
  }, [fileId, fileName, onSuccess, state]);

  const closePreview = () => {
    viewPreview(false);
  };

  const fileIconTag = <i className={fileIcon} />;

  return (
    <>
      <a id={fileId} style={{ display: 'none' }} href="blank" />
      <ProgressStateModal state={state} />

      {!state.isLoading && state.data ? (
        <AttachmentPreview
          fileName={fileName}
          imageUrl={state.data}
          downloadAttachment={() => downloadFile(fileId, state.data)}
          showPreview={showPreview}
          closePreview={closePreview}
        />
      ) : null}

      {previewSupportedFileExtensions.includes(getFileExtension(fileName))
        ? render(() => viewPreview(true), fileIcon ? fileIconTag : null)
        : render(startDownload, fileIcon ? fileIconTag : null)}
    </>
  );
}

const AttachmentPreview = ({
  fileName,
  imageUrl,
  downloadAttachment,
  showPreview,
  closePreview
}) => {
  const renderContent = () => {
    return (
      <>
        <Box justifyContent="space-between" width="100%" alignItems="center">
          <Box>
            <CircularButton
              background="transparent"
              borderRadius="0"
              onClick={downloadAttachment}
            >
              <Icon imgSrc={downloadIcon} height="20px" />
            </CircularButton>
          </Box>

          <Box>
            <CircularButton
              background="transparent"
              borderRadius="0"
              onClick={closePreview}
            >
              <Icon imgSrc={cancelIcon} height="20px" />
            </CircularButton>
          </Box>
        </Box>
        <Box
          direction={'column'}
          overflow="auto"
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <AttachmentPreviewRenderer fileName={fileName} fileUrl={imageUrl} />
        </Box>
      </>
    );
  };

  return (
    <Modal
      open={showPreview}
      onClose={closePreview}
      background
      children={<ModalContent children={renderContent()} />}
    />
  );
};

const downloadFile = (fileId, data) => {
  let tempLink = document.getElementById(fileId);
  tempLink.style.display = 'none';
  tempLink.href = data;
  tempLink.setAttribute('download', true);

  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }

  tempLink.click();
};

const getFileIcon = fileName => {
  if (fileName && fileName.length > 0) {
    const icon = extensionIcons[getFileExtension(fileName)];
    if (icon) {
      return icon;
    }
    return 'far fa-file fa-lg';
  }
  return '';
};

const ProgressStateModal = ({ state }) => {
  const [showProgressModal, setShowProgressModal] = useState(state.isLoading);
  const [content, setContent] = useState();

  useEffect(() => {
    if (state.isLoading) {
      setContent(<Spinner />);
    }

    if (state.isError) {
      setContent('Something went wrong');
    }

    if (state.isFulfilled && !state.data) {
      setContent('Attachment source not found');
    }

    setShowProgressModal(
      state.isLoading || state.isError || (state.isFulfilled && !state.data)
    );
  }, [state]);

  return (
    <Modal
      open={showProgressModal}
      background
      onClose={() => setShowProgressModal(false)}
      children={<ModalContent children={content} />}
    />
  );
};

const ModalContent = ({ children }) => {
  return (
    <Box
      direction={'column'}
      width={'70vw'}
      height={'85vh'}
      alignItems="center"
      justifyContent="center"
      boxShadow="-3px 5px 5px 0px"
      boxShadowColor="rgba(0, 0, 0, 0.25)"
      backgroundColor="var(--surface)"
      overflow={'hidden'}
    >
      {children}
    </Box>
  );
};
