import React from 'react';
import { AuthContextProvider } from './AuthContext';
import { I18nextProvider } from 'react-i18next';
import i18n from '../i18n';
import { AppThemeProvider } from '../lib/components';
import { defaultColorPalette } from '../Theme';
import { typography } from '../Theme';
import { isBrowserSupported } from '../Core';
import { UnsupportedBrowserMessage } from '../shared/Components';
import { AppInsightsContextProvider } from './AppInsightsContext';
import { CookiesProvider } from 'react-cookie';

const AppContextProviders = ({ children }) => {
  return (
    <I18nextProvider i18n={i18n}>
      <AppInsightsContextProvider>
        <AppThemeProvider
          colorPalette={defaultColorPalette}
          typography={typography}
        >
          {/* this should be in App.js */}
          {!isBrowserSupported() ? <UnsupportedBrowserMessage /> : null}
          <CookiesProvider>
            <AuthContextProvider>{children}</AuthContextProvider>
          </CookiesProvider>
        </AppThemeProvider>
      </AppInsightsContextProvider>
    </I18nextProvider>
  );
};

export default AppContextProviders;

export { AsyncJobContextProvider } from './AsyncJobContext';
export { SettingsContextProvider } from './SettingsContext';
