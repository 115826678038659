import React, { createContext, useContext, useEffect, useState } from 'react';
import { AreArrayContentSame } from '../Core';

export const GridSelectionContext = createContext();

const SelectionContextProvider = props => {
  const {
    data = [],
    singleSelect,
    defaultSelectedItems = [],
    keyField,
    onSelectionChanged
  } = props;
  const [selectedItems, setSelectedItems] = useState(defaultSelectedItems);
  const [isDeselect, setIsDeselect] = useState(false);
  const [areAllItemsSelected, setAreAllItemsSelected] = useState(
    selectedItems.length > 0 && data.length === selectedItems.length
  );

  useEffect(() => {
    if (!AreArrayContentSame(defaultSelectedItems, selectedItems)) {
      onSelectionChanged && onSelectionChanged(selectedItems);
    }
    if (areAllItemsSelected) {
      setSelectedItems(data);
    }
  }, [
    selectedItems,
    onSelectionChanged,
    defaultSelectedItems,
    data,
    keyField,
    isDeselect,
    areAllItemsSelected
  ]);

  const onItemSelect = index => {
    if (index === -1) {
      setSelectedItems(data);
      setAreAllItemsSelected(true);
    } else {
      if (data.length - 1 === selectedItems.length) {
        setAreAllItemsSelected(true);
      }
      if (singleSelect) {
        setSelectedItems([data[index]]);
      } else {
        setSelectedItems([...selectedItems, data[index]]);
      }
    }
  };

  const onResetSelections = () => {
    setSelectedItems([]);
  };
  const onItemUnselect = index => {
    setIsDeselect(true);
    if (index === -1) {
      setSelectedItems([]);
    } else {
      const item = data[index];
      const location = selectedItems
        .map(function(x) {
          return x[keyField];
        })
        .indexOf(item[keyField]);
      setSelectedItems(
        selectedItems.filter((selectedItem, i) => {
          return i !== location;
        })
      );
    }
    setAreAllItemsSelected(false);
  };

  return (
    <GridSelectionContext.Provider
      value={{
        selectedItems,
        onItemSelect,
        onItemUnselect,
        keyAccessor: keyField,
        areAllItemsSelected,
        onResetSelections
      }}
      {...props}
    />
  );
};

const useGridSelectContext = () => {
  const context = useContext(GridSelectionContext);

  if (context === undefined) {
    throw new Error('GridSelection context is undefined');
  }
  return context;
};

export { SelectionContextProvider, useGridSelectContext };
