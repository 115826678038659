import React, { Suspense } from 'react';
import { Image } from './Image';
import { getFileExtension } from '../../Core';
import { Spinner } from './Spinner';
import VideoPreview from './VideoPreview';

const PDFPreview = React.lazy(() => import('./PDFPreview'));

const imageExtensions = ['.jpg', '.png', '.gif'];
const videoExtensions = ['.webm', '.ogg', '.mp4'];

export const previewSupportedFileExtensions = [
  ...imageExtensions,
  ...videoExtensions,
  '.pdf'
];

function AttachmentPreviewRenderer({ fileName, fileUrl }) {
  const extension = getFileExtension(fileName);
  if (extension === '.pdf') {
    return (
      <Suspense fallback={<Spinner />}>
        <PDFPreview fileUrl={fileUrl} />
      </Suspense>
    );
  }
  if (videoExtensions.includes(extension)) {
    return <VideoPreview fileUrl={fileUrl} />;
  }
  if (imageExtensions.includes(extension)) {
    return (
      <Image
        src={fileUrl}
        alt={fileName}
        width="100%"
        resizeMode="scale-down"
      />
    );
  }
  return null;
}

export { AttachmentPreviewRenderer };
