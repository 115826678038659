import React, { useState } from 'react';
import classes from './PromoteActions.module.css';
import classNames from 'classnames';
import { Button, ButtonEnums } from '../../../lib/components';

export const getActionLabel = action => action.displayName || action.name;

export const PromoteButton = ({ allOptions, onSelect, disabled }) => {
  const [isListVisible, toggleList] = useState(false);

  const [primaryOption, ...otherOptions] = allOptions;

  const disableButton = () => {
    return {
      'opacity-light': disabled,
      'pointer-event-none': disabled,
      'pointer-event-auto': !disabled
    };
  };

  const renderActionsOverflowIcon = () => {
    return otherOptions.length ? (
      <Button
        appearance={ButtonEnums.Appearance.Primary}
        disabled={disabled}
        style={{
          borderBottomLeftRadius: 0,
          borderTopLeftRadius: 0,
          color: 'var(--surface)',
          borderLeft: ' 1px solid var(--surface)'
        }}
        size={ButtonEnums.Size.Medium}
        onClick={() => !disabled && toggleList(!isListVisible)}
      >
        <span style={{ lineHeight: '1.4rem', fontSize: '1rem' }}>
          <i className={classNames('fas fa-angle-down', disableButton())} />
        </span>
      </Button>
    ) : null;
  };

  return (
    <>
      <div className={classes['btn-container']}>
        <Button
          text={getActionLabel(primaryOption)}
          title={getActionLabel(primaryOption)}
          appearance={ButtonEnums.Appearance.Primary}
          disabled={disabled || primaryOption.allowPromoteOnlyOnScan}
          style={
            (otherOptions.length
              ? { borderBottomRightRadius: 0, borderTopRightRadius: 0 }
              : { borderBottomRightRadius: 4, borderTopRightRadius: 4 },
            { color: 'var(--surface)' })
          }
          size={ButtonEnums.Size.Medium}
          onClick={() => {
            if (!primaryOption.allowPromoteOnlyOnScan) {
              onSelect(primaryOption);
            }
          }}
        />
        {renderActionsOverflowIcon()}
      </div>
      {isListVisible ? (
        <SecondaryOptions
          options={otherOptions}
          onOptionSelect={onSelect}
          onClose={() => toggleList(!isListVisible)}
        />
      ) : null}
    </>
  );
};

const SecondaryOptions = ({ options, onOptionSelect, onClose }) => {
  return (
    <>
      <div onClick={onClose} className={'action-overlay'} />
      <div
        className={classNames(
          classes['options-popup-layout'],
          classes['options-popup'],
          'background-color-white',
          'border-1px'
        )}
      >
        {options.map((option, index) => {
          return (
            <li
              title={getActionLabel(option)}
              appearance={ButtonEnums.Appearance.Link}
              key={getActionLabel(option)}
              onClick={() => {
                if (!option.allowPromoteOnlyOnScan) {
                  onOptionSelect(option);
                  onClose();
                }
              }}
              className={classNames(
                classes['li-dropdown'],
                'text-overflow-hidden',
                {
                  'opacity-light': option.allowPromoteOnlyOnScan,
                  'pointer-event-none': option.allowPromoteOnlyOnScan,
                  'pointer-event-auto': !option.allowPromoteOnlyOnScan
                }
              )}
            >
              {getActionLabel(option)}
            </li>
          );
        })}
      </div>
    </>
  );
};

export default PromoteButton;
