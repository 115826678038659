export function getBrowser() {
  let ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: 'IE', version: tem[1] || '' };
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR\/(\d+)/);
    if (tem !== null) {
      return { name: 'Opera', version: tem[1] };
    }
  }
  if (window.navigator.userAgent.indexOf('Edge') > -1) {
    tem = ua.match(/\Edge\/(\d+)/);
    if (tem !== null) {
      return { name: 'Edge', version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) !== null) {
    M.splice(1, 1, tem[1]);
  }
  return {
    name: M[0],
    version: +M[1]
  };
}

function isSupported(browser) {
  let supported = false;
  const ua = window.navigator.userAgent;
  if (ua.toLowerCase().includes('android') ||
    ua.toLowerCase().includes('iphone') ||
    ua.toLowerCase().includes('ipad')) {
    return true;
  } else if (browser.name === 'Chrome' && browser.version >= 57) {
    supported = true;
  } else if (browser.name === 'Firefox' && browser.version >= 52) {
    supported = true;
  } else if (browser.name === 'Safari' && browser.version >= 10.1) {
    supported = true;
  } else if (browser.name === 'Opera' && browser.version >= 44) {
    supported = true;
  }
  return supported;
}

export const isBrowserSupported = () => {
  const browser = getBrowser();
  return isSupported(browser);
};

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);
