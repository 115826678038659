import styled from 'styled-components';
import { formCustomOverflowStyles } from './Styles';

const areasStyle = areasProp => {
  if (
    Array.isArray(areasProp) &&
    areasProp.every(area => Array.isArray(area))
  ) {
    return areasProp.map(area => `"${area.join(' ')}"`).join(' ');
  }

  throw new Error('invalid areas prop');
};

const rowsStyle = rowsProp => {
  if (Array.isArray(rowsProp)) {
    return rowsProp.join(' ');
  }
  throw new Error('invalid rows prop');
};

const columnsStyle = columnsProp => {
  if (Array.isArray(columnsProp)) {
    return columnsProp.join(' ');
  }
  throw new Error('invalid columns prop');
};

export const Grid = styled.div`
  display: grid;
  width: ${props => (props.width ? props.width : '100%')};
  box-sizing: border-box;
  height: ${props => props.height};
  grid-gap: ${props => props.gridgap};
  grid-template-areas: ${props => areasStyle(props.areas)};
  grid-template-rows: ${props => rowsStyle(props.rows)};
  grid-template-columns: ${props => columnsStyle(props.columns)};
  align-items: ${props => props.alignItems};
  align-content: ${props => props.alignContent};
  justify-self: ${props => props.justifySelf};
  justify-items: ${props => props.justifyItems};
  text-align: ${props => props.textAlign};
  background-color: ${props => props.theme.colorPalette[props.backgroundColor]};
  position: ${props => props.position};
  padding: ${props => props.padding};
  padding-left: ${props => props.paddingLeft};
  padding-right: ${props => props.paddingRight};
  padding-top: ${props => props.paddingTop};
  padding-bottom: ${props => props.paddingBottom};
  border: ${props => props.border};
  border-bottom: ${props => props.borderBottom};
  border-top: ${props => props.borderTop};
  border-right: ${props => props.borderRight};
  border-left: ${props => props.borderLeft};
  border-radius: ${props => props.borderRadius};
  margin: ${props => props.margin};
  margin-top: ${props => props.marginTop};
  margin-left: ${props => props.marginLeft};
  column-gap: ${props => props.columnGap};
  text-align: ${props => props.textAllign};
  box-shadow: ${props => getBoxShadowStyle(props)};
  z-index: ${props => props.zIndex};
  color: ${props => props.theme.colorPalette[props.color]};
  min-height: ${props => props.minHeight};
  min-width: ${props => props.minWidth};
  max-width: ${props => props.maxWidth};
  max-height: ${props => props.maxHeight};
  justify-content: ${props => props.justifyContent};
  ${props => (props.overflow ? formCustomOverflowStyles : '')};
`;

function getBoxShadowStyle(props) {
  if (!props.boxShadow) {
    return '';
  }
  return (
    props.boxShadow +
    ' ' +
    (props.boxShadowColor
      ? props.boxShadowColor
      : props.theme.colorPalette['PRIMARY_SHADOW'])
  );
}
