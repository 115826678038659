export function dashboardConfigCacheManager(typeName) {
  const cacheKey = `/types/${typeName}/dashboard`;

  function persistFilters(widgetId, newFilters) {
    const cachedConfig = getCachedTypeConfig();
    const cachedWidget =
      cachedConfig && cachedConfig.widgets.find(w => w.id === widgetId);
    if (cachedWidget) {
      cachedWidget.appliedFilters = newFilters;
      saveTypeConfig(cachedConfig);
    }
  }

  function hydrateFiltersAndSort(widgetConfig) {
    const cachedConfig = getCachedTypeConfig();
    const cachedWidget =
      cachedConfig && cachedConfig.widgets.find(w => w.id === widgetConfig.id);

    return {
      filter: getAppliedFilters(cachedWidget, widgetConfig),
      sort: getAppliedSort(cachedWidget, widgetConfig)
    };
  }

  function getAppliedFilters(cachedWidget, widgetConfig) {
    if (cachedWidget && cachedWidget.appliedFilters) {
      return cachedWidget.appliedFilters;
    }
    return {
      stateFilter: widgetConfig.stateFilter,
      parentFilter: widgetConfig.parentFilter,
      attributeFilter: widgetConfig.attributeFilter
    };
  }

  function getAppliedSort(cachedWidget, widgetConfig) {
    if (cachedWidget && cachedWidget.appliedSort) {
      return cachedWidget.appliedSort;
    }
    return {
      sortBy: widgetConfig.sortBy,
      isSortByDescending: widgetConfig.isSortByDescending
    };
  }

  function persistSort(widgetId, appliedSort) {
    const cachedConfig = getCachedTypeConfig();
    const cachedWidget =
      cachedConfig && cachedConfig.widgets.find(w => w.id === widgetId);

    if (cachedWidget) {
      cachedWidget.appliedSort = { ...appliedSort };
      saveTypeConfig(cachedConfig);
    }
  }

  function persistSelectedWidget(widgetId) {
    const cachedConfig = getCachedTypeConfig();
    if (cachedConfig) {
      cachedConfig.selectedWidgetId = widgetId;
      saveTypeConfig(cachedConfig);
    }
  }

  function hydrateSelectedWidget() {
    const cachedConfig = getCachedTypeConfig();
    if (!cachedConfig) {
      return null;
    }
    return cachedConfig.selectedWidgetId;
  }

  function getCachedTypeConfig() {
    return JSON.parse(window.localStorage.getItem(cacheKey));
  }

  function saveTypeConfig(newConfig) {
    window.localStorage.setItem(cacheKey, JSON.stringify(newConfig));
  }

  return {
    hydrateFiltersAndSort,
    hydrateSelectedWidget,
    persistFilters,
    persistSort,
    persistSelectedWidget
  };
}
