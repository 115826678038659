import React from 'react';
import { CSVLink } from 'react-csv';
import { Parser } from 'json2csv';
import { Button, ButtonEnums } from '.';
import Icon, { IconBoxSize } from './Icon';
import { ReactComponent as MsExcelSVG } from '../../Content/Images/excel.svg';
import { useSettingsContext, formatDate, formatDateTimeWithTimeZone } from '../../Core';

export const ExportCSVButton = ({
  data,
  headers,
  filename,
  attributeFields
}) => {
  const { settings } = useSettingsContext();
  const { timeFormat, dateFormat, timeZone } = settings.dateTimeFormat;

  const formattedData = getFormattedData(
    data,
    headers,
    dateFormat,
    timeZone,
    timeFormat,
    attributeFields
  );

  const sanitizedCsv = getSanitizedCsv(
    formattedData,
    headers
  );

  return (
    <Button
      size={ButtonEnums.Size.Medium}
      appearance={ButtonEnums.Appearance.TransparentWithHover}
      style={{ float: 'left', margin: '0.4rem 0.55rem' }}
    >
      <CSVLink
        data={sanitizedCsv}
        filename={filename.replace(/ /g, '_') + '.csv'}
        target="_blank"
      >
        <Icon SVG={MsExcelSVG} size={IconBoxSize.XLarge} />
      </CSVLink>
    </Button>
  );
};
function getFormattedData(
  data,
  headers,
  dateFormat,
  timeZone,
  timeFormat,
  attributeFields
) {
  const formattedData = [];
  data.forEach(element => {
    const clonedElement = {};
    headers.forEach(header => {
      if (header.attributeType === 'Date') {
        const dateStr = element[header.key + 'original']
          ? element[header.key + 'original']
          : element[header.key];
        const formattedDate = formatDate(dateStr, dateFormat, timeZone);
        clonedElement[header.key] = `${header.prefix} ${formattedDate ? formattedDate : '-'
          } ${header.suffix}`;
      } else if (header.attributeType === 'DateTime') {
        const dateStr = element[header.key + 'original']
          ? element[header.key + 'original']
          : element[header.key];
        const formattedDate = formatDateTimeWithTimeZone(
          dateStr,
          timeFormat,
          dateFormat,
          timeZone
        );
        clonedElement[header.key] = `${header.prefix} ${formattedDate ? formattedDate : '-'
          } ${header.suffix}`;
      } else if (header.attributeType === 'Picklist') {
        const picklistField =
          attributeFields &&
          attributeFields.find(f => f.attributeName === header.key);
        const attributeField =
          picklistField &&
          picklistField.options &&
          picklistField.options.find(f => f.name === element[header.key]);
        let picklistDisplayName;
        if (attributeField && attributeField.displayName) {
          picklistDisplayName = attributeField.displayName;
        } else {
          picklistDisplayName = getValueOfKeyFromElement(element, header.key);
        }
        clonedElement[
          header.key
        ] = `${header.prefix} ${picklistDisplayName} ${header.suffix}`;
      } else if (header.prefix || header.suffix) {
        clonedElement[header.key] = `${header.prefix
          } ${getValueOfKeyFromElement(element, header.key)} ${header.suffix}`;
      } else {
        clonedElement[header.key] = getValueOfKeyFromElement(
          element,
          header.key
        );
      }
    });
    formattedData.push(clonedElement);
  });

  return formattedData;
}

function getSanitizedCsv(
  formattedData,
  headers
) {
  const data = formattedData;
  const fields = headers.map(
    obj => {
      return {
        'label': obj.label,
        'value': obj.key
      };
    }
  );
  const json2csvParser = new Parser({ fields, excelStrings: true });
  const csv = json2csvParser.parse(data);

  return csv;
}

const getValueOfKeyFromElement = (element, key) => {
  if (element[key] || element[key] === 0) {
    return element[key];
  }
  return '-';
};
