import _ from 'lodash';

export const ReactFlowConstants = {
  top: 'top',
  bottom: 'bottom',
  left: 'left',
  right: 'right',
  source: 'source',
  target: 'target',
  promote: 'Promote',
  vertical: 'TB',
  horizontal: 'LR',
  actionDeleted: 'Action Deleted',
  initialZoom: 1.2,
  program: 'Program'
};

const defaultConfig = {
  nodeHeight: 55,
  nodeWidth: 148,
  handlerHorizontalDiff: 6,
  handlerVerticalDiff: 6,
  edgeHorizontalDiff: 25,
  edgeVerticalDiff: 25,
  margin: 7, // keep bottom edge in horizontal and right edge in vertical connected to node
  enableNodeClick: true,
  enableEdgeClick: true,
  nodeHorizontalDiff: 52,
  nodeVerticalDiff: 25
};

const defaultNodeStyle = {
  defaultStateColor: '#888888',
  getBackground: (isCurrent, isVisited, stateColor) => {
    if (stateColor) {
      return `${stateColor}54`;
    }
    return isCurrent ? '#FFFFFF54' : '#E9E6EB54';
  },
  getOpacity: (isCurrent, isVisited) => {
    return !isCurrent && !isVisited ? '0.7' : '1';
  },
  getBoxShadow: (isCurrent, isVisited, stateColor) => {
    return isCurrent ? '0px 3px 4px 3px rgba(0, 0, 0, 0.21)' : '';
  },
  getBorder: (isCurrent, isVisited, stateColor) => {
    return `1px solid ${stateColor}`;
  },
  getBorderRadius: (isCurrent, isVisited) => {
    return '3px';
  },
  getTextStyle: (isCurrent, isVisited, stateColor) => {
    return {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '130px',
      display: '-webkit-box !important',
      whiteSpace: 'normal',
      textAlign: 'center',
      lineHeight: '1.3em',
      fontStyle: 'normal',
      fontWeight: isCurrent ? 'bold' : 'normal',
      fontSize: '12px',
      color: '#1E2226'
    };
  }
};

const defaultEdgeStyle = {
  strokeWidth: '1px',
  getTextStyle: (sourceStateColor, targetStateColor) => {
    return {
      fontWeight: 500,
      fontSize: '12px',
      fontStyle: 'normal',
      color: '#6c6c6c'
    };
  }
};

let config = {};
let style = {};
let totalNodes = 0;

export function getReactFlowRendererDataFromLifecyclesAndActivities(
  lifecycleData,
  instanceActivities,
  orientation,
  flowConfig = {},
  flowStyle = {}
) {
  config = {};
  totalNodes = 0;
  initializeDrawConfig(flowConfig);
  initializeStyle(flowStyle);
  const filteredStates = filterLifecycleStates(
    lifecycleData.states,
    instanceActivities
  );
  let stateTargetStatePair = mapLifecycleDataToKeyValuePair(filteredStates);
  const basicRendererData = mapLifeCycleToFlowRenderer({
    lifecycleStates: lifecycleData.states,
    stateTargetStatePair: stateTargetStatePair,
    orientation: orientation,
    readonly: false,
    promoteActivities: instanceActivities
  });
  return basicRendererData;
}

// gives current state node position and index
export function getNodeToBeCentered(
  currentState,
  rendererData,
  currentOrientation
) {
  let interestedNode;
  let nodesToBeFitted = 0;
  const nodeWidthWithZoom = config.nodeWidth * ReactFlowConstants.initialZoom;
  const nodeHeightWithZoom = config.nodeHeight * ReactFlowConstants.initialZoom;
  if (currentOrientation === ReactFlowConstants.horizontal) {
    nodesToBeFitted =
      window.innerWidth / (nodeWidthWithZoom + config.nodeHorizontalDiff);
  } else {
    nodesToBeFitted =
      window.innerHeight / (nodeHeightWithZoom + config.nodeVerticalDiff);
  }
  if (Math.floor(nodesToBeFitted) >= totalNodes) {
    return null;
  } else {
    interestedNode = rendererData.find(
      item => item.edgeIndex === undefined && item.id === currentState.name
    );
    return {
      centerNode: calculateFocusingCenter(
        {
          x: interestedNode.position.x,
          y: interestedNode.position.y,
          index: interestedNode.index
        },
        currentOrientation
      )
    };
  }
}

export function getAdminLifecycleRearrangeStatesFlowRendererData(
  lifecycleData,
  readOnly = false,
  orientation,
  dispatch,
  flowConfig = {},
  flowStyle = {}
) {
  config = {};
  totalNodes = 0;
  initializeDrawConfig(flowConfig);
  initializeStyle(flowStyle);
  let stateTargetStatePair = mapAdminLifecycleDataToKeyValuePair(
    lifecycleData.states
  );
  const basicRendererData = mapAdminLifecycleToOnlyNodesFlowRenderer({
    lifecycleStates: lifecycleData.states,
    stateTargetStatePair: stateTargetStatePair,
    orientation: orientation,
    readonly: readOnly,
    dispatch: dispatch,
    rearrangeMode: true
  });
  return basicRendererData;
}

function mapAdminLifecycleToOnlyNodesFlowRenderer({
  lifecycleStates,
  stateTargetStatePair,
  orientation,
  readonly = false,
  onEdgeClick,
  dispatch,
  rearrangeMode = true
}) {
  resetCoordinates();

  let flowRendererData = addNodesByLifecycleStates(
    stateTargetStatePair,
    orientation,
    lifecycleStates,
    readonly,
    dispatch,
    rearrangeMode,
    false
  );
  const nodesOnly = flowRendererData.filter(r => 'index' in r);
  totalNodes = nodesOnly.length;
  const updatedNodes =
    orientation === ReactFlowConstants.horizontal
      ? alignHorizontalHandlersToCenter(nodesOnly)
      : alignVerticalHandlersToCenter(nodesOnly);
  return updatedNodes;
}

export function getAdminLifecycleFlowRendererData(
  lifecycleData,
  readOnly = false,
  orientation,
  dispatch,
  flowConfig = {},
  flowStyle = {}
) {
  config = {};
  totalNodes = 0;
  initializeDrawConfig(flowConfig);
  initializeStyle(flowStyle);
  let stateTargetStatePair = mapAdminLifecycleDataToKeyValuePair(
    lifecycleData.states
  );
  const basicRendererData = mapAdminLifecycleToFlowRenderer({
    lifecycleStates: lifecycleData.states,
    stateTargetStatePair: stateTargetStatePair,
    orientation: orientation,
    readonly: readOnly,
    dispatch: dispatch
  });
  return basicRendererData;
}

function calculateFocusingCenter(currentNode, currentOrientation) {
  const margin = getXMarginForCenterNode(currentOrientation, currentNode);
  return { x: currentNode.x + margin, y: currentNode.y + 60 };
}

function getXMarginForCenterNode(currentOrientation, currentNode) {
  if (currentOrientation === ReactFlowConstants.horizontal) {
    return currentNode.index === 0 ? 300 : 200;
  } else {
    return window.innerWidth / 2 - window.innerWidth / 3;
  }
}

function initializeDrawConfig(flowConfig) {
  config = { ...defaultConfig, ...flowConfig };
  config.handlerXValue = config.nodeWidth - config.margin;
  config.handlerYValue = config.nodeHeight - config.margin;
  config.edgeHeightConfig = {};
  config.edgeHeightTop = 0;
  config.edgeHeightBottom = 0;
  config.edgeHeightLeft = 0;
  config.edgeHeightRight = 0;
}

function initializeStyle(flowStyle) {
  style.nodeStyle = { ...defaultNodeStyle, ...flowStyle.nodeStyle };
  style.edgeStyle = { ...defaultEdgeStyle, ...flowStyle.edgeStyle };
}

function mapLifeCycleToFlowRenderer({
  lifecycleStates,
  stateTargetStatePair,
  orientation,
  readonly = false,
  onEdgeClick,
  dispatch,
  promoteActivities
}) {
  resetCoordinates();
  const updatedLifecycleStatesData = updateLifecycleStateData(
    lifecycleStates,
    promoteActivities
  );
  let flowRendererData = addNodesByLifecycleStates(
    stateTargetStatePair,
    orientation,
    updatedLifecycleStatesData,
    readonly,
    dispatch
  );

  Object.keys(stateTargetStatePair).forEach(stateName => {
    stateTargetStatePair[stateName].forEach((action, i) => {
      const activities = promoteActivities.filter(
        activity =>
          activity.fromState.name === stateName &&
          activity.toState.name === action.targetStateName
      );
      if (activities.length > 0) {
        let sourceState = flowRendererData.find(f => f.id === stateName);
        let targetState = flowRendererData.find(
          f => f.id === action.targetStateName
        );

        flowRendererData = addEdgesByPromoteAction(
          sourceState,
          targetState,
          flowRendererData,
          action,
          i,
          orientation,
          onEdgeClick,
          activities
        );
      }
    });
  });

  const nodesOnly = flowRendererData.filter(r => 'index' in r);
  const edgesOnly = flowRendererData.filter(r => 'edgeIndex' in r);

  totalNodes = nodesOnly.length;

  const updatedNodes =
    orientation === ReactFlowConstants.horizontal
      ? alignHorizontalHandlersToCenter(nodesOnly)
      : alignVerticalHandlersToCenter(nodesOnly);

  return [...updatedNodes, ...edgesOnly];
}

function mapAdminLifecycleToFlowRenderer({
  lifecycleStates,
  stateTargetStatePair,
  orientation,
  readonly = false,
  onEdgeClick,
  dispatch
}) {
  resetCoordinates();

  let flowRendererData = addNodesByLifecycleStates(
    stateTargetStatePair,
    orientation,
    lifecycleStates,
    readonly,
    dispatch,
    false,
    false
  );

  Object.keys(stateTargetStatePair).forEach(name => {
    stateTargetStatePair[name].forEach((action, i) => {
      let sourceState = flowRendererData.find(f => f.id === name);
      let targetState = flowRendererData.find(
        f => f.id === action.targetStateName
      );

      flowRendererData = addEdgesByPromoteAction(
        sourceState,
        targetState,
        flowRendererData,
        { name: action.actioName, targetStateName: action.targetStateName },
        i,
        orientation,
        onEdgeClick,
        null,
        action.action
      );
    });
  });
  const nodesOnly = flowRendererData.filter(r => 'index' in r);
  const edgesOnly = flowRendererData.filter(r => 'edgeIndex' in r);

  totalNodes = nodesOnly.length;

  const updatedNodes =
    orientation === ReactFlowConstants.horizontal
      ? alignHorizontalHandlersToCenter(nodesOnly)
      : alignVerticalHandlersToCenter(nodesOnly);

  return [...updatedNodes, ...edgesOnly];
}

function mapAdminLifecycleDataToKeyValuePair(lifecycleStates) {
  let stateToTargetStates = {};
  lifecycleStates.forEach(i => {
    let operation = [];
    i &&
      i.operations &&
      i.operations.forEach(op => {
        const updatedActions =
          op && op.actions
            ? op.actions.filter(
                action => action.actionType !== ReactFlowConstants.program
              )
            : [];
        operation = [...operation, ...updatedActions];
      });

    stateToTargetStates = {
      ...stateToTargetStates,
      [i.name]: operation
        ? operation.map(a => {
            return {
              actioName: a.name,
              action: a,
              targetStateName: a.targetStateName
            };
          })
        : []
    };
  });

  return stateToTargetStates;
}

function mapLifecycleDataToKeyValuePair(lifecycleStates) {
  let stateToTargetStates = {};
  lifecycleStates.forEach(state => {
    stateToTargetStates = {
      ...stateToTargetStates,
      [state.name]: state.promoteActions
    };
  });

  return stateToTargetStates;
}

function filterLifecycleStates(lifecycleStates, instanceActivities) {
  const promoteActivities = getPromoteActivities(instanceActivities);
  return lifecycleStates.map(state => {
    return {
      ...state,
      promoteActions: getPromoteActionsForState(state.name, promoteActivities)
    };
  });
}

// filtering instance created activity from promote activities
function getPromoteActivities(promoteActivities) {
  return promoteActivities.filter(
    activity =>
      activity.title && !activity.title.toLowerCase().includes('created')
  );
}

function groupActivitiesByToStateName(objectArray) {
  return objectArray.reduce((acc, obj) => {
    const key = obj['toState'].name;
    if (!acc[key]) {
      acc[key] = [];
    }
    // Add object to list for given key's value
    acc[key].push(obj);
    return acc;
  }, {});
}

// call this method to get promoteActions for state from provided promoteActivities
function getPromoteActionsForState(stateName, promoteActivities) {
  const sourceStateActivities = promoteActivities.filter(
    activity => activity.fromState && activity.fromState.name === stateName
  );
  let promoteActions = [];
  // filtering all activities for one target state
  const activitiesGroupByTargetState = groupActivitiesByToStateName(
    sourceStateActivities
  );
  sourceStateActivities.forEach(sourceStateActivity => {
    const activitiesWithPromoteAction = activitiesGroupByTargetState[
      sourceStateActivity.toState.name
    ].filter(a => a.promoteAction);
    const activitiesWithoutPromoteAction = activitiesGroupByTargetState[
      sourceStateActivity.toState.name
    ].filter(a => !a.promoteAction);
    activitiesWithPromoteAction.forEach(activityWithPromoteAction => {
      if (
        promoteActions.filter(
          action =>
            action.targetStateName === activityWithPromoteAction.toState.name &&
            action.name === activityWithPromoteAction.promoteAction.name
        ).length === 0
      ) {
        promoteActions.push({
          ...activityWithPromoteAction.promoteAction,
          targetStateName: activityWithPromoteAction.toState.name
        });
      }
    });
    activitiesWithoutPromoteAction.forEach(activityWithoutPromoteAction => {
      if (
        promoteActions.filter(
          action =>
            action.targetStateName === activityWithoutPromoteAction.toState.name
        ).length === 0
      ) {
        promoteActions.push({
          name: ReactFlowConstants.actionDeleted,
          targetStateName: activityWithoutPromoteAction.toState.name
        });
      }
    });
  });
  return promoteActions;
}

// add data to states about which state isCurrent or which is visited
function updateLifecycleStateData(lifecycleStates, promoteActivities) {
  promoteActivities.sort(
    (a, b) => new Date(a.startTime) - new Date(b.startTime)
  );
  const currentStateName =
    promoteActivities[promoteActivities.length - 1].toState.name;

  return lifecycleStates.map(item => {
    if (item.name === currentStateName) {
      item.isCurrentNode = true;
    } else if (
      promoteActivities.some(activity => activity.toState.name === item.name)
    ) {
      item.isVisitedNode = true;
    } else {
      item.isVisitedNode = false;
    }
    return item;
  });
}

function addEdgesByPromoteAction(
  sourceState,
  targetState,
  flowRendererData,
  action,
  edgeIndex,
  orientation,
  onEdgeClick,
  activities,
  actionDetails
) {
  const [srcHandler, trgHandler, srcState, trgState] = createHandlers(
    sourceState,
    targetState,
    sourceState.stateColor,
    orientation,
    action.name
  );

  const edgeData = createEdgeModel(
    srcState,
    trgState,
    srcHandler,
    trgHandler,
    edgeIndex,
    action,
    orientation,
    onEdgeClick,
    activities,
    actionDetails
  );

  flowRendererData = flowRendererData.filter(
    f => f.id !== srcState.id && f.id !== trgState.id
  );

  // Additional check if it is a self loop
  if (trgState.id === srcState.id) {
    let mergedHandlerData = [
      ...srcState.data.handlers,
      ...trgState.data.handlers
    ];
    mergedHandlerData = _.uniqBy(mergedHandlerData, 'id');
    const mergedState = {
      ...srcState,
      data: { ...srcState.data, handlers: mergedHandlerData }
    };
    return [...flowRendererData, mergedState, edgeData];
  }

  return [...flowRendererData, trgState, srcState, edgeData];
}

function addNodesByLifecycleStates(
  promoteActionsToTargetStates,
  orientation = ReactFlowConstants.horizontal,
  lifecycleStates,
  readonly,
  dispatch,
  rearrangeMode = false,
  showStateDisplayName = true
) {
  let flowRendererData = [];
  Object.keys(promoteActionsToTargetStates).forEach((stateName, index) => {
    const lifecycleData = lifecycleStates.find(l => l.name === stateName);

    flowRendererData.push(
      createNodeModel(
        lifecycleData,
        index,
        orientation,
        readonly,
        dispatch,
        rearrangeMode,
        showStateDisplayName
      )
    );
  });

  return flowRendererData;
}

function createNodeModel(
  lifecycleData,
  index,
  orientation,
  readonly,
  dispatch,
  rearrangeMode = false,
  showStateDisplayName = true
) {
  const {
    name,
    displayName,
    isVisitedNode,
    isCurrentNode,
    backgroundColor,
    isEdited = false
  } = lifecycleData;
  const stateColor = backgroundColor
    ? backgroundColor
    : style.nodeStyle.defaultStateColor;
  const styleN = {
    background: style.nodeStyle.getBackground(
      isCurrentNode,
      isVisitedNode,
      stateColor
    ),
    borderRadius: style.nodeStyle.getBorderRadius(isCurrentNode, isVisitedNode),
    border: style.nodeStyle.getBorder(isCurrentNode, isVisitedNode, stateColor),
    height: `${config.nodeHeight}px`,
    width: `${config.nodeWidth}px`,
    boxShadow: style.nodeStyle.getBoxShadow(
      isCurrentNode,
      isVisitedNode,
      stateColor
    ),
    opacity: style.nodeStyle.getOpacity(isCurrentNode, isVisitedNode),
    cursor: 'default'
  };
  return {
    id: name,
    displayName: displayName,
    data: {
      name: name,
      edited: isEdited,
      enableNodeClick: config.enableNodeClick,
      handlers: [],
      isCurrentNode: isCurrentNode,
      isVisitedNode: isVisitedNode,
      label:
        showStateDisplayName && displayName && displayName !== ''
          ? displayName
          : name,
      readonly: rearrangeMode ? rearrangeMode : readonly,
      selected: false,
      textStyle: style.nodeStyle.getTextStyle(
        isCurrentNode,
        isVisitedNode,
        stateColor
      ),
      dispatch: dispatch
    },
    type: 'custom',
    draggable: rearrangeMode,
    index,
    stateColor,
    style: isEdited ? { ...styleN, borderLeftColor: '#7719aa' } : styleN,
    position:
      orientation === ReactFlowConstants.horizontal
        ? { x: index * (config.nodeWidth + config.nodeHorizontalDiff), y: 0 }
        : { x: 0, y: index * (config.nodeHeight + config.nodeVerticalDiff) }
  };
}

function createHandlers(
  sourceState,
  targetState,
  stateColor,
  orientation,
  actionName
) {
  const srcHandler = createHandler(
    sourceState,
    targetState,
    ReactFlowConstants.source,
    stateColor,
    orientation,
    actionName
  );
  sourceState = addHandlerToNode(sourceState, srcHandler);

  const trgHandler = createHandler(
    sourceState,
    targetState,
    ReactFlowConstants.target,
    stateColor,
    orientation,
    actionName
  );
  targetState = addHandlerToNode(targetState, trgHandler);
  return [srcHandler, trgHandler, sourceState, targetState];
}

function getHandlerId(type, isSelfLoop, baseHandlerId) {
  if (type === ReactFlowConstants.source) {
    return `${baseHandlerId}_SRC`;
  } else {
    return isSelfLoop ? `${baseHandlerId}_TRG_SELF` : `${baseHandlerId}_TRG`;
  }
}

function getEdgePosition(sourceState, targetState, isHorizontal) {
  if (sourceState.index <= targetState.index) {
    return isHorizontal ? ReactFlowConstants.top : ReactFlowConstants.right;
  } else {
    return isHorizontal ? ReactFlowConstants.bottom : ReactFlowConstants.left;
  }
}

function createHandler(
  sourceState,
  targetState,
  type,
  stateColor,
  orientation,
  actionName
) {
  const isSelfLoop = sourceState.id === targetState.id;
  const baseHandlerId = `${sourceState.id}_TO_${targetState.id}`;
  let handlerId = getHandlerId(type, isSelfLoop, baseHandlerId);

  const existingHandlerData =
    type === ReactFlowConstants.source
      ? sourceState.data.handlers.find(h => h.id === handlerId)
      : targetState.data.handlers.find(h => h.id === handlerId);
  if (existingHandlerData) {
    handlerId = `${handlerId}_${actionName}`;
  }

  const isHorizontal = orientation === ReactFlowConstants.horizontal;
  const edgePosition = getEdgePosition(sourceState, targetState, isHorizontal);
  const latestHandlerPosition = isHorizontal
    ? getMaxLeftFromSrcHandler(
        type === ReactFlowConstants.source
          ? sourceState.data.handlers
          : targetState.data.handlers,
        edgePosition
      )
    : getMaxTopFromSrcHandler(
        type === ReactFlowConstants.source
          ? sourceState.data.handlers
          : targetState.data.handlers,
        edgePosition
      );

  let handlerData = {
    type,
    position: edgePosition,
    id: handlerId,
    style: {
      background: stateColor,
      borderColor: stateColor,
      opacity: 0
    }
  };

  const updatedStyle = isHorizontal
    ? {
        left: latestHandlerPosition + config.handlerHorizontalDiff,
        top:
          edgePosition === ReactFlowConstants.top
            ? '0px'
            : `${config.handlerYValue}px`
      }
    : {
        top: latestHandlerPosition + config.handlerVerticalDiff,
        left:
          edgePosition === ReactFlowConstants.right
            ? `${config.handlerXValue}px`
            : '0px'
      };

  handlerData = {
    ...handlerData,
    style: { ...handlerData.style, ...updatedStyle }
  };

  return handlerData;
}

function createEdgeModel(
  sourceState,
  targetState,
  srcHandler,
  trgHandler,
  edgeIndex,
  action,
  orientation,
  onEdgeClick,
  activities,
  actionDetails
) {
  const edgeCoordinates = getEdgeCoordinates(
    srcHandler.position,
    trgHandler.position,
    edgeIndex,
    sourceState.index,
    targetState.index
  );

  return {
    id: `horizontal-e${sourceState.index}-${edgeIndex}-${targetState.id}`,
    source: sourceState.id,
    target: targetState.id,
    sourceHandle: srcHandler.id,
    targetHandle: trgHandler.id,
    style: {
      stroke: sourceState.stateColor,
      strokeWidth: style.edgeStyle.strokeWidth
    },
    type: 'custom',
    arrowHeadType: 'arrowclosed',
    edgeIndex: edgeIndex,
    data: {
      actionDetails: actionDetails,
      enableEdgeClick: config.enableEdgeClick,
      action: action,
      activities: activities,
      noOfTimesActionPerformed: activities ? activities.length : null,
      centerX: edgeCoordinates,
      centerY: edgeCoordinates,
      style: { color: sourceState.stateColor },
      textStyle: style.edgeStyle.getTextStyle(
        sourceState.stateColor,
        targetState.stateColor
      ),
      markerEndId: 'arrow',
      fillColor: sourceState.stateColor,
      orientation,
      selected: false,
      edgePosition: srcHandler.position,
      onEdgeClick: onEdgeClick ? onEdgeClick : null
    }
  };
}

function addHandlerToNode(node, handler) {
  return {
    ...node,
    data: {
      ...node.data,
      handlers: [...node.data.handlers, handler],
      label: node.data.label
    }
  };
}

function getEdgeCoordinateForTop(sourceNode, edgeIndex) {
  if (sourceNode && sourceNode.incomingHeightTop && edgeIndex === 0) {
    config.edgeHeightTop = sourceNode.incomingHeightTop;
  } else {
    config.edgeHeightTop -= config.edgeHorizontalDiff;
  }
  return config.edgeHeightTop;
}

function getEdgeCoordinateForBottom(targetNode, edgeIndex, sourceNode) {
  if (edgeIndex === 0 && sourceNode && sourceNode.incomingHeightBottom) {
    config.edgeHeightBottom = sourceNode.incomingheightBottom;
  } else if (
    targetNode &&
    !targetNode.incomingCountBottom &&
    targetNode.outgoingCountBottom &&
    targetNode.outgoingHeightBottom
  ) {
    config.edgeHeightBottom = targetNode.outgoingHeightBottom;
  } else if (
    sourceNode &&
    !sourceNode.outgoingCountBottom &&
    sourceNode.incomingCountBottom &&
    sourceNode.incomingHeightBottom
  ) {
    config.edgeHeightBottom = sourceNode.incomingHeightBottom;
  } else {
    config.edgeHeightBottom += config.edgeHorizontalDiff;
  }

  return config.edgeHeightBottom;
}

function getEdgeCoordinateForLeft(sourceNode, edgeIndex, targetNode) {
  if (edgeIndex === 0 && sourceNode && sourceNode.incomingHeightLeft) {
    config.edgeHeightLeft = sourceNode.incomingHeightLeft;
  } else if (
    targetNode &&
    !targetNode.incomingCountLeft &&
    targetNode.outgoingCountLeft &&
    targetNode.outgoingHeightLeft
  ) {
    config.edgeHeightLeft = targetNode.outgoingHeightLeft;
  } else if (
    sourceNode &&
    !sourceNode.outgoingCountLeft &&
    sourceNode.incomingCountLeft &&
    sourceNode.incomingHeightLeft
  ) {
    config.edgeHeightLeft = sourceNode.incomingHeightLeft;
  } else {
    config.edgeHeightLeft -= config.edgeVerticalDiff;
  }
  return config.edgeHeightLeft;
}

function getEdgeCoordinateForRight(sourceNode, edgeIndex, targetNode) {
  if (sourceNode && sourceNode.incomingHeightRight && edgeIndex === 0) {
    config.edgeHeightRight = sourceNode.incomingHeightRight;
  } else if (
    targetNode &&
    !targetNode.incomingCountRight &&
    targetNode.outgoingCountRight &&
    targetNode.outgoingHeightRight
  ) {
    config.edgeHeightRight = targetNode.outgoingHeightRight;
  } else if (
    sourceNode &&
    !sourceNode.outgoingCountRight &&
    sourceNode.incomingCountRight &&
    sourceNode.incomingHeightRight
  ) {
    config.edgeHeightRight = sourceNode.incomingHeightRight;
  } else {
    config.edgeHeightRight += config.edgeVerticalDiff;
  }
  return config.edgeHeightRight;
}

function getEdgeCoordinates(
  srcPosition,
  trgPosition,
  edgeIndex,
  sourceNodeIndex,
  targetNodeIndex
) {
  let edgeCoordinates = 0;
  const sourceNode = config.edgeHeightConfig[sourceNodeIndex];
  const targetNode = config.edgeHeightConfig[targetNodeIndex];
  let incomingPropertyName = '';
  let outgoingPropertyName = '';
  let incomingNodesCountPropertyName = '';
  let outgoingNodesCountPropertyName = '';
  if (
    srcPosition === ReactFlowConstants.top &&
    trgPosition === ReactFlowConstants.top
  ) {
    edgeCoordinates = getEdgeCoordinateForTop(sourceNode, edgeIndex);
    incomingPropertyName = 'incomingHeightTop';
    outgoingPropertyName = 'outgoingHeightTop';
    incomingNodesCountPropertyName = 'incomingCountTop';
    outgoingNodesCountPropertyName = 'outgoingCountTop';
  } else if (
    srcPosition === ReactFlowConstants.bottom &&
    trgPosition === ReactFlowConstants.bottom
  ) {
    edgeCoordinates = getEdgeCoordinateForBottom(
      targetNode,
      edgeIndex,
      sourceNode
    );
    incomingPropertyName = 'incomingHeightBottom';
    outgoingPropertyName = 'outgoingHeightBottom';
    incomingNodesCountPropertyName = 'incomingCountBottom';
    outgoingNodesCountPropertyName = 'outgoingCountBottom';
  } else if (
    srcPosition === ReactFlowConstants.left &&
    trgPosition === ReactFlowConstants.left
  ) {
    edgeCoordinates = getEdgeCoordinateForLeft(
      sourceNode,
      edgeIndex,
      targetNode
    );
    incomingPropertyName = 'incomingHeightLeft';
    outgoingPropertyName = 'outgoingHeightLeft';
    incomingNodesCountPropertyName = 'incomingCountLeft';
    outgoingNodesCountPropertyName = 'outgoingCountLeft';
  } else if (
    srcPosition === ReactFlowConstants.right &&
    trgPosition === ReactFlowConstants.right
  ) {
    edgeCoordinates = getEdgeCoordinateForRight(
      sourceNode,
      edgeIndex,
      targetNode
    );
    incomingPropertyName = 'incomingHeightRight';
    outgoingPropertyName = 'outgoingHeightRight';
    incomingNodesCountPropertyName = 'incomingCountRight';
    outgoingNodesCountPropertyName = 'outgoingCountRight';
  }
  if (sourceNode) {
    sourceNode[outgoingPropertyName] = edgeCoordinates;
    sourceNode[outgoingNodesCountPropertyName] = sourceNode[
      outgoingNodesCountPropertyName
    ]
      ? sourceNode[outgoingNodesCountPropertyName]
      : 1;
  } else {
    config.edgeHeightConfig[sourceNodeIndex] = {
      [outgoingPropertyName]: edgeCoordinates,
      [outgoingNodesCountPropertyName]: 1
    };
  }
  if (targetNode) {
    targetNode[incomingPropertyName] = edgeCoordinates;
    targetNode[incomingNodesCountPropertyName] = targetNode[
      incomingNodesCountPropertyName
    ]
      ? targetNode[incomingNodesCountPropertyName]
      : 1;
  } else {
    config.edgeHeightConfig[targetNodeIndex] = {
      [incomingPropertyName]: edgeCoordinates,
      [incomingNodesCountPropertyName]: 1
    };
  }
  return edgeCoordinates;
}

function getMaxLeftFromSrcHandler(sourceHandlers, position) {
  const srcHandlerStyles = sourceHandlers
    ? sourceHandlers
        .map(h => {
          if (h.position === position) {
            return h.style;
          }
          return null;
        })
        .filter(h => h)
    : [];

  return getObjectWithMaxValue(srcHandlerStyles, ReactFlowConstants.left);
}

function getMaxTopFromSrcHandler(sourceHandlers, position) {
  const srcHandlerStyles = sourceHandlers
    ? sourceHandlers
        .map(h => {
          if (h.position === position) {
            return h.style;
          }
          return null;
        })
        .filter(h => h)
    : [];

  return getObjectWithMaxValue(srcHandlerStyles, ReactFlowConstants.top);
}

function alignHorizontalHandlersToCenter(nodesOnly) {
  return nodesOnly.map(node => {
    const topHandlers = node.data.handlers.filter(
      h => h.position === ReactFlowConstants.top
    );
    const bottomHandlers = node.data.handlers.filter(
      h => h.position === ReactFlowConstants.bottom
    );
    let topOrigin = getStartLocationForHorizontalHandler(topHandlers.length);
    let bottomOrigin = getStartLocationForHorizontalHandler(
      bottomHandlers.length
    );
    const newTopHandlers = updateHorizontalHandlersByStartPoint(
      topHandlers,
      topOrigin
    );
    const newBottomHandlers = updateHorizontalHandlersByStartPoint(
      bottomHandlers,
      bottomOrigin
    );

    return {
      ...node,
      data: {
        ...node.data,
        label: node.data.label,
        handlers: [...newTopHandlers, ...newBottomHandlers]
      }
    };
  });
}

function alignVerticalHandlersToCenter(nodesOnly) {
  return nodesOnly.map(node => {
    const leftHandlers = node.data.handlers.filter(
      h => h.position === ReactFlowConstants.left
    );
    const rightHandlers = node.data.handlers.filter(
      h => h.position === ReactFlowConstants.right
    );
    let leftOrigin = getStartLocationForVerticalHandler(leftHandlers.length);
    let rightOrigin = getStartLocationForVerticalHandler(rightHandlers.length);
    const newLeftHandlers = updateVerticalHandlersByStartPoint(
      leftHandlers,
      leftOrigin
    );
    const newRightHandlers = updateVerticalHandlersByStartPoint(
      rightHandlers,
      rightOrigin
    );

    return {
      ...node,
      data: {
        ...node.data,
        label: node.data.label,
        handlers: [...newRightHandlers, ...newLeftHandlers]
      }
    };
  });
}

function updateHorizontalHandlersByStartPoint(handlers, startPoint) {
  return handlers.map(handle => {
    const handleData = {
      ...handle,
      style: {
        ...handle.style,
        left: startPoint
      }
    };
    startPoint = startPoint + config.handlerHorizontalDiff;
    return handleData;
  });
}

function updateVerticalHandlersByStartPoint(handlers, startPoint) {
  return handlers.map(handle => {
    const handleData = {
      ...handle,
      style: {
        ...handle.style,
        top: startPoint
      }
    };
    startPoint = startPoint + config.handlerVerticalDiff;
    return handleData;
  });
}

// FORMULA TO FIND THE POSITION OF CENTER ALIGNED HORIZONTAL FIRST HANDLER
function getStartLocationForHorizontalHandler(handlerCount) {
  return (
    (config.nodeWidth -
      config.handlerHorizontalDiff -
      (handlerCount - 2) * config.handlerHorizontalDiff) /
    2
  );
}

// FORMULA TO FIND THE POSITION OF CENTER ALIGNED VERTICAL FIRST HANDLER
function getStartLocationForVerticalHandler(handlerCount) {
  return (
    (config.nodeHeight -
      config.handlerVerticalDiff -
      (handlerCount - 2) * config.handlerVerticalDiff) /
    2
  );
}

function resetCoordinates() {
  config.edgeHeightTop = 0;
  // 10 is extra margin added for bottom edge separation from node
  config.edgeHeightBottom = config.nodeHeight + 10;
  config.edgeHeightLeft = 0;
  config.edgeHeightRight = config.nodeWidth + 2;
}

function getObjectWithMaxValue(list, property) {
  const maxItem = list.reduce(
    (prev, current) => (prev[property] > current[property] ? prev : current),
    0
  );

  return maxItem ? maxItem[property] : 0;
}
