import styled from 'styled-components';
import React from 'react';

const CheckboxContainer = styled.div`
  display: flex;
  vertical-align: middle;
  align-items: center;
`;
export const CheckboxLabel = styled.label`
  display: inline-block;
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '300')};
  margin-left: ${props => (props.leftMargin ? props.leftMargin : '5px')};
  margin-right: ${props => (props.rightMargin ? props.rightMargin : '10px')};
  text-overflow: ${props =>
    props.textOverflow ? props.textOverflow : 'ellipses'};
`;
const Icon = styled.svg`
  fill: none;
  stroke: white;
  display: flex;
  stroke-width: 5px;
  justify-content: center;
`;

const HiddenCheckbox = styled.input.attrs(props => ({
  type: 'checkbox',
  disabled: props.disabled === 'true' ? props.disabled : ''
}))`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 10px;
  height: 10px;
  cursor: pointer;
  background-color: ${props =>
    props.checked ? props.theme.colorPalette.SECONDARY_DARK : 'transparent'};
  border-radius: 3px;
  transition: all 150ms;
  border: 1px solid
    ${props =>
      props.checked
        ? props.theme.colorPalette.SECONDARY_DARK
        : props.theme.colorPalette.PRIMARY_DARK};

  &:hover {
    border: 1px solid ${props => props.theme.colorPalette.SECONDARY_DARK};
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;

const StyledCheckboxDisabled = styled.div`
  display: inline-block;
  width: 10px;
  height: 10px;
  background: ${props =>
    props.checked ? props.theme.colorPalette.PRIMARY_DARK : 'transparent'}
  border-radius: 3px;
  opacity:0.5;
  transition: all 150ms;
border:1px solid ${props =>
  props.checked
    ? props.theme.colorPalette.PRIMARY_LIGHTER
    : props.theme.colorPalette.PRIMARY_LIGHTER};
  
  &:hover {
    border:1px solid ${props => props.theme.colorPalette.PRIMARY_LIGHTER};
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')}
  }
`;

const RenderStyledCheckbox = (disabled, checked) => {
  if (disabled === 'true') {
    return (
      <StyledCheckboxDisabled checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckboxDisabled>
    );
  } else {
    return (
      <StyledCheckbox checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    );
  }
};
export const CheckBox = ({ checked = false, onChange, ...props }) => {
  return (
    <label>
      <CheckboxContainer className={props.className && props.className}>
        <HiddenCheckbox
          checked={checked}
          {...props}
          onChange={event => {
            onChange(event, props.option && props.option.name);
          }}
        />
        {props.disabled ? (
          RenderStyledCheckbox(props.disabled, checked)
        ) : (
          <StyledCheckbox checked={checked}>
            <Icon viewBox="0 0 24 24">
              <polyline points="20 6 9 17 4 12" />
            </Icon>
          </StyledCheckbox>
        )}

        {props.label && (
          <CheckboxLabel title={props.title && props.title}>
            {props.label}
          </CheckboxLabel>
        )}
      </CheckboxContainer>
    </label>
  );
};
