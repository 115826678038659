import React from 'react';
import { Box } from '../Box';
import { RadioButton } from './RadioButton';

export const RadioButtonGroup = ({
  options,
  value,
  onEdit,
  direction = 'column'
}) => {
  const onValueChange = (event, changedValue) => {
    onEdit([changedValue]);
  };

  return (
    <Box direction={direction}>
      {options &&
        options.map((option, index) => {
          return (
            <Box position="relative" key={index} padding="5px 0px">
              <RadioButton
                key={index}
                option={option}
                onChange={onValueChange}
                checked={value && value === option.name}
                title={option.name}
                label={option.displayName.length > 0 && option.displayName}
              />
            </Box>
          );
        })}
    </Box>
  );
};
