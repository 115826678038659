import React from 'react';
import { Enums } from '../../../Enums';
import InstanceActions from '../InstanceActions';
import { EditAction } from '../InstanceActions/EditAction';

export const ProtrakGridActions = ({ row, reload, relatedTypeName }) => {
  const availableActions = () => {
    let actions = [];
    if (row.allowedOperations && row.allowedOperations.length) {
      if (row.allowedOperations.includes(Enums.AllowedOperations.Edit)) {
        actions.push({
          actionType: Enums.AllowedOperations.Edit,
          actionRenderer: (
            <EditAction
              key="edit"
              typeName={row.instanceTypeName || relatedTypeName}
              instanceId={row.id || row.relatedInstanceId}
              type={Enums.AllowedOperations.Edit}
            />
          )
        });
      }
      if (row.allowedOperations.includes(Enums.AllowedOperations.Promote)) {
        actions.push({
          actionType: Enums.AllowedOperations.Promote,
          actionsData: {
            allowedActions: row.allowedActions,
            instanceId: row.id,
            instanceName: row.name,
            successCallback: reload,
            instanceTypeName: row.instanceTypeName
          }
        });
      }
      if (row.allowedOperations.includes(Enums.AllowedOperations.Delete)) {
        actions.push({
          actionType: Enums.AllowedOperations.Delete,
          actionsData: {
            promiseParams: {
              instanceId: row.id
            },
            message: `Are you sure you want to delete "${row.name}"?`,
            successCallback: reload
          }
        });
      }
    }

    return actions;
  };

  return <InstanceActions isActionsOpen={false} actions={availableActions()} />;
};

export default ProtrakGridActions;
