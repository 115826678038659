import React from 'react';
import NumberInput from '../../Components/Forms/NumberInput';
import { Enums } from '../../Enums';
import { Prefix, Suffix, PrefixForInput, SuffixForInput } from './prefixSuffix';
import { TextArea, Box } from '../../lib/components';
import { convertToLocaleString, useSettingsContext } from '../../Core';

export const NumericAttribute = config => {
  const getValue = value => {
    if (!value) {
      return null;
    }
    if (!isNaN(parseFloat(value)) && typeof parseFloat(value) === 'number') {
      return value;
    }
    if (value.numericValue || value.numericValue === 0) {
      return value.numericValue;
    }
    return null;
  };

  const getDefaultValue = () => {
    return {
      numericValue: config.defaultValue ? config.defaultValue[0] : null
    };
  };

  const renderAttributeValue = value => {
    const val = getValue(value);
    if (!val && val !== 0) {
      return <>{'-'}</>;
    }
    return (
      <RenderAttributeLocaleStringValue
        prefix={config.prefix}
        suffix={config.suffix}
        value={val}
      />
    );
  };

  const renderAttributeInput = (value, onEdit) => {
    return (
      <Box>
        <PrefixForInput value={config.prefix} />
        <NumberInput
          value={getValue(value)}
          onEdit={newValue => onEdit({ numericValue: newValue })}
          placeholder={config.placeholder}
        />
        <SuffixForInput value={config.sufix} />
      </Box>
    );
  };

  const renderFilterAttributeInput = (
    value,
    onEdit,
    isWorksheet,
    isSecondvalue
  ) => {
    if (isSecondvalue) {
      return (
        <NumberInput
          value={getValue(value)}
          onEdit={newValue => onEdit({ numericValue: newValue })}
          placeholder={config.placeholder}
        />
      );
    }
    return (
      <TextArea
        value={getValue(value)}
        onEdit={onEdit}
        style={{ fontWeight: 'bold' }}
      />
    );
  };

  const filterOptions = [
    { displayName: 'Between', name: Enums.Conditions.Between },
    {
      displayName: 'Less Than or Equal',
      name: Enums.Conditions.LessThanOrEqual
    },
    { displayName: 'Less Than', name: Enums.Conditions.LessThan },
    {
      displayName: 'Greater Than or Equal',
      name: Enums.Conditions.GreaterThanOrEqual
    },
    { displayName: 'Greater Than', name: Enums.Conditions.GreaterThan },
    { displayName: 'Equals', name: Enums.Conditions.Equals },
    { displayName: 'Is Empty', name: Enums.Conditions.IsEmpty },
    { displayName: 'Is Not Empty', name: Enums.Conditions.IsNotEmpty },
    { displayName: 'In', name: Enums.Conditions.In }
  ];

  const evaluateCondition = (
    condition,
    valueObj,
    compareToValueStr,
    secondValueStr
  ) => {
    const value = getValue(valueObj);
    const compareToValue = parseInt(compareToValueStr, 10);
    const compareToValuetwo = parseInt(secondValueStr, 10);
    switch (condition) {
      case Enums.Conditions.Equals:
        return value === compareToValue;
      case Enums.Conditions.GreaterThanOrEqual:
        return value >= compareToValue;
      case Enums.Conditions.LessThan:
        return value < compareToValue;
      case Enums.Conditions.LessThanOrEqual:
        return value <= compareToValue;
      case Enums.Conditions.GreaterThan:
        return value > compareToValue;
      case Enums.Conditions.IsNotEmpty:
        return Boolean(value);
      case Enums.Conditions.IsEmpty:
        return !value;
      case Enums.Conditions.In:
        const comparearray = compareToValueStr.split('\n').map(Number);
        return comparearray.includes(value);
      case Enums.Conditions.Between:
        return value > compareToValue && value < compareToValuetwo;
      default:
        return false;
    }
  };

  const defaultSortOrder = 'Ascending';

  return {
    renderAttributeValue,
    renderAttributeInput,
    renderFilterAttributeInput,
    filterOptions,
    getValue,
    defaultSortOrder,
    getDefaultValue,
    evaluateCondition
  };
};

const RenderAttributeLocaleStringValue = ({ prefix, suffix, value }) => {
  const { settings } = useSettingsContext();
  return (
    <>
      <Prefix value={prefix} />
      {convertToLocaleString(value, settings.locale)}
      <Suffix value={suffix} />
    </>
  );
};
