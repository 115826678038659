import styled from 'styled-components';

export const H2 = styled.h2`
  font-size: ${props => props.theme.fontVariants.h2.size};
  line-height: ${props =>
    props.lineHeight
      ? props.lineHeight
      : props.theme.fontVariants.h2.lineHeight};
  color: ${props =>
    props.color
      ? props.theme.colorPalette[props.color]
      : props.theme.fontVariants.h2.color};
  display: ${props => (props.display ? props.display : ' inline-block')};
  margin: ${props => (props.margin ? props.margin : ' 0px')};
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
`;
