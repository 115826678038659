import { useProtrakApi } from '../utils';

export const useTokens = () => {
  const { state, run } = useProtrakApi({ requestConfig: getPermanentToken });
  return { state, run };
};
export const useCreateToken = () => {
  const { state, run } = useProtrakApi(
    {
      requestConfig: createPermanentToken
    },
    { defer: true }
  );
  return { state, run };
};

export const useRevokeTokenAccess = () => {
  const { state, run } = useProtrakApi(
    { requestConfig: removeTokenAccess },
    { defer: true }
  );
  return { state, run };
};

const getPermanentToken = () => {
  const tokenUrl = '/users/tokens';
  return {
    endpoint: tokenUrl, config: {
      params: { skip: 0, take: 100, sortBy: 'Created' }
    }
  };
};
const removeTokenAccess = ({ accessTokenId }) => {
  const tokenUrl = '/users/tokens?accessTokenId=' + accessTokenId;
  return {
    endpoint: tokenUrl, config: {
      method: 'DELETE'
    }
  };
};
const createPermanentToken = payload => {
  const createUrl = '/users/tokens';
  return {
    endpoint: createUrl, config: {
      method: 'POST',
      data: payload
    }
  };
};
