import React from 'react';
import { H2, Grid, TitleOnBorder, Box } from '../../../lib/components';
import { useDeviceType, DeviceTypes } from '../../../UseDeviceType';
export const Section = ({
  showTitle,
  showBorder,
  displayName,
  name,
  children,
  sectionHeight,
  defaultHeight = null
}) => {
  const currentDeviceType = useDeviceType();
  return (
    <Box
      direction={'column'}
      paddingTop={'1rem'}
      paddingBottom={'1.2rem'}
      borderBottom={showBorder ? '1px solid var(--primary-lighter)' : ''}
      width="100%"
    >
      {showTitle && (
        <TitleOnBorder
          padding={'0 0 1rem 1rem'}
          height={showTitle ? '2.5rem' : ''}
          lineHeight={showTitle ? '2rem' : ''}
          display={'flex'}
          alignItems={'center'}
        >
          <H2 display={'block'} color={'PRIMARY'} fontWeight={700}>
            {displayName}
          </H2>
        </TitleOnBorder>
      )}
      <Grid
        columns={
          currentDeviceType === DeviceTypes.mobile ||
          currentDeviceType === DeviceTypes.tablet
            ? ['auto']
            : ['repeat(auto-fit, minmax(250px, 1fr))']
        }
        areas={[['auto']]}
        rows={['auto']}
        minHeight={defaultHeight ? null : getMinHeight()}
        maxHeight={defaultHeight ? null : getMaxHeight()}
        height={getHeight()}
        overflow={
          sectionHeight && !currentDeviceType === DeviceTypes.mobile
            ? { overflowX: 'hidden', overflowY: 'auto' }
            : null
        }
        gridgap={'1.2rem'}
        paddingLeft={'1rem'}
        paddingRight={'1rem'}
      >
        {children}
      </Grid>
    </Box>
  );

  function getMinHeight() {
    return sectionHeight > '300px' ? '300px' : sectionHeight;
  }

  function getMaxHeight() {
    if (!sectionHeight) {
      return null;
    }
    return currentDeviceType === DeviceTypes.mobile ||
      currentDeviceType === DeviceTypes.tablet
      ? null
      : sectionHeight;
  }

  function getHeight() {
    if (!sectionHeight) {
      return defaultHeight;
    }
    return currentDeviceType === DeviceTypes.mobile ||
      currentDeviceType === DeviceTypes.tablet
      ? '100%'
      : null;
  }
};
