import React from 'react';
import { Prompt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const AlertBox = ({ show = false }) => {
  const { t } = useTranslation('translationsNm');

  return <Prompt when={show} message={t('ALERTBOX_TITLE')} />;
};

export default AlertBox;
