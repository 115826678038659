import React from 'react';
import { useRouter } from './useRouter';
import { Button, ButtonEnums, Box } from '../../lib/components';
import { BackIcon } from '../Icons';

export function BackButton() {
  const { history } = useRouter();

  return (
    <Box
      padding={'0px 10px'}
      backgroundColor={'SURFACE'}
      alignItems="center"
      height="100%"
    >
      <Button
        appearance={ButtonEnums.Appearance.Transparent}
        onClick={history.goBack}
      >
        <BackIcon />
      </Button>
    </Box>
  );
}
