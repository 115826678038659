import styled from 'styled-components';

export const H5 = styled.h5`
  font-size: ${props => props.theme.fontVariants.h5.size};
  line-height: ${props => props.theme.fontVariants.h5.lineHeight};

  color: ${props =>
    props.color
      ? props.theme.colorPalette[props.color]
      : props.theme.fontVariants.h5.color};
  display: ${props => (props.display ? props.display : ' inline-block')};
  margin: ${props => (props.margin ? props.margin : ' 0px')};
  margin-left: ${props => props.marginLeft};
  margin-bottom: ${props => props.marginBottom};
  margin-top: ${props => props.marginTop};
  padding-left: ${props => props.paddingLeft};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 'bold')};
  &:hover {
    color: ${props =>
      props.color
        ? props.theme.colorPalette[props.hoverColor]
        : props.theme.fontVariants.h5.hoverColor};
  }
`;
