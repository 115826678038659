import { Box } from '../Box';
import { CheckBox } from '../CheckboxGroup/CheckBox';

export const Boolean = ({ value, onEdit }) => {
  const onValueChange = event => {
    onEdit(event.target.checked);
  };

  return (
    <Box position="relative" padding="5px 0px">
      <CheckBox checked={value} onChange={event => onValueChange(event)} />
    </Box>
  );
};
