import { InstanceCreateContextProvider } from '../../../Contexts/InstanceCreateContextProvider';
import { CreateLayoutPopup } from './CreateLayoutPopup';
import {
  useTypeLayoutConfig,
  CONFIG_TYPE,
  useInstanceDetailsForFilter
} from '../../../Core';
import { useEffect, useState } from 'react';
import { isEmpty, isEmptyGuid } from '../../../Core';
import {
  Container,
  Spinner,
  H3,
  Grid,
  Box,
  Button,
  ButtonEnums,
  Modal
} from '../../../lib/components';
import Skeleton from 'react-loading-skeleton';
import { CancelIcon } from '../../Icons';
export const CreateInstance = props => {
  const {
    instanceTypeName,
    onInstanceCreated,
    onClose,
    directRelatedInstance,
    isLinkLoading,
    originalInstanceTypeName,
    instanceId,
    instanceName,
    relationTypeName,
    saveSuccessCallback,
    successCallback
  } = props;

  const [isCreateLinkSuccess, setIsCreateLinkSuccess] = useState(false);

  const config = useTypeLayoutConfig(instanceTypeName, CONFIG_TYPE.CREATE);
  const [
    directRelatedInst,
    runDirectRelatedInst
  ] = useInstanceDetailsForFilter();

  useEffect(() => {
    if (
      directRelatedInstance &&
      directRelatedInstance.instanceId !== null &&
      !directRelatedInst.isFulfilled
    ) {
      runDirectRelatedInst({
        id: directRelatedInstance.instanceId,
        attributes: ['Name']
      });
    }
  }, [
    directRelatedInst.isFulfilled,
    directRelatedInstance,
    runDirectRelatedInst
  ]);

  const showSpinner =
    config.isLoading ||
    isLinkLoading ||
    !config.data ||
    directRelatedInst.isLoading;

  if (showSpinner) {
    return (
      <Modal
        children={
          <Container>
            <Spinner />
          </Container>
        }
        open={true}
        background
      />
    );
  }

  const referenceAttributes = getReferenceAttributesFromConfig(config.data);

  let defaultValues = {};

  referenceAttributes &&
    referenceAttributes.forEach(r => {
      if (directRelatedInstance) {
        if (
          r.relationTypeName === directRelatedInstance.relationTypeName &&
          directRelatedInstance.typeName === r.relatedTypeName
        ) {
          defaultValues[r.attributeName] = {
            name: r.attributeName,
            type: r.attributeType,
            canUpdate: false,
            referenceValues: [
              {
                id: directRelatedInstance.instanceId,
                name: directRelatedInst.data.name
              }
            ]
          };
        }
      } else if (
        r.relationTypeName === relationTypeName &&
        originalInstanceTypeName === r.relatedTypeName
      ) {
        defaultValues[r.attributeName] = {
          name: r.attributeName,
          type: r.attributeType,
          canUpdate: false,
          referenceValues: [{ id: instanceId, name: instanceName }]
        };
      }
    });

  const onSuccess = createdInstance => {
    //when reference attribute of instance is already present in create layout, no need to call link API separately
    if (!isEmpty(defaultValues) && !isCreateLinkSuccess) {
      successCallback([createdInstance]);
      setIsCreateLinkSuccess(true);
    } else {
      onInstanceCreated && onInstanceCreated([createdInstance]);
    }
  };

  const Title = () => {
    if (!directRelatedInst.isLoading && directRelatedInst.data) {
      return (
        // Remove the condition once server side changes complete for typeSingularName
        <H3 lineHeight="1.8rem">{`Add ${config.data.typeSingularName} to ${
          directRelatedInst.data.name
        } (${
          directRelatedInst.data.typeSingularName
            ? directRelatedInst.data.typeSingularName
            : directRelatedInst.data.instanceTypeName
        }) `}</H3>
      );
    }
    if (
      !directRelatedInstance ||
      (directRelatedInstance && !isEmptyGuid(directRelatedInstance))
    ) {
      return (
        <H3 lineHeight="1.8rem">{`Add ${
          config.data.typeSingularName
        } to ${instanceName || ''} (${originalInstanceTypeName})`}</H3>
      );
    }
    return <Skeleton />;
  };
  const header = () => {
    return (
      <Grid
        columns={['4fr', '1fr']}
        padding="10px 10px 0px"
        borderBottom="0.5px solid var(--primary-lighter)"
        areas={[['topleft', 'topright']]}
        rows={['auto']}
        style={{ alignItems: 'center', padding: '10px' }}
      >
        <Title />
        <Box justifySelf="end">
          <Button
            appearance={ButtonEnums.Appearance.TransparentWithHover}
            onClick={onClose}
            size={ButtonEnums.Size.Small}
            disabled={isLinkLoading}
          >
            <CancelIcon />
          </Button>
        </Box>
      </Grid>
    );
  };

  return (
    <InstanceCreateContextProvider
      config={config.data}
      defaultValues={defaultValues}
    >
      <CreateLayoutPopup
        header={header()}
        successButtonText="Done"
        instanceTypeName={instanceTypeName}
        onInstanceCreated={onSuccess}
        config={config.data}
        isDisabled={showSpinner}
        saveSuccessCallback={saveSuccessCallback}
      />
    </InstanceCreateContextProvider>
  );
};

const getReferenceAttributesFromConfig = config => {
  const { sections } = config;
  if (!sections || sections.length === 0) {
    return [];
  }
  let attributes = new Set();
  sections.forEach(section => {
    section.widgets.forEach(widget => {
      widget.fields.forEach(field => {
        if (
          field.fieldType === 'Attribute' &&
          field.attributeType === 'Reference'
        ) {
          attributes.add(field);
        }
      });
    });
  });
  return [...attributes];
};
