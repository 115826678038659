import { useRef, useLayoutEffect, useState } from 'react';

export function useScrollPosition() {
  const contentRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const updateScrollPosition = () => {
    if (contentRef.current) {
      setScrollPosition(contentRef.current.scrollTop);
    }
  };

  useLayoutEffect(() => {
    if (scrollPosition && contentRef.current) {
      contentRef.current.scrollTo(0, scrollPosition);
    }
  });

  return [contentRef, updateScrollPosition];
}
