import React, { useState } from 'react';
import { isEmpty, validateFiles } from '../../../Core';
import { FileDropZone } from '../../../Components/FileDropZone';
import { Box, H5 } from '../../../lib/components';

export const AttachmentUploader = ({ isMultiselect = false, onFileSelect }) => {
  const [fileList, setFileList] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const onFileSelected = files => {
    setFileList(files);
    onFileSelect && onFileSelect(files);
  };

  const onFileRejected = file => {
    let errorMsg = validateFiles(file);
    setErrorMessage(errorMsg);
  };

  return (
    <FileDropZone
      onFileSelect={onFileSelected}
      isMultiSelect={isMultiselect}
      onFileRejected={onFileRejected}
      isUploadPermitted
      showBorder
      showText
    >
      {() => (
        <Box overflow="auto" justifyContent="center">
          {!isEmpty(fileList) &&
            fileList.map((file, index) => {
              return (
                <H5
                  key={index}
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '100px'
                  }}
                >
                  {file.name} &nbsp;
                </H5>
              );
            })}
          <H5 color={'ERROR'}>{errorMessage}</H5>
        </Box>
      )}
    </FileDropZone>
  );
};
