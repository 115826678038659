import React from 'react';
import Select from 'react-select';
import { customStyles } from './PicklistRenderStyling';

export const Dropdown = ({
  defaultValues,
  picklistOptions,
  isMultiselect,
  customStyle,
  onValueChange,
  isDisabled,
  components,
  menuPlacement = 'auto',
  placeholder,
  isLoading = false,
  isClearable
}) => (
  <Select
    menuPortalTarget={document.body}
    value={defaultValues}
    options={picklistOptions}
    isMulti={isMultiselect}
    styles={{ ...customStyles, ...customStyle }}
    onChange={onValueChange}
    placeholder={placeholder || 'Select an option'}
    isDisabled={isDisabled}
    components={components}
    menuPlacement={menuPlacement}
    isLoading={isLoading}
    isClearable={isClearable}
  />
);

export default Dropdown;
