import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  ButtonEnums,
  Box,
  H2,
  Modal,
  CloneModalContainer,
  Container,
  Spinner
} from '../../../../lib/components';
import InstanceLayoutPopup from '../../../Forms/InstanceLayoutPopup/InstanceLayoutPopup';
import { isEmpty, useConnectInstance } from '../../../../Core';
import InstanceEditContext from '../../../../Contexts/InstaceEditContext';
import { CancelIcon } from '../../../Icons';
import { usePromoteOperation } from '../../../Common/PromoteActions/usePromoteOperation';
import { ErrorModal } from '../../../../shared/Components';
import { useRouter } from '../../../Page';

export const CloneLayout = ({
  headerTitle,
  successButtonText,
  instanceTypeName,
  config,
  isCreateLinkSuccess,
  relationTypeName,
  relationDirection,
  onInstanceCreated,
  saveSuccessCallback,
  onSuccess,
  onClose
}) => {
  const { instanceEditState, createInstance } = useContext(InstanceEditContext);
  const { setIsModalOpen, onCancel, isModalOpen } = usePromoteOperation();
  const [instLinkHook, linkInstance] = useConnectInstance();
  const [isLinkInProgress, setLinkInProgress] = useState(false);
  const { match } = useRouter();
  const { instanceId } = match.params;

  const { isFulfilled, isError, isLoading } = instLinkHook;

  useEffect(() => {
    if (isFulfilled) {
      onSuccess();
    }
  }, [isFulfilled, onSuccess]);
  if (isError) {
    return <ErrorModal state={instLinkHook} />;
  }
  if (isLoading) {
    return (
      <Modal
        children={
          <Container>
            <Spinner />
          </Container>
        }
        open={true}
        background
      />
    );
  }

  const createRelatedInstance = () => {
    if (isEmpty(instanceEditState.invalidAttributes)) {
      createInstance({ typeName: instanceTypeName });
      setIsModalOpen(true);
    }
  };
  const onCreateInstanceSuccess = createdInstance => {
    if (
      createdInstance &&
      !isCreateLinkSuccess &&
      !isLinkInProgress &&
      relationTypeName &&
      relationDirection
    ) {
      linkInstance({
        instanceId: instanceId,
        relationTypeName,
        relationDirection,
        destinationInstanceId: createdInstance.id
      });
      setLinkInProgress(true);
    } else {
      onInstanceCreated(createdInstance);
    }
  };

  const renderFooter = () => {
    return (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '10px',
          borderTop: '1px solid var(--primary-lighter)',
          position: 'absolute',
          width: '100%',
          bottom: '0'
        }}
      >
        <Button
          appearance={ButtonEnums.Appearance.Primary}
          text={successButtonText}
          onClick={createRelatedInstance}
          size={ButtonEnums.Size.Medium}
          style={{ padding: '2px' }}
        />
      </Box>
    );
  };

  const renderContent = () => {
    return (
      <>
        <div style={{ height: '100%', width: '100%', position: 'relative' }}>
          {modalHeader(headerTitle, onClose)}
          <Box
            direction={'column'}
            style={{ overflow: 'auto', maxHeight: '80vh' }}
          >
            <InstanceLayoutPopup
              config={config}
              onSuccess={createdInstance =>
                onCreateInstanceSuccess(createdInstance)
              }
              isModalOpen={isModalOpen}
              onCancel={() => onCancel()}
              saveSuccessCallback={() => {
                saveSuccessCallback();
              }}
            />
          </Box>
          {renderFooter()}
        </div>
      </>
    );
  };

  return (
    <Modal
      open={true}
      background
      children={<CloneModalContainer children={renderContent()} />}
    />
  );
};

const modalHeader = (headerTitle, onClose) => {
  return (
    <Box
      justifyContent="space-between"
      borderBottom="1px solid var(--primary-lighter)"
      padding="10px"
      alignItems="center"
    >
      <H2>{headerTitle}</H2>
      <Box>
        <Button
          appearance={ButtonEnums.Appearance.TransparentWithHover}
          onClick={() => onClose()}
          size={ButtonEnums.Size.Small}
        >
          <CancelIcon />
        </Button>
      </Box>
    </Box>
  );
};
