import React from 'react';
import { TextBox } from './TextBox';

export const Currency = ({
  allowDecimal = true,
  value,
  onEdit,
  placeholder
}) => {
  return (
    <TextBox
      numericOnly
      allowDecimal={allowDecimal}
      value={value}
      onEdit={onEdit}
      placeholder={placeholder}
    />
  );
};
