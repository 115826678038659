import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback
} from 'react';
import { ai, getAppInsights } from '../Components/AppInsights/TelemetryService';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { useRouter } from '../Components/Page';
import { isEmpty } from '../Core';

const PROTRAK_EVENTS = {
  LOGIN: 'LOGIN',
  PROMOTE: 'PROMOTE',
  BULKPROMOTE: 'BULKPROMOTE',
  LOGOUT: 'LOGOUT',
  FILTER: 'FILTER'
};

const AppInsightsContext = createContext();

const AppInsightsInstrumentationKey = process.env.REACT_APP_INSTRUMENTATION_KEY;

const AppInsightsContextProvider = ({ children }) => {
  const [initialized, setInitialized] = useState(false);
  const { history } = useRouter();
  const [tenantDetails, setTenantDetails] = useState({});
  let appInsights = getAppInsights();

  useEffect(() => {
    if (!initialized && Boolean(AppInsightsInstrumentationKey)) {
      ai.initialize(AppInsightsInstrumentationKey, history);
      setInitialized(true);
    }
  }, [history, initialized]);

  const trackException = useCallback(
    (error, pageTitle, customWidgetName) => {
      if (initialized) {
        let customProps = {};
        if (customWidgetName) {
          customProps['customWidgetTitle'] = customWidgetName;
        }
        if (pageTitle) {
          customProps['pageTitle'] = pageTitle;
        }
        if (tenantDetails && !isEmpty(tenantDetails)) {
          customProps['tenantId'] = tenantDetails.id;
        }
        appInsights.trackException({
          error: new Error(error.stack),
          severityLevel: SeverityLevel.Error,
          properties: customProps
        });
      }
    },
    [appInsights, initialized, tenantDetails]
  );

  const trackEvent = useCallback(
    (customEvent) => {
      if (initialized) {
        let trackInfo = {};
        if (tenantDetails && !isEmpty(tenantDetails)) {
          trackInfo['tenantId'] = tenantDetails.id;
        }
        appInsights.trackEvent({ name: PROTRAK_EVENTS[customEvent] }, trackInfo);
      }
    },
    [appInsights, initialized, tenantDetails]
  );

  return (
    <AppInsightsContext.Provider value={{ trackException, trackEvent, setTenantDetails }}>
      {children}
    </AppInsightsContext.Provider>
  );
};

const useAppInsightsContext = () => useContext(AppInsightsContext);

export { AppInsightsContextProvider, useAppInsightsContext, PROTRAK_EVENTS };
