import React, { useState } from 'react';
import classnames from 'classnames';
import classes from './Relation.module.css';
import { Container, Spinner } from '../../../lib/components';

const ConnectInstances = React.lazy(
  async () => await import('./ConnectInstances')
);

const Loader = () => {
  return (
    <Container>
      <Spinner />
    </Container>
  );
};

const noop = () => {};

export const LinkExistingInstancesButton = ({
  config,
  relatedInstances,
  relationTypeName,
  instanceId,
  isDisabled,
  relationDirection,
  relatedTypeName,
  typeSingularName,
  relationTypeCardinality,
  successCallback,
  directRelatedInstance,
  instanceTypeName,
  instanceName,
  instanceTypePluralName,
  isPopupVisible = noop
}) => {
  const [isInstanceListPopupVisible, setInstanceListPopupVisible] = useState(
    false
  );

  const toggleInstanceListPopup = () => {
    setInstanceListPopupVisible(() => !isInstanceListPopupVisible);
    isPopupVisible(() => !isInstanceListPopupVisible);
  };

  const closeInstListPopup = performReload => {
    setInstanceListPopupVisible(false);
    isPopupVisible(false);

    if (performReload) {
      successCallback();
    }
  };

  return (
    <>
      <div
        className={classnames(classes['white-btn'], classes['link-btn'], {
          'opacity-light': isDisabled,
          'pointer-event-none': isDisabled,
          'pointer-event-auto': !isDisabled
        })}
        onClick={() => toggleInstanceListPopup()}
      >
        <i className="fas fa-link" /> Link {typeSingularName}
      </div>

      {isInstanceListPopupVisible ? (
        <React.Suspense fallback={<Loader />}>
          <ConnectInstances
            config={config}
            relatedInstances={relatedInstances}
            instanceId={instanceId}
            relationTypeName={relationTypeName}
            relationDirection={relationDirection}
            typeName={relatedTypeName}
            relationTypeCardinality={relationTypeCardinality}
            successCallback={closeInstListPopup}
            directRelatedInstance={directRelatedInstance}
            instanceTypeName={instanceTypeName}
            instanceName={instanceName}
            instanceTypePluralName={instanceTypePluralName}
          />
        </React.Suspense>
      ) : null}
    </>
  );
};
