export function dataFetchReducer(state, action) {
  if (!action || !action.type) {
    throw new Error('Invalid action passed, action.type value not available');
  }
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isLoading: true,
        isError: false,
        isFulfilled: false
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
        isFulfilled: true
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        isLoading: false,
        isFulfilled: false,
        isError: true,
        data: action.payload
      };
    default:
      throw new Error('Invalid action passed');
  }
}
