import styled from 'styled-components';
import React, { useState } from 'react';
import placeholder from '../../../Content/Images/placeholder.png';

export const StyledImage = styled.img.attrs(props => ({
  src: props.src,
  alt: props.alt
}))`
  height: ${props => (props.height ? props.height : 'auto')};
  width: ${props => (props.width ? props.width : 'auto')};
  object-fit: ${props => (props.resizeMode ? props.resizeMode : 'fill')};
  max-height: ${props => props.maxHeight || 'inherit'};
  transition: '400ms all ease';
`;

export const Image = ({ height, resizeMode, width, src, alt, style }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <StyledImage
      src={isLoaded ? src : placeholder}
      resizeMode={resizeMode}
      width={width}
      alt={alt}
      height={height}
      onLoad={() => setIsLoaded(true)}
      style={style}
    />
  );
};
