import React, { useContext } from 'react';
import InstanceEditContext from '../Contexts/InstaceEditContext';

import { Button, ButtonEnums, Box } from '../lib/components';
import { isEmpty } from '../Core';

export const InstanceInlineSaveButton = ({ attributeName }) => {
  const { instanceEditState, saveInstance } = useContext(InstanceEditContext);

  const isAttributeEdited = instanceEditState.editedValues[attributeName];
  const shouldDisable =
    !isAttributeEdited ||
    isSaveInProgress(instanceEditState) ||
    !isEmpty(instanceEditState.invalidAttributes[attributeName]);

  return (
    <SaveButton
      onClick={() => saveInstance({ showPromoteReminder: false })}
      disable={shouldDisable}
    />
  );
};

//TODO: move this to proper folder structure
export const InstaceSaveButton = ({
  showPromoteReminder = false,
  setIsModalOpen
}) => {
  const { instanceEditState, saveInstance } = useContext(InstanceEditContext);

  const shouldDisable =
    !isEdited(instanceEditState) ||
    isSaveInProgress(instanceEditState) ||
    !isEmpty(instanceEditState.invalidAttributes);

  return (
    <SaveButton
      onClick={() => {
        saveInstance({ showPromoteReminder });
        setIsModalOpen(true);
      }}
      disable={shouldDisable}
      size={'large'}
    />
  );
};

export const InstanceCreateButton = ({ typeName, setIsModalOpen }) => {
  const { instanceEditState, createInstance } = useContext(InstanceEditContext);

  const onSaveClick = () => {
    if (!isEmpty(instanceEditState.invalidAttributes)) {
      return;
    }
    setIsModalOpen(true);
    createInstance({ typeName });
  };

  const shouldDisable =
    !isEdited(instanceEditState) ||
    !isEmpty(instanceEditState.invalidAttributes) ||
    isSaveInProgress(instanceEditState);

  return (
    <SaveButton onClick={onSaveClick} size={'large'} disable={shouldDisable} />
  );
};

const SaveButton = ({ onClick, disable, size }) => {
  return (
    <Box padding={'0px 5px'} alignSelf={'center'}>
      {size === 'large' ? (
        <Button
          outline
          disabled={disable}
          icon={''}
          size={ButtonEnums.Size.Medium}
          text={'Save'}
          title="Save"
          appearance={ButtonEnums.Appearance.Primary}
          outline
          onClick={() => {
            if (!disable) {
              onClick();
            }
          }}
        >
          <i
            style={{ fontSize: 12 }}
            className="fa fa-save"
            aria-hidden="true"
          />
        </Button>
      ) : (
        <Button
          title="Save"
          outline
          appearance={ButtonEnums.Appearance.Primary}
          disabled={disable}
          size={ButtonEnums.Size.Small}
          onClick={() => {
            if (!disable) {
              onClick();
            }
          }}
        >
          <i className="fa fa-save" aria-hidden="true" />
        </Button>
      )}
    </Box>
  );
};

const isSaveInProgress = instanceEditState => {
  return instanceEditState.saveOperationState === 'pending';
};

export const isEdited = editState => {
  if (editState && editState.editedValues) {
    return Object.keys(editState.editedValues).length > 0;
  }
  return false;
};
