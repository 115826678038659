import { UserAgentApplication } from 'msal';
import { getHostname, isLocalhost } from '../../Core';

export const getMsalApp = (clientId, tenantId, redirectUri) => {
  let redirectUrl = redirectUri;
  if (isLocalhost || !redirectUri) {
    redirectUrl = getHostname();
  }
  return new UserAgentApplication({
    auth: {
      clientId: clientId,
      authority: `https://login.microsoftonline.com/${tenantId}/`,
      redirectUri: redirectUrl,
      validateAuthority: true,
      navigateToLoginRequestUrl: false
    },
    cache: {
      cacheLocation: 'sessionStorage'
    },
    system: {
      navigateFrameWait: 0
    }
  });
};
