import React from 'react';
import { AttachmentPicker } from './AttachmentPicker';

const SUPPORTED_FILE_TYPES = process.env.REACT_APP_SUPPORTED_FILE_TYPE;

export const Attachment = ({ value, onEdit, acceptTypes }) => {
  const onChanged = fileList => {
    onEdit(fileList ? fileList[0] : null);
  };

  const extensions = acceptTypes ? acceptTypes : SUPPORTED_FILE_TYPES;

  return (
    <AttachmentPicker
      fileValue={value}
      onChange={onChanged}
      acceptTypes={extensions}
    />
  );
};
