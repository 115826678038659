import { Attribute } from './Attribute';

export const isAttributeSortByDescending = (attributeName, fields) => {
  if (!fields) {
    return false;
  }
  if (!fields.length) {
    return false;
  }

  if (attributeName) {
    let attributeField = fields.find(f => f.attributeName === attributeName);

    if (!attributeField && attributeName === 'ModifiedDate') {
      return true;
    }
    let attribute = attributeField && Attribute(attributeField);
    return attribute && attribute.defaultSortOrder === 'Descending';
  }

  return null;
};
