import React, { useContext } from 'react';
import InstanceEditContext from '../../../Contexts/InstaceEditContext';
import {
  Button,
  ButtonEnums,
  Box,
  Grid,
  H3,
  Modal
} from '../../../lib/components';
import { isEmpty } from '../../../Core';
import InstanceLayoutPopup from '../../../Components/Forms/InstanceLayoutPopup/InstanceLayoutPopup';
import useTranslatedValue from '../../../Hooks/useTranslatedValue';
import { usePromoteOperation } from '../../../Components/Common/PromoteActions/usePromoteOperation';

export const RelationEditPopup = props => {
  const {
    relationDetails,
    parentInstanceId,
    config,
    editConfig,
    successCallback,
    saveSuccessCallback,
    closeCallback
  } = props;

  const save = useTranslatedValue('SAVE');
  const editRelation = useTranslatedValue('EDIT_RELATION');
  const { instanceEditState, saveRelationInstance } = useContext(
    InstanceEditContext
  );
  const { setIsModalOpen, onCancel, isModalOpen } = usePromoteOperation();

  const relationFilter = config.relationFilters
    ? config.relationFilters[config.relationFilters.length - 1]
    : {};

  let relationProperties = {
    relationId: relationDetails.relationId,
    relationTypeName: relationFilter.relationTypeName,
    sourceInstanceId:
      config.relationDirection === 'To'
        ? parentInstanceId
        : relationDetails.relatedInstanceId,
    destinationInstanceId:
      config.relationDirection === 'To'
        ? relationDetails.relatedInstanceId
        : parentInstanceId,
    direction: config.relationDirection,
    relationAttributes: instanceEditState.editedValues
  };

  const createRelatedInstance = () => {
    if (isEmpty(instanceEditState.invalidAttributes)) {
      saveRelationInstance({ relationProperties, successCallback });
      setIsModalOpen(true);
    }
  };

  const renderFooter = () => {
    return (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '10px',
          borderTop: '1px solid var(--primary-lighter)'
        }}
      >
        <Button
          appearance={ButtonEnums.Appearance.Primary}
          text={save}
          onClick={createRelatedInstance}
          size={ButtonEnums.Size.Medium}
        />
      </Box>
    );
  };

  return (
    <Modal open={true} background>
      <Grid
        columns={['1fr']}
        areas={[['top']]}
        rows={['auto', '4fr', 'auto']}
        style={{
          position: 'absolute',
          left: '15%',
          right: '15%',
          top: '10%',
          bottom: 'auto',
          margin: 'auto',
          background: 'var(--surface)',
          borderRadius: '5px',
          height: '75vh',
          overflow: 'auto',
          textAlign: 'left'
        }}
        width="auto"
      >
        <Grid
          columns={['4fr', '1fr']}
          align-items="center"
          padding="10px"
          borderBottom="0.5px solid var(--primary-lighter)"
          areas={[['topleft', 'topright']]}
          rows={['auto']}
        >
          <H3 lineHeight="1.8rem">{editRelation}</H3>
          <Box justifySelf="end">
            <Button
              appearance={ButtonEnums.Appearance.TransparentWithHover}
              onClick={closeCallback}
              size={ButtonEnums.Size.Medium}
            >
              <i className="fas fa-times" />
            </Button>
          </Box>
        </Grid>
        <InstanceLayoutPopup
          config={editConfig}
          onSuccess={successCallback}
          isModalOpen={isModalOpen}
          onCancel={() => onCancel()}
          saveSuccessCallback={() => {
            saveSuccessCallback();
          }}
        />
        {renderFooter()}
      </Grid>
    </Modal>
  );
};
