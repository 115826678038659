import { Enums } from ".";

const areFileExtensionsSupported = files => {
  const allowedFileTypes = process.env.REACT_APP_SUPPORTED_FILE_TYPE;

  let result = false;
  Array.from(files).forEach(file => {
    if (file.name) {
      result = allowedFileTypes
        ? allowedFileTypes.includes(getFileExtension(file.name))
        : true;
    }
  });
  return result;
};

const isFileSizeValid = files => {
  let result = false;
  Array.from(files).forEach(file => {
    if (file.name) {
      result = file.size > process.env.REACT_APP_FILE_SIZE_BYTES ? false : true;
    }
  });

  return result;
};

export const validateFiles = files => {
  let errorMessage = '';

  if (!areFileExtensionsSupported(files)) {
    errorMessage = 'Unsupported file format, Please select valid files.';
  } else if (!isFileSizeValid(files)) {
    errorMessage = `file size exceeds limit of ${convertbyteToMb(
      process.env.REACT_APP_FILE_SIZE_BYTES
    )} MB, Please select valid files.`;
  }

  return errorMessage;
};

const convertbyteToMb = size => {
  let sizeInput = Number(size);
  return sizeInput / 1000000;
};

export const getFileExtension = fileName => {
  return fileName && fileName.substr(fileName.lastIndexOf('.')).toLowerCase();
};


export const getFileType = fileName => {
  if (fileName && fileName.length > 0) {
    var extension = fileName
      .substr(fileName.lastIndexOf('.') + 1)
      .toLowerCase();

    switch (extension) {
      case 'pdf':
      case 'pptx':
      case 'ppt':
      case 'docx':
      case 'doc':
      case 'txt':
      case 'xlsx':
      case 'xls':
      case 'zip':
      case 'rar':
      case 'tar':
      case 'rtf':
        return Enums.FileType.Document;

      case 'png':
      case 'jpg':
      case 'gif':
      case 'bmp':
        return Enums.FileType.Picture;

      case 'wmv':
      case 'avi':
      case 'mov':
      case 'mkv':
      case 'mp4':
        return Enums.FileType.Video;

      default:
        break;
    }
  }
};
