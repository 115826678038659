import React from 'react';
import FilterGroup from './FilterGroup';
import { Enums } from '../../Enums';
import classes from '../Filter/Filter.module.css';
import classNames from 'classnames';
import { defaultSelection, generateGuid } from '../../Core';
import {
  RadioButtonGroup,
  Box,
  H3,
  Grid,
  Text,
  Button,
  ButtonEnums
} from '../../lib/components';
import useTranslatedValue from '../../Hooks/useTranslatedValue';

export const AttributeFilter = ({
  attributesFilter,
  fields,
  setInvalidExpressionPresent,
  clearAttributeFilter,
  onAttributeFilterApplied
}) => {
  const attributefilter = useTranslatedValue('ATTRIBUTE_FILTER');
  const addgroup = useTranslatedValue('ADD_GROUP');

  const filteredFields = filterFields(fields);

  const getSampleFilterGroup = attributeName => {
    return {
      id: generateGuid(),
      operator: 'None',
      attributeFilterConditions: [
        {
          attributeName: defaultSelection,
          condition: defaultSelection,
          firstValue: '',
          operator: 'None'
        }
      ]
    };
  };

  const addGroup = newOperator => {
    let attributeFilter = {};

    if (attributesFilter && attributesFilter.attributeGroups) {
      attributeFilter = { ...attributesFilter };
      let attributeGroups = attributeFilter.attributeGroups;
      let lastGroup = attributeGroups[attributeGroups.length - 1];
      lastGroup.operator =
        newOperator && newOperator.length > 0 && newOperator[0];

      attributeFilter.attributeGroups = [
        ...attributeFilter.attributeGroups,
        getSampleFilterGroup()
      ];
    } else {
      let sampleFilterGroup = getSampleFilterGroup();
      sampleFilterGroup.operator =
        newOperator && newOperator.length > 0 && newOperator[0];

      attributeFilter = {
        attributeGroups: [sampleFilterGroup, getSampleFilterGroup()]
      };
    }

    onAttributeFilterApplied(attributeFilter);
  };

  const changeGroup = (groupIndex, newOperator) => {
    let attributeFilter = { ...attributesFilter };
    let attributeGroups = attributeFilter.attributeGroups;

    attributeGroups[groupIndex].operator =
      newOperator && newOperator.length > 0 && newOperator[0];
    onAttributeFilterApplied(attributeFilter);
  };

  const onGroupDelete = grpIndex => {
    let attributeFilter = { ...attributesFilter };
    let attributeGroups = attributeFilter.attributeGroups;

    if (attributeGroups[grpIndex].operator !== 'None') {
      attributeGroups[grpIndex].operator =
        attributeGroups[grpIndex + 1].operator;
    } else {
      attributeGroups[grpIndex].operator = 'None';
    }
    attributeGroups.splice(grpIndex + 1, 1);

    onAttributeFilterApplied(attributeFilter);
  };

  const onGroupModified = (groupIndex, modifiedAttributeGroup) => {
    if (
      modifiedAttributeGroup.attributeFilterConditions &&
      modifiedAttributeGroup.attributeFilterConditions.length === 0 &&
      groupIndex !== 0
    ) {
      onGroupDelete(groupIndex - 1);
    } else if (attributesFilter && attributesFilter.attributeGroups) {
      let attributeFilter = { ...attributesFilter };
      let attributeGroups = attributeFilter.attributeGroups;

      attributeGroups[groupIndex] = modifiedAttributeGroup;
      onAttributeFilterApplied(attributeFilter);
    } else {
      onAttributeFilterApplied({ attributeGroups: [modifiedAttributeGroup] });
    }
  };

  const renderDeleteIcon = index => (
    <div
      onClick={() => onGroupDelete(index)}
      className={classNames('font-color-red')}
    >
      <i className="fas fa-trash-alt" aria-hidden="true" />
    </div>
  );

  const RenderGroupOperator = (filterGroup, groupIndex) => {
    return filterGroup.operator && filterGroup.operator !== 'None' ? (
      <div className={classes['group-partions']}>
        <RadioButtonGroup
          options={[
            { name: 'And', displayName: 'And' },
            { name: 'Or', displayName: 'Or' }
          ]}
          onEdit={e => changeGroup(groupIndex, e)}
          value={filterGroup.operator}
          direction={'inherit'}
        />
        <Box width="100%" border="0.5px solid var(--primary-lighter)" />
        <div className={classes['delete-icon']}>
          {renderDeleteIcon(groupIndex)}
        </div>
      </div>
    ) : null;
  };

  const renderAttributesGroupHeader = (
    <Grid
      columns={['repeat(5, 18%) 10%']}
      areas={[['top']]}
      rows={['auto']}
      alignContent="center"
      borderBottom="1px solid var(--primary-lighter)"
      margin="5px"
      backgroundColor="none repeat scroll 0 0 #fff"
    >
      <Box>
        <Text fontWeight={400} margin={'5px'}>
          Attribute
        </Text>
      </Box>
      <Box>
        <Text fontWeight={400} margin={'5px'}>
          Condition
        </Text>
      </Box>
      <Box>
        <Text fontWeight={400} margin={'5px'}>
          First Value
        </Text>
      </Box>
      <Box>
        <Text fontWeight={400} margin={'5px'}>
          Second Value
        </Text>
      </Box>
      <Box>
        <Text fontWeight={400} margin={'5px'}>
          Operator
        </Text>
      </Box>
      <Box className={classes['display-action']}>
        <Text fontWeight={400} margin={'5px'}>
          Actions
        </Text>
      </Box>
    </Grid>
  );

  return (
    <>
      <Grid
        columns={['4fr', '1fr']}
        areas={[['filter', 'filtertop']]}
        rows={['auto']}
        alignItems={'center'}
        background="var(--surface)"
        style={{ left: '8px' }}
        top="-8px"
        padding="0 3px"
      >
        <H3 fontWeight={500}>{attributefilter}</H3>
        <Button
          style={{
            justifySelf: 'flex-end',
            cursor: 'pointer',
            lineHeight: '1.5rem',
            color: 'var(--error)'
          }}
          size={ButtonEnums.Size.Medium}
          text={'Clear All'}
          title="Clear All"
          appearance={ButtonEnums.Appearance.SubtleLink}
          onClick={clearAttributeFilter}
        />
      </Grid>

      {renderAttributesGroupHeader}
      <div>
        {attributesFilter && attributesFilter.attributeGroups ? (
          attributesFilter.attributeGroups.map((attributeGroup, key) => {
            return (
              <div key={key}>
                <FilterGroup
                  attributeGroup={attributeGroup}
                  fields={filteredFields}
                  attributesFilter={attributesFilter}
                  onGroupModified={onGroupModified}
                  setInvalidExpressionPresent={setInvalidExpressionPresent}
                  index={key}
                  clearAttributeFilter={clearAttributeFilter}
                />
                {RenderGroupOperator(attributeGroup, key)}
              </div>
            );
          })
        ) : (
          <FilterGroup
            attributeGroup={getSampleFilterGroup()}
            fields={filteredFields}
            attributesFilter={attributesFilter}
            onGroupModified={onGroupModified}
            setInvalidExpressionPresent={setInvalidExpressionPresent}
            index={0}
            clearAttributeFilter={clearAttributeFilter}
          />
        )}
      </div>

      <Box alignItems="center" padding="6px 0" marginTop="15px">
        <Button
          title={addgroup}
          text={addgroup}
          size={ButtonEnums.Size.Small}
          appearance={ButtonEnums.Appearance.SubtleLink}
          onClick={() => {
            addGroup(['And']);
          }}
        >
          <Text lineHeight={'1.5rem'} color={'SECONDARY'}>
            <i className="fa fa-plus" aria-hidden="true" />
          </Text>
        </Button>
      </Box>
    </>
  );
};

export default AttributeFilter;

const filterFields = unFilteredFields => {
  return (
    unFilteredFields &&
    unFilteredFields.filter(field => {
      return (
        field.attributeType !== Enums.AttributeTypes.Attachment &&
        field.fieldType !== Enums.FieldType.Actions &&
        field.fieldType !== Enums.FieldType.State &&
        (field.value = field.attributeName)
      );
    })
  );
};
