import qs from 'qs';

export const toQueryString = obj => {
  let stringifiedQuery = qs.stringify(obj, {
    allowDots: true,
    encode: true
  });
  return stringifiedQuery;
};

export const parseQueryString = queryString => {
  return qs.parse(queryString);
};
