import React from 'react';
import ReactDOM from 'react-dom';
import classes from './Modal.module.css';
import classnames from 'classnames';
import { Box } from '../Box';
import { usePortal } from './Modal';

export function TopAlignedModal({ children, onClose, open, background }) {
  const target = usePortal();

  if (!open) {
    return null;
  }

  const renderContent = () => {
    return (
      <Box
        className={classnames(
          classes['display-top-aligned-modal'],
          background ? classes['dark-background'] : null
        )}
        paddingTop="1rem"
        onClick={onClose}
      >
        <Box className={classnames(classes['white-background'])}>
          {children}
        </Box>
      </Box>
    );
  };

  return ReactDOM.createPortal(renderContent(), target);
}
