import React from 'react';
import { useTypeLayoutConfig, CONFIG_TYPE } from '../../../../Core';
import {
  Modal,
  CloneModalContainer,
  CloneLoadingState
} from '../../../../lib/components';
import { CloneInstanceDetails } from './CloneInstanceDetails';

export const CloneInstance = ({
  instanceId,
  typeName,
  relationTypeId,
  relationTypeName,
  relationDirection,
  onSuccess,
  onClose
}) => {
  const config = useTypeLayoutConfig(typeName, CONFIG_TYPE.CREATE);

  if (config.isLoading || !config.data) {
    return (
      <Modal
        open={true}
        background
        children={<CloneModalContainer children={<CloneLoadingState />} />}
      />
    );
  }

  if (config.data) {
    return (
      <CloneInstanceDetails
        instanceTypeName={typeName}
        instanceId={instanceId}
        relationTypeId={relationTypeId}
        relationTypeName={relationTypeName}
        relationDirection={relationDirection}
        onInstanceCreated={createdInstance => onClose(createdInstance)}
        onClose={onClose}
        saveSuccessCallback={onClose}
        onSuccess={onSuccess}
        config={config.data}
      />
    );
  }
  return null;
};
