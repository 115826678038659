export const isSingleSelect = (cardinality, direction) => {
  let result = false;

  if (cardinality === RELATION_CARDINALITY_CONTRACT.OneToOne) {
    result = true;
  }

  if (direction === RELATION_DIRECTION_CONTRACT.To) {
    if (cardinality === RELATION_CARDINALITY_CONTRACT.ManyToOne) {
      result = true;
    }
  } else {
    if (cardinality === RELATION_CARDINALITY_CONTRACT.OneToMany) {
      result = true;
    }
  }

  return result;
};

const RELATION_DIRECTION_CONTRACT = {
  To: 'To',
  From: 'From'
};

const RELATION_CARDINALITY_CONTRACT = {
  OneToOne: 'OneToOne',
  OneToMany: 'OneToMany',
  ManyToOne: 'ManyToOne',
  ManyToMany: 'ManyToMany'
};
