import React, { useEffect } from 'react';
import { useScript, useScriptPreview } from '../Common/UseScript/UseScript';
import { ErrorBoundaryWithAppInsights } from '../ErrorBoundary';
import { Container, Spinner } from '../../lib/components';
import { useFileDownloadUrl, useSettingsContext } from '../../Core';
import { CustomWidgetContextProvider } from '../../shared/Components';

const CustomWidget = ({ id, title, pageContext, isLocalPreview }) => {
  useEffect(() => {
    return () => {
      if (window[title]) {
        window[title] = null;
      }
    };
  }, [title]);

  if (!id) {
    return (
      <h3 className={'font-color-red'}>
        Custom widget does not have fileId. Check with Administrator
      </h3>
    );
  }

  if (isLocalPreview) {
    return (
      <CustomWidgetLocalPreview
        widgetCode={id}
        title={title}
        pageContext={pageContext}
      />
    );
  }

  return (
    <CustomWidgetFileLoader id={id} title={title} pageContext={pageContext} />
  );
};

export default CustomWidget;

const CustomWidgetFileLoader = ({ id, title, pageContext }) => {
  const { state: widgetUrl } = useFileDownloadUrl(id, false);

  if (widgetUrl.isLoading) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  if (widgetUrl.isError) {
    return (
      <h3 className={'font-color-red'}>
        Custom widget file could not be downloaded. Check with Administrator
      </h3>
    );
  }

  return (
    <ErrorBoundaryWithAppInsights customWidgetName={title}>
      <CustomWidgetLoader
        widgetUrl={widgetUrl.data}
        title={title}
        pageContext={pageContext}
      />
    </ErrorBoundaryWithAppInsights>
  );
};

const CustomWidgetLocalPreview = ({ widgetCode, title, pageContext }) => {
  const [loaded, error] = useScriptPreview(widgetCode);
  const widgetFunc = window[title];
  if (!loaded) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }
  if (error) {
    return <div>{error}</div>;
  }
  if (typeof widgetFunc !== 'function') {
    return (
      <h3 className={'font-color-red'}>{'Custom widget function not found'}</h3>
    );
  }
  return (
    <CustomWidgetContextProvider>
      <CustomWidgetRenderer widgetFunc={widgetFunc} pageContext={pageContext} />
    </CustomWidgetContextProvider>
  );
};

const CustomWidgetLoader = ({ widgetUrl, title, pageContext = {} }) => {
  const [loaded, error] = useScript(widgetUrl);
  const widgetFunc = window[title];
  const { settings } = useSettingsContext();
  pageContext = { ...pageContext, settings };

  if (!loaded) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (typeof widgetFunc !== 'function') {
    return (
      <h3 className={'font-color-red'}>{'Custom widget function not found'}</h3>
    );
  }

  return (
    <CustomWidgetContextProvider>
      <CustomWidgetRenderer widgetFunc={widgetFunc} pageContext={pageContext} />
    </CustomWidgetContextProvider>
  );
};

const CustomWidgetRenderer = ({ widgetFunc, pageContext }) => {
  return widgetFunc(pageContext);
};
