import React, { useReducer, useEffect, useCallback } from 'react';
import { useAsyncJobs } from '../Core';
import {
  AsyncJobContext,
  ASYNC_JOB_ACTIONS,
  PAGE_SIZE,
  asyncJobsReducer,
  asyncJobInitialState
} from '../Core';

const AsyncJobContextProvider = props => {
  const {
    state: fetchState,
    nextPage,
    reload: reloadJobs,
    hasMoreItems,
    paginationState
  } = useAsyncJobs(PAGE_SIZE);

  const [state, dispatch] = useReducer(asyncJobsReducer, asyncJobInitialState);

  useEffect(() => {
    if (fetchState) {
      if (fetchState.isLoading) {
        dispatch({
          type: ASYNC_JOB_ACTIONS.JOBS_REQUESTED
        });
      } else if (fetchState.isError) {
        dispatch({
          type: ASYNC_JOB_ACTIONS.JOBS_FETCH_FAILURE
        });
      } else if (fetchState.data) {
        dispatch({
          type: ASYNC_JOB_ACTIONS.JOBS_FETCH_SUCCESS,
          payload: fetchState.data
        });
      }
    }
  }, [fetchState]);

  const reload = useCallback(() => {
    dispatch({
      type: ASYNC_JOB_ACTIONS.RELOAD
    });
    reloadJobs();
  }, [dispatch, reloadJobs]);
  return (
    <AsyncJobContext.Provider
      value={{
        ...state,
        dispatch,
        hasMoreItems,
        paginationState,
        reload,
        nextPage
      }}
      {...props}
    />
  );
};

export { AsyncJobContextProvider };
