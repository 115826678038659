import {
    format,
    isAfter,
    isBefore,
    isEqual,
    isWithinInterval,
    isToday,
    setYear,
    getYear,
    addDays,
    setMonth,
    getMonth,
    startOfDay,
    addSeconds,
    differenceInDays,
    endOfDay
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { mapToISOTimeFormat, mapToISODateFormat, parseDate } from '.';

// mostly used in SPA
export const formatDateTimeWithTimeZone = (
    dateTimeStr,
    timeFormat,
    dateFormat,
    timeZone
) => {
    let timeFormatVal = timeFormat ? ' ' + mapToISOTimeFormat(timeFormat) : '';
    let dateFormatVal = dateFormat ? mapToISODateFormat(dateFormat) : '';
    let formattedTime = '';
    if (dateTimeStr && timeZone) {
        const zonedDate = parseTimeZone(parseDate(dateTimeStr, timeZone), timeZone);
        let strformat = `${dateFormatVal}${timeFormatVal}`;
        formattedTime = format(zonedDate, strformat);
    } else {
        formattedTime = '-';
    }

    return formattedTime;
};

// Note: do not modify this. as this is used in Custom widgets
export const formatDateTime = (
    dateTimeStr,
    dateFormat,
    utcOffset,
    dateRangeInDays
) => {
    let formattedTime = '';
    let isInGivenRange;
    let dateRange = dateRangeInDays ? dateRangeInDays : 0;

    if (dateTimeStr && utcOffset) {
        const startDate = startOfDay(new Date());

        let endDateval = addDays(new Date(), dateRange);
        const endDate = endOfDay(endDateval);

        const zonedDate = utcToZonedTime(dateTimeStr, utcOffset);

        formattedTime = format(zonedDate, dateFormat);

        isInGivenRange = isWithinInterval(zonedDate, {
            start: startDate,
            end: endDate
        });
    } else {
        formattedTime = '-';
    }

    return { time: formattedTime, isInGivenRange: isInGivenRange };
};

export const convertUTCToTenantTimeZone = (dateTimeStr, timeZone) => {
    let zonedDate = null;
    if (dateTimeStr && timeZone) {
        zonedDate = parseTimeZone(dateTimeStr, timeZone);
    }
    return zonedDate;
};

export const convertTenantTimeZoneToUTC = (dateTimeStr, timeZone) => {
    let timevalue = '';
    if (dateTimeStr && timeZone) {
        let tenantTimeZone = getTenantTimeZone(timeZone);

        const utcDate = zonedTimeToUtc(dateTimeStr, tenantTimeZone);
        timevalue = utcDate.toISOString();
    } else {
        timevalue = '';
    }
    return timevalue;
};

export const getDate = (daysToAdd = 0) => {
    let addedDays = addDays(new Date(), daysToAdd);
    let date = startOfDay(addedDays);

    return date.toISOString();
};

export const differenceInDaysFromToday = (dateStringISO) => {
    return differenceInDays(new Date(), new Date(dateStringISO));;
};

export const differnceInDays = (fromDate, toDate) => {
    return differenceInDays(new Date(toDate), new Date(fromDate));;
};

export const addDaysToDate = (dateString, days = 0) => {
    const date = addDays(new Date(dateString), days);
    return date.toISOString();
};

export const formatDate = (dateString, dateFormat, userTimeZone) => {
    return formatDateTimeWithTimeZone(dateString, '', dateFormat, userTimeZone);
};

export const sortDataByDate = (data, attributeName) => {
    if (attributeName && attributeName !== 'NA') {
        const sortedData = data.sort((a, b) => {
            return new Date(a[attributeName]) - new Date(b[attributeName]);
        });
        return sortedData;
    } else {
        const sortedData = {};
        Object.keys(data)
            .sort(function (a, b) {
                return new Date(a) - new Date(b);
            })
            .forEach(function (key) {
                sortedData[key] = data[key];
            });
        return sortedData;
    }
};

export const sortDataByDateAccordingToOrder = (data, sortState) => {
    if (sortState.isDescending) {
        data.sort((a, b) =>
            getDateInMS(a, sortState) > getDateInMS(b, sortState) ? -1 : 1
        );
    } else {
        data.sort((a, b) =>
            getDateInMS(a, sortState) > getDateInMS(b, sortState) ? 1 : -1
        );
    }
    return data;
};
function getDateInMS(data, sortState) {
    return new Date(data[sortState.sortedBy]);
}

function parseTimeZone(dateTimeStr, timeZone) {
    let tenantTimeZone = getTenantTimeZone(timeZone);
    const date = new Date(dateTimeStr);
    const zonedDate = utcToZonedTime(date, tenantTimeZone);

    return zonedDate;
}

// get timezone: e.g. '+05:30'
function getTenantTimeZone(timeZone) {
    let regEx = /\((.*?)\)/; // get the string inside of ()
    return regEx.exec(timeZone)[1].split('UTC')[1] || '+00:00';
}

export function getPreviousMonth(date) {
    return setMonth(date, getMonth(date) - 1);
}

export function getNextMonth(date) {
    return setMonth(date, getMonth(date) + 1);
}

export function isDateToday(dateStr) {
    return isToday(new Date(dateStr));
}

export function isDateNextWeek(dateStr) {
    const tomorrow = addDays(startOfDay(new Date()), 1);
    const nextWeekDate = addDays(tomorrow, 7);
    return isWithinInterval(new Date(dateStr), {
        start: tomorrow,
        end: nextWeekDate
    });
}

export function isDateLastWeek(dateStr) {
    const yesterday = startOfDay(new Date());
    const lastWeekDate = addSeconds(addDays(yesterday, -7), 1);
    return isWithinInterval(new Date(dateStr), {
        start: lastWeekDate,
        end: yesterday
    });
}

export function isDateLastOneMonth(dateStr) {
    const yesterday = startOfDay(new Date());
    const lastMonthDate = addSeconds(getPreviousMonth(yesterday), 1);
    return isWithinInterval(new Date(dateStr), {
        start: lastMonthDate,
        end: yesterday
    });
}

export function isDateNextOneMonth(dateStr) {
    const tomorrow = addDays(startOfDay(new Date()), 1);
    const nextMonthDate = addSeconds(getNextMonth(tomorrow), 1);
    return isWithinInterval(new Date(dateStr), {
        start: tomorrow,
        end: nextMonthDate
    });
}

export function isDateLastOneYear(dateStr) {
    const yesterday = startOfDay(new Date());
    const lastYearDate = addSeconds(
        setYear(yesterday, getYear(yesterday) - 1),
        1
    );
    return isWithinInterval(new Date(dateStr), {
        start: lastYearDate,
        end: yesterday
    });
}

export function isDateNextOneYear(dateStr) {
    const tomorrow = addDays(startOfDay(new Date()), 1);
    const nextYearDate = addSeconds(
        setYear(tomorrow, getYear(tomorrow) + 1),
        1
    );
    return isWithinInterval(new Date(dateStr), {
        start: tomorrow,
        end: nextYearDate
    });
}

export function isDateEqual(dateStr, compareToDateStr) {
    return isEqual(new Date(dateStr), new Date(compareToDateStr));
}

export function isDateBetween(dateStr, fromDateStr, toDateStr) {
    return isWithinInterval(new Date(dateStr), {
        start: new Date(fromDateStr),
        end: new Date(toDateStr)
    });
}

export function isDateGreater(dateStr, fromDateStr) {
    return isAfter(new Date(dateStr), new Date(fromDateStr));
}

export function isDateGreaterOrEqual(dateStr, fromDateStr) {
    return (
        isDateGreater(dateStr, fromDateStr) || isDateEqual(dateStr, fromDateStr)
    );
}

export function isDateLesser(dateStr, fromDateStr) {
    return isBefore(new Date(dateStr), new Date(fromDateStr));
}

export function isDateLessOrEqual(dateStr, fromDateStr) {
    return (
        isDateLesser(dateStr, fromDateStr) || isDateEqual(dateStr, fromDateStr)
    );
}
