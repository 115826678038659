import { useRef, useEffect } from 'react';
import React from 'react';
import ReactDOM from 'react-dom';
import classes from './Modal.module.css';
import classnames from 'classnames';
import { Box } from '../Box';

const modalRoot = document.getElementById('modal-root');

export function Modal({ children, onClose, open, background, zIndex }) {
  const target = usePortal();

  if (!open) {
    return null;
  }

  const renderContent = () => {
    return (
      <Box
        className={classnames(
          classes['display-modal'],
          background ? classes['dark-background'] : null,
          zIndex ? zIndex : null
        )}
        onClick={onClose}
      >
        <Box className={classnames(classes['white-background'])}>
          {children}
        </Box>
      </Box>
    );
  };

  return ReactDOM.createPortal(renderContent(), target);
}

export function usePortal() {
  const modalRef = useRef(null);

  useEffect(() => {
    modalRoot.appendChild(modalRef.current);
    return () => {
      modalRoot.removeChild(modalRef.current);
    };
  }, []);

  const getTarget = () => {
    if (!modalRef.current) {
      modalRef.current = document.createElement('div');
    }
    return modalRef.current;
  };

  return getTarget();
}
