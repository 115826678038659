import styled from 'styled-components';

export const StyledTextArea = styled.textarea.attrs(props => ({
  disabled: props.disabled === true ? 'disabled' : '',
  rows: props.rows ? props.rows : '5'
}))`
  width: 100%;
  height:${props=>props.height};
  resize:none;
  color: ${props => props.theme.colorPalette.SURFACE};
  vertical-align: middle;
  border: 1px solid ${props => props.theme.colorPalette.PRIMARY_LIGHTER};
  align-self: center;
  color: ${props => props.theme.colorPalette.PRIMARY_DARK};
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: 1.1rem;
  outline: none;
  &:focus {
    border: 1px solid ${props => props.theme.colorPalette.SECONDARY_LIGHT};
    box-shadow: 0 0 3px ${props => props.theme.colorPalette.SECONDARY_LIGHT};
  }
`;
