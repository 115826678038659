import React, { useState, useEffect } from 'react';
import {
  Spinner,
  H3,
  Container,
  Button,
  ButtonEnums,
  H4
} from '../lib/components';
import { useAuthContext, SettingsContext, useAllSettings } from '../Core';

const SettingsContextProvider = props => {
  const [settings, setSettings] = useState(null);
  const [settingsState, fetchSettings] = useAllSettings();
  const { logout, updateCookieForSystemGeneratedPassword } = useAuthContext();

  useEffect(() => {
    if (!settings && settingsState.isFulfilled) {
      updateCookieForSystemGeneratedPassword(settingsState.data);
      setSettings(settingsState.data);
    }
  }, [settingsState, settings, updateCookieForSystemGeneratedPassword]);

  const resetSettings = () => {
    setSettings(null);
    fetchSettings();
  };

  if (settingsState.isLoading) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  if (settingsState.isError) {
    return (
      <ErrorState
        settingsState={settingsState}
        fetchSettings={fetchSettings}
        logout={logout}
      />
    );
  }

  if (!settings) {
    return null;
  }

  return (
    <SettingsContext.Provider
      value={{ ...settings, resetSettings }}
      {...props}
    />
  );
};

export { SettingsContextProvider };

function ErrorState({ settingsState, fetchSettings, logout }) {
  const authContext = useAuthContext();
  let message = 'Error in fetching settings.';
  if (settingsState.data && typeof settingsState.data === 'string') {
    message = settingsState.data;
  }
  return (
    <Container direction={'column'}>
      <H3 color="ERROR">{message}</H3>
      <Button appearance={ButtonEnums.Appearance.Link} onClick={fetchSettings}>
        <H4>{'Try again'}</H4>
      </Button>
      <Button
        appearance={ButtonEnums.Appearance.Link}
        onClick={() => logout(() => authContext.clearSelectedOrgId())}
      >
        <H4>{'Logout'}</H4>
      </Button>
    </Container>
  );
}
