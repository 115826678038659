import classnames from 'classnames';
import React from 'react';

export const TextBox = ({
  isDisabled,
  numericOnly,
  allowDecimal,
  value,
  onEdit,
  style,
  placeholder,
  inputType = 'text'
}) => {
  const classes = classnames({});

  const onValueChange = event => {
    let val = event.target.value;
    val = numericOnly ? numericOnlyInput(val, allowDecimal) : val;

    onEdit(val);
  };

  const inputValue = value || value === 0 ? value : '';

  return (
    <>
      <input
        type={inputType}
        onChange={event => onValueChange(event)}
        className={classnames('form-input', classes, style)}
        value={inputValue}
        readOnly={isDisabled}
        style={style}
        placeholder={placeholder}
      />
    </>
  );
};

export const numericOnlyInput = (inputValue, allowDecimal) => {
  if (inputValue.length > 1) {
    inputValue =
      inputValue.substr(0, 1) +
      inputValue.substr(1, inputValue.length).replace(/-/g, '');
  }
  if (!allowDecimal) {
    return inputValue.replace(/[^0-9-]/g, '');
  }
  if (inputValue.includes('.', inputValue.indexOf('.') + 1)) {
    return parseFloat(inputValue.replace(/[^0-9.-]/g, ''));
  }
  return inputValue.replace(/[^0-9.-]/g, '');
};
