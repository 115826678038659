import { paginatedReducer } from './paginatedReducer';
import { useState, useEffect, useCallback } from 'react';
import { useProtrakApi } from './useProtrakApi';
export function usePaginatedGet(
  { requestConfig, promiseFn, ...args },
  options = {},
  pageSize = 50
) {
  const reducer = options.reducer || paginatedReducer;
  const [paginationState, setPagination] = useState({
    skip: 0,
    take: pageSize
  });

  const { state, run } = useProtrakApi(
    { requestConfig, promiseFn, ...args, ...paginationState },
    { ...options, reducer: reducer },
    { defer: false }
  );

  useEffect(() => {
    if (paginationState.skip > 0) {
      run(paginationState);
    }
  }, [paginationState, run]);

  const nextPage = useCallback(() => {
    setPagination(prevState => ({
      skip: prevState.skip + pageSize,
      take: pageSize
    }));
  }, [pageSize]);

  const previousPage = useCallback(() => {
    setPagination(prevState => ({
      skip: prevState.skip - pageSize,
      take: pageSize
    }));
  }, [pageSize]);

  const reload = useCallback(() => {
    setPagination({
      skip: 0,
      take: pageSize
    });
    run({
      skip: 0,
      take: pageSize
    });
  }, [pageSize, run]);

  const fetchItems = useCallback(
    runArgs => {
      setPagination({
        skip: 0,
        take: pageSize
      });
      run({
        ...runArgs,
        skip: 0,
        take: pageSize
      });
    },
    [pageSize, run]
  );

  const hasMoreItems = () => {
    return state.data
      ? state.data.totalCount > paginationState.skip + paginationState.take
      : false;
  };

  const getPageItems = useCallback(
    skipCount => {
      setPagination({
        skip: skipCount,
        take: pageSize
      });
    },
    [pageSize]
  );

  return {
    state,
    fetchItems,
    nextPage,
    previousPage,
    reload,
    hasMoreItems,
    paginationState,
    getPageItems
  };
}
