import useEventListener from './useEventListener';

export function useOnClickOutside(ref, handler) {
  const listener = event => {
    if (!ref.current || ref.current.contains(event.target)) {
      return;
    }

    handler(event);
  };
  useEventListener('click', listener);
}
