import React from 'react';
import classes from './DataGrid.module.css';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';
import { Cell } from './Cell';
import { Header } from './Header';
import { getBrowser } from '../../../Core';

const GRID_COLUMN_MIN_WIDTH = '100px';

export function DataGrid({
  columns,
  data,
  keyAccessor = 'id',
  isLoading,
  sortConfig = {},
  onSortApplied,
  getRowClassName,
  numberOfLoadingRows = 10
}) {
  const renderRows = items => {
    if (!items || items.length === 0) {
      return null;
    }
    return items.map((item, itemIndex) => {
      return renderRow(item, itemIndex);
    });
  };

  const renderRow = (item, itemIndex) => {
    const cells = columns.map((column, columnIndex) => {
      const cellKey = `${item[keyAccessor]}_${columnIndex}`;
      return renderCell(cellKey, column, columnIndex, item, itemIndex);
    });

    const rowCustomStyle = getRowClassName ? getRowClassName(item) : null;
    const rowClassNames = classNames(classes['row'], rowCustomStyle);

    //Legacy edge does not support display:contents
    //below is a workaround, compromising features of row bg color on hover
    //for proper display of grid on Edge only
    const ua = getBrowser();
    if (ua.name === 'Edge') {
      return <React.Fragment key={item[keyAccessor]}>{cells}</React.Fragment>;
    }

    return (
      <div key={item[keyAccessor]} className={rowClassNames}>
        {cells}
      </div>
    );
  };

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: formTemplateColumnString(columns),
        width: '100%',
        gridAutoRows: 'max-content'
      }}
      className={classes['x-scroll']}
      data-testid="data-grid"
    >
      <Header
        columns={columns}
        sortConfig={sortConfig}
        onSortApplied={onSortApplied}
      />
      {isLoading
        ? renderLoadingRow(columns, numberOfLoadingRows)
        : renderRows(data)}
    </div>
  );
}

const getElementWidth = (element) => {
  if (element.Width){
    return element.Width.includes('px')
      ? `minmax(${element.Width}, auto)`
      : element.Width;
  }
  return `minmax(${GRID_COLUMN_MIN_WIDTH}, auto)`;
};
const formTemplateColumnString = columns => {
  const style = [];
  columns.forEach(element => {
    let width = getElementWidth(element);


    style.push(width);
  });
  return style.join(' ');
};

const renderCell = (cellKey, column, columnIndex, item, itemIndex) => {
  if (column.Cell) {
    const cellRendererProps = {
      original: item,
      rowIndex: itemIndex,
      columnIndex: columnIndex
    };
    if (column.accessor) {
      cellRendererProps['value'] = item[column.accessor];
    }
    return (
      <Cell key={cellKey} className={classes['cell']}>
        {column.Cell(cellRendererProps)}
      </Cell>
    );
  }
  if (column.accessor) {
    return (
      <Cell key={cellKey} className={classes['cell']}>
        {item[column.accessor]}
      </Cell>
    );
  }
  return null;
};

const renderLoadingRow = (columns, loadingRows) => {
  let rows = [];
  while (loadingRows > 0) {
    rows.push(
      columns.map((column, index) => (
        <Cell key={index}>
          <Skeleton />
        </Cell>
      ))
    );
    loadingRows--;
  }

  return rows.map(r => r);
};
