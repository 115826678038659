import React, { useContext } from 'react';
import InstaceEditContext from '../../../Contexts/InstaceEditContext';
import {
  Button,
  ButtonEnums,
  Grid,
  BorderBox,
  Modal
} from '../../../lib/components';
import { isEmpty } from '../../../Core';
import InstanceLayoutPopup from '../InstanceLayoutPopup/InstanceLayoutPopup';
import { usePromoteOperation } from '../../Common/PromoteActions/usePromoteOperation';

export const CreateLayoutPopup = ({
  header,
  successButtonText,
  instanceTypeName,
  onInstanceCreated,
  saveSuccessCallback,
  config,
  isDisabled
}) => {
  const { instanceEditState, createInstance } = useContext(InstaceEditContext);
  const { setIsModalOpen, onCancel, isModalOpen } = usePromoteOperation();
  const createRelatedInstance = () => {
    if (isEmpty(instanceEditState.invalidAttributes)) {
      createInstance({ typeName: instanceTypeName });
      setIsModalOpen(true);
    }
  };

  const renderFooter = () => {
    return (
      <BorderBox
        border={{
          side: 'horizontal',
          color: 'PRIMARY_LIGHTER',
          style: 'solid',
          size: '1px'
        }}
        display="flex"
        justifyContent="flex-end"
        style={{
          padding: '10px'
        }}
      >
        <Button
          appearance={ButtonEnums.Appearance.Primary}
          text={successButtonText}
          onClick={createRelatedInstance}
          size={ButtonEnums.Size.Medium}
          disabled={isDisabled}
          style={{ padding: '2px' }}
        />
      </BorderBox>
    );
  };

  const renderContent = () => {
    return (
      <>
        {header}
        <Grid
          columns={['1fr']}
          areas={[['top']]}
          rows={['auto', 'auto', 'auto']}
          overflow={{ overflowY: 'auto' }}
          maxHeight="70vh"
          minHeight="20vh"
          width="auto"
        >
          <InstanceLayoutPopup
            config={config}
            onSuccess={onInstanceCreated}
            isModalOpen={isModalOpen}
            onCancel={() => onCancel()}
            saveSuccessCallback={() => {
              saveSuccessCallback();
              onCancel();
            }}
          />
        </Grid>
        {renderFooter()}
      </>
    );
  };

  return (
    <Modal open={true} background>
      <Grid
        columns={['1fr']}
        areas={[['top']]}
        rows={['auto', '4fr', 'auto']}
        style={{
          position: 'absolute',
          left: '25%',
          right: '25%',
          top: '10%',
          bottom: '15%',
          margin: 'auto',
          background: 'var(--surface)',
          borderRadius: '5px',
          textAlign: 'left'
        }}
        width="auto"
      >
        {renderContent()}
      </Grid>
    </Modal>
  );
};
