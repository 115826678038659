export const defaultSelection = 'Select';

export const generateStateFilterObj = stateFilter => {
  if (!stateFilter || !stateFilter.states) {
    return {};
  }

  if (stateFilter && stateFilter.states && stateFilter.states.length) {
    return {
      stateFilter: stateFilter.states.map(state => {
        return state.name;
      })
    };
  }
};

export const generateParentFilterObj = parentFilter => {
  if (!parentFilter || !parentFilter.filters) {
    return [];
  }

  if (parentFilter && parentFilter.filters) {
    return {
      ParentFilters: parentFilter.filters.map(state => {
        return {
          TypeName: state.typeName,
          InstanceId: state.instanceId,
          RelationTypeName: state.relationTypeName,
          RelationDirection: state.relationDirection
        };
      })
    };
  }
};

export const generateRelationFilterObj = relationFilter => {
  if (!relationFilter) {
    return [];
  }

  if (relationFilter) {
    return {
      RelationFilters: relationFilter.map(filter => {
        return {
          InstanceId: filter.instanceId,
          TypeName: filter.typeName,
          RelationTypeName: filter.relationTypeName,
          RelationDirection: filter.relationDirection
        };
      })
    };
  }
};

export const generateAttributeFilterObj = attributeFilterGroups => {
  if (!attributeFilterGroups || !attributeFilterGroups.length) {
    return null;
  }

  let filterGroup = {
    AttributeFilterExpressions: attributeFilterGroups.map(
      attributeFilterGroup => {
        return {
          AttributeFilterConditions: parsedArrayOfExpressions(
            attributeFilterGroup
          ),
          Operator: getOperator(attributeFilterGroup.operator)
        };
      }
    )
  };

  filterGroup.AttributeFilterExpressions.map(attributeFilterExpression => {
    deleteNullOrUndefinedProperties(attributeFilterExpression);
  });
  return filterGroup;
};

function getOperator(operator) {
  if (operator) {
    if (Array.isArray(operator)) {
      return operator[0];
    } else {
      return operator;
    }
  }
  return '';
}

const parsedArrayOfExpressions = group => {
  let filterExpressions = [];

  group.attributeFilterConditions &&
    group.attributeFilterConditions.forEach(groupExpression => {
      let filterExpression = {
        AttributeName: groupExpression.attributeName,
        Condition: groupExpression.condition,
        FirstValue: groupExpression.firstValue,
        SecondValue: groupExpression.secondValue,
        Operator: groupExpression.operator
      };

      deleteNullOrUndefinedProperties(filterExpression);

      filterExpressions.push(filterExpression);
    });

  return filterExpressions;
};

export const validateAttributeFilter = attributeFilter => {
  const validGroupsReducer = (validGroups, currentGroup) => {
    const { attributeFilterConditions } = currentGroup;
    const validFilterConditions = attributeFilterConditions.reduce(
      validFilterConditionsReducer,
      []
    );
    if (validFilterConditions.length > 0) {
      validGroups.push(currentGroup);
    }

    return validGroups;
  };

  const validFilterConditionsReducer = (
    validFilterconditions,
    currentFilterCondition
  ) => {
    if (
      currentFilterCondition !== defaultSelection &&
      currentFilterCondition.condition !== defaultSelection
    ) {
      validFilterconditions.push(currentFilterCondition);
    }
    return validFilterconditions;
  };

  let validAttributeFilterGroups =
    attributeFilter &&
    attributeFilter.attributeGroups &&
    attributeFilter.attributeGroups.reduce(validGroupsReducer, []);

  return validAttributeFilterGroups;
};

const deleteNullOrUndefinedProperties = objectToDeleteNullOrUndefined => {
  Object.keys(objectToDeleteNullOrUndefined).map(key => {
    return objectToDeleteNullOrUndefined[key] === undefined ||
      objectToDeleteNullOrUndefined[key] === '' ||
      objectToDeleteNullOrUndefined[key] === null
      ? delete objectToDeleteNullOrUndefined[key]
      : '';
  });
};
