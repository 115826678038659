export const userBase = '/users';
export const allUsersUrl = `${userBase}`;
export const specificUser = `${userBase}/{0}`; // {0}: userId

//  Here {0} represents 'Type Name'
export const typeBase = '/types';

export const instanceBase = '/instances';
export const getInstancesURL = `${instanceBase}`;
export const getInstanceURL = `${instanceBase}/{0}`;

export const getInstanceVersions = instanceId => {
  return `${instanceBase}/${instanceId}/versions`;
};

export const getInstanceMessages = `${instanceBase}/{0}/messages`; // {0}: instanceId
export const getInstanceReply = `${instanceBase}/{0}/messages/{1}/replies`; // {0}: instanceId, {1}: messageId
export const getInstanceVersionAttachments = `${instanceBase}/{0}/versions/{1}/files`;
