import { useProtrakApi, protrakApiClient, uploadFile, usePaginatedGet } from '../utils';
import { getFileType } from '../utils';

const instanceBase = '/instances';
const getInstanceReply = `${instanceBase}/{0}/messages/{1}/replies`; // {0}: instanceId, {1}: messageId
const getInstanceVersionReply = `${instanceBase}/{0}/versions/{1}/messages/{2}/replies`; // {0}: instanceId, {1}: versionId, {2}: messageId

export function useInstanceMessages(instanceId, versionId, PAGE_SIZE) {
  const {
    state: instanceMessages,
    nextPage,
    reload,
    paginationState,
    hasMoreItems
  } = usePaginatedGet({
    requestConfig: getInstanceMessages,
    take: PAGE_SIZE,
    instanceId: instanceId,
    versionId: versionId,
  });
  return { instanceMessages, nextPage, reload, paginationState, hasMoreItems };
}

export function useMessageUrl(config) {
  const { state, run } = useProtrakApi({
    requestConfig: generateGetMessagePromiseFn,
    ...config
  });
  return { state, run };
}

export function useMessageReplyList(instanceId, messageId, versionId, PAGE_SIZE, showMessagesAsChat = false) {
  const {
    state: replies,
    nextPage,
    paginationState,
    hasMoreItems
  } = usePaginatedGet({
    requestConfig: fetchMessageReplyList,
    skip: 0,
    take: PAGE_SIZE,
    instanceId: instanceId,
    messageId: messageId,
    versionId: versionId
  },
    { reducer: showMessagesAsChat ? messageReplyListPaginatedReducer : null },
    PAGE_SIZE);
  return { replies, nextPage, paginationState, hasMoreItems };
}

function messageReplyListPaginatedReducer(state, action) {
  if (!action || !action.type) {
    throw new Error('Invalid action passed, action.type value not available');
  }
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isLoading: true,
        isError: false,
        isFulfilled: false
      };
    case 'FETCH_SUCCESS':
      let initalData = state.data || [];
      let paginateditems =
        action.payload && action.payload.skip > 0
          ? [...action.payload.items, ...initalData.items]
          : action.payload.items;
      let paginatedData = { ...action.payload, items: paginateditems };
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: paginatedData,
        isFulfilled: true
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        isLoading: false,
        isFulfilled: false,
        isError: true,
        data: action.payload
      };
    default:
      throw new Error('Invalid action passed');
  }
}

const fetchMessageReplyList = ({
  skip,
  take,
  messageId,
  instanceId,
  versionId
}) => {
  const endpoint = replyEndpoint(messageId, instanceId, versionId);
  return { endpoint: endpoint, config: { params: { skip: skip, take: take, order: 'desc-asc' } } };
};

export function usePostNewMessageReplyUrl() {
  const { state, run } = useProtrakApi(
    {
      promiseFn: generatepostNewMessageReplyPromiseFn
    },
    { defer: true }
  );
  return { state, run };
}

export function usePostNewMessageUrl() {
  const { state, run } = useProtrakApi(
    {
      promiseFn: generatepostNewMessagePromiseFn
    },
    { defer: true }
  );
  return { state, run };
}

const getInstanceMessages = ({ take, skip, versionId, instanceId }) => {
  const endPointUrl = versionId
    ? `${instanceBase}/${instanceId}/versions/${versionId}/messages`
    : `${instanceBase}/${instanceId}/messages`;

  return {
    endpoint: endPointUrl, config: {
      params: { take: take, skip: skip },
      method: 'GET'
    }
  };
};

const replyEndpoint = (messageId, instanceId, versionId) => {
  if (!versionId) {
    return getInstanceReply
      .replace('{0}', instanceId)
      .replace('{1}', messageId);
  } else {
    return getInstanceVersionReply
      .replace('{0}', instanceId)
      .replace('{1}', versionId)
      .replace('{2}', messageId);
  }
};

const generatepostNewMessageReplyPromiseFn = async ({
  instanceId,
  instanceMessageId,
  payload,
  fileList, authContext
}) => {
  let uploadedFiles = [];
  if (fileList.length > 0) {
    uploadedFiles = await Promise.all(
      fileList.map(file => {
        return getAttachmentUploadPromise(file, authContext);
      })
    );
  }

  payload.files = uploadedFiles;

  const endpoint = `${instanceBase}/${instanceId}/messages/${instanceMessageId}/replies`; // {0}: instanceId, {1}: messageId

  return await protrakApiClient(endpoint, {
    method: 'POST',
    data: payload
  }, authContext);
};

const generatepostNewMessagePromiseFn = async ({
  instanceId,
  payload,
  fileList, authContext
}) => {
  let uploadedFiles = [];
  if (fileList.length > 0) {
    uploadedFiles = await Promise.all(
      fileList.map(file => {
        return getAttachmentUploadPromise(file, authContext);
      })
    );
  }

  payload.files = uploadedFiles;

  const endpoint = `${instanceBase}/${instanceId}/messages`; // {0}: instanceId
  return await protrakApiClient(endpoint, {
    method: 'POST',
    data: payload
  }, authContext);
};

const getAttachmentUploadPromise = async (file, authContext) => {
  let fileData = await uploadFile(file, authContext);

  let uploadedFileData = {};

  uploadedFileData = {
    fileId: fileData.data.fileId,
    fileName: file.name,
    type: getFileType(file.name)
  };
  return uploadedFileData;
};

const generateGetMessagePromiseFn = ({ skip, take }) => {
  const endpoint = '/users/me/messages';
  return {
    endpoint: endpoint, config: {
      params: {
        skip: skip,
        take: take
      }
    }
  };
};
