import Select, { components as selectComponents } from 'react-select';
// import { customStyles } from './PicklistRenderStyling';
import { theme } from '../AppThemeProvider';
import { H3 } from '../H3';

const customStyle = {
  control: provided => ({
    ...provided,
    minHeight: '26px',
    borderRadius: 0,
    width: '100%',
    border: 'none',
    cursor: 'pointer',
    boxShadow: 'none'
  }),

  singleValue: provided => ({
    ...provided,
    position: 'relative',
    maxWidth: '100%',
    transform: 'none'
  }),
  menu: provided => ({
    ...provided,
    width: 'max-content'
  }),
  input: provided => ({
    ...provided,
    padding: 0,
    margin: 0
  }),
  option: (provided, { isSelected }) => {
    return {
      ...provided,
      backgroundColor: isSelected ? theme.colorPalette.SECONDARY_LIGHT : '',
      '&:hover': {
        backgroundColor: isSelected
          ? theme.colorPalette.SECONDARY_LIGHT
          : theme.colorPalette.SECONDARY_LIGHTER
      }
    };
  }
};

export const ArrowSelector = ({
  defaultValues,
  picklistOptions,
  isMultiselect,
  onValueChange,
  isDisabled,
  style
}) => {
  const SingleValue = ({ children, ...props }) => (
    <selectComponents.SingleValue {...props}>
      <H3
        color={'SECONDARY_DARK'}
        style={{
          fontWeight:
            style && style.headerFontWeight ? style.headerFontWeight : 'bold'
        }}
      >
        {children}
      </H3>
      <i
        style={{
          marginLeft:
            style && style.iconsMarginLeft ? style.iconsMarginLeft : '4px'
        }}
        className="fas fa-chevron-down"
      />
    </selectComponents.SingleValue>
  );

  const components = {
    IndicatorSeparator: () => null,
    DropdownIndicator: () => null
  };

  return (
    <Select
      value={defaultValues}
      options={picklistOptions}
      isMulti={isMultiselect}
      styles={customStyle}
      onChange={onValueChange}
      placeholder="Select an option"
      isDisabled={isDisabled}
      isSearchable={false}
      components={{ ...components, SingleValue }}
    />
  );
};

export default ArrowSelector;
