import React from 'react';

export const EditIcon = ({ onEditClicked }) => {
  return (
    <i
      className="fas fa-pencil-alt"
      onClick={onEditClicked}
      title="Edit"
      style={{ padding: '0px 2px 0px 2px', cursor: 'pointer' }}
    />
  );
};
