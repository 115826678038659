import React from 'react';
import { StyledTextArea } from '../StyledTextArea';

export const TextArea = ({
  value,
  onEdit,
  rows,
  placeholder,
  disabled,
  style,
  height
}) => {
  const onValueChange = event => {
    onEdit(event.target.value);
  };
  if (height) {
    const textAreaHeight = `${height}px`;
    return (
      <StyledTextArea
        value={value || ''}
        onChange={onValueChange}
        resize="none"
        rows={rows}
        placeholder={placeholder}
        disabled={disabled}
        style={style}
        height={textAreaHeight}
      />
    );
  }
  return (
    <StyledTextArea
      value={value || ''}
      onChange={onValueChange}
      resize="none"
      rows={rows}
      placeholder={placeholder}
      disabled={disabled}
      style={style}
    />
  );
};
