import React, { useContext } from 'react';
import Group from '../Common/Group';
import Section from '../Common/Section';
import { Container, Spinner } from '../../lib/components';
import InstanceEditContext from '../../Contexts/InstaceEditContext';
import {
  useSettingsContext,
  evaluateConditionalFormatting,
  evaluateGroupConditions
} from '../../Core';

const InstanceLayout = props => {
  const {
    sections,
    editMode,
    details,
    shouldFieldRender,
    widgetRenderer
  } = props;
  const {
    instanceEditState,
    canConnect,
    getAttributeWorkingValue
  } = useContext(InstanceEditContext);
  const settings = useSettingsContext();
  const { promoteOperationState } = instanceEditState;

  const renderWidgets = widgets => {
    return widgets.map((widget, index) => {
      let fields =
        widget &&
        widget.fields &&
        widget.fields.map(f => {
          const conditionalFormatting = evaluateConditionalFormatting(
            f,
            getAttributeWorkingValue,
            settings
          );
          if (f.attributeType === 'Reference') {
            return {
              ...f,
              canLinkRelatedType: canConnect,
              conditionalFormattingStyle: conditionalFormatting
            };
          } else {
            return {
              ...f,
              conditionalFormattingStyle: conditionalFormatting
            };
          }
        });

      const { showTitle, showBorder, name, displayName } = widget.config;
      return (
        <Group
          key={index}
          showTitle={showTitle}
          showBorder={showBorder}
          name={name}
          displayName={displayName}
          overflowVisible
        >
          {widgetRenderer({
            config: widget.config,
            fields: fields,
            editMode: editMode
          })}
        </Group>
      );
    });
  };

  const renderSections = inputSections => {
    return inputSections.map((section, index) => {
      const { config, visibleWidgets } = section;
      return (
        <Section
          key={index}
          showTitle={config.showTitle}
          showBorder={config.showBorder}
          name={config.name}
          displayName={config.displayName}
        >
          {renderWidgets(visibleWidgets)}
        </Section>
      );
    });
  };

  const isPromoting = () => {
    return promoteOperationState && promoteOperationState.isLoading;
  };

  if (!details || isPromoting()) {
    return (
      <Container>
        <Spinner small />
      </Container>
    );
  }

  const visibleSections = getVisibleSections(
    sections,
    shouldFieldRender,
    getAttributeWorkingValue,
    settings
  );

  if (visibleSections.length <= 0) {
    return (
      <div style={{ display: 'grid', justifyContent: 'center' }}>
        Layout is not configured for you.
      </div>
    );
  }
  return renderSections(visibleSections);
};

export const getVisibleSections = (
  sections,
  shouldFieldRender,
  getAttributeWorkingValue,
  settings
) => {
  const areDisplayConditionsSatisfied = ({ displayCondition }) => {
    if (
      !displayCondition ||
      !displayCondition.rules ||
      !displayCondition.rules.length
    ) {
      return true;
    }
    return evaluateGroupConditions(
      displayCondition.rules,
      getAttributeWorkingValue,
      settings
    );
  };

  const visibleSectionReducer = (visibleSections, currentSection) => {
    if (areDisplayConditionsSatisfied(currentSection)) {
      const { widgets } = currentSection;
      const visibleWidgets = widgets.reduce(visibleWidgetReducer, []);
      if (visibleWidgets.length > 0) {
        visibleSections.push({ config: currentSection, visibleWidgets });
      }
    }
    return visibleSections;
  };

  const visibleWidgetReducer = (visibleWidgets, currentWidget) => {
    if (areDisplayConditionsSatisfied(currentWidget)) {
      if (currentWidget.widgetType !== 'FieldsWidget') {
        visibleWidgets.push({ config: currentWidget });
      } else {
        const { fields, ...otherConfig } = currentWidget;
        const visibleFields = fields.reduce(visibleFieldReducer, []);
        if (visibleFields.length > 0) {
          visibleWidgets.push({ config: otherConfig, fields: visibleFields });
        }
      }
    }
    return visibleWidgets;
  };

  const visibleFieldReducer = (visibleFields, currentField) => {
    if (shouldFieldRender(currentField)) {
      visibleFields.push(currentField);
    }
    return visibleFields;
  };
  if (!sections) {
    return [];
  }
  const visibleSections = sections.reduce(visibleSectionReducer, []);

  return visibleSections;
};

export default InstanceLayout;
