import React, { useState } from 'react';
import {
  Text,
  H3,
  Box,
  Button,
  ButtonEnums,
  Image,
  theme
} from '../../lib/components';
import Icon from '../../lib/components/Icon';
import { ReactComponent as ErrorSVG } from '../../Content/Images/Error_48px.svg';
import chromeLogo from '../../Content/Images/Chrome_48px.svg';
import firefoxLogo from '../../Content/Images/Firefox_48px.svg';
import edgeLogo from '../../Content/Images/InternetExplorer_48px.svg';
import safariLogo from '../../Content/Images/Safari_48px.svg';
import { TopAlignedModal } from '../../lib/components';
import { DeviceTypes, useDeviceType } from '../../UseDeviceType';
import useTranslatedValue from '../../Hooks/useTranslatedValue';

export const UnsupportedBrowserMessage = () => {
  const [isOpen, setIsOpen] = useState(true);
  const currentDeviceType = useDeviceType();
  const unsupportedBrowserMessage1 = useTranslatedValue(
    'UNSUPPORTED_BROWSER_MESSAGE_1'
  );
  const unsupportedBrowserMessage2 = useTranslatedValue(
    'UNSUPPORTED_BROWSER_MESSAGE_2'
  );
  const headingMessage = useTranslatedValue('UNSUPPROTED_BROWSER_HEADER');
  return (
    <TopAlignedModal open={isOpen} background>
      <Box
        direction="column"
        border={`1px solid ${theme.colorPalette.SECONDARY}`}
      >
        <Box height="0" alignSelf="flex-end" style={{ zIndex: 1 }}>
          <Button
            appearance={ButtonEnums.Appearance.Transparent}
            onClick={() => {
              setIsOpen(false);
            }}
            size={ButtonEnums.Size.Large}
            style={{ height: '2rem' }}
          >
            <i className="fas fa-times" />
          </Button>
        </Box>
        <Box
          direction={currentDeviceType === DeviceTypes.mobile ? 'column' : 'row'}
          backgroundColor={'SURFACE'}
          paddingLeft={currentDeviceType === DeviceTypes.mobile ? '4rem' : ''}
          paddingRight={currentDeviceType === DeviceTypes.mobile ? '4rem' : ''}
        >
          <Box alignSelf="center">
            <Icon SVG={ErrorSVG} size="7rem" />
          </Box>
          <Box direction="column" alignSelf="center">
            <H3 lineHeight="1.5rem">{headingMessage}</H3>
            <Text paddingTop="1rem">
              {unsupportedBrowserMessage1} <br />
              {unsupportedBrowserMessage2}
            </Text>
          </Box>

          <Box
            direction="row"
            alignSelf="center"
            paddingLeft="0.5rem"
            paddingRight={currentDeviceType === DeviceTypes.mobile ? '' : '2rem'}
            paddingTop={currentDeviceType === DeviceTypes.mobile ? '1rem' : ''}
            paddingBottom={currentDeviceType === DeviceTypes.mobile ? '1rem' : ''}
            justifyContent="center"
          >
            <Image src={chromeLogo} alt="Chrome" height="5rem" width="auto" />
            <Image src={firefoxLogo} alt="Firefox" height="5rem" width="auto" />
            <Image src={edgeLogo} alt="Edge" height="5rem" width="auto" />
            <Image src={safariLogo} alt="Safari" height="5rem" width="auto" />
          </Box>
        </Box>
      </Box>
    </TopAlignedModal>
  );
};
