import React from 'react';
import classnames from 'classnames';
import classes from './Layout.module.css';

export const NoRecordsFound = () => {
  return (
    <>
      <div className={classnames(classes['no-data-found'])}>
        <label>No records found</label>
      </div>
    </>
  );
};

export const GridStatus = ({ paginationContext, totalRecords }) => {
  if (totalRecords < 1) {
    return null;
  }

  const { skip, take } = paginationContext;
  const totalRecordsInCurrentSet = Math.ceil(skip + take);

  const from = skip + 1;
  const to =
    totalRecordsInCurrentSet > totalRecords
      ? totalRecords
      : totalRecordsInCurrentSet;

  return (
    <>
      <div className={classes['stats-container']}>
        {from} - {to} of {totalRecords}
      </div>
    </>
  );
};
export const VirtualizedGridStatus = ({ totalRecords, selectedRowsCount }) => {
  if (totalRecords < 1) {
    return null;
  }

  const getHeaderText = () => {
    if (!totalRecords) {
      return null;
    }
    if (selectedRowsCount) {
      return `selected ${selectedRowsCount} of  ${totalRecords} records`;
    }
    return `${totalRecords} records`;
  };

  return (
    <>
      <div className={(classes['stats-container'], 'margin-right-5px')}>
        {getHeaderText()}
      </div>
    </>
  );
};
