import Table from 'react-base-table';
import 'react-base-table/styles.css';
import { Box, theme } from '../../../lib/components';
import { mapFieldToColumnConfig } from '../Grid';
import { EmptyState, renderCell } from './utils';
import { useRef, useEffect, useState } from 'react';

const VirtualizedDataGrid = props => {
  const {
    data,
    isLoading,
    nextPage,
    fields,
    dimensions,
    actionsContext,
    gridColumns,
    isRowSelected,
    totalCount,
    reload,
    onItemSelect,
    onItemUnselect
  } = props;
  const columns = gridColumns
    ? gridColumns
    : mapFieldToColumnConfig(fields, data, reload, actionsContext, true);

  const ref = useRef();
  const loadMoreItems = () => {
    if (isLoading || !hasMoreItems()) {
      return;
    }
    nextPage();
  };
  const hasMoreItems = () => {
    if (data && data.length < totalCount) {
      return true;
    }
    return false;
  };
  const headerRenderer = ({ cells }) => {
    return (
      <Box
        ref={ref}
        backgroundColor="SECONDARY_LIGHTER"
        alignItems="center"
        borderBottom={`1px solid ${theme.colorPalette['PRIMARY_LIGHTER']}`}
        width="100%"
      >
        {
          <Box
            alignItems="center"
            style={{
              visibility: totalCount !== undefined ? 'visible' : 'hidden'
            }}
          >
            {cells}
          </Box>
        }
      </Box>
    );
  };

  function onRowClick(rowData) {
    if (isRowSelected && !isRowSelected(rowData)) {
      onItemSelect && onItemSelect(rowData.key);
    } else {
      onItemUnselect && onItemUnselect(rowData.key);
    }
  }

  const rowRenderer = ({ rowData, cells }) => {
    if (rowData.content) {
      return <Box onClick={() => onRowClick(rowData)}>{rowData.content}</Box>;
    }
    if (!isRowSelected) {
      return (
        <Box
          borderBottom={`1px solid ${theme.colorPalette['PRIMARY_LIGHTER']}`}
          onClick={() => onRowClick(rowData)}
        >
          {cells}
        </Box>
      );
    }
    const isSelected = isRowSelected && isRowSelected(rowData);
    let editedCells = cells;
    const column = columns[0];
    if (isSelected) {
      editedCells[0] = (
        <Box
          width={column.width + 'px'}
          justifyContent="center"
          paddingLeft="0.1rem"
          key={`${rowData.key} 0`}
        >
          {renderCell(
            rowData[column.keyAccessor],
            column,
            0,
            rowData,
            rowData.key,
            isSelected
          )}
        </Box>
      );
    } else {
      editedCells[0] = (
        <Box
          width={column.width + 'px'}
          justifyContent="center"
          paddingLeft="0.1rem"
          key={`${rowData.key} 0`}
        >
          {renderCell(
            rowData[column.keyAccessor],
            column,
            0,
            rowData,
            rowData.key,
            false
          )}
        </Box>
      );
    }
    return (
      <Box
        backgroundColor={isSelected ? 'PRIMARY_LIGHT' : 'black'}
        borderBottom={`1px solid ${theme.colorPalette['PRIMARY_LIGHTER']}`}
        onClick={() => onRowClick(rowData)}
      >
        {editedCells}
      </Box>
    );
  };
  const [height, setHeight] = useState(ref.current && ref.current.offsetHeight);

  useEffect(() => setHeight(ref.current && ref.current.offsetHeight), [ref]);
  return (
    <>
      <Table
        fixed
        columns={columns}
        data={data}
        footerHeight={10}
        onEndReachedThreshold={200}
        width={dimensions.width ? dimensions.width : 400}
        height={dimensions.height ? dimensions.height : 500}
        estimatedRowHeight={40}
        rowHeight={40}
        onEndReached={loadMoreItems}
        rowRenderer={rowRenderer}
        emptyRenderer={isLoading ? <EmptyState columns={gridColumns} /> : null}
        headerRenderer={headerRenderer}
        headerHeight={height}
      />
    </>
  );
};

export default VirtualizedDataGrid;
