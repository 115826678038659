import React, { useContext } from 'react';
import * as protrakUtils from '../../Core';
import * as protrakSharedComponents from '../../shared/Components';
import * as protrakLibComponents from '../../lib/components';

const CustomWidgetContext = React.createContext();

function CustomWidgetContextProvider({ children }) {
  return (
    <CustomWidgetContext.Provider
      value={{
        protrakUtils: { ...protrakUtils },
        protrakComponents: { ...protrakSharedComponents, ...protrakLibComponents }
      }}
      children={children}
    />
  );
}

const useCustomWidgetContext = () => {
  const context = useContext(CustomWidgetContext);
  if (context === undefined) {
    throw new Error('must be used as a child of an AuthContext provider');
  }
  return context;
};

export { CustomWidgetContext, CustomWidgetContextProvider, useCustomWidgetContext };
