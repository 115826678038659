import React from 'react';
import { Box } from '../../lib/components';

export const AttributeFilterContainer = ({children, height}) => {
    return (
        <Box
            display="block"
            border="1px solid var(--primary-lighter)"
            marginTop="15px"
            padding="5px"
            borderRadius="5px"
            overflow="auto"
            height={height}>
                {children}
        </Box>
    );
};
