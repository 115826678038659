import React, { useState } from 'react';
import { InstanceLink } from '../../../shared/Components';
import { CellContent } from '../Grid/CellContent';
import {
  FormattedDateTime,
  FormattedDate,
  Box,
  Container,
  Spinner,
  BorderBox
} from '../../../lib/components';
import { ExportCSVButton } from '../../../lib/components';
import { replaceStateWithDisplayName } from './utils';
import { GridHeaderLayout } from '../../Layouts/GridHeaderLayout';
import { convertToLocaleString, isNumber, useSettingsContext, sortDataByDateAccordingToOrder } from '../../../Core';
import { useDimensions } from '../../../Hooks/useDimensions';
import {
  convertColumnsToDesiredFormatForVirtualizedGrid,
  convertDataToDesiredFormatForVirtualizedGrid
} from '../VirtualizedDataGrid';
import { getPageNumber, paginate } from '../Grid/Pagination';
import { VirtualizedGridStatus } from '../Grid/TableGridFooter';
const VirtualDataGrid = React.lazy(
  async () => await import('../VirtualizedDataGrid/VirtualizedDataGrid')
);

const GRAND_TOTAL = 'Grand Total';
export const CreateDataGridForDrillDown = ({
  data,
  config,
  filter,
  typeName,
  sortState,
  onSortApplied,
  states,
  csvFileTitle
}) => {
  const defaultSkipTake = { skip: 0, take: 50 };
  let drilldownData = [];
  const [ref, dimensions] = useDimensions();
  const [previouslyConvertedData, setPreviouslyConvertedData] = useState([]);
  const [paginationContext, setPaginationContext] = useState(defaultSkipTake);
  const { skip, take } = paginationContext;
  const { settings } = useSettingsContext();
  const checkWhetherValueMatchesWithFilter = (value, filters) => {
    const isMatching =
      filters &&
      filters.some(filterValue => {
        return value === filterValue;
      });
    return isMatching;
  };
  if (typeof filter === 'string') {
    if (filter === GRAND_TOTAL) {
      drilldownData = [
        ...data.filter(d => d[config.group.attribute.attributeName])
      ];
    } else {
      data.forEach(element => {
        if (element.state) {
          replaceStateWithDisplayName(element, states);
        }
        if (element[config.group.attribute.attributeName] === filter) {
          drilldownData.push(element);
        }
      });
    }
  } else {
    data.forEach(element => {
      if (element.state) {
        replaceStateWithDisplayName(element, states);
      }
      if (
        checkWhetherValueMatchesWithFilter(
          element[config.group.attribute.attributeName],
          filter
        )
      ) {
        drilldownData.push(element);
      }
    });
  }
  const sortByAttributeObj = config.fields.find(
    f => f.attributeName === sortState.sortedBy
  );
  if (
    sortByAttributeObj &&
    (sortByAttributeObj.attributeType === 'Date' ||
      sortByAttributeObj.attributeType === 'DateTime')
  ) {
    data = sortDataByDateAccordingToOrder(data, sortState);
  } else {
    if (sortState.isDescending) {
      drilldownData.sort((a, b) =>
        a[sortState.sortedBy] > b[sortState.sortedBy] ? -1 : 1
      );
    } else {
      drilldownData.sort((a, b) =>
        a[sortState.sortedBy] > b[sortState.sortedBy] ? 1 : -1
      );
    }
  }
  const getCellValue = (rowProps, accessor) => {
    if (!rowProps.original[accessor.attributeName]) {
      return '-';
    }
    //unconverted date
    const originalDate = rowProps.original[accessor.attributeName + 'original'];
    const dateStr = originalDate
      ? originalDate
      : rowProps.original[accessor.attributeName];
    if (accessor.attributeType === 'Date') {
      return <FormattedDate dateStr={dateStr} />;
    }
    if (accessor.attributeType === 'DateTime') {
      return <FormattedDateTime dateTimeStr={dateStr} />;
    }
    if (accessor.attributeType === 'Picklist') {
      const picklistField = config.fields.find(
        f => f.attributeName === accessor.attributeName
      );
      const attributeField =
        picklistField &&
        picklistField.options &&
        picklistField.options.find(
          f => f.name === rowProps.original[accessor.attributeName]
        );
      return attributeField && attributeField.displayName
        ? attributeField.displayName
        : rowProps.original[accessor.attributeName];
    }
    return isNumber(rowProps.original[accessor.attributeName])
      ? convertToLocaleString(
        parseFloat(rowProps.original[accessor.attributeName]), settings.locale
      )
      : rowProps.original[accessor.attributeName];
  };
  const reportColumns = [];
  const addReportColumns = (column, displayName, aggregateType) => {
    if (aggregateType && aggregateType !== 'None') {
      reportColumns.push({
        ...column,
        displayName: column.label
      });
    } else {
      reportColumns.push({
        ...column,
        displayName: displayName
      });
    }
  };
  config.columns.forEach(c => {
    if (c.showInDrillDown) {
      if (c.firstAttribute) {
        if (!reportColumns.some(e => e.displayName === c.displayName)) {
          addReportColumns(c.firstAttribute, c.displayName, c.aggregateType);
        }
      }
      if (c.secondAttribute) {
        if (
          !reportColumns.some(
            e => e.attributeName === c.secondAttribute.attributeName
          )
        ) {
          addReportColumns(c.secondAttribute, c.displayName, c.aggregateType);
        }
      }
    }
  });
  const filteredKeys = [];
  if (config.group.showInDrillDown) {
    filteredKeys.push({
      ...config.group.attribute,
      displayName: config.group.displayName
    });
  }

  const getColumnHeader = column => {
    return column.displayName ? column.displayName : column.attributeName;
  };
  const getIsDate = column => {
    return Boolean(column.attributeType === 'Date');
  };
  const getIsDateTime = column => {
    return Boolean(column.attributeType === 'DateTime');
  };
  const keys = filteredKeys.concat(reportColumns);
  const columns = keys.map(c => {
    if (c.attributeName === 'name' || c.attributeName === 'trackingId') {
      return {
        Header: <b>{getColumnHeader(c)}</b>,
        accessor: c.attributeName,
        displayName: getColumnHeader(c),
        Cell: rowProps => (
          <InstanceLink
            typeName={typeName}
            instanceId={rowProps.original.id}
            targetLayout={'view'}
            openInNewTab={true}
          >
            {rowProps.original[c.attributeName]
              ? rowProps.original[c.attributeName]
              : '-'}
          </InstanceLink>
        ),
        sortable: true,
        attributeType: c.attributeType,
        prefix: c.prefix,
        suffix: c.suffix
      };
    }
    //unconverted date
    const originalDate = c.attributeName + 'original';
    const isDate = getIsDate(c) || getIsDateTime(c);
    return {
      Header: (
        <Box
          display="block"
          width="100%"
          textAlign={c.attributeType === 'Numeric' ? 'end' : 'start'}
        >
          <b>{getColumnHeader(c)}</b>
        </Box>
      ),
      accessor: c.originalDate && isDate ? originalDate : c.attributeName,
      sortable: true,
      displayName: getColumnHeader(c),
      Cell: rowProps => (
        <CellContent
          style={{
            justifyContent:
              c.attributeType === 'Numeric' ? 'flex-end' : 'flex-start'
          }}
        >
          {c.prefix}&nbsp;{getCellValue(rowProps, c)}&nbsp;{c.suffix}
        </CellContent>
      ),
      attributeType: c.attributeType,
      prefix: c.prefix,
      suffix: c.suffix
    };
  });

  const headers = [];
  columns.forEach(c => {
    if (c.accessor && c.displayName) {
      headers.push({
        label: c.displayName,
        key: c.accessor,
        attributeType: c.attributeType,
        prefix: c.prefix ? c.prefix : '',
        suffix: c.suffix ? c.suffix : ''
      });
    }
  });

  let paginatedData = drilldownData.slice(skip, skip + take);

  let convertedColumns = [];
  let convertedData;
  if (dimensions) {
    convertedColumns = convertColumnsToDesiredFormatForVirtualizedGrid(
      columns,
      dimensions
    );
    convertedData = convertDataToDesiredFormatForVirtualizedGrid(
      paginatedData,
      convertedColumns,
      false,
      previouslyConvertedData
    );
  }
  const getCombinedData = () => {
    if (data.isLoading && skip === 0) {
      return [];
    }
    if (skip === 0) {
      if (previouslyConvertedData.length !== 0) {
        setPreviouslyConvertedData([]);
      }
      return convertedData;
    }
    return previouslyConvertedData.concat(convertedData);
  };

  const nextPage = () => {
    setPreviouslyConvertedData(previouslyConvertedData.concat(convertedData));
    const nextPageNo = getPageNumber(skip, take) + 1;
    const { startIndex } = paginate(drilldownData.length, nextPageNo, take);
    setPaginationContext({ skip: startIndex, take: take });
  };

  function leftHeader() {
    return (
      <ExportCSVButton
        data={drilldownData}
        headers={headers}
        filename={
          csvFileTitle.length > 100 ? csvFileTitle.slice(0, 100) : csvFileTitle
        }
        attributeFields={config.fields}
      />
    );
  }

  function rightHeader() {
    return (
      <>
        <VirtualizedGridStatus
          totalRecords={drilldownData ? drilldownData.length : 0}
        />
      </>
    );
  }
  const Loader = () => {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  };

  return (
    <>
      <GridHeaderLayout leftHeader={leftHeader()} rightHeader={rightHeader()} />
      <Box height="60vh">
        <BorderBox
          style={{ height: '80%', width: '99.7%' }}
          display={'block'}
          ref={ref}
        >
          {dimensions ? (
            <React.Suspense fallback={<Loader />}>
              <VirtualDataGrid
                data={getCombinedData()}
                isLoading={data.isLoading}
                onSortApplied={onSortApplied}
                nextPage={nextPage}
                gridColumns={convertedColumns}
                dimensions={dimensions}
                totalCount={drilldownData.length}
                isSortable={true}
                sortConfig={sortState}
                keyAccessor="id"
                key={dimensions.width + dimensions.height}
              />
            </React.Suspense>
          ) : null}
        </BorderBox>
      </Box>
    </>
  );
};
