export const mapPicklistValues = (userItems, labelKey, valueKey) => {
  if (!userItems || !userItems.length) {
    return [];
  }

  return userItems.map(item => {
    return {
      label: labelKey ? item[labelKey] : item,
      value: valueKey ? item[valueKey] : item
    };
  });
};
