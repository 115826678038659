import { useReducer, useEffect, useCallback, useRef } from 'react';
import { keyValueDataFetchReducer } from './keyValueDataFetchReducer';

const initialState = { isLoading: true, isError: false, isFulfilled: false };

const getInitialState = keyValueArgs => {
  if (!keyValueArgs) {
    return {};
  }
  const state = {};
  Object.keys(keyValueArgs).forEach(key => {
    state[key] = initialState;
  });
  return state;
};

//TODO revisit this for auth error handlings
export function useMultiApi({ promiseFn, keyValueArgs }, _options = {}) {
  const [state, dispatch] = useReducer(
    keyValueDataFetchReducer,
    getInitialState(keyValueArgs)
  );

  const promiseArgs = useRef(keyValueArgs);
  const options = useRef(_options);
  const isMounted = useRef(true);

  const handleSuccess = useCallback(
    (key, response) => {
      dispatch({
        type: 'FETCH_SUCCESS',
        key,
        payload: response.data,
        status: response.status
      });

      options.onSuccess && options.onSuccess(key);
    },
    [options]
  );

  const handleError = useCallback(
    (key, error) => {
      dispatch({ type: 'FETCH_FAILURE', key, payload: error });
      options.onError && options.onError(key, error);
    },
    [options]
  );

  const execute = useCallback(async () => {
    Object.keys(promiseArgs.current).forEach(async key => {
      dispatch({ type: 'FETCH_INIT', key });
      try {
        const response = await promiseFn(promiseArgs.current[key], options.current.middleware);
        if (isMounted.current) {
          handleSuccess(key, response);
        }
      } catch (error) {
        if (isMounted.current) {
          handleError(key, error);
        }
      }
    });
  }, [handleError, handleSuccess, promiseFn]);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  useEffect(() => {
    if (!options.current.defer) {
      execute();
    }
  }, [execute, options.current.defer]);

  const run = useCallback(
    newArgs => {
      if (newArgs) {
        promiseArgs.current = newArgs;
      }
      execute();
    },
    [execute]
  );

  return {
    state,
    run
  };
}
