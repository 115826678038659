import React from 'react';
import { getSmoothStepPath, getMarkerEnd } from 'react-flow-renderer';
import { ReactFlowConstants } from './lifecycleToReactFlowMapping';

const SVGSIZE = 20;

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  arrowHeadType
}) {
  const edgePath = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    centerX: data.centerX,
    centerY: data.centerY
  });
  const markerEnd = getMarkerEnd(arrowHeadType, data.markerEndId);
  const isHorizontal = data.orientation === ReactFlowConstants.horizontal;

  const textCenter = isHorizontal
    ? (sourceX + targetX) / 2
    : (sourceY + targetY) / 2;

  const textOrigin = data.centerX;
  let cursorStyle = data.enableEdgeClick ? 'grab' : 'default';
  let textStyle = { ...data.textStyle, cursor: cursorStyle };
  if (!isHorizontal) {
    textStyle = { ...textStyle, writingMode: 'tb' };
  }
  if (data.action.name === ReactFlowConstants.actionDeleted) {
    textStyle = { ...textStyle, fontStyle: 'italic' };
  }

  const textX = isHorizontal ? textCenter : textOrigin;
  const textY = isHorizontal ? data.centerX : textCenter;
  const verticalTransform = `rotate(180, ${textX}, ${textY})`;
  const actionOccurances = ` ${data.noOfTimesActionPerformed}`;

  const textContent = (
    <text
      textAnchor="middle"
      x={isHorizontal ? textCenter : textOrigin}
      y={isHorizontal ? data.centerX : textCenter}
      dy={isHorizontal ? '-4' : null} // -4 is the margin between edge line and text in horizontal
      dx={isHorizontal ? null : '7'} // 7 is the margin between edge line and text in vertical
      fill={textStyle.color}
      style={textStyle}
      transform={data.edgePosition === ReactFlowConstants.left ? verticalTransform : null}
    >
      {data.action.displayName ? data.action.displayName : data.action.name}
    </text>
  );

  const getX = () => {
    if (isHorizontal) {
      return textCenter;
    } else {
      return data.edgePosition === ReactFlowConstants.right ? textOrigin - 20 : textOrigin;
    }
  };

  const finalTextContent = (
    <>
      {textContent}
      {actionOccurances > 1 ?
        <svg width={SVGSIZE} height={SVGSIZE}
          x={getX()}
          y={isHorizontal ? data.centerX : (textCenter - 10)} // 10 -> moving in center to text in vertical layout
          transform={data.edgePosition === ReactFlowConstants.left ? verticalTransform : null}
          cursor={cursorStyle}
        >
          <circle cx={SVGSIZE / 2} cy={SVGSIZE / 2} r="8" fill={textStyle.color} />
          <text x="50%" y="50%" textAnchor="middle" fill="white" fontSize="10px" dy=".3em">{actionOccurances}</text>
        </svg> : null}
    </>
  );

  return (
    <>
      <defs>
        <marker
          id="arrow"
          viewBox="0 0 10 10"
          refX="11"
          refY="5"
          markerWidth="9"
          markerHeight="6"
          orient="auto-start-reverse"
          fill={style && style.stroke ? style.stroke : textStyle.color}
        >
          <path d="M 0 0 L 10 5 L 0 10 z" />
        </marker>
      </defs>
      <g>
        <path
          id={id}
          style={style}
          className="react-flow__edge-path"
          d={edgePath}
          markerEnd={markerEnd}
        />

        {data.onEdgeClick ? data.onEdgeClick(data.action) : finalTextContent}
      </g>
    </>
  );
}
