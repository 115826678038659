import React from 'react';
import { useStates } from '../../Core';
import { Picklist, Box, H3 } from '../../lib/components';
import useTranslatedValue from '../../Hooks/useTranslatedValue';

export const StateFilter = ({ stateFilter, onStateFilterChanged }) => {
  const lifecycleName = stateFilter.lifecycleName;

  const { state: lifecycleStates } = useStates(lifecycleName);
  const statefilter = useTranslatedValue('STATE_FILTER');

  const stateFilterPicklistChanged = selectedOptions => {
    let newStateFilter =
      selectedOptions &&
      selectedOptions
        .map(option => {
          let state = lifecycleStates.data.states.find(s => s.name === option);
          if (state) {
            return state;
          }
        })
        .filter(s => s);

    onStateFilterChanged(newStateFilter || []);
  };

  return (
    <>
      <Box
        background="var(--surface)"
        padding="5px"
        style={{ left: '8px' }}
        top="-8px"
        padding="0 3px"
      >
        <H3 fontWeight={500}>{statefilter}</H3>
      </Box>
      <Box paddingTop={'5px'}>
        <Picklist
          isMultiselect={true}
          isDisabled={stateFilter.enforced}
          fieldValue={getFilteringState(stateFilter)}
          options={setStateFilterOptions(
            lifecycleStates.data && lifecycleStates.data.states
          )}
          placeholder={
            !(
              lifecycleStates &&
              lifecycleStates.data &&
              lifecycleStates.data.states
            )
              ? 'Loading...'
              : null
          }
          onEdit={stateFilterPicklistChanged}
          isLoading={
            !(
              lifecycleStates &&
              lifecycleStates.data &&
              lifecycleStates.data.states
            )
          }
          customStyle={{
            container: provided => ({
              ...provided,
              width: '100%'
            })
          }}
        />
      </Box>
    </>
  );
};

export default StateFilter;

const setStateFilterOptions = lifecycleStates => {
  if (!lifecycleStates) {
    return [];
  }

  return lifecycleStates.map(state => {
    return {
      name: state.name,
      displayName: state.displayName ? state.displayName : state.name
    };
  });
};

const getFilteringState = stateFilter => {
  if (!stateFilter.states || !stateFilter.states.length) {
    return '';
  }

  let stateNames = stateFilter.states.map(state => {
    return state.name;
  });

  return stateNames;
};
