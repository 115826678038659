import { toQueryString, usePaginatedGet } from '../utils';
import { generateInstanceQuery } from './instanceQuery';

const instanceBase = '/instances';

export const createEndpoint = query => {
  if (query) {
    const queryParams = toQueryString(query);

    const endpoint = `${instanceBase}?${queryParams}`;

    return endpoint;
  }

  return null;
};

export function useInstanceQuery({
  widget,
  typeName,
  stateFilter,
  attributeFilter,
  parentFilter,
  sortBy,
  isSortByDescending,
  skip,
  take,
  pageSize
}) {
  const {
    state,
    reload,
    nextPage,
    previousPage,
    paginationState,
    hasMoreItems,
    fetchItems
  } = usePaginatedGet(
    {
      requestConfig: instanceQueryPromiseFn,
      widget,
      typeName,
      stateFilter,
      attributeFilter,
      parentFilter,
      sortBy,
      isSortByDescending,
      skip,
      take
    },
    { defer: true },
    pageSize
  );
  return {
    state,
    reload,
    nextPage,
    previousPage,
    paginationState,
    hasMoreItems,
    fetchItems
  };
}

const instanceQueryPromiseFn = ({
  widget,
  typeName,
  stateFilter,
  attributeFilter,
  parentFilter,
  sortBy,
  isSortByDescending,
  skip,
  take
}) => {
  const instQuery = generateInstanceQuery({
    widget,
    typeName,
    stateFilter,
    attributeFilter,
    parentFilter,
    sortBy,
    isSortByDescending
  });
  const endpoint = createEndpoint({ ...instQuery, skip, take });
  return { endpoint: endpoint };
};
