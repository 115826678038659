function doOR(firstValue, secondValue) {
  return firstValue || secondValue;
}

function doAND(firstValue, secondValue) {
  return firstValue && secondValue;
}

const logicalConditionEvaluators = {
  Or: doOR,
  And: doAND
};

function expressionEvaluator(firstExpression, secondExpression, condition) {
  return logicalConditionEvaluators[condition](
    firstExpression,
    secondExpression
  );
}

function multipleExpressionEvaluator(expressions, conditions) {
  if (expressions.length === 1) {
    return expressions[0];
  }

  if (expressions.length === 2) {
    return expressionEvaluator(
      Boolean(expressions[0]),
      Boolean(expressions[1]),
      conditions[0]
    );
  }

  return expressionEvaluator(
    expressions[0],
    multipleExpressionEvaluator(expressions.slice(1), conditions.slice(1)),
    conditions[0]
  );
}

function evaluateConditions(expressions, conditions) {
  if (
    !expressions ||
    !conditions ||
    !expressions.length ||
    !conditions.length ||
    expressions.length < conditions.length
  ) {
    throw new Error('Invalid conditions or expressions passed');
  }

  return multipleExpressionEvaluator(expressions, conditions);
}

export { evaluateConditions };
