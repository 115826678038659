import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';
import reactFlowClass from './reactFlow.module.css';
import classNames from 'classnames';

export default memo(({ data }) => {
  const populateNodeHandlers = handlers => {
    return handlers
      ? handlers.map((handlerData, i) => {
        return (
          <Handle
            key={i}
            type={handlerData.type}
            position={handlerData.position}
            id={handlerData.id}
            style={{ ...handlerData.style, cursor: 'default' }} // made handler cursor default
          />
        );
      })
      : null;
  };

  const cursorStyle = data.enableNodeClick ? { cursor: 'grab' } : { cursor: 'default' };
  return (
    <div className={classNames(reactFlowClass['node-container'])}
      style={cursorStyle}>
      {populateNodeHandlers(data.handlers)}
      <div
        title={data.label}
        style={{ ...data.textStyle }}
      >
        {data.label}
      </div>
    </div >
  );
});
