export const Enums = {
  AttributeTypes: {
    Text: 'Text',
    Numeric: 'Numeric',
    Date: 'Date',
    Currency: 'Currency',
    Picklist: 'Picklist',
    DateTime: 'DateTime',
    RichText: 'RichText',
    Expression: 'Expression',
    Boolean: 'Boolean',
    Attachment: 'Attachment',
    User: 'User',
    Reference: 'Reference'
  },

  FieldType: {
    Actions: 'Actions',
    State: 'State'
  },

  ViewWidgetType: {
    RELATION: 'Relation',
    Attachments: 'Attachments',
    LIFECYCLE: 'Lifecycle',
    TASKS: 'Tasks',
    VERSIONS: 'Versions',
    MESSAGES: 'Messages',
    ACTIVITIES: 'Activities',
    GEOLOCATION: 'Geolocation',
    ACCESSCONTROL: 'Access Control'
  },
  StandardWidgetType: {
    ImageCarousel: 'ImageCarousel',
    Geolocation: 'Geolocation',
    QRCode: 'QRCode'
  },
  ActivityType: {
    ActionPromoted: 'ActionPromoted',
    GeolocationChanged: 'GeolocationChanged',
    MessagePosted: 'MessagePosted',
    TaskCreated: 'TaskCreated',
    CloneCreated: 'CloneCreated',
    MinorCreated: 'MinorCreated'
  },
  FileType: {
    Picture: 'Picture',
    Video: 'Video',
    Document: 'Document'
  },
  AllowedOperations: {
    Edit: 'Edit',
    Delete: 'Delete',
    Promote: 'Promote',
    Link: 'Link',
    Unlink: 'Unlink',
    FileUpload: 'File Upload',
    FileDownload: 'File Download',
    PrintSticker: 'Print Sticker',
    Export: 'Export',
    ViewRelation: 'View Relation',
    EditRelation: 'Edit Relation',
    QuickEdit: 'Quick Edit'
  },
  EditMode: {
    None: 'None',
    Inline: 'Inline',
    Full: 'Full'
  },
  HomeLayoutWidgetType: {
    Standard: 'Standard',
    Report: 'Report',
    Custom: 'Custom',
    Dashboard: 'Dashboard'
  },
  Conditions: {
    Contains: 'Contains',
    NotContains: 'NotContains',
    Equals: 'Equals',
    NotEquals: 'NotEquals',
    In: 'In',
    IsEmpty: 'IsEmpty',
    IsNotEmpty: 'IsNotEmpty',
    LessThan: 'LessThan',
    GreaterThan: 'GreaterThan',
    LessThanOrEqual: 'LessThanOrEqual',
    GreaterThanOrEqual: 'GreaterThanOrEqual',
    Between: 'Between',
    Today: 'Today',
    LastOneWeek: 'LastOneWeek',
    LastOneMonth: 'LastOneMonth',
    LastOneYear: 'LastOneYear',
    NextWeek: 'NextWeek',
    NextOneMonth: 'NextOneMonth',
    NextOneYear: 'NextOneYear',
    ContextUser: 'ContextUser'
  }
};

export const AllowedOperations = [
  'Edit',
  'Delete',
  'Promote',
  'Link',
  'Unlink',
  'File Upload',
  'File Download',
  'Print Sticker',
  'Export',
  'View Relation',
  'Edit Relation'
];

export const logicalOperator = [
  { label: 'And', value: 'and' },
  { label: 'Or', value: 'or' }
];

export const googleMapsAPIKey = 'AIzaSyCxOo9cOJMaXXfp5_5KYmeC__ULhxobUFk';
