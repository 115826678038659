export const isArrayOfObject = arr => {
  let result = arr.some(val => {
    return typeof val === 'object';
  });

  return result;
};

export const isEmpty = obj => {
  // eslint-disable-next-line no-undef
  if (obj instanceof File) {
    return false;
  }
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
};
