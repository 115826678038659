import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { ASYNC_JOB_STATUS_CONTRACT } from '../../../Core';

const mapByName = arr => {
  let ret = {};
  arr &&
    arr.forEach(element => {
      ret[element.name] = element;
    });
  return ret;
};

const omitAttributeFromEdited = (state, name) => {
  const otherEditedAttributeNames = Object.keys(state.editedValues).filter(
    e => e !== name
  );
  const newEditedValues = pick(state.editedValues, otherEditedAttributeNames);
  return newEditedValues;
};

const omitErrorsFromState = (state, name) => {
  let otherEditedAttributeNames = null;
  if (state.invalidAttributes) {
    otherEditedAttributeNames = Object.keys(state.invalidAttributes).filter(
      e => e !== name
    );
  }

  const newEditedValues = pick(
    state.invalidAttributes,
    otherEditedAttributeNames
  );
  return newEditedValues;
};

const attributeModified = (state, action) => {
  if (
    state.attributeValues &&
    isEqual(state.attributeValues[action.payload.name], action.payload.value)
  ) {
    return {
      ...state,
      editedValues: omitAttributeFromEdited(state, action.payload.name),
      invalidAttributes: omitErrorsFromState(state, action.payload.name),
      saveOperationState: null
    };
  } else {
    const newEditedValues = {
      ...state.editedValues,
      [action.payload.name]: action.payload.value
    };

    let newErrorMap = {};
    if (!action.payload.errorMessage) {
      newErrorMap = omitErrorsFromState(state, action.payload.name);
    } else {
      newErrorMap = {
        ...state.invalidAttributes,
        [action.payload.name]: action.payload.errorMessage
      };
    }

    return {
      ...state,
      editedValues: newEditedValues,
      invalidAttributes: newErrorMap,
      saveOperationState: null
    };
  }
};

const handleCommonActions = (state, action) => {
  switch (action.type) {
    case 'INST_DETAILS_ERROR':
      return state;

    case 'ATTRIBUTE_MODIFIED':
      return attributeModified(state, action);

    case 'INST_SAVE_PENDING':
      const fileCount = action.payload && action.payload.fileCount;
      return {
        ...state,
        saveOperationState: 'pending',
        // uploadAttachmentState: null
        uploadAttachmentState: fileCount ? 'inProgress' : null,
        fileCount: fileCount
      };

    case 'FILE_UPLOAD_SUCCESS':
      return {
        ...state,
        editedValues: { ...state.editedValues, ...action.payload.editedValues },
        uploadAttachmentState: 'success',
        fileCount: null
      };
    case 'FILE_UPLOAD_ERROR':
      return {
        ...state,
        error: getErrorMessage(action),
        fileCount: null,
        saveOperationState: 'error',
        uploadAttachmentState: 'error'
      };

    case 'INST_SAVE_ERROR':
      return {
        ...state,
        error: getErrorMessage(action),
        saveOperationState: 'error',
        uploadAttachmentState: null
      };

    case 'PROMOTE_REMINDER_DISMISSED':
      return {
        ...state,
        showPromoteReminder: false
      };

    case 'PROMOTE_SUCCESS':
      return {
        ...state,
        validationRuleErrors: {},
        promoteOperationState: 'success'
      };

    case 'PROMOTE_ERROR':
      let invalidKeyValue = action.payload;
      return {
        ...state,
        invalidAttributes: { ...state.invalidAttributes, ...invalidKeyValue },
        validationRuleErrors: {
          ...invalidKeyValue
        },
        saveOperationState: null,
        showPromoteReminder: false,
        promoteActionAsyncJobData: null
      };

    case 'AUTO_SAVE_CHANGE':
      return {
        ...state,
        autoSaveEnabled: action.payload
      };

    default:
      return state;
  }
};

export const instanceEditReducer = (state, action) => {
  const initialState = {
    instanceId: null,
    modified: null,
    details: null,
    canCreateInstance: false,
    attributeValues: {},
    editedValues: {},
    newlyAddedAttachments: [],
    saveOperationState: null,
    promoteActionAsyncJobData: null,
    promoteOperationState: null,
    shouldReloadInstanceDetails: false,
    uploadAttachmentState: null,
    showPromoteReminder: false,
    validationRuleErrors: state.validationRuleErrors,
    invalidAttributes: state.validationRuleErrors
      ? { ...state.validationRuleErrors }
      : {},
    autoSaveEnabled: false
  };

  switch (action.type) {
    case 'INST_DETAILS_REQUESTED':
      return initialState;
    case 'INST_DETAILS_SUCCESS':
      const { instanceDetails, canCreateInstance } = action.payload;
      const {
        id,
        modified,
        attributes,
        instanceId,
        versionNumber,
        ...details
      } = instanceDetails;

      return {
        ...state,
        instanceId: instanceId ? instanceId : id,
        versionId: instanceId ? id : null,
        versionNumber: versionNumber,
        modified: modified,
        details: details,
        attributeValues: mapByName(attributes),
        // TODO: editedAttributes
        editedValues: {},
        canCreateInstance: canCreateInstance
      };

    case 'INST_DETAILS_FAILURE':
      const { errorDetails } = action.payload;

      return {
        error: errorDetails
      };
    case 'ATTRIBUTE_RESET':
      return {
        ...state,
        editedValues: omitAttributeFromEdited(state, action.payload.name),
        invalidAttributes: omitErrorsFromState(state, action.payload.name)
      };

    case 'INST_SAVE_SUCCESS':
      let newDetails = { ...state.details };
      if (state.editedValues['Name']) {
        newDetails.name = state.editedValues['Name'].textValue;
      }
      return {
        ...state,
        attributeValues: { ...state.attributeValues, ...state.editedValues },
        details: newDetails,
        editedValues: {},
        saveOperationState: 'success',
        showPromoteReminder: action.payload.showPromoteReminder,
        // action.payload.promoteReminder === 'SHOW_PROMOTE_REMINDER',
        modified: action.payload.modified
      };

    case 'LINK_SUCCESS':
      return {
        ...state,
        shouldReloadInstanceDetails: true
      };
    case 'PROMOTE_ACTION_ASYNC_JOB_DATA_SUCCESS':
      const { jobData } = action.payload;
      if (jobData.status === ASYNC_JOB_STATUS_CONTRACT.IN_PROGRESS) {
        return { ...state, promoteActionAsyncJobData: action.payload.jobData };
      } else if (jobData.status === ASYNC_JOB_STATUS_CONTRACT.COMPLETED) {
        return {
          ...state,
          promoteActionAsyncJobData: null,
          promoteOperationState: 'success'
        };
      }
      return state;

    default:
      return handleCommonActions(state, action);
  }
};

export const instanceCreateReducer = (state, action) => {
  if (action.type === 'INST_CREATE_SUCCESS') {
    return {
      ...state,
      instance: action.payload,
      saveOperationState: 'success'
    };
  }

  if (action.type === 'INST_PROMOTE_CLICKED') {
    return {
      ...state,
      onPromoteClick: action.payload
    };
  }

  return handleCommonActions(state, action);
};

function getErrorMessage(action) {
  if (action && typeof action.payload === 'string') {
    return action.payload;
  }
  return action.payload.response
    ? action.payload.response.data
    : action.payload.message;
}
