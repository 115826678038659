import React, { useEffect, useMemo, useState } from 'react';
import { useInstanceBulkPromote, isEmpty } from '../../../Core';
import {
  Box,
  Text,
  Button,
  ButtonEnums,
  Spinner
} from '../../../lib/components';
import ErrorDetails from '../ErrorDetails';
import useTranslatedValue from '../../../Hooks/useTranslatedValue';

export const InstanceBulkPromoteStatus = ({
  instanceId,
  action,
  actionLabel,
  comment,
  errorType,
  promoteCallback,
  callApi,
  openErrorDisplayModal
}) => {
  const [apiCalled, setApiCalled] = useState(false);
  const [state, run] = useInstanceBulkPromote({
    instanceId,
    action,
    comment,
    defer: true
  });
  const errorOccured = useTranslatedValue('ERROR_OCCURED');
  const inProgressText = useTranslatedValue('IN_PROGRESS');
  const promotedSuccessFully = useTranslatedValue('PROMOTED_SUCCESSFULLY');
  const asyncPromoteStarted = useTranslatedValue('ASYNC_PROMOTE_STARTED');

  function getError(data) {
    let errorMsg = '';
    if (typeof data === 'object') {
      errorMsg = {};
      data &&
        data.forEach(d => {
          if (d.AttributueName) {
            errorMsg[d.AttributueName] = d.ErrorMessage;
          } else if (d.RelationTypeName) {
            errorMsg[d.RelationTypeName] = d.ErrorMessage;
          }
        });
    } else if (typeof data === 'string') {
      //for program generated errors.
      errorMsg = data;
    }
    return errorMsg;
  }

  let errorMessage = useMemo(() => {
    return state.data && state.data.response && state.data.response.data
      ? getError(state.data.response.data)
      : '';
  }, [state.data]);

  useEffect(() => {
    const { isFulfilled, isError, data } = state;
    if (callApi && !apiCalled) {
      run();
      setApiCalled(true);
    }
    if (isFulfilled && data) {
      promoteCallback(instanceId);
    } else if (isError) {
      promoteCallback(instanceId, errorMessage);
    }
  }, [
    errorMessage,
    instanceId,
    promoteCallback,
    state,
    callApi,
    run,
    apiCalled,
    setApiCalled
  ]);

  const SuccessMessageRenderer = ({ message }) => {
    return (
      <>
        <Box
          color="SUCCESS"
          margin="0 20px"
          alignItems="center"
          background="#C8E6C9"
          borderRadius="50%"
          height="20px"
          width="20px"
        >
          <i
            className="fas fa-check"
            aria-hidden="true"
            style={{ padding: '0 4px' }}
          />
        </Box>
        <Text>{message}</Text>
      </>
    );
  };

  if (state.isLoading) {
    return (
      <>
        <Box margin="0 20px" alignItems="center" height="20px" width="20px">
          <Spinner small />
        </Box>
        <Text>{`${action} ${inProgressText}...`}</Text>
      </>
    );
  }

  if (state.isError) {
    return errorType === 'object' ? (
      <>
        <Button
          appearance={ButtonEnums.Appearance.Link}
          onClick={() => {
            openErrorDisplayModal(errorMessage);
          }}
        >
          <Box
            color={'ERROR'}
            margin="0 20px"
            alignItems="center"
            height="20px"
            width="20px"
          >
            <i
              className="fas fa-exclamation-triangle"
              style={{ padding: '0 4px' }}
            />
          </Box>
          <Text color={'ERROR'}>{errorOccured}</Text>
        </Button>
      </>
    ) : (
      <>
        <Box
          color={'ERROR'}
          margin="0 20px"
          alignItems="center"
          height="20px"
          width="20px"
        >
          <i className="fas fa-exclamation-triangle" />
        </Box>
        <ErrorDetails state={state} />
      </>
    );
  }

  if (!isEmpty(state.data)) {
    let successMsg = promotedSuccessFully;
    if (state.data.promoteActionAsyncJobId) {
      successMsg = asyncPromoteStarted.replace('{0}', actionLabel);
    } else {
      successMsg = successMsg.replace('{0}', actionLabel);
    }
    return <SuccessMessageRenderer message={successMsg} />;
  }

  return (
    <>
      <Box margin="0 20px" alignItems="center" height="20px" width="20px">
        <Spinner small />
      </Box>
      <Text>{'Queued '}</Text>
    </>
  );
};
