import React from 'react';
import { Link } from 'react-router-dom';
import { Caption, theme, Box } from '../../lib/components';
import PopOutLink from '../../Components/Common/PopOutLink';

export const InstanceLink = ({
  children,
  typeName,
  instanceId,
  targetLayout,
  canCreateInstance,
  containerStyle,
  openInNewTab = false,
  showPopOutIcon = false
}) => {
  const route = {
    pathname: `/${typeName}/${targetLayout}/${instanceId}`,
    state: {
      canCreateInstance: canCreateInstance
    }
  };
  const styles = { color: theme.colorPalette.SECONDARY, alignSelf: 'center' };

  return (
    <Box style={containerStyle}>
      {openInNewTab ? (
        <PopOutLink to={route}>
          <Box style={styles}>{children}</Box>
        </PopOutLink>
      ) : (
        <Link
          onClick={e => e.stopPropagation()}
          to={route}
          style={{ width: '100%', ...styles }}
        >
          {children}
        </Link>
      )}
      {showPopOutIcon ? (
        <Caption lineHeight={'1.5rem'} style={{ marginLeft: '5px' }}>
          <PopOutLink to={route}>
            <i
              className="fas fa-external-link-alt"
              aria-hidden="true"
              style={{ color: 'var(--secondary)', alignSelf: 'center' }}
            />
          </PopOutLink>
        </Caption>
      ) : null}
    </Box>
  );
};
