export const INSTANCE_VISIBILITY_CONTRACT = {
  Specific: 'Specific',
  Everyone: 'Everyone',
  EveryoneRecursive: 'EveryoneRecursive'
};

export const INSTANCE_VISIBILITY_DISPLAY_NAMES = {
  [INSTANCE_VISIBILITY_CONTRACT.Specific]: 'Specific Users',
  [INSTANCE_VISIBILITY_CONTRACT.Everyone]: 'Everyone',
  [INSTANCE_VISIBILITY_CONTRACT.EveryoneRecursive]: 'Everyone Recursive'
};
