import React from 'react';
import { Button, ButtonEnums, Rotate } from '../lib/components';
import Icon, { IconBoxSize } from '../lib/components/Icon';
import { ReactComponent as RefreshSVG } from '../Content/Images/Reload_32px.svg';

export function ReloadButton({
  isLoading,
  onClick,
  filter,
  colorToFill,
  outline,
  size = ButtonEnums.Size.Large,
  iconSize = IconBoxSize.XLarge,
  appearance = ButtonEnums.Appearance.Transparent
}) {
  return (
    <Button
      title={'Reload'}
      size={size}
      onClick={onClick}
      appearance={appearance}
      outline={outline}
    >
      {isLoading ? (
        <Rotate>
          <RefreshIcon colorToFill={colorToFill} filter={filter} iconSize={iconSize}/>
        </Rotate>
      ) : (
        <RefreshIcon colorToFill={colorToFill} filter={filter} iconSize={iconSize} />
      )}
    </Button>
  );
}

const RefreshIcon = ({ colorToFill, filter, iconSize }) => {
  return (
    <Icon
      SVG={RefreshSVG}
      size={iconSize}
      colorToFill={colorToFill}
      filter={filter}
    />
  );
};
