import React from 'react';
import { ReactComponent as HomeSVGIcon } from '../../Content/Images/Home_32px.svg';
import { ReactComponent as HomeActiveSVGIcon } from '../../Content/Images/Homefill.svg';
import { ReactComponent as ReportSVGIcon } from '../../Content/Images/Bar_Chart_32px.svg';
import { ReactComponent as ReportActiveSVGIcon } from '../../Content/Images/Barchartfill.svg';
import { ReactComponent as SettingsSVGIcon } from '../../Content/Images/Settings_32px.svg';
import { ReactComponent as SettingsActiveSVGIcon } from '../../Content/Images/Settingsfill.svg';
import { ReactComponent as NotificationSVGIcon } from '../../Content/Images/bell.svg';
import { ReactComponent as MenuSVGIcon } from '../../Content/Images/Menu_32px.svg';
import { ReactComponent as MenuCloseSVGIcon } from '../../Content/Images/Multiply_32px.svg';
import { ReactComponent as BackSVGIcon } from '../../Content/Images/Circled Chevron Left_50px.svg';
import { ReactComponent as TokenSVGIcon } from '../../Content/Images/Ticket_50px.svg';
import { ReactComponent as TrashSVGIcon } from '../../Content/Images/icons8-trash-can.svg';
import { ReactComponent as CancelSVGIcon } from '../../Content/Images/Cancel_32px.svg';
import { ReactComponent as RefreshSVG } from '../../Content/Images/Reload_32px.svg';
import { ReactComponent as LogoutSVGIcon } from '../../Content/Images/Logout.svg';
import { ReactComponent as InstallSVGIcon } from '../../Content/Images/Installer.svg';
import { ReactComponent as PasswordSVGIcon } from '../../Content/Images/Key.svg';
import { ReactComponent as QuickEditSVGIcon } from '../../Content/Images/Edit Row_50px.svg';
import { ReactComponent as EditSVGIcon } from '../../Content/Images/Edit File_50px.svg';
import { ReactComponent as UnlinkSVGIcon } from '../../Content/Images/Broken Link_50px.svg';
import { ReactComponent as DeleteSVGIcon } from '../../Content/Images/Delete_50px.svg';
import { ReactComponent as LinkSVGIcon } from '../../Content/Images/Link_50px.svg';
import { ReactComponent as EditRelationSVGIcon } from '../../Content/Images/Edit Property_50px.svg';
import { ReactComponent as PromoteSVGIcon } from '../../Content/Images/One Way Transition_50px.svg';
import { ReactComponent as HelpSVGIcon } from '../../Content/Images/Help_32px.svg';

import { ReactComponent as AndroidSVGIcon } from '../../Content/Images/android.svg';
import { ReactComponent as IOSSVGIcon } from '../../Content/Images/apple_ios.svg';
import { ReactComponent as ExcelSVGIcon } from '../../Content/Images/excel.svg';

import { ReactComponent as DownloadSVGIcon } from '../../Content/Images/download.svg';

import { ReactComponent as CopySVGIcon } from '../../Content/Images/Copy.svg';
import { ReactComponent as MarkerSVGIcon } from '../../Content/Images/Marker.svg';
import { ReactComponent as ExportSVGIcon } from '../../Content/Images/Export.svg';
import { ReactComponent as SaveSVGIcon } from '../../Content/Images/Save.svg';
import { ReactComponent as QRCodeSVGIcon } from '../../Content/Images/QRCode.svg';

import { ReactComponent as FilterActiveSVGIcon } from '../../Content/Images/FilterApplied.svg';
import { ReactComponent as FilterSVGIcon } from '../../Content/Images/FilternotApplied.svg';
import { ReactComponent as FilterAppliedSVGIcon } from '../../Content/Images/checkmark.svg';
import { ReactComponent as UploadSVGIcon } from '../../Content/Images/Upload.svg';
import { ReactComponent as PdfSVGIcon } from '../../Content/Images/Pdf.svg';
import { ReactComponent as DocSVGIcon } from '../../Content/Images/Doc.svg';
import { ReactComponent as JpgSVGIcon } from '../../Content/Images/Jpg.svg';
import { ReactComponent as DefaultSVGIcon } from '../../Content/Images/Default.svg';
import { ReactComponent as XlsSVGIcon } from '../../Content/Images/Xls.svg';
import { ReactComponent as TextSVGIcon } from '../../Content/Images/Txt.svg';
import { ReactComponent as PptSVGIcon } from '../../Content/Images/Ppt.svg';
import { ReactComponent as VideoSVGIcon } from '../../Content/Images/Video.svg';
import { ReactComponent as AudioSVGIcon } from '../../Content/Images/Audio.svg';
import { ReactComponent as AdminEmptyGridSVGIcon } from '../../Content/Images/EmptyGrid.svg';
import { ReactComponent as AdminInfoSVGIcon } from '../../Content/Images/AdminInfoIcon.svg';
import { ReactComponent as ImagePreviewSVGIcon } from '../../Content/Images/ImagePreview.svg';
import { ReactComponent as ProfileSVGIcon } from '../../Content/Images/ProfileIcon.svg';

import classnames from 'classnames';

import classes from './Icons.module.css';

export const HomeIcon = () => (
  <>
    <HomeSVGIcon
      className={classnames(
        classes['svgIcon'],
        classes['svg-border-bottom'],
        classes['svg']
      )}
    />
    <HomeActiveSVGIcon
      className={classnames(
        classes['svgActiveIcon'],
        classes['svg-border-bottom'],
        classes['svg']
      )}
    />
  </>
);

export const ReportIcon = () => (
  <>
    <ReportSVGIcon
      className={classnames(
        classes['svgIcon'],
        classes['svg-border-bottom'],
        classes['svg']
      )}
    />
    <ReportActiveSVGIcon
      className={classnames(
        classes['svgActiveIcon'],
        classes['svg-border-bottom'],
        classes['svg']
      )}
    />
  </>
);

export const SettingsIcon = () => (
  <>
    <SettingsSVGIcon
      className={classnames(
        classes['svgIcon'],
        classes['svg-border-bottom'],
        classes['svg']
      )}
    />
    <SettingsActiveSVGIcon
      className={classnames(
        classes['svgActiveIcon'],
        classes['svg-border-bottom'],
        classes['svg']
      )}
    />
  </>
);

export const NotificationIcon = () => (
  <NotificationSVGIcon
    className={classnames(classes['svg-xsmall'], classes['svg-border-bottom'])}
  />
);

export const MenuIcon = () => <MenuSVGIcon className={classes['svg']} />;

export const MenuCloseIcon = () => (
  <MenuCloseSVGIcon className={classes['svg']} />
);

export const TokenIcon = () => (
  <TokenSVGIcon className={classes['svg-xsmall']} />
);
export const ProfileIcon = () => (
  <ProfileSVGIcon className={classes['svg-xsmall']} />
);
export const LogoutIcon = () => (
  <LogoutSVGIcon className={classes['svg-xsmall']} />
);
export const InstallIcon = () => (
  <InstallSVGIcon className={classes['svg-xsmall']} />
);
export const PasswordIcon = ({ style }) => (
  <PasswordSVGIcon className={classes['svg-xsmall']} style={style} />
);

export const RemoveTokenIcon = () => (
  <TrashSVGIcon className={classes['svg-xsmall']} />
);

export const CancelIcon = () => (
  <CancelSVGIcon className={classes['svg-xsmall']} />
);
export const BackIcon = () => <BackSVGIcon className={classes['svg-small']} />;

export const QuickEditIcon = () => (
  <QuickEditSVGIcon className={classes['svg-small']} />
);

export const EditIcon = () => <EditSVGIcon className={classes['svg-small']} />;

export const DeleteIcon = () => (
  <DeleteSVGIcon className={classes['svg-small']} />
);

export const LinkIcon = () => <LinkSVGIcon className={classes['svg-xsmall']} />;

export const UnlinkIcon = () => (
  <UnlinkSVGIcon className={classes['svg-xsmall']} />
);

export const EditRelationIcon = () => (
  <EditRelationSVGIcon className={classes['svg-xsmall']} />
);

export const PromoteIcon = () => (
  <PromoteSVGIcon className={classes['svg-small']} />
);

//installable icons
export const AndroidIcon = () => (
  <AndroidSVGIcon className={classes['svg-large']} />
);
export const IOSIcon = () => <IOSSVGIcon className={classes['svg-large']} />;
export const ExcelIcon = () => (
  <ExcelSVGIcon className={classes['svg-medium']} />
);
export const ExcelLargeIcon = () => (
  <ExcelSVGIcon className={classes['svg-large']} />
);
export const DownloadIcon = () => (
  <DownloadSVGIcon className={classes['svg-xsmall']} />
);

export const RefreshIcon = () => (
  <RefreshSVG className={classes['svg-xsmall']} />
);

//overflow dropdown icons
export const CopyIcon = () => <CopySVGIcon className={classes['svg-small']} />;
export const MarkerIcon = () => (
  <MarkerSVGIcon className={classes['svg-xsmall']} />
);

export const ExportIcon = () => (
  <ExportSVGIcon className={classes['svg-small']} />
);
export const SaveIcon = () => <SaveSVGIcon className={classes['svg-xsmall']} />;

export const QRCodeIcon = () => (
  <QRCodeSVGIcon className={classes['svg-small']} />
);

//filter
export const FilterActiveIcon = () => (
  <FilterActiveSVGIcon className={classes['svg-small']} />
);

export const FilterAppliedIcon = () => (
  <FilterAppliedSVGIcon className={classes['svg-filter']} />
);

export const FilterIcon = () => (
  <FilterSVGIcon className={classes['svg-medium']} />
);
//home -navitem
export const HomeNavIcon = () => (
  <HomeSVGIcon className={classes['svg-home']} />
);

export const HelpIcon = () => <HelpSVGIcon className={classes['svg-medium']} />;

export const UploadIcon = () => (
  <UploadSVGIcon className={classes['svg-xxsmall']} />
);
export const DefaultIcon = () => (
  <DefaultSVGIcon className={classes['svg-xsmall']} />
);
export const PdfIcon = () => <PdfSVGIcon className={classes['svg-xsmall']} />;
export const DocIcon = () => <DocSVGIcon className={classes['svg-xsmall']} />;
export const XlsIcon = () => <XlsSVGIcon className={classes['svg-xsmall']} />;
export const JpgIcon = () => <JpgSVGIcon className={classes['svg-xsmall']} />;
export const AudioIcon = () => (
  <AudioSVGIcon className={classes['svg-xsmall']} />
);
export const TextIcon = () => <TextSVGIcon className={classes['svg-xsmall']} />;
export const VideoIcon = () => (
  <VideoSVGIcon className={classes['svg-xsmall']} />
);
export const PptIcon = () => <PptSVGIcon className={classes['svg-xsmall']} />;
export const AdminEmptyGridIcon = ({ style }) => (
  <AdminEmptyGridSVGIcon className={classes['svg-large']} style={style} />
);
export const AdminInfoIcon = ({ style }) => (
  <AdminInfoSVGIcon className={classes['svg-xsmall']} style={style} />
);
export const ImagePreviewIcon = ({ style }) => (
  <ImagePreviewSVGIcon className={classes['svg-xsmall']} style={style} />
);
