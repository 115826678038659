import { useProtrakApi } from '../utils';

export const usePreviousLocations = () => {
  const { state, run } = useProtrakApi(
    { requestConfig: getPreviousGeolocations },
    { defer: true }
  );
  return { state, run };
};
const getPreviousGeolocations = ({ instanceId }) => {
  const geolocationActivityUrl = `/instances/${instanceId}/activities?Type=GeolocationChanged`;
  return {
    endpoint: geolocationActivityUrl, config: {
      method: 'GET'
    }
  };
};
