import React from 'react';
import { Enums } from '../../Enums';
import { TextBox, TextArea, Box } from '../../lib/components';
import { Prefix, PrefixForInput, Suffix, SuffixForInput } from './prefixSuffix';
export const TextAttribute = config => {
  const getValue = value => {
    if (typeof value === 'string' || typeof value === 'number') {
      return value;
    }
    if (value && value.textValue) {
      return value.textValue;
    }
    return null;
  };

  const getDefaultValue = () => {
    return { textValue: config.defaultValue ? config.defaultValue[0] : null };
  };

  const getDisplayValue = value => {
    return getValue(value);
  };

  const renderAttributeValue = value => {
    const val = getValue(value);
    if (!val) {
      return <>{'-'}</>;
    }
    return (
      <>
        <Prefix value={config.prefix} />
        {val}
        <Suffix value={config.suffix} />
      </>
    );
  };

  const renderAttributeInput = (value, onEdit) => {
    if (config.isMultiline) {
      return (
        <TextArea
          height={config.height}
          value={getValue(value)}
          onEdit={newValue =>
            onEdit({ textValue: newValue.replace(/^ +/, '') })
          }
          placeholder={config.placeholder}
        />
      );
    } else {
      return (
        <Box>
          <PrefixForInput value={config.prefix} />
          <TextBox
            value={getValue(value)}
            onEdit={newValue =>
              onEdit({ textValue: newValue.replace(/^ +/, '') })
            }
            placeholder={config.placeholder}
          />
          <SuffixForInput value={config.suffix} />
        </Box>
      );
    }
  };

  const renderFilterAttributeInput = (value, onEdit) => {
    return <TextArea value={getValue(value)} onEdit={onEdit} />;
  };

  const filterOptions = [
    { displayName: 'Contains', name: Enums.Conditions.Contains },
    { displayName: 'Not Contains', name: Enums.Conditions.NotContains },
    { displayName: 'Equals', name: Enums.Conditions.Equals },
    { displayName: 'Not Equals', name: Enums.Conditions.NotEquals },
    { displayName: 'In', name: Enums.Conditions.In },
    { displayName: 'Is Empty', name: Enums.Conditions.IsEmpty },
    { displayName: 'Is Not Empty', name: Enums.Conditions.IsNotEmpty }
  ];

  const evaluateCondition = (condition, valueObj, compareToValueStr) => {
    const value = getValue(valueObj);
    switch (condition) {
      case Enums.Conditions.Equals:
        return value && value.toLowerCase() === compareToValueStr.toLowerCase();
      case Enums.Conditions.NotEquals:
        return value && value.toLowerCase() !== compareToValueStr.toLowerCase();
      case Enums.Conditions.Contains:
        return (
          value && value.toLowerCase().includes(compareToValueStr.toLowerCase())
        );
      case Enums.Conditions.NotContains:
        return (
          value &&
          !value.toLowerCase().includes(compareToValueStr.toLowerCase())
        );
      case Enums.Conditions.IsNotEmpty:
        return value && value.length > 0;
      case Enums.Conditions.IsEmpty:
        return !value || value.length === 0;
      case Enums.Conditions.In:
        return compareToValueStr
          .toLowerCase()
          .split('\n')
          .includes(value.toLowerCase());
      default:
        return false;
    }
  };

  const defaultSortOrder = 'Ascending';

  return {
    getDisplayValue,
    renderAttributeValue,
    renderAttributeInput,
    renderFilterAttributeInput,
    filterOptions,
    getValue,
    defaultSortOrder,
    getDefaultValue,
    evaluateCondition
  };
};
