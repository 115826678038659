import React from 'react';

export const HelptextIcon = ({ helpText }) => {
  return helpText ? (
    <i
      className="fa fa-info-circle"
      title={helpText || ''}
      style={{
        cursor: 'pointer',
        color: 'var(--font-color-light)',
        padding: '0px 2px 0px 2px'
      }}
    />
  ) : null;
};
