import React from 'react';
import Attachment from './Attachment';
import classNames from 'classnames';
import { FileDownloader, Text } from '../../lib/components';
import { Prefix, Suffix } from './prefixSuffix';
import { getFileExtension, validateFiles } from '../../Core';
import { getFileIcon } from './Attachment/AttachmentContainer';

export const AttachmentAttribute = config => {
  const getValue = value => {
    return value && value.fileValue;
  };

  const getFileName = value => {
    //name is available when file is saved and filevalue is fetched.
    return value && value.fileValue
      ? value.fileValue.fileName || value.fileValue.name
      : null;
  };

  const getDisplayValue = value => {
    return getFileName(value);
  };

  const renderAttributeValue = value => {
    const fieldValue = getValue(value);
    const fileName = getFileName(value);
    const fileTypeIcon = fileName ? getFileIcon(fileName) : null;
    return fieldValue ? (
      <>
        <Prefix value={config.prefix} />
        <FileDownloader
          fileId={fieldValue.fileId}
          fileName={fileName}
          render={(previewOrDownload, fileIcon) => (
            <button
              className={classNames('action-link', 'cursor-pointer')}
              style={{
                display: 'flex',
                border: '1px solid var(--primary-lighter)',
                padding: '0.1rem 0.7rem 0.1rem 0.2rem',
                alignItems: 'center'
              }}
              onClick={previewOrDownload}
            >
              <div>{fileTypeIcon ? fileTypeIcon : null}</div>
              <Text
                color="SECONDARY"
                style={{
                  padding: '0px 0.5rem',
                  tableLayout: 'fixed',
                  overflow: 'hidden',
                  lineHeight: '1.5rem',
                  wordWrap: 'break-word',
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-word',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  '-webkit-line-clamp': '2',
                  '-webkit-box-orient': 'vertical'
                }}
              >
                {fileName}
              </Text>
            </button>
          )}
        />
        <Suffix value={config.suffix} />
      </>
    ) : (
      '-'
    );
  };

  const validateValue = value => {
    if (value && value.name) {
      const validationResult = validateFiles([value]);
      if (validationResult) {
        return validationResult;
      }

      const extn = getFileExtension(value.name);
      if (!getAcceptedFileExtensions().includes(extn)) {
        return config.regExErrorMessage;
      }
    }
    return null;
  };

  const getAcceptedFileExtensions = () => {
    if (config.regEx) {
      const extensions = config.regEx.split('|');
      if (extensions.length > 0) {
        return extensions.map(e => `.${e.toLowerCase()}`);
      }
    }
    return [];
  };

  const renderAttributeInput = (fieldValue, onEdit) => {
    return (
      <Attachment
        value={getValue(fieldValue)}
        onEdit={newValue => onEdit({ fileValue: newValue })}
        acceptTypes={getAcceptedFileExtensions().join(',')}
      />
    );
  };

  const filterOptions = [];
  const defaultSortOrder = '';

  return {
    getDisplayValue,
    renderAttributeValue,
    renderAttributeInput,
    validateValue,
    filterOptions,
    getValue,
    defaultSortOrder
  };
};
