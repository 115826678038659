import { Box } from '../../../lib/components';
import { Header } from '../DataGrid/Header';

export const HeaderComponent = ({
  columns,
  columnIndex,
  onSortApplied,
  sortConfig = {},
  style = {}
}) => {
  return (
    <Box style={style}>
      <Header
        columns={columns}
        sortConfig={sortConfig}
        onSortApplied={onSortApplied}
        columnObj={{
          ...columns[columnIndex],
          width: style.width,
          index: columnIndex
        }}
      />
    </Box>
  );
};
