import styled from 'styled-components';

export const Text = styled.span`
  font-size: ${props => props.theme.fontVariants.text.size};
  line-height: ${props =>
    props.lineHeight
      ? props.lineHeight
      : props.theme.fontVariants.text.lineHeight};
  color: ${props =>
    props.color
      ? props.theme.colorPalette[props.color]
      : props.theme.fontVariants.text.color};
  font-weight: ${props =>
    props.fontWeight ? props.fontWeight : props.theme.fontVariants.text.weight};
  font-style: ${props =>
    props.fontStyle
      ? props.fontStyle
      : props.theme.fontVariants.text.fontStyle};
  padding-right: ${props => props.paddingRight};
  padding-left: ${props => props.paddingLeft};
  padding-top: ${props => props.paddingTop};
  padding-bottom: ${props => props.paddingBottom};
  padding: ${props => props.padding};
  height: ${props => props.height};
  width: ${props => props.width};
  text-align: ${props => props.textAlign};
`;
