import React from 'react';
import { useSettingsContext, formatDateTimeWithTimeZone } from '../../../Core';

export function FormattedDateTime({ dateTimeStr }) {
  const { settings } = useSettingsContext();
  const { timeFormat, dateFormat, timeZone } = settings.dateTimeFormat;
  const formattedValue = formatDateTimeWithTimeZone(
    dateTimeStr,
    timeFormat,
    dateFormat,
    timeZone
  );
  return <span>{formattedValue}</span>;
}
