import { useEffect, useState } from 'react';
import {
  poll,
  protrakApiClient,
  usePaginatedGet,
  useProtrakApi,
  ASYNC_JOB_ACTIONS,
  useAsyncJobContext,
  client
} from '../utils';
import { generateInstanceQuery } from './instanceQuery';
const asyncJobBase = '/asyncJobs';

export const ASYNC_JOB_TYPES_CONTRACT = {
  ExportSchema: 'ExportSchema',
  ImportSchema: 'ImportSchema',
  ExportDashboardWidget: 'ExportDashboardWidget',
  ExportRelationWidget: 'ExportRelationWidget',
  ExportDocument: 'ExportDocument',
  GenerateReport: 'GenerateReport',
  HandleInstanceAccess: 'HandleInstanceAccess',
  DeleteInstance: 'DeleteInstance',
  PromoteInstance: 'PromoteInstance'
};

export const ASYNC_JOB_STATUS_CONTRACT = {
  IN_PROGRESS: 'InProgress',
  COMPLETED: 'Completed',
  FAILED: 'Failed',
  ABANDONED: 'Abandoned'
};

export function useExportCsv(jobType) {
  const { dispatch, reload } = useAsyncJobContext();

  const onSuccess = () => {
    dispatch({
      type: ASYNC_JOB_ACTIONS.TOGGLE_NOTIFICATION_DRAWER
    });
    reload();
  };

  const { state: asyncJob, run: doCreateJob } = useProtrakApi(
    { promiseFn: postAsyncJob },
    { defer: true, onSuccess: onSuccess }
  );

  const exportCsv = ({ instanceQuery, skip, take, widgetId, instanceId }) => {
    let payload = {};
    if (jobType === ASYNC_JOB_TYPES_CONTRACT.ExportDashboardWidget) {
      payload = {
        type: jobType,
        instanceQuery: generateInstanceQuery(instanceQuery),
        skip,
        take,
        widgetId,
        instanceId
      };
    } else if (jobType === ASYNC_JOB_TYPES_CONTRACT.ExportRelationWidget) {
      payload = {
        type: jobType,
        relatedInstanceQuery: generateInstanceQuery(instanceQuery),
        skip,
        take,
        widgetId,
        instanceId
      };
    }
    doCreateJob(payload);
  };

  useEffect(() => {
    if (asyncJob.isError) {
      alert('Failed to Export');
    }
  }, [asyncJob]);

  return [asyncJob, exportCsv];
}

export function useAsyncJobs(pageSize, asyncJobType) {
  return usePaginatedGet(
    { requestConfig: getAsyncJobs, asyncJobType },
    {},
    pageSize
  );
}

export function useAsyncJobPoll(id) {
  const { dispatch } = useAsyncJobContext();
  const { state, run } = useProtrakApi({ promiseFn: getJobDetails, id: id });
  let POLLING_INTERVAL = parseInt(
    process.env.REACT_APP_ASYNC_JOB_POLLING_INTERVAL_VALUE_IN_MILLISECONDS
  );
  const [pollingInterval, setPollingInterval] = useState(POLLING_INTERVAL);
  const MAX_TIME = parseInt(
    process.env.REACT_APP_ASYNC_JOB_POLLING_MAX_TIME_IN_MINUTE
  );
  const INCREASE_COUNT = parseInt(
    process.env.REACT_APP_ASYNC_JOB_POLLING_COUNT_INCREASE_IN_MILLISECONDS
  );

  useEffect(() => {
    let unmounted = false;
    const pollJobTillComplete = () => {
      if (!state.isLoading && state.data && !state.isError && !unmounted) {
        if (isJobExecutionComplete(state.data)) {
          dispatch({
            type: ASYNC_JOB_ACTIONS.JOB_DATA_UPDATED,
            payload: state.data
          });
        } else if (pollingInterval / 60000 < MAX_TIME) {
          setPollingInterval(prevState => prevState + INCREASE_COUNT);
          run();
        }
      }
    };
    let intervalId = setInterval(pollJobTillComplete, pollingInterval);
    return () => {
      //this will run when pendingjob component unmounts , i.e. when job is completed
      unmounted = true;
      clearInterval(intervalId);
    };
  }, [
    INCREASE_COUNT,
    MAX_TIME,
    POLLING_INTERVAL,
    dispatch,
    pollingInterval,
    run,
    state
  ]);
}

export function useUpdateAsyncJobStatus(id) {
  const { state, run } = useProtrakApi(
    {
      requestConfig: updateAsyncJobStatus,
      id
    },
    { defer: true }
  );

  return { state, run };
}

const updateAsyncJobStatus = async ({ id, authContext }) => {
  const endpoint = `${asyncJobBase}/${id}`;
  const res = await protrakApiClient(
    endpoint,
    {
      method: 'PUT',
      data: { isRead: true }
    },
    authContext
  );
  return res;
};

export function useInstancePromoteProgress() {
  const { dispatch } = useAsyncJobContext();
  const { state, run } = useProtrakApi(
    { promiseFn: getJobDetails },
    { defer: true }
  );
  const POLLING_INTERVAL = process.env.REACT_APP_ASYNC_JOB_POLLING_INTERVAL;
  useEffect(() => {
    let unmounted = false;
    const pollJobTillComplete = () => {
      if (!state.isLoading && state.data && !state.isError && !unmounted) {
        if (isJobExecutionComplete(state.data)) {
          dispatch({
            type: ASYNC_JOB_ACTIONS.JOB_DATA_UPDATED,
            payload: state.data
          });
        } else {
          run();
        }
      }
    };
    let intervalId;
    if (state.data && !isJobExecutionComplete(state.data)) {
      intervalId = setInterval(pollJobTillComplete, POLLING_INTERVAL);
    }
    return () => {
      //this will run when pendingjob component unmounts , i.e. when job is completed
      unmounted = true;
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [POLLING_INTERVAL, dispatch, run, state]);

  return [state, run];
}

export function useLatestReportJobData(widgetId) {
  const { state, run } = useProtrakApi(
    {
      promiseFn: fetchReportDataForLatestJob,
      widgetId
    },
    { defer: true }
  );

  return { state, run };
}

export function useChangeInstanceVisibility() {
  const { dispatch, reload } = useAsyncJobContext();
  const onSuccess = () => {
    dispatch({
      type: ASYNC_JOB_ACTIONS.TOGGLE_NOTIFICATION_DRAWER
    });
    reload();
  };

  const { state: asyncJob, run: doCreateJob } = useProtrakApi(
    { promiseFn: postAsyncJob },
    { defer: true, onSuccess: onSuccess }
  );

  const changeVisibility = ({ instanceId, instanceVisibility }) => {
    let payload = {
      type: ASYNC_JOB_TYPES_CONTRACT.HandleInstanceAccess,
      instanceId,
      instanceVisibility
    };

    doCreateJob(payload);
  };

  return [asyncJob, changeVisibility];
}

export function useDeleteInstance() {
  const { dispatch, reload } = useAsyncJobContext();
  const onSuccess = () => {
    dispatch({
      type: ASYNC_JOB_ACTIONS.TOGGLE_NOTIFICATION_DRAWER
    });
    reload();
  };

  const { state: asyncJob, run: doCreateJob } = useProtrakApi(
    { promiseFn: postAsyncJob },
    { defer: true, onSuccess: onSuccess }
  );

  const deleteInstance = ({ promiseParams }) => {
    let payload = {
      type: ASYNC_JOB_TYPES_CONTRACT.DeleteInstance,
      instanceId: promiseParams.instanceId
    };
    doCreateJob(payload);
  };

  return [asyncJob, deleteInstance];
}

export function useReportDataGenerator() {
  const { dispatch, reload } = useAsyncJobContext();

  //TODO: to avoid double polling, this needs to be refactored
  //omce refactor is done, there is no need to pass onCreateJob to
  //generateReportData also.
  //create job - open drawer which will start polling
  //once the job is complete, get file url and json content
  //in effect

  const onCreateJob = () => {
    reload();
    dispatch({
      type: ASYNC_JOB_ACTIONS.TOGGLE_NOTIFICATION_DRAWER
    });
  };

  const { state, run } = useProtrakApi(
    { promiseFn: generateReportData, onCreateJob: onCreateJob },
    {
      defer: true
    }
  );

  const generateReport = ({ widgetId, reportQuery }) => {
    let payload = {
      type: ASYNC_JOB_TYPES_CONTRACT.GenerateReport,
      widgetId: widgetId,
      reportQuery: reportQuery
    };
    run(payload);
  };

  return { state, generateReport };
}

export const postAsyncJob = async ({
  jobType,
  authContext,
  ...otherParams
}) => {
  const res = await protrakApiClient(
    asyncJobBase,
    {
      method: 'POST',
      data: {
        type: jobType,
        ...otherParams
      }
    },
    authContext
  );
  return res;
};

// const updateAsyncJobStatus = ({ id }) => {
//   const endpoint = `${asyncJobBase}/${id}`;
//   return {
//     endpoint: endpoint, config: {
//       method: 'PUT',
//       data: { isRead: true }
//     }
//   };
// };

export const isJobExecutionComplete = data => {
  return data.status !== ASYNC_JOB_STATUS_CONTRACT.IN_PROGRESS;
};

const generateReportData = async ({
  widgetId,
  reportQuery,
  onCreateJob,
  authContext
}) => {
  const postState = await postAsyncJob({
    jobType: ASYNC_JOB_TYPES_CONTRACT.GenerateReport,
    widgetId,
    reportQuery,
    authContext
  });

  onCreateJob && onCreateJob();

  const jobDetails = await pollJobDetails({ id: postState.data, authContext });
  return getReportDataFromJob({ jobDetails });
};

const fetchReportDataForLatestJob = async ({ widgetId, authContext }) => {
  let jobDetails = await getLatestJobForReportWidget({ widgetId, authContext });
  if (!isJobExecutionComplete(jobDetails)) {
    jobDetails = await pollJobDetails({ id: jobDetails.id, authContext });
  }
  return getReportDataFromJob({ jobDetails });
};

const getReportDataFromJob = async ({ jobDetails }) => {
  if (jobDetails.status === ASYNC_JOB_TYPES_CONTRACT.FAILED) {
    return { data: { ...jobDetails } };
  }
  const reportJson = await loadReportJson({ fileUrl: jobDetails.url });
  return { data: { ...jobDetails, reportJson } };
};

const pollJobDetails = async ({ id, authContext }) => {
  const res = await poll(
    () => getJobDetails({ id, authContext }),
    isJobExecutionComplete,
    process.env.REACT_APP_ASYNC_JOB_POLLING_INTERVAL
  );

  return res;
};

const getAsyncJobs = ({ skip, take, asyncJobType }) => {
  return {
    endpoint: asyncJobBase,
    config: {
      params: { skip, take, asyncJobType }
    }
  };
};

const getJobDetails = async ({ id, authContext }) => {
  const endpoint = `${asyncJobBase}/${id}`;
  const res = await protrakApiClient(endpoint, {}, authContext);
  return res;
};

const getLatestJobForReportWidget = async ({ widgetId, authContext }) => {
  const endpoint = `${asyncJobBase}/reportWidgets/${widgetId}`;
  const res = await protrakApiClient(endpoint, {}, authContext);
  return res.data;
};

const loadReportJson = async ({ fileUrl }) => {
  try {
    const res = await client(fileUrl);
    return res.data;
  } catch (error) {
    return error;
  }
};

const getAsyncJobStatus = ({ jobid }) => {
  const url = `${asyncJobBase}/${jobid}`;
  return {
    endpoint: url,
    config: {
      method: 'GET'
    }
  };
};

export function useAsyncJobStatus() {
  const { state, run } = useProtrakApi(
    {
      requestConfig: getAsyncJobStatus
    },
    { defer: true }
  );

  return { state, run };
}
