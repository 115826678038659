import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  display: inline-block;
  border: 3px solid ${props => props.theme.colorPalette.SECONDARY_LIGHTER};
  border-left-color: ${props => props.theme.colorPalette.SECONDARY_DARK};
  border-radius: 50%;
  width: ${props => (props.smallest ? '0.8rem' : props.small ? '1.2rem' : '2rem')};
  height: ${props => (props.smallest ? '0.8rem' : props.small ? '1.2rem' : '2rem')};
  animation: ${spin} 1.2s infinite cubic-bezier(0.53, 0.21, 0.29, 0.67);
`;
