import { Attribute } from '../../shared/Components';
import { evaluateStyleModelFromString } from './conditionalFormattingEvaluator';
import { evaluateConditions } from './conditionEvaluator';

export const evaluateFilterConditions = (
  filterConditions,
  getAttributeWorkingValue,
  allSettings
) => {
  const filterConditionValues = filterConditions.map(fc => {
    const { attributeName, condition, firstValue, secondValue } = fc;
    const attributeValue = getAttributeWorkingValue(attributeName);
    if (!attributeValue) {
      return false;
    }
    const { userData, settings } = allSettings;
    const { timeZone } = settings.dateTimeFormat;
    const attr = Attribute({
      attributeName: attributeName,
      attributeType: attributeValue.type ? attributeValue.type : 'Text',
      currentUserId: userData.userId
    });
    return attr.evaluateRuleCondition(
      condition,
      attributeValue,
      firstValue,
      secondValue,
      timeZone
    );
  });
  const filterConditionOperators = filterConditions.map(fc => fc.operator);

  return evaluateConditions(filterConditionValues, filterConditionOperators);
};

export const evaluateGroupConditions = (
  attributeGroups,
  getAttributeWorkingValue,
  settings
) => {
  const conditionValues = attributeGroups.map(ag => {
    return evaluateFilterConditions(
      ag.attributeFilterConditions,
      getAttributeWorkingValue,
      settings
    );
  });

  const operators = attributeGroups.map(ag => ag.operator);
  return evaluateConditions(conditionValues, operators);
};

export function evaluateConditionalFormatting(
  field,
  getAttributeWorkingValue,
  settings
) {
  let matchingStyleString = '';
  if (field.formatConditions && field.formatConditions.length > 0) {
    field.formatConditions.every(item => {
      const result = evaluateGroupConditions(
        item.rule.rules,
        getAttributeWorkingValue,
        settings
      );
      if (result) {
        matchingStyleString = item.style;
        return false;
      }
      return true;
    });
  }

  return evaluateStyleModelFromString(matchingStyleString);
}
