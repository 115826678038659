import React from 'react';
import { Box } from '../../lib/components';

export const ReportRelationFilterContainer = ({children, applyMarginTop}) => {
    return (
        <Box border=" 1px solid var(--primary-lighter)" marginTop={applyMarginTop ? '15px' : '0px'}>
            {children}
        </Box>
    );
};
