import styled from 'styled-components';

export const SmallCaption = styled.span`
  font-size: ${props => props.theme.fontVariants.xsmall.size};
  line-height: ${props => props.theme.fontVariants.xsmall.lineHeight};
  color: ${props =>
    props.color
      ? props.theme.colorPalette[props.color]
      : props.theme.fontVariants.caption.color};
  font-weight: ${props =>
    props.fontWeight
      ? props.fontWeight
      : props.theme.fontVariants.caption.fontWeight};
`;
