import { useContext, createContext } from 'react';

export const AsyncJobContext = createContext();

const ASYNC_JOB_ACTIONS = {
  JOBS_REQUESTED: 'JOBS_REQUESTED',
  JOBS_FETCH_SUCCESS: 'JOBS_FETCH_SUCCESS',
  JOBS_FETCH_FAILURE: 'JOBS_FETCH_FAILURE',
  TOGGLE_NOTIFICATION_DRAWER: 'TOGGLE_NOTIFICATION_DRAWER',
  JOB_DATA_UPDATED: 'JOB_DATA_UPDATED',
  RELOAD: 'RELOAD'
};

const PAGE_SIZE = 15;

const asyncJobInitialState = {
  isLoading: false,
  isError: false,
  jobs: null,
  totalCount: null,
  skip: 0,
  take: PAGE_SIZE,
  drawerVisible: false
};

const asyncJobsReducer = (state, action) => {
  switch (action.type) {
    case ASYNC_JOB_ACTIONS.JOBS_REQUESTED:
      return {
        ...state,
        isLoading: true,
        isError: false
      };

    case ASYNC_JOB_ACTIONS.JOBS_FETCH_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        jobs: [...action.payload.items],
        totalCount: action.payload.totalCount
      };
    }
    case ASYNC_JOB_ACTIONS.JOBS_FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    case ASYNC_JOB_ACTIONS.TOGGLE_NOTIFICATION_DRAWER:
      return {
        ...state,
        drawerVisible: !state.drawerVisible
      };
    case ASYNC_JOB_ACTIONS.JOB_DATA_UPDATED:
      const updatedJob = action.payload;
      const newJobs = state.jobs.map(job =>
        job.id === updatedJob.id ? updatedJob : job
      );
      return {
        ...state,
        jobs: newJobs
      };

    case ASYNC_JOB_ACTIONS.RELOAD:
      return {
        ...state,
        jobs: [],
        totalCount: 0,
        isLoading: true,
        isError: false
      };

    default:
      return asyncJobInitialState;
  }
};

const useAsyncJobContext = () => {
  const context = useContext(AsyncJobContext);

  if (context === undefined) {
    throw new Error('Asyncjob context is undefined');
  }
  return context;
};

export {
  useAsyncJobContext,
  ASYNC_JOB_ACTIONS,
  asyncJobsReducer,
  PAGE_SIZE,
  asyncJobInitialState
};
