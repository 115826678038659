import { Container } from './Container';
import { Spinner } from './Spinner';

export const CloneLoadingState = () => {
  return (
    <Container>
      <Spinner />
    </Container>
  );
};
