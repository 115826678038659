import { usePaginatedGet } from '../utils';

const instanceBase = '/instances';
const recentlyUpdatedItemsURL = `${instanceBase}/myRecentlyUpdated`;

export function useRecentlyUpdatedItems(pageSize) {
  const {
    state,
    reload,
    nextPage,
    paginationState,
    fetchItems
  } = usePaginatedGet(
    {
      requestConfig: getRecentlyUpdatedItems,
      skip: 0,
      take: pageSize
    },
    {},
    pageSize
  );

  return { state, fetchItems, reload, nextPage, paginationState };
}

const getRecentlyUpdatedItems = ({ skip, take }) => {
  return {
    endpoint: recentlyUpdatedItemsURL, config: {
      params: { skip, take }
    }
  };
};
