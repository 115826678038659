import React from 'react';
import { Button, ButtonEnums, theme, Box } from '../../../lib/components';
import { ActionIcon } from '../../../shared/Components/ActionIcon';
import { Enums } from '../../../Enums';

export const ActionMenuItem = ({ text, onSelect, type, disabled }) => {
  return (
    <Box
      width="100%"
      justifyItems="flex-start"
      key="text"
      borderBottom={'1px solid ' + theme.colorPalette.PRIMARY_LIGHTER}
    >
      <Button
        text={text}
        onClick={onSelect}
        appearance={
          type === Enums.AllowedOperations.Promote
            ? ButtonEnums.Appearance.SubtleLink
            : ButtonEnums.Appearance.Subtle
        }
        style={{ width: '100%', justifyContent: 'start' }}
        textStyle={{ whiteSpace: 'pre-wrap' }}
        disabled={disabled}
        size={ButtonEnums.Size.Medium}
      >
        <ActionIcon type={type} />
      </Button>
    </Box>
  );
};
