import { dataFetchReducer } from './dataFetchReducer';

export function keyValueDataFetchReducer(state, action) {
  if (!action || !action.key) {
    throw new Error('Invalid action passed, action.key not available');
  }
  const { key } = action;
  const prevState = state[key] || {};
  return {
    ...state,
    [key]: dataFetchReducer(prevState, action)
  };
}
