import React from 'react';
import { Box } from './Box';
import ReactDOM from 'react-dom';
import classes from './Modal/Modal.module.css';
import classnames from 'classnames';
import { Manager, Reference, Popper } from 'react-popper';
import { usePortal } from './Modal/Modal';

export const Menu = ({ menuComponent, open }) => {
  const target = usePortal();
  if (!open) {
    return null;
  }

  return (
    <Manager>
      <Reference>{({ ref }) => <div ref={ref} />}</Reference>
      {ReactDOM.createPortal(renderContent(menuComponent), target)}
    </Manager>
  );
};

const renderContent = menuComponent => {
  return (
    <Box
      style={{
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'visible'
      }}
    >
      <Box className={classnames(classes['white-background'])}>
        <Popper placement={'bottom-end'}>
          {({ ref, style, placement, arrowProps }) => (
            <div ref={ref} style={style} data-placement={placement}>
              {menuComponent}
              <div ref={arrowProps.ref} style={arrowProps.style} />
            </div>
          )}
        </Popper>
      </Box>
    </Box>
  );
};
