import styled from 'styled-components';
import React from 'react';

const RadioButtonContainer = styled.div`
  display: flex;
  vertical-align: middle;
  align-items: center;
`;
export const RadioButtonLabel = styled.label`
  display: inline-block;
  font-family: ${props => props.theme.typography.baseFontFamily};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '400')};
  margin-left: ${props => (props.leftMargin ? props.leftMargin : '5px')};
  margin-right: ${props => (props.rightMargin ? props.rightMargin : '10px')};
  text-overflow: ${props =>
    props.textOverflow ? props.textOverflow : 'ellipses'};
`;
const Icon = styled.svg``;
const Circle = styled.circle.attrs(props => ({
  cx: '50%',
  cy: '30%',
  r: '8',
  stroke: `${props.theme.colorPalette.SECONDARY_DARK}`,
  fill: 'white'
}))``;
const HiddenRadioButton = styled.input.attrs(props => ({
  type: 'radio',
  disabled: props.disabled === 'true' ? props.disabled : ''
}))`
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 0px;
`;

const StyledRadioButton = styled.div`
  display: inline-block;
  cursor: pointer;
  width: 10px;
  height: 10px;
  background: ${props =>
    props.checked ? props.theme.colorPalette.SECONDARY_DARK : 'transparent'};
  border-radius: 50%;
  transition: all 150ms;
  border: 1px solid
    ${props =>
      props.checked
        ? props.theme.colorPalette.SECONDARY_DARK
        : props.theme.colorPalette.PRIMARY_DARK};

  &:hover {
    border: 1px solid ${props => props.theme.colorPalette.SECONDARY_DARK};
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;

const StyledRadioButtonDisabled = styled.div`
  display: inline-block;
  width: 10px;
  height: 10px;
  background: ${props =>
    props.checked ? props.theme.colorPalette.PRIMARY_DARK : 'transparent'}
 border-radius: 50%;
  opacity:0.5;
  transition: all 150ms;
border:1px solid ${props =>
  props.checked
    ? props.theme.colorPalette.PRIMARY_LIGHTER
    : props.theme.colorPalette.PRIMARY_LIGHTER};
  
  &:hover {
    border:1px solid ${props => props.theme.colorPalette.PRIMARY_LIGHTER};
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')}
  }
`;

const RenderStyledRadioButton = (disabled, checked) => {
  if (disabled === true) {
    return (
      <StyledRadioButtonDisabled checked={checked}>
        <Icon viewBox={checked ? '0 0 35 35' : '0 0 24 24'}>
          <Circle />
        </Icon>
      </StyledRadioButtonDisabled>
    );
  } else {
    return (
      <StyledRadioButton checked={checked}>
        <Icon viewBox={checked ? '0 0 35 35' : '0 0 24 24'}>
          <Circle />
        </Icon>
      </StyledRadioButton>
    );
  }
};
export const RadioButton = ({ checked = false, onChange, ...props }) => {
  return (
    <label>
      <RadioButtonContainer className={props.className && props.className}>
        <HiddenRadioButton
          checked={checked}
          {...props}
          onChange={event => {
            onChange(event, props.option && props.option.name);
          }}
        />
        {props.disabled ? (
          RenderStyledRadioButton(props.disabled, checked)
        ) : (
          <StyledRadioButton checked={checked}>
            <Icon viewBox={checked ? '0 0 35 35' : '0 0 24 24'}>
              <Circle />
            </Icon>
          </StyledRadioButton>
        )}

        {props.label && (
          <RadioButtonLabel title={props.title && props.title}>
            {props.label}
          </RadioButtonLabel>
        )}
      </RadioButtonContainer>
    </label>
  );
};
