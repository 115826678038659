import { useAuthContext } from './AuthContext';
import { useApi } from './useApi';

export function useProtrakApi({ requestConfig, promiseFn, ...args }, _options = {}) {
  const authContext = useAuthContext();

  const protrakOptions = {
    ..._options,
    onAuthError: () => {
      if (authContext && authContext.onAuthError) {
        authContext.onAuthError(run, abort);
      }
    },
    middleware: authContext
  };

  const { state, run, abort } = useApi({ requestConfig, promiseFn, ...args }, protrakOptions);
  return { state, run, abort };
}