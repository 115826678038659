import React from 'react';
import useTranslatedValue from '../../../Hooks/useTranslatedValue';
import { InstanceLink } from '../../../shared/Components/InstanceLink';
import { Button, ButtonEnums, Box, theme } from '../../../lib/components';
import { EditIcon } from '../../Icons';

export const EditAction = ({ typeName, instanceId }) => {
  return (
    <InstanceLink
      typeName={typeName}
      instanceId={instanceId}
      targetLayout={'edit'}
    >
      <Box
        width="100%"
        justifyItems="flex-start"
        key="Edit"
        borderBottom={'1px solid ' + theme.colorPalette.PRIMARY_LIGHTER}
      >
        <Button
          text={useTranslatedValue('ACTION_EDIT')}
          appearance={ButtonEnums.Appearance.Subtle}
          style={{ width: '100%', justifyContent: 'start' }}
          size={ButtonEnums.Size.Medium}
        >
          <EditIcon />
        </Button>
      </Box>
    </InstanceLink>
  );
};

export const EditButtonAction = ({ typeName, instanceId }) => {
  return (
    <InstanceLink
      containerStyle={{ alignSelf: 'center' }}
      typeName={typeName}
      instanceId={instanceId}
      targetLayout={'edit'}
    >
      <Button
        text={'Edit'}
        title={'Edit'}
        appearance={ButtonEnums.Appearance.Primary}
        style={{ color: 'var(--surface)' }}
        size={ButtonEnums.Size.Medium}
      />
    </InstanceLink>
  );
};
