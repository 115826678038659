import { theme } from '../AppThemeProvider';

export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: '29px',
    borderRadius: 0,
    width: '100%',
    boxShadow: state.isFocused
      ? ' inset 0 1px 1px rgba(0,0,0,.075), 0 0 1.5px #7719aa'
      : 'none',
    borderColor: state.isFocused
      ? theme.colorPalette.SECONDARY
      : theme.colorPalette.PRIMARY_LIGHTER,
    '&:hover': {
      borderColor: state.isFocused
        ? theme.colorPalette.SECONDARY
        : theme.colorPalette.PRIMARY_LIGHTER
    },
    alignItems: 'inherit'
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '1.1rem',
    marginTop: '0.5rem',
    color: theme.colorPalette.PRIMARY_DARK,
    cursor: 'pointer'
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0 0 0 5px'
  }),
  clearIndicator: provided => ({
    ...provided,
    padding: '5px'
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: '5px'
  }),
  multiValueLabel: provided => ({
    ...provided,
    padding: '2px',
    fontSize: '1.1rem',
    whiteSpace: 'initial'
  }),
  multiValue: provided => ({
    ...provided,
    backgroundColor: theme.colorPalette.SECONDARY_LIGHTER,
    borderRadius: '15px',
    fontSize: '1.1rem'
  }),
  input: provided => ({
    ...provided,
    padding: 0,
    margin: 0
  }),
  singleValue: provided => ({
    ...provided,
    color: theme.colorPalette.PRIMARY_DARK,
    overflow: 'initial',
    fontSize: '1.1rem'
  }),
  menuPortal: provided => ({ ...provided, zIndex: 9999 }),
  menu: provided => ({ ...provided, zIndex: 9999 }),
  option: (provided, { isSelected, isFocused }) => {
    return {
      ...provided,
      backgroundColor: getBackgroundColor(isSelected, isFocused),
      '&:hover': {
        backgroundColor: isSelected
          ? theme.colorPalette.SECONDARY_LIGHT
          : theme.colorPalette.SECONDARY_LIGHTER
      }
    };
  }
};

function getBackgroundColor(isSelected, isFocused) {
  if (isSelected) {
    return theme.colorPalette.SECONDARY_LIGHT;
  } else if (isFocused) {
    return theme.colorPalette.SECONDARY_LIGHTER;
  } else {
    return '';
  }
}

export const customStylesForMultiSelect = {
  control: (provided, state) => ({
    ...provided,
    minHeight: '30px',
    borderRadius: '3px',
    width: '100%',
    fontSize: '1.05em',
    boxShadow: 'none',
    borderColor: state.isFocused ? 'var(--secondary)' : 'rgba(78,78,78,0.22)',
    '&:hover': {
      borderColor: 'var(--secondary)'
    },
    alignItems: 'inherit',
    backgroundColor: state.isDisabled ? '#f7f7f7' : '#fff'
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '1rem',
    marginTop: '0.6rem',
    color: '#34383c',
    cursor: 'pointer'
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0 0 0 0.3rem',
    fontSize: '1.05em'
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: '5px'
  }),
  multiValueLabel: provided => ({
    ...provided,
    padding: '0.1rem 0.1rem 0.1rem 0.3rem !important',
    fontSize: '1.1rem',
    whiteSpace: 'initial'
  }),
  clearIndicator: provided => ({
    ...provided,
    display: 'none'
  }),
  multiValue: provided => ({
    ...provided,
    backgroundColor: '#e6e6e6',
    borderRadius: '2.5rem',
    fontSize: '1em',
    padding: '0 3px 1px 7px'
  }),
  input: provided => ({
    ...provided,
    padding: 0,
    margin: 0
  }),
  singleValue: provided => ({
    ...provided,
    color: '#555',
    overflow: 'initial',
    fontSize: '1em'
  }),
  menuPortal: provided => ({ ...provided, zIndex: 9999 }),
  menu: provided => ({ ...provided, zIndex: 9999 }),
  option: (provided, { isSelected, isFocused }) => {
    return {
      ...provided,
      height: '30px',
      paddingTop: '5px',
      //   margin: 0,
      backgroundColor: getBackgroundColor(isSelected, isFocused),
      '&:hover': {
        backgroundColor: isSelected ? '#a137da' : '#e9e6eb'
      }
    };
  }
};

export const customStylesForSingleSelect = {
  control: (provided, state) => ({
    ...provided,
    minHeight: '30px',
    borderRadius: '3px',
    width: '100%',
    fontSize: '1.05em',
    borderColor: state.isFocused ? 'var(--secondary)' : 'rgba(78,78,78,0.22)',
    boxShadow: 'none',
    '&:hover': {
      borderColor: 'var(--secondary)'
    },
    alignItems: 'inherit',
    backgroundColor: state.isDisabled ? '#f7f7f7' : 'var(--surface)'
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '1rem',
    marginTop: '0.6rem',
    color: '#34383c',
    cursor: 'pointer'
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0 0 0 0.3rem',
    fontSize: '1.05em'
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: '5px'
  }),
  multiValueLabel: provided => ({
    ...provided,
    padding: '2px',
    fontSize: '1.1rem',
    whiteSpace: 'initial'
  }),
  multiValue: provided => ({
    ...provided,
    backgroundColor: '#e6e6e6',
    borderRadius: '7px',
    fontSize: '1em'
  }),
  input: provided => ({
    ...provided,
    padding: 0,
    margin: 0
  }),
  singleValue: provided => ({
    ...provided,
    color: '#555',
    overflow: 'initial',
    fontSize: '1em'
  }),
  menuPortal: provided => ({ ...provided, zIndex: 9999 }),
  menu: provided => ({ ...provided, zIndex: 9999 }),
  option: (provided, { isSelected, isFocused }) => {
    return {
      ...provided,
      height: '30px',
      paddingTop: '5px',
      //   margin: 0,
      backgroundColor: getBackgroundColor(isSelected, isFocused),
      '&:hover': {
        backgroundColor: isSelected ? '#a137da' : '#e9e6eb'
      }
    };
  }
};
