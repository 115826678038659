import * as React from 'react';
import styled from 'styled-components';

const StyledIndicator = styled('span')({
  color: 'var(--error)'
});

export const RequiredIndicator = ({ required }) => {
  if (required) {
    return <StyledIndicator>*</StyledIndicator>;
  }
  return null;
};
