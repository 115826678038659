import CustomWidget from './CustomWidget';
import FieldsWidget from './FieldsWidget';
import { useContext } from 'react';
import InstanceEditContext from '../../Contexts/InstaceEditContext';

export const CreateWidgetRenderer = ({ config, fields, editMode }) => {
  const { widgetType } = config;

  const { instanceEditState } = useContext(InstanceEditContext);

  const { details, editedValues } = instanceEditState;

  if (widgetType === 'CustomWidget') {
    const pageContext = {
      instanceType: details.instanceTypeName,
      attributeValues: editedValues
    };
    return (
      <CustomWidget
        id={config.customWidgetFileId}
        title={config.customWidgetTitle}
        pageContext={pageContext}
      />
    );
  }

  return <FieldsWidget fields={fields} editMode={editMode} />;
};
