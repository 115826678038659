import React, { useEffect, useState } from 'react';
import {
  getUsers,
  deserializeUserValues,
  useUserDetails,
  mapPicklistValues,
  useAuthContext
} from '../../Core';
import Skeleton from 'react-loading-skeleton';
import { AsyncDropdown, Grid } from '../../lib/components';

export const AllUsers = ({
  isMultiselect,
  value,
  onEdit,
  placeholder,
  userRoleFilters
}) => {
  const [userDetails, runUserDetails] = useUserDetails(value);
  const [uservalue, setuservalue] = useState(value);
  const authContext = useAuthContext();

  useEffect(() => {
    //typeof value[0] === 'string' condition is for default uservalue in attribute filter
    let arr = [];
    if (
      value &&
      typeof value[0] === 'string' &&
      !userDetails.isLoading &&
      !userDetails.isFulfilled
    ) {
      runUserDetails({
        value
      });
    }
    if (value && typeof value[0] === 'string' && userDetails.isFulfilled) {
      arr.push(userDetails.data);
      setuservalue(arr);
    }
  }, [runUserDetails, userDetails, value]);

  const onValueChange = selectedItems => {
    let selectedUsers = deserializeUserValues(
      selectedItems,
      value,
      isMultiselect
    );
    onEdit(selectedUsers);
    setuservalue(selectedUsers);
  };

  if (userDetails.isLoading) {
    return <Skeleton isLoading={true} />;
  }

  const UsersPromise = inputValue => {
    return getUsers({
      inputValue,
      includeAdmins: true,
      roleFilter: userRoleFilters ? { roles: userRoleFilters } : {},
      authContext
    });
  };

  return (
    <Grid height="26px" rows={['auto']} columns={['auto']} areas={[['auto']]}>
      <AsyncDropdown
        value={mapPicklistValues(uservalue, 'userName', 'userId')}
        isMultiselect={isMultiselect}
        onValueChange={onValueChange}
        defaultOptions={mapPicklistValues(uservalue, 'userName', 'userId')}
        loadOptions={UsersPromise}
        placeholder={placeholder}
      />
    </Grid>
  );
};
