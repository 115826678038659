import { useRef, useState, useEffect } from 'react';

export function useHover() {
  const [isHovered, setHover] = useState(false);

  const ref = useRef(null);

  const handleMouseOver = () => setHover(true);
  const handleMouseOut = () => setHover(false);

  useEffect(() => {
    const contentRef = ref.current;
    if (contentRef) {
      contentRef.addEventListener('mouseover', handleMouseOver);
      contentRef.addEventListener('mouseout', handleMouseOut);

      return () => {
        contentRef.removeEventListener('mouseover', handleMouseOver);
        contentRef.removeEventListener('mouseout', handleMouseOut);
      };
    }
  });
  // this ref needs to be attached to the component for which use hover is being done
  return [ref, isHovered];
}
