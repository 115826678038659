import { Box } from '../Box';
import { CheckBox } from './CheckBox';

export const CheckboxGroup = ({ options, value, onEdit }) => {
  function onValueChange(event, changedValue) {
    let checkedValues = value ? [...value] : [];

    let updatedValues = [];
    if (event.target.checked) {
      updatedValues = [...checkedValues, changedValue];
    } else {
      updatedValues = checkedValues.filter(v => v !== changedValue);
    }

    onEdit(updatedValues);
  }

  return (
    <Box>
      {options &&
        options.map((option, index) => {
          return (
            <Box key={option.name} position="relative" padding="5px 0px">
              <CheckBox
                key={option.name}
                option={option}
                onChange={onValueChange}
                label={option.displayName.length > 0 && option.displayName}
                checked={value && value.includes(option.name)}
              />
            </Box>
          );
        })}
    </Box>
  );
};
