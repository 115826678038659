import { useCallback, useEffect } from 'react';
import {
  useProtrakApi,
  protrakApiClient,
  uploadFile,
  isEmpty,
  usePaginatedGet,
  useProtrakMultiApi
} from '../utils';
import { populateAttributeParams, getEditedAttachmentAttributes } from './file';
import { getFileType } from '../utils';
import { saveWorksheetRelationAttributes } from './relations';

const instanceBase = '/instances';
const getInstanceVersionAttachments = (id, versionId) =>
  `${instanceBase}/${id}/versions/${versionId}/files`;
const getInstanceURL = id => `${instanceBase}/${id}`;
const getVersionURL = (id, versionId) =>
  `${instanceBase}/${id}/versions/${versionId}`;
const instanceAttachmentsURL = id => `${instanceBase}/${id}/files`;
const instanceAttachmentURL = `${instanceBase}/{0}/files`;

export function useInstanceDetailsForSections(id, sections, versionId = null) {
  const attributes = getAttributeNames(sections);
  const { state, run } = useProtrakApi(
    { requestConfig: instanceDetailsPromise, id, attributes, versionId },
    { defer: false }
  );
  return { state, run };
}

export function useLifecycleDetails(instanceId, defer, versionId) {
  const { state, run } = useProtrakApi(
    { requestConfig: instanceDetailsPromise, id: instanceId, versionId },
    { defer: defer }
  );
  return { state, run };
}

export function useInstanceDetailsForFilter() {
  const { state, run } = useProtrakApi(
    { requestConfig: instanceDetailsPromise },
    { defer: true }
  );
  return [state, run];
}

export function useInstanceAttachments(instanceId, versionId, pageSize) {
  const {
    state,
    reload,
    nextPage,
    paginationState,
    fetchItems
  } = usePaginatedGet(
    {
      requestConfig: fetchInstanceAttachments,
      instanceId,
      versionId
    },
    { defer: true },
    pageSize
  );

  return { state, fetchItems, reload, nextPage, paginationState };
}

export function useInstanceImages(instanceId) {
  const { state, run } = useProtrakApi({
    requestConfig: fetchInstanceAttachments,
    instanceId,
    params: { fileType: 'Picture' }
  });
  return [state, run];
}

export function useInstancePromote({ instanceId, action, comment, onSuccess }) {
  const { state, run } = useProtrakApi(
    {
      promiseFn: promoteInstance,
      instanceId,
      action,
      comment
    },
    { onSuccess }
  );
  return [state, run];
}

export function useInstanceBulkPromote({ instanceId, action, comment, defer }) {
  const { state, run } = useProtrakApi(
    {
      promiseFn: promoteInstance,
      instanceId,
      action,
      comment
    },
    { defer: defer }
  );
  return [state, run];
}

export const removeInstanceUser = async ({
  instanceId,
  userId,
  authContext
}) => {
  return await protrakApiClient(
    `${instanceBase}/${instanceId}/users?userId=${userId}`,
    {
      method: 'DELETE'
    },
    authContext
  );
};

export const deleteInstanceAttachment = async ({
  instanceId,
  fileId,
  authContext
}) => {
  return await protrakApiClient(
    `${instanceBase}/${instanceId}/files/${fileId}`,
    {
      method: 'DELETE'
    },
    authContext
  );
};

export function useInstanceSaveAction(context) {
  const [state, run] = useUploadAndSaveInstance(
    context && context.instanceEditDispatch
  );

  const saveInstance = useCallback(
    args => {
      const showPromoteReminder = args && args.showPromoteReminder;
      const attachmentAttributes = getEditedAttachmentAttributes(context);
      run({ attachmentAttributes, context, showPromoteReminder });
    },
    [context, run]
  );

  return [state, saveInstance];
}

export function useInstanceAttachmentUpload(successCallback) {
  const { state, run } = useProtrakApi(
    {
      promiseFn: uploadAndCreateInstanceAttachment
    },
    { defer: true, onSuccess: successCallback }
  );
  return [state, run];
}

export function useInstanceCreateAction(context) {
  const [state, run] = useUploadAndCreateInstance(
    context && context.instanceEditDispatch
  );

  const createInstance = useCallback(
    ({ typeName }) => {
      const attachmentAttributes = getEditedAttachmentAttributes(context);
      run({ attachmentAttributes, context, typeName });
    },
    [context, run]
  );

  return [state, createInstance];
}

export function useSaveWorksheetInstance() {
  const { state, run } = useProtrakMultiApi(
    { promiseFn: saveWorksheetInstance },
    {
      defer: true
    }
  );
  return { state, run };
}

const saveWorksheetInstance = async (worksheetEditedInstance, authContext) => {
  let relationAttrResponse = {};
  let attrResponse = {};
  if (!isEmpty(worksheetEditedInstance.relationattributes)) {
    relationAttrResponse = await saveWorksheetRelationAttributes({
      worksheetEditedInstance,
      authContext
    });
  }

  if (!isEmpty(worksheetEditedInstance.editedValues)) {
    attrResponse = await saveWorksheetNormalAttributes({
      worksheetEditedInstance,
      authContext
    });
  }
  let response = { ...relationAttrResponse, ...attrResponse };
  return response;
};

export const useUploadAndSaveFile = () => {
  const { state, run } = useProtrakApi({
    promiseFn: uploadAndCreateInstanceAttachment
  });
  return [state, run];
};

export function useInstanceAttachmentUpdate(successCallback) {
  const { state, run } = useProtrakApi(
    {
      promiseFn: updateInstanceAttachment
    },
    { defer: true, onSuccess: successCallback }
  );
  return [state, run];
}

const addAttributesForDisplayCondition = (dc, attributes) => {
  if (!dc || !dc.rules || !dc.rules.length) {
    return;
  }
  dc.rules.forEach(rule => {
    rule.attributeFilterConditions.forEach(afc => {
      attributes.add(afc.attributeName);
    });
  });
};

const getAttributeNames = sections => {
  if (!sections || sections.length === 0) {
    return [];
  }

  let attributes = new Set();

  sections.forEach(section => {
    addAttributesForDisplayCondition(section.displayCondition, attributes);
    section.widgets.forEach(widget => {
      addAttributesForDisplayCondition(widget.displayCondition, attributes);
      widget.fields.forEach(field => {
        attributes.add(field.attributeName);
      });
    });
  });
  attributes.add('Geolocation');
  attributes.add('QRCode');
  return [...attributes];
};

const getNewInstanceFile = uploadedFileInfo => {
  return {
    fileId: uploadedFileInfo.fileId,
    fileName: uploadedFileInfo.name,
    type: uploadedFileInfo.type
  };
};

const instanceDetailsPromise = ({ id, attributes, versionId }) => {
  const url = versionId ? getVersionURL(id, versionId) : getInstanceURL(id);
  return {
    endpoint: url,
    config: {
      method: 'GET',
      params: { attributes }
    }
  };
};

const fetchInstanceAttachments = ({ instanceId, versionId, params }) => {
  const endpoint = versionId
    ? getInstanceVersionAttachments(instanceId, versionId)
    : instanceAttachmentsURL(instanceId);
  return {
    endpoint: endpoint,
    config: {
      params: { ...params },
      method: 'GET'
    }
  };
};

function useUploadAndSaveInstance(instanceEditDispatch) {
  const { state, run } = useProtrakApi(
    {
      promiseFn: uploadAndSaveInstance
    },
    {
      defer: true
    }
  );
  useEffect(() => {
    if (state.isFulfilled) {
      let payload = {
        modified: state.data
      };

      instanceEditDispatch({
        type: 'INST_SAVE_SUCCESS',
        payload: payload
      });
    } else if (state.isError) {
      instanceEditDispatch({
        type: 'INST_SAVE_ERROR',
        payload: state.data
      });
    }
  }, [state, instanceEditDispatch]);
  return [state, run];
}

function useUploadAndCreateInstance(instanceEditDispatch) {
  const { state, run } = useProtrakApi({
    promiseFn: uploadAndCreateInstance
  });
  useEffect(() => {
    if (state.isFulfilled && state.data) {
      instanceEditDispatch({
        type: 'INST_CREATE_SUCCESS',
        payload: state.data
      });
    } else if (state.isError) {
      instanceEditDispatch({
        type: 'INST_SAVE_ERROR',
        payload: state.data
      });
    }
  }, [state, instanceEditDispatch]);
  return [state, run];
}

const uploadAndSaveInstance = async ({
  attachmentAttributes,
  context,
  authContext
}) => {
  let editedValues = await initiateSave(
    attachmentAttributes,
    context,
    authContext
  );
  dispatchActionBasedOnEditedValues(
    editedValues,
    context,
    attachmentAttributes
  );
  const response = await saveAttributeValues({
    modified: context && context.instanceEditState.modified,
    instanceId: context && context.instanceEditState.instanceId,
    editedValues,
    authContext
  });

  return response;
};

const saveWorksheetNormalAttributes = async ({
  worksheetEditedInstance,
  authContext
}) => {
  let { modified, instanceId, editedValues } = worksheetEditedInstance;

  const response = await saveAttributeValues({
    modified,
    instanceId,
    editedValues,
    authContext
  });

  return response;
};

const saveAttributeValues = async ({
  modified,
  instanceId,
  editedValues,
  authContext
}) => {
  let modifiedDate = modified && modified.replace('Z', '');

  const url = `${instanceBase}?lastModified=${modifiedDate}`;
  const params = {
    id: instanceId,
    attributes: populateAttributeParams(editedValues)
  };

  const response = await protrakApiClient(
    url,
    {
      method: 'PUT',
      data: params
    },
    authContext
  );
  return response;
};

const uploadAndCreateInstance = async ({
  attachmentAttributes,
  context,
  typeName,
  authContext
}) => {
  let editedValues = await initiateSave(
    attachmentAttributes,
    context,
    authContext
  );
  dispatchActionBasedOnEditedValues(
    editedValues,
    context,
    attachmentAttributes
  );
  const { url, params } = getSaveParams(typeName, editedValues);
  if (!url) {
    return {};
  }
  const response = await protrakApiClient(
    url,
    {
      method: 'POST',
      data: params
    },
    authContext
  );
  return response;
};

const getSaveParams = (typeName, editedValues) => {
  if (!typeName) {
    return { url: null, params: null };
  }

  const url = instanceBase;

  const params = {
    instanceTypeName: typeName,
    attributes: populateAttributeParams(editedValues),
    lifecycle: populateLifecycleFromAttributes(editedValues)
  };

  return { url, params };
};

const populateLifecycleFromAttributes = editedValues => {
  let lifecycle = null;
  if (editedValues) {
    for (let value of Object.values(editedValues)) {
      if (value.type === 'Lifecycle') {
        lifecycle = { Name: value.textValue };
      }
    }
  }
  return lifecycle;
};

function dispatchActionBasedOnEditedValues(
  editedValues,
  context,
  attachmentAttributes
) {
  if (!editedValues) {
    context &&
      context.instanceEditDispatch({
        type: 'FILE_UPLOAD_ERROR',
        payload: { message: 'file not uploaded' }
      });
  } else if (attachmentAttributes.length > 0) {
    context &&
      context.instanceEditDispatch({
        type: 'FILE_UPLOAD_SUCCESS',
        payload: { editedValues: editedValues }
      });
  }
}

async function initiateSave(attachmentAttributes, context, authContext) {
  const fileCount = attachmentAttributes && attachmentAttributes.length;
  context &&
    context.instanceEditDispatch({
      type: 'INST_SAVE_PENDING',
      payload: { fileCount: fileCount }
    });
  let editedValues = await uploadAttachmentsAsync(
    attachmentAttributes,
    context,
    authContext
  );

  return editedValues;
}

const updateInstanceAttachment = async ({
  instanceId,
  attachmentInfo,
  newlyUploadedFileInfo,
  authContext
}) => {
  try {
    let url = instanceAttachmentURL.replace('{0}', instanceId);
    let uploadedFileData = await uploadFile(
      newlyUploadedFileInfo[0],
      authContext
    );
    let filType = getFileType(newlyUploadedFileInfo[0].name);
    let newlyUploadedFileData = getNewInstanceFile(uploadedFileData.data);
    newlyUploadedFileData.type = filType;
    newlyUploadedFileData.fileName = newlyUploadedFileInfo[0].name;

    const payload = { ...attachmentInfo, ...newlyUploadedFileData };
    const response = await protrakApiClient(
      url,
      {
        data: payload,
        method: 'PUT'
      },
      authContext
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const uploadAndCreateInstanceAttachment = async ({
  instanceId,
  fileData,
  authContext
}) => {
  try {
    let uploadedFileData = await uploadFile(fileData[0], authContext);
    let filType = getFileType(fileData[0].name);
    let payload = getNewInstanceFile(uploadedFileData.data);
    payload.type = filType;
    payload.fileName = fileData[0].name;
    payload.fileType = fileData[0].fileType;

    let url = instanceAttachmentURL.replace('{0}', instanceId);
    const response = await protrakApiClient(
      url,
      {
        data: payload,
        method: 'POST'
      },
      authContext
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const getUpdatedAttachmentAttributes = (
  existingAttributes,
  uploadedFileInfo
) => {
  let updatedAttributes = {};
  if (!uploadedFileInfo.length) {
    return existingAttributes;
  }

  updatedAttributes = Object.assign({}, ...uploadedFileInfo, null);

  return updatedAttributes;
};

const getAttachmentAttributeUploadPromise = async (
  attachmentAttribute,
  authContext
) => {
  let fileData = await uploadFile(attachmentAttribute.fileValue, authContext);

  let uploadedFileData = {};

  uploadedFileData[attachmentAttribute.name] = Object.assign({}, null, {
    name: attachmentAttribute.name,
    type: attachmentAttribute.type,
    canUpdate: true,
    fileValue: {
      fileId: fileData.data.fileId,
      fileName: attachmentAttribute.fileValue.name,
      type: getFileType(attachmentAttribute.fileValue.name)
    }
  });
  return uploadedFileData;
};

const uploadAttachmentsAsync = async (
  attachmentAttributes,
  context,
  authContext
) => {
  let fileData = [];
  try {
    if (context && context.instanceEditState.editedValues) {
      if (attachmentAttributes.length) {
        fileData = await Promise.all(
          attachmentAttributes.map(attachmentAttribute => {
            if (attachmentAttribute.fileValue.fileId) {
              return attachmentAttribute;
            }
            return getAttachmentAttributeUploadPromise(
              attachmentAttribute,
              authContext
            );
          })
        );
      }

      let uploadedAttachmentAttributes = null;
      if (fileData.length > 0) {
        uploadedAttachmentAttributes = getUpdatedAttachmentAttributes(
          context && context.instanceEditState.editedValues,
          fileData
        );
      }

      const newEditedValues = {
        ...context.instanceEditState.editedValues,
        ...uploadedAttachmentAttributes
      };

      return newEditedValues;
    }
  } catch (error) {
    throw error;
  }
};

export const promoteInstance = async ({
  instanceId,
  action,
  comment,
  authContext
}) => {
  const encodedComment = encodeURIComponent(comment);
  return await protrakApiClient(
    `${instanceBase}/${instanceId}/promote?actionName=${action}&comments=${encodedComment}`,
    {
      method: 'PUT'
    },
    authContext
  );
};
