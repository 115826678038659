import { createGlobalStyle } from 'styled-components';
import { css } from 'styled-components';

const fontFamilyStyle = css`
  font-family: ${props =>
    props.typography && props.typography.baseFontFamily
      ? props.typography.baseFontFamily
      : 'Arial, sans-serif'};
`;

export const GlobalStyle = createGlobalStyle`
html,
body {
  height: 100%;
  font-size: ${props =>
    props.typography && props.typography.baseFontSize
      ? props.typography.baseFontSize
      : '12px'};
  line-height: ${props =>
    props.typography && props.typography.lineHeight
      ? props.typography.lineHeight
      : '1.2rem'};
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:${props => props.theme.colorPalette.PRIMARY};
  background-color:${props => props.theme.colorPalette.PRIMARY_LIGHT};
  ${props => fontFamilyStyle};
}

a {
  color: var(--secondary);
  text-decoration: none;
  cursor: pointer;
}

button {
  ${props => fontFamilyStyle};
}

*{
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
*:hover::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 7px grey;
  border-radius: 10px;
}


::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}


::-webkit-scrollbar-thumb:hover {
  background: #888;
}



`;
