import React from 'react';
import { Box } from '../Box';
import styled from 'styled-components';

const Icon = ({
  imgSrc,
  iconClasses = [],
  SVG,
  filter,
  size = IconBoxSize.Medium,
  fontSize,
  colorToFill,
  ...props
}) => {
  return (
    <Box
      alignItems="center"
      justifyContent="center"
      height={size}
      width={size}
      {...props}
    >
      {imgSrc ? (
        <img src={imgSrc} alt="img icon" style={{ height: '100%' }} />
      ) : iconClasses.length ? (
        <I
          className={iconClasses.join(' ')}
          aria-hidden="true"
          fontSize={fontSize}
        />
      ) : null}

      {SVG ? (
        <SVG
          filter={filter}
          style={{
            height: '100%',
            fill: colorToFill ? colorToFill : 'default'
          }}
        />
      ) : null}
    </Box>
  );
};

export default Icon;

export const IconBoxSize = {
  Small: '0.5rem',
  Medium: '1rem',
  Large: '1.5rem',
  XLarge: '2rem'
};

const I = styled.i`
  &&& {
    color: ${props => props.color || props.theme.colorPalette.TextColor};
    font-size: ${props => props.fontSize || props.theme.fontVariants.text.size};
  }
`;
