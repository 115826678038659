export const delay = interval =>
  new Promise(resolve => setTimeout(resolve, interval));

export const poll = async (promiseFn, shouldResolve, interval = 200) => {
  const res = await promiseFn();
  if (shouldResolve(res.data)) {
    return res.data;
  }
  await delay(interval);
  const data = await poll(promiseFn, shouldResolve, interval);
  return data;
};
