import React from 'react';
import useTranslatedValue from '../../../Hooks/useTranslatedValue';
import { ActionSelector } from './ActionSelector';
import {
  TextArea,
  Box,
  RadioButton,
  H3,
  Text,
  H5
} from '../../../lib/components';
import { Prefix } from '../../../shared/Components/prefixSuffix';

export const PromoteDialog = ({
  promoteActions,
  handleChange,
  isPromoteFieldsDisabled,
  selectedAction,
  comments,
  setComments,
  setSelectedAction,
  selectedPromoteAction,
  isInline
}) => {
  const CommentsText = useTranslatedValue('COMMENTS');
  const ActionNameText = useTranslatedValue('SELECT_ACTION');
  const NextActionText = useTranslatedValue('NEXT_ACTION_MESSAGE');

  const renderContent = () => {
    if (isInline && !selectedPromoteAction) {
      return null;
    } else if (isInline && selectedPromoteAction) {
      return (
        <Text>
          {selectedPromoteAction.displayName || selectedPromoteAction.name}
        </Text>
      );
    } else if (!selectedPromoteAction) {
      return (
        <>
          <H5 color="SECONDARY_LIGHT">{NextActionText}</H5>
          {renderPromoteContent()}
        </>
      );
    } else {
      return (
        <Text>
          <Prefix value={'Selected action:'} />
          <Text />
          <H3>
            {selectedPromoteAction.displayName || selectedPromoteAction.name}
          </H3>
        </Text>
      );
    }
  };

  const renderPromoteContent = () => {
    if (promoteActions.length > 3) {
      return (
        <ActionSelector
          disable={isPromoteFieldsDisabled}
          promoteActions={promoteActions}
          selectedAction={selectedAction}
          onChange={selectedOption => {
            setSelectedAction(selectedOption);
          }}
        />
      );
    } else {
      return (
        <>
          <H3 margin="6px">{ActionNameText}</H3>
          {promoteActions.map((promoteAction, index) => {
            return (
              <Box
                key={index}
                position="relative"
                padding="5px "
                alignItems="center"
              >
                <RadioButton
                  value={promoteAction.name}
                  checked={
                    selectedAction
                      ? selectedAction.value === promoteAction.name
                      : null
                  }
                  onChange={handleChange}
                  title={promoteAction.name}
                  label={promoteAction.displayName || promoteAction.name}
                  disabled={isPromoteFieldsDisabled}
                />
              </Box>
            );
          })}
        </>
      );
    }
  };

  return (
    <Box margin="10px" direction="column">
      {renderContent()}
      <Box direction="column">
        <H3 margin="10px 0px">{CommentsText}</H3>
        <Box width="100%" alignSelf="center">
          <TextArea
            rows={5}
            value={comments}
            onEdit={setComments}
            disabled={isPromoteFieldsDisabled}
          />
        </Box>
      </Box>
    </Box>
  );
};

export const PromoteEnums = {
  DisplayType: {
    Confirmation: 'Confirmation',
    Reminder: 'Reminder'
  }
};
