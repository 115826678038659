import React, { useEffect, useContext, useState } from 'react';
import useWindowCloseWarning from '../../../Hooks/useWindowCloseWarning';
import AlertBox from '../../Common/AlertBox/AlertBox';
import InstanceLayout from '../../Layouts/InstanceLayout';
import InstanceEditContext from '../../../Contexts/InstaceEditContext';
import { CreateWidgetRenderer } from '../../Layouts/CreateWidgetRenderer';
import { SaveProgressModal } from '../../Layouts/SaveProgressModal';

export const InstanceLayoutPopup = ({
  config,
  onSuccess,
  isModalOpen,
  saveSuccessCallback,
  onCancel
}) => {
  const { instanceEditState } = useContext(InstanceEditContext);
  const [createdInstance, setCreatedInstance] = useState(null);

  let hasUnsavedChanges =
    instanceEditState.editedValues &&
    Object.keys(instanceEditState.editedValues).length > 0;

  useWindowCloseWarning(hasUnsavedChanges);

  if (
    instanceEditState.saveOperationState === 'pending' ||
    instanceEditState.saveOperationState === 'success'
  ) {
    hasUnsavedChanges = false;
  }

  useEffect(() => {
    if (instanceEditState.saveOperationState === 'success') {
      let successResponse = {
        name: instanceEditState.instance && instanceEditState.instance.name,
        id: instanceEditState.instance && instanceEditState.instance.id
      };
      setCreatedInstance(successResponse);
      onSuccess(successResponse);
    }
  }, [instanceEditState, onSuccess]);

  return (
    <>
      <AlertBox show={hasUnsavedChanges} />

      {isModalOpen ? (
        <SaveProgressModal
          instance={createdInstance}
          isModalOpen={isModalOpen}
          disablePromoteReminder={true}
          onCancel={onCancel}
          saveSuccessCallback={saveSuccessCallback}
        />
      ) : null}

      <InstanceLayout
        sections={config.sections}
        shouldFieldRender={() => true}
        editMode={true}
        widgetRenderer={(configur, fields, editMode) =>
          CreateWidgetRenderer(configur, fields, editMode)
        }
        details={true}
      />
    </>
  );
};

export default InstanceLayoutPopup;
