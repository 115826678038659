import React from 'react';
import { ExpressionGroup } from './ExpressionGroup';
import { defaultSelection } from '../../Core';

const FilterGroup = ({
  attributeGroup,
  fields,
  attributesFilter,
  onGroupModified,
  clearAttributeFilter,
  index,
  setInvalidExpressionPresent
}) => {
  const attrFilterConditionLength =
    attributeGroup &&
    attributeGroup.attributeFilterConditions &&
    attributeGroup.attributeFilterConditions.length;

  const onExpressionDelete = conditionIndex => {
    let newAttributeGroup = {
      ...attributeGroup
    };

    if (
      !attributesFilter &&
      conditionIndex === 0 &&
      attributeGroup.attributeFilterConditions.length === 1
    ) {
      clearAttributeFilter();
      return;
    } else if (
      attributesFilter &&
      attributesFilter.attributeGroups &&
      attributesFilter.attributeGroups.length === 1 &&
      conditionIndex === 0 &&
      attributeGroup.attributeFilterConditions.length === 1
    ) {
      clearAttributeFilter();
      return;
    } else {
      newAttributeGroup.attributeFilterConditions.splice(conditionIndex, 1);
    }

    onGroupModified(index, newAttributeGroup);
  };

  const onFilterConditionOperatorChanged = (
    newFilterCondition,
    conditionIndex
  ) => {
    let newAttributeGroup = {
      ...attributeGroup
    };

    newAttributeGroup.attributeFilterConditions[
      conditionIndex
    ] = newFilterCondition;

    newAttributeGroup.attributeFilterConditions.push({
      attributeName: defaultSelection,
      condition: defaultSelection,
      firstValue: '',
      operator: 'None'
    });

    onGroupModified(index, newAttributeGroup);
  };

  const onFilterConditionChanged = (filterCondition, filterConditionIndex) => {
    let newAttributeGroup = {
      ...attributeGroup
    };

    newAttributeGroup.attributeFilterConditions[
      filterConditionIndex
    ] = filterCondition;

    onGroupModified(index, newAttributeGroup);
  };

  return attributeGroup.attributeFilterConditions.map(
    (filterExpression, key) => {
      return (
        <ExpressionGroup
          key={key}
          filterExpression={filterExpression}
          attrFilterConditionLength={attrFilterConditionLength}
          fields={fields}
          onFilterConditionOperatorChanged={onFilterConditionOperatorChanged}
          onExpressionDelete={onExpressionDelete}
          onFilterConditionChanged={onFilterConditionChanged}
          index={key}
          setInvalidExpressionPresent={setInvalidExpressionPresent}
          showDelete={
            attributeGroup &&
            attributeGroup.attributeFilterConditions &&
            attributeGroup.attributeFilterConditions.length
          }
        />
      );
    }
  );
};

export default FilterGroup;
