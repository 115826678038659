import { Cell } from '../DataGrid/Cell';
import { theme, Box } from '../../../lib/components';
import Skeleton from 'react-loading-skeleton';
import { HeaderComponent } from './Header';

const MINIMUM_COLUMN_WIDTH = 100;
export const renderCell = (
  cellKey,
  column,
  columnIndex,
  item,
  itemIndex,
  isSelected
) => {
  if (column.Cell) {
    const cellRendererProps = {
      original: item,
      rowIndex: itemIndex,
      columnIndex: columnIndex
    };
    if (column.accessor) {
      cellRendererProps['value'] = item[column.accessor];
    }

    return (
      <Cell
        key={cellKey}
        style={{
          backgroundColor: isSelected ? theme.colorPalette['PRIMARY_LIGHT'] : ''
        }}
      >
        {column.Cell(cellRendererProps)}
      </Cell>
    );
  }
  if (column.accessor) {
    return (
      <Cell
        style={{
          backgroundColor: isSelected
            ? theme.colorPalette['PRIMARY_LIGHT']
            : '',
          width: column.width + 'px'
        }}
        key={cellKey}
      >
        {item[column.accessor]}
      </Cell>
    );
  }
  return null;
};

export const getColumnWidthOffset = (columns, windowWidth, widthType) => {
  if (widthType === 'px') {
    let width = 0;
    columns.forEach(column => {
      width += column.width;
    });
    if (width < windowWidth) {
      return (windowWidth - width) / columns.length;
    }
  }
  let percentageWidth = 0;
  columns.forEach(column => {
    percentageWidth += column.percentageWidth;
  });
  if (percentageWidth < 100) {
    //Mathematical correction to cover whole window
    return (101 - percentageWidth) / columns.length;
  }
  return 0;
};

export const convertDataToDesiredFormatForVirtualizedGrid = (
  data,
  columns,
  isRowSelected,
  previouslyConvertedData,
  keyAccessor
) => {
  const ids = new Set(previouslyConvertedData.map(d => d.id));
  const convertedData = [];
  let index = 0;
  if (data === undefined) {
    return convertedData;
  }
  data.forEach((row, rowIndex) => {
    if (ids.has(row.id)) {
      return;
    }
    const key = previouslyConvertedData.length + index;
    const converted = {
      key: key,
      id: key
    };
    const isSelected = isRowSelected && isRowSelected(row);

    const convertedCells = [];
    columns.forEach((column, i) => {
      const cell = (
        <Box
          display="block"
          height="100%"
          width={column.width + 'px'}
          justifyContent={column.WidthType === 'fixed' ? 'center' : ''}
        >
          {renderCell(row[column.keyAccessor], column, i, row, key, isSelected)}
        </Box>
      );
      converted[`${i}`] = <Box>{cell}</Box>;
      convertedCells.push(
        <Box width={column.width + 'px'} key={`${index} ${i}`}>
          {cell}
        </Box>
      );
    });
    converted[keyAccessor] = row[keyAccessor];
    convertedData.push(converted);
    index++;
  });
  return convertedData;
};

export const getColumnWidths = props => {
  const {
    column,
    widthType,
    minimumColumnWidth,
    dimensions,
    lengthOfColumns,
    columnWidthOffsetForUsingFullWindowWidth,
    columnWidthOffset
  } = props;

  if (widthType === 'px' && column) {
    if (!column.Width) {
      return (
        MINIMUM_COLUMN_WIDTH +
        columnWidthOffset +
        columnWidthOffsetForUsingFullWindowWidth
      );
    }

    if (column.WidthType === 'fixed') {
      return parseInt(column.Width, 10) + columnWidthOffset;
    }
    return (
      Math.max(
        parseInt(column.Width, 10) + columnWidthOffset,
        minimumColumnWidth
      ) + columnWidthOffsetForUsingFullWindowWidth
    );
  }
  if (dimensions && dimensions.width) {
    let columnOffset = 0;

    if (widthType === 'percentage' && column && column.percentageWidth) {
      return Math.max(
        (dimensions.width - columnOffset) *
        (column.percentageWidth + columnWidthOffset / 100),
        minimumColumnWidth
      );
    }
    return Math.max(dimensions.width / lengthOfColumns, minimumColumnWidth);
  }
  return minimumColumnWidth;
};

export const getColumnWidthsTotal = columns => {
  let columnWidthsTotal = 0;
  columns.forEach(c => {
    if (c.Width) {
      columnWidthsTotal = columnWidthsTotal + parseInt(c.Width, 10);
    } else {
      columnWidthsTotal = columnWidthsTotal + MINIMUM_COLUMN_WIDTH;
    }
  });
  return columnWidthsTotal;
};
export const EmptyState = ({ columns, loadingRows = 10 }) => {
  let rows = [];

  while (loadingRows > 0) {
    rows.push(
      columns.map((column, index) => (
        <Cell key={index}>
          <Skeleton />
        </Cell>
      ))
    );
    loadingRows--;
  }
  return rows.map(r => r);
};

const getColumnWidthOffsetForUsingFullWindowWidth = (
  allColumns,
  width,
  columns
) => {
  const columnWidthsTotal = getColumnWidthsTotal(allColumns);
  if (columnWidthsTotal >= width) {
    return 0;
  }
  if (columns) {
    return (width - columnWidthsTotal - 7) / columns.length;
  }
  return (width - columnWidthsTotal - 7) / allColumns.length;
};

export function convertColumnsToDesiredFormatForVirtualizedGrid(
  allColumns,
  dimensions,
  sortConfig = {},
  onSortApplied,
  columns
) {
  const convertedColumns = [];
  const columnWidthOffsetForUsingFullWindowWidth = getColumnWidthOffsetForUsingFullWindowWidth(
    allColumns,
    dimensions.width,
    columns
  );
  const columnWidthOffset = getColumnWidthOffset(
    allColumns,
    dimensions && dimensions.width,
    'px'
  );
  allColumns.forEach((column, index) => {
    const width = getColumnWidths({
      column: column,
      widthType: 'px',
      minimumColumnWidth: 100,
      dimensions: dimensions,
      lengthOfColumns: allColumns.length,
      columnWidthOffsetForUsingFullWindowWidth: columnWidthOffsetForUsingFullWindowWidth,
      columnWidthOffset: columnWidthOffset
    });
    convertedColumns.push({
      ...column,
      key: `${index}`,
      dataKey: `${index}`,
      width: width,
      headerRenderer: (
        <Box height="100%">
          <HeaderComponent
            columnIndex={index}
            columns={allColumns}
            columnIndex={index}
            sortConfig={sortConfig}
            onSortApplied={onSortApplied}
            style={{
              width: width
            }}
          />
        </Box>
      ),
      style: {
        padding: 0
      }
    });
  });
  return convertedColumns;
}

export const getNewlyLoadedData = (paginationState, data, isLoading, items) => {
  if ((paginationState.skip === 0 && isLoading) || !data) {
    return [];
  }
  if (data.skip === 0) {
    return data.items;
  }
  return data.items && data.items.slice(items.length);
};
