import React from 'react';
import classes from './DataGrid.module.css';
import classNames from 'classnames';
import { Box } from '../../../lib/components';

export function Cell({ children, classNamesToApply = {}, style }) {
  const appliedClasses = classNames({
    [classes['cell']]: true,
    ...classNamesToApply
  });
  return (
    <Box display="block" className={appliedClasses} style={style}>
      {children}
    </Box>
  );
}
