import React from 'react';
import { Box, Text } from '.';
import { theme } from './AppThemeProvider';

export const TimelineHeader = ({ heading }) => {
  return (
    <Box
      backgroundColor="PRIMARY_LIGHTER"
      width="10rem"
      height="2.8rem"
      alignItems="center"
      borderRadius="1.5rem"
    >
      <Box
        width="1.5rem"
        height="1.5rem"
        backgroundColor="SURFACE"
        borderRadius="50%"
        marginLeft="1rem"
        boxShadow="0px 4px 4px"
        boxShadowColor={theme.colorPalette.PRIMARY_LIGHT}
        style={{ zIndex: 0 }}
      />
      <Text
        fontWeight="500"
        paddingTop="0.15rem"
        style={{ marginLeft: '1rem' }}
      >
        {heading}
      </Text>
    </Box>
  );
};
