import DateTimePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import classnames from 'classnames';
import '../ProtrakDateTime/DatePicker.css';
import { MonthYearSelector } from '../MonthYearSelector';
import {
  useSettingsContext,
  mapToISODateFormat,
  parseDate,
  convertUTCToTenantTimeZone,
  convertTenantTimeZoneToUTC
} from '../../../Core';
import { format } from 'date-fns';

export function ProtrakDate({
  value: selectedValue,
  onEdit,
  placeholder,
  disabled = false
}) {
  const { settings } = useSettingsContext();
  const { dateFormat, timeZone } = settings.dateTimeFormat;

  let dateValue = selectedValue
    ? convertUTCToTenantTimeZone(parseDate(selectedValue, timeZone), timeZone)
    : null;

  const onDateChange = changedDate => {
    if (changedDate) {
      const onlyDate = format(changedDate, 'yyyy-MM-dd');
      onEdit(convertTenantTimeZoneToUTC(`${onlyDate} 00:00:00`, timeZone));
    } else {
      onEdit('');
    }
  };

  return (
    <div>
      <DateTimePicker
        className={classnames('form-input')}
        dateFormat={mapToISODateFormat(dateFormat)}
        fixedHeight
        placeholderText={placeholder}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled
        }) => (
          <MonthYearSelector
            date={date}
            changeYear={changeYear}
            changeMonth={changeMonth}
            decreaseMonth={decreaseMonth}
            increaseMonth={increaseMonth}
            prevMonthButtonDisabled={prevMonthButtonDisabled}
            nextMonthButtonDisabled={nextMonthButtonDisabled}
          />
        )}
        selected={dateValue}
        onChange={date => {
          onDateChange(date);
        }}
        disabled={disabled}
      />
    </div>
  );
}
