import { Box } from './Box';

export const CloneModalContainer = ({ children }) => {
  return (
    <Box
      direction={'column'}
      width={'90vw'}
      height={'95vh'}
      style={{ overflow: 'hidden' }}
    >
      {children}
    </Box>
  );
};
