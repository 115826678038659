import React, { useState, useMemo, useCallback, useEffect } from 'react';
import {
  Container,
  Spinner,
  Button,
  ButtonEnums,
  Box
} from '../../../lib/components';
import { SelectableDataGrid } from '../../Common/DataGrid';
import {
  validateAttributeFilter,
  isEmpty,
  generateStateFilterObj,
  generateAttributeFilterObj,
  useRelatedInstances,
  useTypeConfiguration
} from '../../../Core';
import {
  SelectionContextProvider,
  useGridSelectContext
} from '../../../Contexts/GridSelectionContext';
import Filter from '../../Filter';
import {
  GridHeaderLayout,
  VirtualizedGridRecordsToolbar
} from '../../Layouts/GridHeaderLayout';
import { getNewlyLoadedData } from '../../Common/VirtualizedDataGrid';
import ErrorDetails from '../../Common/ErrorDetails';

export const ReferenceRelatedInstanceList = props => {
  const {
    relatedInstances: selectedInstancesDetails,
    relatedTypeName,
    isMultiselect,
    successCallback,
    config
  } = props;

  const [filterState, setFilterState] = useState(config);
  const [showFilterPopup, setShowFilterPopup] = useState(false);

  const {
    state: relatedAttributes,
    run: fetchRelatedAttributes
  } = useTypeConfiguration(relatedTypeName);

  const applyFilter = (statefilter, attributefilter) => {
    setFilterState({
      stateFilter: statefilter,
      attributeFilter: attributefilter
    });
  };

  const toggleFilterPopup = () => {
    setShowFilterPopup(showPopup => !showPopup);
  };

  let validAttributeFilterGroups = useMemo(() => {
    return validateAttributeFilter(filterState.attributeFilter);
  }, [filterState.attributeFilter]);

  let stateFilterObject = useMemo(() => {
    return generateStateFilterObj(filterState.stateFilter);
  }, [filterState.stateFilter]);

  let attributeFilterObj = useMemo(() => {
    return generateAttributeFilterObj(validAttributeFilterGroups);
  }, [validAttributeFilterGroups]);

  const {
    state: instanceList,
    fetchItems,
    nextPage,
    paginationState,
    reload
  } = useRelatedInstances(
    relatedTypeName,
    0,
    config.pageSize,
    stateFilterObject,
    attributeFilterObj
  );

  useEffect(() => {
    if (showFilterPopup && relatedAttributes.isFulfilled === undefined) {
      fetchRelatedAttributes();
    }
  }, [fetchRelatedAttributes, relatedAttributes.isFulfilled, showFilterPopup]);

  useEffect(() => {
    fetchItems({
      stateFilter: stateFilterObject && stateFilterObject.stateFilter,
      attributeFilter: attributeFilterObj
    });
  }, [attributeFilterObj, fetchItems, stateFilterObject]);

  const defaultSelectedValues = selectedInstancesDetails || [];

  const onRowSelected = useCallback(
    (indices = []) => {
      let selectedInst = [];

      let freshSelections = indices.filter(
        i => !defaultSelectedValues.some(r => r.id === i.id)
      );
      instanceList.data &&
        instanceList.data.items.forEach(i => {
          if (freshSelections.some(r => r.id === i.id)) {
            selectedInst.push(i);
          }
        });

      if (selectedInst && selectedInst.length) {
        successCallback(selectedInst);
      }
    },
    [defaultSelectedValues, instanceList.data, successCallback]
  );

  const { data, isLoading } = instanceList;

  const [items, setItems] = useState([]);
  let totalData = data;
  const reloadGrid = () => {
    totalData = {};
    reload();
  };
  const getNextPage = (skip, take) => {
    setItems(totalData.items);
    nextPage();
  };

  function leftHeader() {
    return (
      <Button
        title="Filter"
        size={ButtonEnums.Size.Medium}
        appearance={ButtonEnums.Appearance.Default}
        onClick={toggleFilterPopup}
      >
        <i className="fas fa-filter save-icon" aria-hidden="true" />
      </Button>
    );
  }

  const RightHeader = () => {
    const { selectedItems = [] } = useGridSelectContext();
    return (
      <VirtualizedGridRecordsToolbar
        data={data}
        isLoading={isLoading}
        onReload={reloadGrid}
        selectedRowsCount={selectedItems && selectedItems.length}
      />
    );
  };

  const configuration = getRelatedInstanceConfig(
    filterState.stateFilter,
    config.stateFilter
  );

  const FilterProvider = () => {
    const { onResetSelections } = useGridSelectContext();

    if (relatedAttributes.isLoading || !relatedAttributes.data) {
      return (
        <Container>
          <Spinner small />
        </Container>
      );
    }

    const { isError } = relatedAttributes;

    if (isError) {
      return <ErrorDetails state={relatedAttributes} />;
    }

    return (
      <Filter
        attributeFilter={filterState.attributeFilter}
        stateFilter={configuration.stateFilter}
        fields={
          relatedAttributes &&
          relatedAttributes.data &&
          relatedAttributes.data.attributes
        }
        onFilterApplied={(defaultStateFilter, defaultAttributeFilter) => {
          applyFilter(defaultStateFilter, defaultAttributeFilter);
          onResetSelections();
        }}
        defaultConfig={config}
      />
    );
  };

  return (
    <>
      {isEmpty(instanceList) && (
        <Container>
          <Spinner small />
        </Container>
      )}

      {!isEmpty(instanceList) && instanceList.isError && (
        <Box margin="20px 0 20px 0" justifyContent="center">
          <ErrorDetails state={instanceList} />{' '}
        </Box>
      )}

      {!isEmpty(instanceList.data) && !instanceList.isError && (
        <SelectionContextProvider
          singleSelect={!isMultiselect}
          keyField={'id'}
          data={totalData && !isLoading ? totalData.items : []}
          defaultSelectedItems={defaultSelectedValues}
          onSelectionChanged={onRowSelected}
        >
          <GridHeaderLayout
            leftHeader={leftHeader()}
            rightHeader={<RightHeader />}
          />
          {showFilterPopup ? <FilterProvider /> : null}
          <Box height="50vh">
            <SelectableDataGrid
              isLoading={instanceList.isLoading}
              data={instanceList.data.items}
              columns={refTypeInstListColumnNames}
              singleSelect={!isMultiselect}
              paginationContext={paginationState}
              reload={reloadGrid}
              nextPage={getNextPage}
              data={getNewlyLoadedData(paginationState, data, isLoading, items)}
              totalCount={
                totalData && totalData.totalCount
                  ? totalData.totalCount
                  : data && data.totalCount
              }
            />
          </Box>
        </SelectionContextProvider>
      )}
    </>
  );
};

export default ReferenceRelatedInstanceList;

const refTypeInstListColumnNames = [
  {
    Header: <b>Name</b>,
    accessor: 'name' // String-based value accessors!
  },
  {
    Header: <b>State</b>,
    accessor: '',
    Cell: props => <span>{props.original.state.displayName}</span>
  }
];

const getRelatedInstanceConfig = (stateFilter = {}, stateFilterConfig) => {
  let stateFilterOption = { ...stateFilter };

  stateFilterOption.enforced = !!(
    stateFilterConfig && stateFilterConfig.states.length > 0
  );

  return {
    stateFilter: stateFilterOption
  };
};
